import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TextInput = (props) => {
  const { value, onChange, meta, onEnter } = props;
  const { t } = useTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState(false);

  const onChangeInput = (e) => {
    let new_value = e.target.value;
    onChange(e.target.value, meta.key);
    if (meta.regexp) {
      if (new_value) {
        let targetRegexp = new RegExp(meta.regexp, 'g');
        const isValidtion = targetRegexp.test(new_value);
        setError(!isValidtion);
      } else {
        setError(false);
      }
    }
    if (meta.equal) {
      console.log('meta.equal', meta.equal);
      if (meta.equal === new_value) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const buildInputType = () => {
    switch (meta.type) {
      case 'password': {
        if (passwordShow) {
          return 'text';
        } else {
          return 'password';
        }
      }
      case 'number': {
        return 'number';
      }
      default: {
        return 'text';
      }
    }
  };

  const buildInputClassname = () => {
    if (error) {
      return 'error';
    } else {
      return null;
    }
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article className='form-text-input' style={meta && meta.style ? { ...meta.style } : {}}>
      {meta.label && (
        <header>
          <span className='mt-4 font-bold'>{t(meta.label)}</span>
          {renderRequired()}
        </header>
      )}

      <div className='input__box'>
        <input
          className={buildInputClassname()}
          placeholder={t(meta.placeholder)}
          disabled={meta.disabled || meta.readOnly}
          maxLength={meta.maxLength}
          value={value || ''}
          onChange={onChangeInput}
          type={buildInputType()}
          onKeyPress={(e) => {
            // console.log(`Pressed keyCode ${e.key}`);
            if (e.key === 'Enter') {
              // Do code here
              e.preventDefault();
              if (onEnter) {
                onEnter();
              }
            }
          }}
        ></input>
        {(meta.key === 'password' || meta.key === 'passwordConfirm') && (
          <div className='eye__box'>
            <div
              className={passwordShow ? 'ico eye-off' : 'ico eye'}
              onClick={() => {
                setPasswordShow(!passwordShow);
              }}
            ></div>
          </div>
        )}
        {meta.unitLabel && (
          <div className='unit__box'>
            <span>{t(meta.unitLabel)}</span>
          </div>
        )}
      </div>

      {meta.helperText && (
        <div className='helper-text'>
          <p>{t(meta.helperText)}</p>
        </div>
      )}
    </article>
  );
};

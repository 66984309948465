import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import API from '../../api';
import { Button, SelectBox } from '../../components';

export const ModalReductionSelect = (props) => {
  const { onClose } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const REDUCTION = t('REDUCTION_PROCESS', { returnObjects: true });
  const [menus, setMenus] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    API.ReductionCert.GetProject()
      .then((res) => {
        const result = res.data;
        let new_menus = [];
        for (let i in result) {
          new_menus.push({
            value: result[i].seq,
            label: result[i].title,
          });
        }
        setMenus(new_menus);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  return (
    <article className='modal-reduction-select'>
      <header>
        <h4>{t('REDUCTION_CERT')}</h4>
        <div
          className='ico close-small'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='project__box'>
        <span>{REDUCTION['SELECT_BIZ_MSG']}</span>
        <SelectBox
          value={selectedProject}
          onChange={(value) => {
            setSelectedProject(value);
          }}
          meta={{
            style: { width: '100%', marginTop: 20 },
            menus: menus,
          }}
        ></SelectBox>
      </section>
      <section className='button__box'>
        <Button
          style={{ width: '180px', marginRight: 24 }}
          onClick={onClose}
          label={t('CANCEL')}
          type='secondary'
        ></Button>
        <Button
          disabled={!selectedProject}
          style={{ width: '180px', marginRight: 24 }}
          onClick={() => {
            API.ReductionCert.PostProject(selectedProject)
              .then((res) => {
                history.push('/reduction/writing/' + res.data.seq);
                onClose();
              })
              .catch((err) => {
                if (err.data.message === 'Invalid reduction cert status') {
                  alert(REDUCTION['CHECK_BIZ_MSG']);
                } else {
                  alert(err.data.message);
                }
              });
          }}
          label={t('OK')}
        ></Button>
      </section>
    </article>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLabel } from '../../hooks';
import { NoDataRow } from './NoDataRow';

export const TableAgencyList = (props) => {
  const { data, isKorean, rowClick } = props;

  const { t } = useTranslation();
  const { TABLE, STANDARD_THIRD_PARTY_PAGE, STANDARD_TYPE, AREA_TYPE } = useLabel();

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>
                <p className='py-1 whitespace-pre-wrap'>{STANDARD_THIRD_PARTY_PAGE['AGENCY_NAME']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 200 }}>
                <p className='py-1 whitespace-pre-wrap'>{STANDARD_THIRD_PARTY_PAGE['AGENCY_AREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 200 }}>
                <p className='py-1 whitespace-pre-wrap'>{STANDARD_THIRD_PARTY_PAGE['AGENCY_NUMBER']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 200 }}>
                <p className='py-1 whitespace-pre-wrap'>{STANDARD_THIRD_PARTY_PAGE['AGENCY_EXPIRATION_PERIOD']}</p>
              </TableCell>
              <TableCell align='center'>
                <p className='py-1 whitespace-pre-wrap'>{STANDARD_THIRD_PARTY_PAGE['AGENCY_CONTACT']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 200 }}>
                URL
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, idx) => (
                <TableRow
                  key={`agency-item-${idx}`}
                  className='cursor-pointer'
                  onClick={() => {
                    rowClick(row);
                  }}
                >
                  <TableCell align='center'>
                    <p className='whitespace-pre-wrap'>{isKorean ? row.nameKo : row.nameEn}</p>
                  </TableCell>
                  <TableCell align='center'>
                    <p className='whitespace-pre-wrap'>{isKorean ? row.areaKo : row.areaEn}</p>
                  </TableCell>
                  <TableCell align='center'>
                    <p className='whitespace-pre-wrap'>{isKorean ? row.certNumKo : row.certNumEn}</p>
                  </TableCell>
                  <TableCell align='center'>
                    <p className='whitespace-pre-wrap'>{isKorean ? row.periodKo : row.periodEn}</p>
                  </TableCell>
                  <TableCell align='center'>
                    <p className='whitespace-pre-wrap'>{isKorean ? row.addressKo : row.addressEn}</p>
                  </TableCell>
                  <TableCell align='center'>
                    <a
                      className='text-sm whitespace-pre-wrap break-all underline text-primary'
                      href={row.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {row.url}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { NoDataRow } from './NoDataRow';

export const TableProjectHistory = (props) => {
  const { data, callback } = props;
  const { t } = useTranslation();
  const renderDetailButton = (row) => {
    switch (row.status) {
      case 'WaitingForAuditReductionCert':
      case 'WaitingForAuditProject': {
        return '-';
      }
      default: {
        return (
          <button
            onClick={() => {
              callback('View', row);
            }}
            style={{ height: 30, minWidth: 56, borderRadius: 8, background: '#fff', border: 'solid 1px #161616' }}
          >
            {t('VIEW_DETAILS')}
          </button>
        );
      }
    }
  };

  const renderBuildStatusText = (row) => {
    switch (row.status) {
      case 'AuditLaunch':
      case 'WaitingForAuditLaunch':
      case 'AuditProject':
      case 'AuditReductionCert':
      case 'WaitingForAuditProject':
      case 'WaitingForAuditReductionCert': {
        return t(Handler.ENUM.ACTION.STATUS(row.status)) + '(' + row.degree + ')';
      }
      default: {
        return t(Handler.ENUM.ACTION.STATUS(row.status));
      }
    }
  };

  return (
    <article className='table-project-history'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 160 }}>
                NO
              </TableCell>
              <TableCell align='center' style={{ minWidth: 160 }}>
                {t('DATE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 430 }}>
                {t('STATUS')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 220 }}>
                {t('STATUS')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 160 }}>
                {t('VIEW_DETAILS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={`table-row-${row.seq}-${index}`}>
                  <TableCell align='center'>{row.seq}</TableCell>
                  <TableCell align='center'>{row.adminCommentDate}</TableCell>
                  <TableCell align='center'>{renderBuildStatusText(row)}</TableCell>
                  <TableCell align='center'>
                    {row.isNewVersion ? (
                      <div>
                        <span style={{ fontSize: 14 }}>{row.projectVersion}</span>
                        <button
                          style={{
                            marginLeft: 10,
                            height: 30,
                            minWidth: 56,
                            borderRadius: 8,
                            background: '#fff',
                            border: 'solid 1px #161616',
                          }}
                          onClick={() => {
                            callback('Document', row);
                          }}
                        >
                          {t('VIEW_DETAILS')}
                        </button>
                      </div>
                    ) : null}
                  </TableCell>
                  <TableCell align='center'>{renderDetailButton(row)}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={5} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

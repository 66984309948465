import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import ProcessRegistration from '../../styles/images/Frame 1261155399.svg';

const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 400,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
    fontSize: '15px',
  },
  button: {
    borderRadius: '0',
    height: 44,
    width: 215,
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px',
  },
};
export const ProgramDetailsPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { PROGRAM_DETAILS_PAGE } = useLabel();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('');
  const history = useHistory();
  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setActiveLink(sectionId);
    }
  };
  const handleButtonClick = () => {
    history.push('/program/details');
  };
  const handleSignUpClick = () => {
    history.push('/user/signup');
  };
  const handleInquiries = () => {
    history.push('/cs/contact_us');
  };

  const handleGotoFaq = () => {
    history.push('/cs/faq');
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.5, rootMargin: '0px -100px 0px 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);
  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 300,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: '700',
  };
  const goToDetailsPage = () => {
    window.location.href = '/program/details';
  };

  return (
    <article className='content h-full'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE] h-[395px]'>
          <PageHeader type='standard'>
            <div className='flex justify-between w-full'>
              <div>
                <h1 className='text-[36px]'>{`${PROGRAM_DETAILS_PAGE['TITLE']}`}</h1>
              </div>
              <div className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                  {PROGRAM_DETAILS_PAGE['DESCRIPTION']}
                </p>
                <p className='description mt-5 whitespace-pre-wrap leading-7'>
                  {PROGRAM_DETAILS_PAGE['DESCRIPTION_BOTTOM']}
                </p>
                <div className='mt-10'>
                  <Button
                    variant='contained'
                    style={{ borderRadius: '0', height: 56, width: 241, fontSize: '16px' }}
                    onClick={goToDetailsPage}
                  >
                    프로젝트 등록 및 발급절차.pdf
                  </Button>
                </div>
              </div>
            </div>
          </PageHeader>
        </article>
        <div className='row-page-row py-[80px] ' style={{ display: 'flex', top: '200px' }}>
          <div className='fixed-sidebar mr-12'>
            <nav className='fixed-sidebar' style={styles.sideBar}>
              <div
                style={{
                  borderRight: '1px solid #D7DAE2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingRight: 24,
                  width: 200,
                }}
              >
                <Button
                  onClick={() => handleNavClick('register-procedure')}
                  style={activeLink === 'principle' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('프로젝트 등록 절차')}
                </Button>
                <Button
                  onClick={() => handleNavClick('create-account')}
                  style={activeLink === 'create-account' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('계정 생성')}
                </Button>
                <Button
                  onClick={() => handleNavClick('pre-review')}
                  style={activeLink === 'pre-review' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('프로젝트 사전 검토')}
                </Button>
                <Button
                  onClick={() => handleNavClick('registration')}
                  style={activeLink === 'registration' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('프로젝트 등재')}
                </Button>

                <Button
                  onClick={() => handleNavClick('assessment')}
                  style={activeLink === 'assessment' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('프로젝트 타당성 평가')}
                </Button>
                <Button
                  onClick={() => handleNavClick('verification')}
                  style={activeLink === 'verification' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('프로젝트 검증')}
                </Button>
                <Button
                  onClick={() => handleNavClick('vrc-certification')}
                  style={activeLink === 'vrc-certification' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('VRC(크레딧) 발행을 위한 인증')}
                </Button>
                <Button
                  onClick={() => handleNavClick('vrc-issuance')}
                  style={activeLink === 'vrc-issuance' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('VRC(크레딧) 발행')}
                </Button>
                <Button
                  onClick={() => handleNavClick('faq')}
                  style={activeLink === 'faq' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('FAQ')}
                </Button>
              </div>
            </nav>
          </div>

          <div className='content-section w-full pl-5'>
            <section className='w-full py-[30px]'>
              <section id='register-procedure' data-anchor='register-procedure'>
                <article className='pb-12 '>
                  <div className='mb-8'>
                    <p className='mb-6 leading-8' style={{ fontWeight: '500', fontSize: '20px' }}>
                      프로젝트 등록 절차
                    </p>

                    <img src={ProcessRegistration} alt='프로세스 등록 절차' className='mb-8' />
                  </div>
                </article>
              </section>
              <section id='create-account' data-anchor='safeguard-principle'>
                <div className='mb-12'>
                  <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                    계정 생성
                  </p>
                  <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                    {PROGRAM_DETAILS_PAGE['CREATE_ACCOUNT']}
                  </p>
                  <div className=' flex justify-start '>
                    <Button variant='outlined' onClick={handleSignUpClick} style={{ ...styles.button, width: 154 }}>
                      계정 생성 하기 {<ArrowForwardIcon fontSize='small' />}
                    </Button>
                  </div>
                </div>
              </section>
              <section id='pre-review'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      프로젝트 사전 검토
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION']}
                    </p>
                    <p
                      className='whitespace-pre-wrap text-gray-700 leading-7 mb-5'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_2'] }}
                    ></p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_3']}
                    </p>
                    <div className='flex justify-start mb-10'>
                      <Button variant='outlined' style={{ ...styles.button, width: 213 }}>
                        프로젝트 계획서(PDD).docx
                      </Button>
                    </div>
                    <p
                      className='whitespace-pre-wrap text-gray-700 leading-7 mb-5'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_4'] }}
                    ></p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_5']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_6']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_7']}
                    </p>
                    <div className='flex justify-start mb-10'>
                      <Button variant='outlined' style={{ ...styles.button, width: 238 }}>
                        방법론 개발 및 등록 프로세스.pdf
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: 159 }}>
                        방법론 제안서.docx
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: 202 }}>
                        방법론 아이디어 노트.docx
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: 176 }}>
                        방법론 컨셉 노트.docx
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='registration'>
                <article className='pb-12 '>
                  <div className='mb-8'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      프로젝트 등재
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['SUB_DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['SUB_DESCRIPTION_1']}
                    </p>
                    <p
                      className='whitespace-pre-wrap text-gray-700 leading-7 mb-5'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{
                        __html: PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['SUB_DESCRIPTION_2'],
                      }}
                    ></p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 189 }}>
                        사전 검토 보고서.docx
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: 213 }}>
                        프로젝트 계획서(PDD).docx
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: 176 }}>
                        이해 상충 평가서.docx
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: 277 }}>
                        비영속성 및 리스크 관리 가이드라인.pdf
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='assessment'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      프로젝트 타당성 평가
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['SUB_DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['SUB_DESCRIPTION_1']}
                    </p>
                    <Button variant='outlined' style={{ ...styles.button, width: 189 }}>
                      타당성 평가 보고서.docx
                    </Button>
                  </div>
                </article>
              </section>
              <section id='verification'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6 ' style={{ fontWeight: '500', fontSize: '20px' }}>
                      프로젝트 검증
                    </p>
                    <p className='whitespace-pre-wrap leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['SUB_DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['SUB_DESCRIPTION_1']}
                    </p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 172 }}>
                        모니터링 보고서.docx
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='vrc-certification'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      VRC(크레딧) 발행을 위한 인증
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VRC_CERTIFICATION']['SUB']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VRC_CERTIFICATION']['SUB_DESCRIPTION']}
                    </p>
                  </div>
                </article>
              </section>
              <section id='vrc-issuance'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      VRC(크레딧) 발행
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VRC_ISSUANCE']['DESCRIPTION']}
                    </p>
                    <p
                      className='whitespace-pre-wrap text-gray-700 leading-7 mb-5'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['VRC_ISSUANCE']['SUB_DESCRIPTION'] }}
                    ></p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 219 }}>
                        마켓 플레이스 등록 양식.docx
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='faq'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      FAQ
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FAQ']}
                    </p>

                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 120 }} onClick={handleGotoFaq}>
                        이동하기 {<ArrowForwardIcon />}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
            </section>
          </div>
        </div>
      </article>
      <article className='bg-[#5D38E5] h-[320px]'>
        <div
          className='flex justify-center w-full '
          style={{ display: 'flex', alignSelf: 'center', justifyItems: 'center' }}
        >
          <h2 style={{ fontSize: '24px', fontWeight: 500, lineHeight: '26px' }} className='flex text-white mt-[80px]'>
            프로그램에 참여하고 싶으신가요?
          </h2>
        </div>
        <div className='flex justify-center'>
          <p className='mt-5 text-white' style={{ fontSize: '16px', lineHeight: '26px', fontWeight: 200 }}>
            탄소 중립을 향한 여정에 함께 참여하시고 싶으시면 언제든지 문의해주세요.
          </p>
        </div>
        <div className='flex justify-center mt-7'>
          <Button
            onClick={handleInquiries}
            variant='contained'
            sx={{ borderRadius: 0, width: 298, height: 56, backgroundColor: '#222222', fontSize: '16px' }}
          >
            문의하기 {<ArrowForwardIcon fontSize='medium' />}
          </Button>
        </div>
      </article>
    </article>
  );
};
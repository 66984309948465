import React, { useContext, useEffect, useState } from 'react';
import API from '../api';
import { ContentContext, UserContext } from '../contexts';

export const ContentProvider = (props) => {
  const { children } = props;
  const { userInfo } = useContext(UserContext);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [monitoringInfo, setMonitoringInfo] = useState(null);

  useEffect(() => {
    if (userInfo) {
      API.Common.GetBusinessType().then(
        (res) => {
          let result = res.data;
          console.log('GetBusinessType result', result);
          setBusinessInfo(result);
        },
        (err) => {
          console.log('err', err);
        },
      );
      API.Common.GetMethodology().then(
        (res) => {
          let result = res.data;
          console.log('GetMethodology result', result);
          setMethodologyInfo(result);
        },
        (err) => {
          console.log('err', err);
        },
      );
      API.Common.GetMonitoring().then(
        (res) => {
          let result = res.data;
          console.log('GetMonitoring result', result);
          setMonitoringInfo(result);
        },
        (err) => {
          console.log('err', err);
        },
      );
    }
  }, [userInfo]);

  return (
    <ContentContext.Provider
      value={{
        businessInfo: businessInfo,
        methodologyInfo: methodologyInfo,
        monitoringInfo: monitoringInfo,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

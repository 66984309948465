import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import {
  Button,
  DropdownInput,
  PageHeader,
  TableMethodology,
  TableMethodologyHistory,
  TablePaging,
} from '../../components';
import { SnackbarContext } from '../../contexts';
import { usePagination } from '../../hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 830,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
  borderRadius: 2,
  overflow: 'hidden',
};

export const MethodologyListPage = (props) => {
  const { updateSnackbar } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const METHODOLOGY = t('METHODOLOGY_STATUS', { returnObjects: true });
  const ROUTE_PATH = t('ROUTE_PATH', { returnObjects: true });
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [histroyList, setHistroyList] = useState(null);
  const [historyPage, totalHistoryPage, onHistoryPageChange, setHistoryPage, setTotalHistoryPage] = usePagination(
    1,
    10,
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log('page effect', page);
    if (page >= 0) {
      requestNewList();
    }
  }, [page]);

  useEffect(() => {
    if (selectedSlug) {
      console.log('historyPage', historyPage);
      if (historyPage) {
        requestNewHistoryList();
      }
    }
  }, [selectedSlug, historyPage]);

  useEffect(() => {
    if (histroyList && histroyList.length > 0 && historyPage > 0) {
      let modal_doc = document.getElementById('methodology-history__modal');
      if (modal_doc) {
        modal_doc.scrollTop = 0;
      }
    }
  }, [historyPage]);

  const requsetSearch = () => {
    requestNewList(0);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedSlug(null);
    setHistoryPage(1);
  };

  const requestNewList = (init_page) => {
    API.Methodology.GetList({
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
      type: searchType,
      keyword: search,
    }).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestNewHistoryList = (init_page) => {
    API.Methodology.GetHistoryList(selectedSlug, {
      page: init_page ? init_page : historyPage,
      limit: 2,
      ordering: 'DESC',
    }).then(
      (res) => {
        let result = res.data;
        console.log('GetHistoryList result', result);
        setHistroyList(result.content);
        setTotalHistoryPage(result.totalPages);
        setOpen(true);
      },
      (err) => {
        closeModal();
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const tableCallback = (type, value) => {
    console.log('tableCallback type', type);
    console.log('tableCallback value', value);

    if (type === 'selected_slug') {
      setSelectedSlug(value);
    }
  };

  return (
    <article className='basic-page'>
      <PageHeader type='methodology' title={ROUTE_PATH['METHODOLOGY_STATUS']} />
      <article className='content__wrapper'>
        <div className='search__container'>
          <DropdownInput
            value={search}
            menuValue={searchType}
            onChange={(value, key) => {
              setSearch(value);
            }}
            onMenuChange={(value, key) => {
              console.log('menu', value);
              setSearchType(value);
            }}
            onEnter={() => {
              requsetSearch();
            }}
            meta={{
              style: { width: '590px' },
              placeholder: t('MSG', { returnObjects: true })['ASK'],
              menus: [
                {
                  value: 'Title',
                  label: t('METHODOLOGY'),
                },
                {
                  value: 'Id',
                  label: METHODOLOGY['UNIQUE_NUMBER'],
                },
              ],
            }}
          ></DropdownInput>
          <Button
            style={{ width: '80px', height: '50px', marginLeft: 12 }}
            onClick={() => {
              requsetSearch();
            }}
            label={t('SEARCH')}
          ></Button>
        </div>
        <div className='border-grey-200'></div>
        <TableMethodology data={list} callback={tableCallback}></TableMethodology>
        <div style={{ height: 40 }}></div>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
      <Modal
        open={open}
        onClose={() => {
          closeModal();
        }}
      >
        <Box sx={style}>
          <article className='methodology-history__modal' id='methodology-history__modal'>
            <header>
              <h4>{METHODOLOGY['REVISION_HISTORY']}</h4>
              <div
                onClick={() => {
                  closeModal();
                }}
                className='ico close-small'
              ></div>
            </header>
            <div className='table__box'>
              <TableMethodologyHistory data={histroyList}></TableMethodologyHistory>
            </div>
            <TablePaging page={historyPage} totalPage={totalHistoryPage} onChange={onHistoryPageChange}></TablePaging>
          </article>
        </Box>
      </Modal>
    </article>
  );
};

import { Box, Button, Modal } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import { ContentRegistry, DetailDocument, Download, PageHeader, WriteConsultation } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const ConsultationDetailPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [intro, setIntro] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { slug } = useParams();
  const [initData, setInitData] = useState(null);
  const [docList, setDocList] = useState([]);
  const [currDoc, setCurrDoc] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const { LABEL, STANDARD_PAGE, REGISTRY_TERM_PAGE } = useLabel();

  const getRegistryDetailData = useCallback(
    (category, registrySeq, title) => {
      API.Registry.GetRegistryDetail(category, registrySeq)
        .then((res) => {
          setInitData(res.data);
          setDocList(Handler.genDocumentGroup(res.data.documents) || []);
          if (!title) setTitle(isKorean ? res.data?.title : res.data?.titleEn);
          setIntro({
            introOne: res.data.introOne,
            introTwo: res.data.introTwo,
          });
        })
        .catch((err) => {
          history.go(-1);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          return;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateSnackbar],
  );

  const getDocumentDetailData = useCallback(
    (category, title) => {
      API.Documents.GetDocuments(category)
        .then((res) => {
          const result = [...res.data];
          const latest = result.filter((e) => e.type === 'HISTORY').sort((a, b) => b.createdAt - a.createdAt)[0];
          const latestIdx = result.indexOf(latest);
          setCurrDoc(result[latestIdx]);
          if (!title) setTitle(isKorean ? result[latestIdx]?.title : result[latestIdx]?.titleEn);
          setIntro({
            introOne: category === 'standard' ? STANDARD_PAGE['DESCRIPTION'] : REGISTRY_TERM_PAGE['DESCRIPTION'],
            introTwo: '',
          });

          result.splice(latestIdx, 1);
          result.sort((a, b) => b.createdAt - a.createdAt);
          setDocList(Handler.genDocumentGroup(result));
        })
        .catch((err) => {
          history.go(-1);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          return;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateSnackbar],
  );

  useEffect(() => {
    if (slug && location?.search) {
      const params = Utils.ParamsToObject(location.search);
      if (params.category === 'standard' || params.category === 'terms') {
        getDocumentDetailData(params.category, params.title);
      } else {
        getRegistryDetailData(params.category, params.registrySeq, params.title);
      }
      setCategory(params.category);
      setTitle(params.title);
    }
  }, [location, slug]);

  const requestFeedback = useCallback(
    (body) => {
      API.Consultation.PostFeedback(slug, body)
        .then((res) => {
          setShowModal(false);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '논평이 등록되었습니다.',
          });
        })
        .catch((err) => {
          setShowModal(false);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          return;
        });
    },
    [slug, updateSnackbar],
  );

  return (
    <article className='basic-page'>
      <PageHeader
        type='consultation'
        parentPath='/consultation/list'
        parentText={t('CONSULTATION')}
        detail={LABEL['CONSULTATION_DETAIL']}
        title={title}
      ></PageHeader>
      <article className='content__wrapper'>
        {intro && (
          <article className='w-full my-4'>
            <p className='whitespace-pre-wrap text-gray-700 leading-6'>{intro?.introOne}</p>
            <p className='whitespace-pre-wrap text-gray-700 leading-6'>{intro?.introTwo}</p>
          </article>
        )}
        <section className='w-full mt-4 mb-16'>
          <Button variant='contained' onClick={() => setShowModal(true)}>
            {t('LEAVE_COMMENT')}
          </Button>
        </section>
        <ContentRegistry category={category} data={initData} />
        {currDoc && <Download {...currDoc} isKorean={isKorean} />}
        <section className='mt-12 w-full'>
          <h2 className='mb-4'>{t('DOCUMENTS')}</h2>
          {category && <DetailDocument docData={docList} type={category} />}
        </section>
      </article>
      <article className='mt-12'>
        <Button color='primary' variant='outlined' onClick={() => history.push('/consultation/list')}>
          {t('LIST')}
        </Button>
      </article>
      <Modal open={showModal} closeAfterTransition={true}>
        <Box>
          <WriteConsultation
            onClose={() => setShowModal(false)}
            category={category}
            request={(body) => requestFeedback(body)}
          />
        </Box>
      </Modal>
    </article>
  );
};

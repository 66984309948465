import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import React from 'react';

export const FileMultipleViewer = (props) => {
  const { value, meta } = props;

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article className='form-file-multiple-viewer'>
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}

      <div className='file__box'>
        {value &&
          value.length > 0 &&
          value.map((item, index) => {
            const fileName = item ? item.split('/')[item.split('/').length - 1] : '';

            return (
              <Button
                onClick={() => {
                  window.open(item, '_blank');
                }}
                size='large'
                variant='outlined'
                endIcon={<FileDownloadIcon />}
              >
                {fileName}
              </Button>
            );
          })}
      </div>
    </article>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Button, FileMultipleUploader, PageHeader, TextArea, TextInput } from '../../components';
import { SnackbarContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';

export const MethodologyRequestPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { ROUTE_PATH, METHODOLOGY_CONSULTATION: METHODOLOGY } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      title: '',
      content: '',
      files: [],
    },
    {
      title: {
        key: 'title',
        label: t('TITLE'),
        style: {
          gridColumn: '1/3',
        },
        required: true,
      },
      content: {
        key: 'content',
        label: t('CONTENT'),
        maxSize: 500,
        style: {
          gridColumn: '1/3',
        },
        required: true,
      },
      files: {
        key: 'files',
        type: 'file',
        label: t('FILE'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
    },
  );

  const requsetSubmit = () => {
    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.User.PostInquiry({ ...body, type: 'Methodology' })
        .then((res) => {
          history.push('/request_complete?type=mypage');
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: t('REQUIRED_INFO'),
      });
    }
  };

  return (
    <article className='basic-page'>
      <PageHeader type='methodology' title={ROUTE_PATH['CONSULTATION_REQUEST']} />
      <article className='content__wrapper'>
        <section className='guide-text__container'>
          <h1>{METHODOLOGY['ARE_YOU_PREPARING']}</h1>
          <h1 style={{ marginBottom: 10 }}>{METHODOLOGY['YOU_CAN_GET_HELP']}</h1>
          <span>{METHODOLOGY['WE_WILL_CONTACT_YOU']}</span>
        </section>
        <section className='form__container'>
          <ul className='grid'>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextArea value={formData.content} onChange={formChange} meta={formMeta.content}></TextArea>
            <FileMultipleUploader
              value={formData.files}
              onChange={formChange}
              meta={formMeta.files}
            ></FileMultipleUploader>
          </ul>
        </section>
        <div className='border-grey-200'></div>
        <section className='button__container'>
          <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('SUBMIT')}></Button>
        </section>
      </article>
    </article>
  );
};

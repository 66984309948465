import React, { useContext } from 'react';
import { TextInput, Button } from '../../components';
import Handler from '../../Handler';
import { useForm } from '../../hooks';
import { SnackbarContext } from '../../contexts';
import API from '../../api';
import { useHistory } from 'react-router-dom';

export const UserFindPwPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange] = useForm(
    {
      email: '',
      name: '',
    },
    {
      email: {
        key: 'email',
        style: {},
        className: 'round',
        required: true,
        placeholder: '이메일',
      },
      name: {
        key: 'name',
        style: {
          marginBottom: 12,
        },
        className: 'round',
        required: true,
        placeholder: '이름',
      },
    },
  );

  const requsetFinwPw = () => {
    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetLogin', body);

    if (body) {
      console.log('body', body);

      API.User.FindPassword(body).then(
        (res) => {
          let result = res.data;
          console.log('User FindPassword result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '이메일 확인 후 임시비밀번호로 재로그인 해주세요.',
          });
          history.push('/user/login');
        },
        (err) => {
          console.log('err.data', err.data);
          if (err.data.message === 'Invalid user name') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: '유효하지 않은 이름입니다.',
            });
          } else if (err.data.message === 'Not found user from email key') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: '유효하지 않은 이메일입니다.',
            });
          } else {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          }
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>비밀번호 찾기</h1>
          </header>
        </section>
        <section className='login-form__container'>
          <div className='guide__text'>
            <span>
              <em>회원가입 시 등록했던</em> 이름과 이메일 주소를 입력하시기 바랍니다.
            </span>
          </div>
          <TextInput
            value={formData.name}
            onChange={formChange}
            meta={formMeta.name}
            onEnter={requsetFinwPw}
          ></TextInput>
          <TextInput
            value={formData.email}
            onChange={formChange}
            meta={formMeta.email}
            onEnter={requsetFinwPw}
          ></TextInput>

          <Button
            style={{ width: '100%', marginTop: 28 }}
            onClick={() => {
              requsetFinwPw();
            }}
            label='비밀번호 찾기'
          ></Button>
        </section>
      </article>
    </article>
  );
};

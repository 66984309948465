import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import {
  BasicTab,
  Button,
  ContentProject,
  ModalProjectAction,
  ModalProjectView,
  SelectBox,
  Stepper,
  TablePaging,
  TableProjectHistory,
} from '../../components';
import { ContentContext, SnackbarContext, UserContext } from '../../contexts';
import { useForm, useLabel, usePagination } from '../../hooks';
import { SUBAREA } from '../../options';
import { docStyle, modalStyle } from '../../styles/style';

const renderTypeText = (value) => {
  if (value === 'New') return 'MOVE';
  if (value === 'Modify') return 'CHANGE';
  if (value === 'Move') return 'TRANSFER';
  return value;
};

export const ProjectDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { HOME_ICON } = useLabel();
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { methodologyInfo } = useContext(ContentContext);
  const { slug } = useParams();
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 10);
  const [initData, setInitData] = useState(null);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [step, setStep] = useState(0);
  const [ordering, setOrdering] = useState('DESC');

  //project data
  const projectFormData = {
    companyName: '',
    ceoName: '',
    businessNumber: '',
    businessFile: '',
    address: {
      addr1: '',
      addr2: '',
    },
    site: '',
    fax: '',
    name: '',
    phone: '',
    department: '',
    email: '',
    isExported: false,
    title: '',
    area: '',
    subArea: '',
    methodologySeq: '',
    purpose: '',
    content: '',
    location: {
      addr1: '',
      addr2: '',
    },
    workplaceImages: [],
    reductionBase: '',
    reductionBusiness: '',
    reductionLeak: '',
    reduction: '',
    monitoringType: '',
    monitoringPlan: '',
    beforeImages: [],
    afterImages: [],
    evidence: [],
    proofFile: '',
  };
  const projectFormMeta = {
    companyName: {
      key: 'companyName',
      label: t('COMPANY_NAME'),
      disabled: true,
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    ceoName: {
      key: 'ceoName',
      label: t('CEO_NAME'),
      disabled: true,
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    businessNumber: {
      key: 'businessNumber',
      label: t('BIZ_NUMBER'),
      disabled: true,
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    businessFile: {
      type: 'file',
      key: 'businessFile',
      label: t('BIZ_CERT'),
      path: 'project/editor',
      disabled: true,
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    address: {
      required: true,
      key: 'address',
      label: t('ADDRESS'),
      type: 'content',
      disabled: true,
      style: {
        gridColumn: '1/3',
      },
    },
    site: {
      key: 'site',
      disabled: true,
      label: t('HOMEPAGE'),
      style: {
        maxWidth: '420px',
      },
    },
    fax: {
      key: 'fax',
      label: t('FAX'),
      disabled: true,
      style: {
        maxWidth: '420px',
      },
    },
    name: {
      key: 'name',
      label: t('CONTACT_NAME'),
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    phone: {
      key: 'phone',
      label: t('MOBILE'),
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    department: {
      key: 'department',
      label: 'DEPARTMENT',
      required: false,
      style: {
        maxWidth: '420px',
      },
    },
    email: {
      key: 'email',
      label: 'EMAIL',
      required: true,
      style: {
        maxWidth: '420px',
      },
    },
    isExported: {
      key: 'isExported',
      style: {
        gridColumn: '1/3',
      },
      label: 'OTHER_ORG',
      subLabel: 'OTHER_ORG_MSG',
      required: true,
      menus: [
        {
          text: 'NO',
          label: 'NO',
          value: false,
        },
        {
          text: 'YES',
          label: 'YES',
          value: true,
        },
      ],
    },
    title: {
      key: 'title',
      label: 'PROJECT',
      required: true,
      style: {
        gridColumn: '1/3',
      },
    },
    area: {
      key: 'area',
      label: 'PROJECT_TYPE',
      required: true,
      disabled: true,
      style: {
        maxWidth: '420px',
      },
      menus: [
        {
          value: 'Removal',
          label: Handler.ENUM.AREA('Removal'),
        },
        {
          value: 'Reduction',
          label: Handler.ENUM.AREA('Reduction'),
        },
      ],
    },
    subArea: {
      key: 'subArea',
      label: 'DETAIL',
      required: true,
      disabled: true,
      style: {
        maxWidth: '420px',
      },
      menus: SUBAREA,
    },
    reductionStartDate: {
      key: 'reductionStartDate',
      label: 'REDUCTION_START_DATE',
      style: {
        maxWidth: '420px',
      },
      menus: [],
    },
    reductionEndDate: {
      key: 'reductionEndDate',
      label: 'REDUCTION_END_DATE',
      style: {
        maxWidth: '420px',
      },
      menus: [],
    },
    methodologySeq: {
      key: 'methodologySeq',
      label: 'METHODOLOGY',
      required: true,
      disabled: true,
      style: {
        gridColumn: '1/3',
      },
      value: '',
      menus: [],
    },
    purpose: {
      key: 'purpose',
      label: 'PURPOSE',
      maxSize: 500,
      style: {
        gridColumn: '1/3',
      },
      required: true,
    },
    content: {
      key: 'content',
      label: 'CONTENT',
      path: 'dummy',
      required: true,
      style: {
        gridColumn: '1/3',
      },
      className: 'content',
    },
    location: {
      required: true,
      key: 'location',
      label: 'BIZ_SITE',
      type: 'content',
      style: {
        gridColumn: '1/3',
      },
    },
    workplaceImages: {
      key: 'workplaceImages',
      type: 'image',
      label: 'BIZ_SITE_PHOTO',
      subLabel: 'MAXIMUM_ATTACHMENTS',
      path: 'dummy',
      max: 10,
      style: {
        gridColumn: '1/3',
      },
    },
    reductionBase: {
      key: 'reductionBase',
      label: 'BASELINE_EMISSION',
      type: 'number',
      className: 'unit',
      unitLabel: `tCO2-eq / year`,
      required: true,
      style: {},
    },
    reductionBusiness: {
      key: 'reductionBusiness',
      label: 'BIZ_EMISSION',
      type: 'number',
      className: 'unit',
      unitLabel: `tCO2-eq / year`,
      required: true,
      style: {},
    },
    reductionLeak: {
      key: 'reductionLeak',
      label: 'LEAKAGE',
      type: 'number',
      className: 'unit',
      unitLabel: `tCO2-eq / year`,
      required: true,
      style: {},
    },
    reduction: {
      key: 'reduction',
      label: 'GHG_REDUCTION_QUANTITY',
      type: 'number',
      className: 'unit',
      unitLabel: `tCO2-eq / year`,
      required: true,
      disabled: true,
      style: {},
    },
    monitoringType: {
      key: 'monitoringType',
      style: {
        gridColumn: '1/3',
      },
      label: 'MONITORING_PLAN_METHOD',
      // subLabel: `${PROJECT['']}\n
      // ${PROJECT['PAPER_MONITORING_MSG']}\n
      // ${PROJECT['SYSTEM_MONITORING_MSG']}`,
      required: true,
      menus: [
        {
          label: 'PAPER_MONITORING',
          value: 'Document',
        },
        {
          label: 'SYSTEM_MONITORING',
          value: 'System',
        },
      ],
    },
    monitoringPlan: {
      key: 'monitoringPlan',
      label: 'REDUCTION_MONITORING_PLAN_EXP',
      path: 'project/editor',
      required: true,
      type: 'content',
      style: {
        gridColumn: '1/3',
      },
    },
    beforeImages: {
      key: 'beforeImages',
      type: 'image',
      label: 'BEFORE_BIZ_IMAGE',
      subLabel: 'MAXIMUM_ATTACHMENTS',
      path: 'dummy',
      max: 10,
      style: {
        gridColumn: '1/3',
      },
    },
    afterImages: {
      key: 'afterImages',
      type: 'image',
      label: 'AFTER_BIZ_IMAGE',
      subLabel: 'MAXIMUM_ATTACHMENTS',
      path: 'dummy',
      max: 10,
      style: {
        gridColumn: '1/3',
      },
    },
    evidence: {
      key: 'evidence',
      label: [],
    },
    proofFile: {
      type: 'file',
      key: 'proofFile',
      label: 'BIZ_CERTIFICATION',
      path: 'project/editor',
      required: true,
      style: {
        gridColumn: '1/3',
      },
    },
  };

  const [formData, formMeta, setFormChange, setFormData, setFormMeta, setReset] = useForm(
    { ...projectFormData },
    { ...projectFormMeta },
  );
  const [contentMeta, setContentMeta] = useState({
    isSameAddress: false,
    methodologyYear: 0,
    termsAggree: false,
    termsList: [],
    methodologyTitle: '',
  });
  const [lastDocument, setLastDocument] = useState(null);

  // modal document content;
  const [
    selectedFormData,
    selectedFormMeta,
    selectedFormChange,
    setSelectedFormData,
    setSelectedFormMeta,
    selectedFormReset,
  ] = useForm({ ...projectFormData }, { ...projectFormMeta });
  const [selectedContentMeta, setSelectedContentMeta] = useState({
    isSameAddress: false,
    methodologyYear: 0,
    termsAggree: false,
    termsList: [],
    methodologyTitle: '',
  });
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [documentStep, setDocumentStep] = useState(0);

  //hooks
  useEffect(() => {
    if (slug && page) {
      requestNewList();
    }
  }, [slug, page]);

  useEffect(() => {
    if (slug && ordering) {
      refreshData(0);
      requestNewList(0);
    }
  }, [slug, ordering]);

  useEffect(() => {
    if (tabIndex === 0) {
      setStep(0);
    } else {
      setStep(tabIndex - 1);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (initData) {
      //초기 값 셋팅 후, 최신 버전 도큐멘트 가져오기
      if (initData.version) {
        API.Project.GetActionDoc(slug, { version: initData.version })
          .then((res) => {
            setLastDocument(res.data);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
      }
    }
  }, [initData, slug]);

  const getInitLocation = (data) => {
    if (data.lastProjectDoc.location) {
      return data.lastProjectDoc.location;
    } else {
      return {
        addr1: '',
        addr2: '',
      };
    }
  };

  useEffect(() => {
    if ((lastDocument || initData) && methodologyInfo) {
      // methodologyInfo <- 방법론 서버에서 불러옴
      let init_termsAgree = lastDocument?.projectDocTerms?.length > 0;
      let init_methodologyTitle = '';

      let originMethodology = null;
      if (initData.methodology) {
        originMethodology = Handler.getMethodologyBySeq(methodologyInfo, initData.methodology.seq);

        // 증빙서류 업데이트
        let evidence_label = Handler.buildEvidenceLabel(originMethodology, false);
        let new_formMeta = _.clone(formMeta);
        new_formMeta.evidence.label = evidence_label;
        setFormMeta(new_formMeta);

        init_methodologyTitle = initData.methodology.title;
      }

      // evidence check;
      let originEvidence = null;
      if (lastDocument?.projectDocEvidence) {
        originEvidence = Handler.getEvidenceByMapper(lastDocument?.projectDocEvidence, false);
      }

      //update logic
      let new_contentMeta = _.clone(contentMeta);
      new_contentMeta.termsAggree = init_termsAgree;
      new_contentMeta.methodologyTitle = init_methodologyTitle;
      new_contentMeta.isSameAddress = initData.isCheckedArea;

      // 약관 세팅
      let terms_value = Handler.buildTermsValue(originMethodology);
      new_contentMeta['termsList'] = terms_value;
      new_contentMeta['methodologyYear'] = originMethodology.expiredYear;
      new_contentMeta['methodologyTitle'] = originMethodology.title;
      setContentMeta(new_contentMeta);
      setFormData({
        ...formData,
        address: initData.user.address,
        companyName: initData.user.companyName,
        ceoName: initData.user.ceoName,
        businessNumber: initData.user.businessNumber,
        businessFile: initData.user.businessFile,
        site: initData.user.site,
        fax: initData.user.fax,
        name: initData.lastProjectDoc.name,
        phone: initData.lastProjectDoc.phone,
        department: initData.lastProjectDoc.department,
        email: initData.lastProjectDoc.email,
        isExported: !!initData.isExported && initData.isExported,
        title: initData.title,
        area: originMethodology ? originMethodology.area : '',
        subArea: originMethodology ? originMethodology.subArea : '',
        reductionStartDate: initData.reductionStartDate,
        reductionEndDate: initData.reductionEndDate,
        methodologySeq: originMethodology ? originMethodology.seq : '',
        purpose: initData.lastProjectDoc.purpose,
        content: initData.lastProjectDoc.content,
        isDomestic: initData.isDomestic,
        location: getInitLocation(initData),
        reductionBase: initData.lastProjectDoc.reductionBase,
        reductionBusiness: initData.lastProjectDoc.reductionBusiness,
        reductionLeak: initData.lastProjectDoc.reductionLeak,
        reduction: initData.lastProjectDoc.reduction,
        monitoringType: initData.monitoringType,
        monitoringPlan: initData.monitoringPlan,
        beforeImages: initData.lastProjectDoc.beforeImages ? initData.lastProjectDoc.beforeImages : [],
        afterImages: initData.lastProjectDoc.afterImages ? initData.lastProjectDoc.afterImages : [],
        evidence: originEvidence,
        workplaceImages: initData.workplaceImages ? initData.workplaceImages : [],
        exportedTitle: initData.exportedTitle,
        exportedOrgan: initData.exportedOrgan,
        exportedDate: initData.exportedDate,
      });
    }
  }, [lastDocument, initData, methodologyInfo]);

  useEffect(() => {
    if (selectedDocument && userInfo && methodologyInfo) {
      let init_termsAgree = selectedDocument?.projectDocTerms?.length > 0;
      let init_methodologyTitle = '';
      let originMethodology = null;
      if (selectedDocument.project && selectedDocument.project.methodology) {
        originMethodology = Handler.getMethodologyBySeq(methodologyInfo, selectedDocument.project.methodology.seq);

        //증빙서류 업데이트
        let evidence_label = Handler.buildEvidenceLabel(originMethodology, false);
        let new_formMeta = _.clone(formMeta);
        new_formMeta.evidence.label = evidence_label;
        setSelectedFormMeta(new_formMeta);
        init_methodologyTitle = selectedDocument.project.methodology.title;
      }

      // evidence check;
      let originEvidence = null;
      if (selectedDocument?.projectDocEvidence) {
        originEvidence = Handler.getEvidenceByMapper(selectedDocument?.projectDocEvidence, false);
      }
      console.log('originEvidence', originEvidence);

      //update logic
      let new_contentMeta = _.clone(selectedContentMeta);
      new_contentMeta.termsAggree = init_termsAgree;
      new_contentMeta.methodologyTitle = init_methodologyTitle;
      new_contentMeta.isSameAddress = selectedDocument.isCheckedArea;

      //setup terms
      let terms_value = Handler.buildTermsValue(originMethodology);
      console.log('terms_value', terms_value);
      new_contentMeta['termsList'] = terms_value;
      new_contentMeta['methodologyYear'] = originMethodology.expiredYear;
      new_contentMeta['methodologyTitle'] = originMethodology.title;
      console.log('selectedDocument', selectedDocument);
      setSelectedContentMeta(new_contentMeta);
      setSelectedFormData({
        ...selectedFormData,
        address: selectedDocument.project.user.address,
        companyName: selectedDocument.project.user.companyName,
        ceoName: selectedDocument.project.user.ceoName,
        businessNumber: selectedDocument.project.user.businessNumber,
        businessFile: selectedDocument.project.user.businessFile,
        site: selectedDocument.project.user.site,
        fax: selectedDocument.project.user.fax,
        name: selectedDocument.name,
        phone: selectedDocument.phone,
        department: selectedDocument.department,
        email: selectedDocument.email,
        isExported: selectedDocument.isExported,
        title: selectedDocument.title,
        area: originMethodology ? originMethodology.area : '',
        subArea: originMethodology ? originMethodology.subArea : '',
        reductionStartDate: selectedDocument.reductionStartDate,
        reductionEndDate: selectedDocument.reductionEndDate,
        methodologySeq: originMethodology ? originMethodology.seq : '',
        purpose: selectedDocument.purpose,
        content: selectedDocument.content,
        isDomestic: selectedDocument.isDomestic,
        location: getInitLocation(selectedDocument.project),
        reductionBase: selectedDocument.reductionBase,
        reductionBusiness: selectedDocument.reductionBusiness,
        reductionLeak: selectedDocument.reductionLeak,
        reduction: selectedDocument.reduction,
        monitoringType: selectedDocument.monitoringType,
        monitoringPlan: selectedDocument.monitoringPlan,
        beforeImages: selectedDocument.beforeImages ? selectedDocument.beforeImages : [],
        afterImages: selectedDocument.afterImages ? selectedDocument.afterImages : [],
        evidence: originEvidence,
        workplaceImages: selectedDocument.workplaceImages ? selectedDocument.workplaceImages : [],
        exportedTitle: selectedDocument.exportedTitle,
        exportedOrgan: selectedDocument.exportedOrgan,
        exportedDate: selectedDocument.exportedDate,
      });
    }
  }, [selectedDocument, userInfo, methodologyInfo]);

  useEffect(() => {
    /* 방법론 Select Box 목록 */
    let new_meta = _.clone(formMeta);
    if (formData.subArea && methodologyInfo) {
      let new_methodology_menus = Handler.buildMethodologyMenus(methodologyInfo, formData.area, formData.subArea);
      new_meta.methodologySeq.menus = new_methodology_menus;
    } else {
      new_meta.methodologySeq.menus = [];
    }
  }, [formData.area, formData.subArea, methodologyInfo]);

  useEffect(() => {
    if (documentStep > 0) {
      let modal_doc = document.getElementById('project-document__modal');
      if (modal_doc) {
        modal_doc.scrollTop = 0;
      }
    }
  }, [documentStep]);

  //event
  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  const tableCallback = (next_action, item) => {
    switch (next_action) {
      case 'View': {
        setSelectedAction(item);
        setViewOpen(true);
        break;
      }
      case 'ModifyWriting': {
        history.push('/project/writing/' + item.seq);
        break;
      }
      case 'RemoveWriting': {
        API.Project.PostNextAction(item.seq, {
          nextAction: 'RemoveWriting',
        })
          .then((res) => {
            closeActionModal();
            requestNewList();
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }

      case 'ModifyProject': {
        API.Project.PostModify(slug)
          .then((res) => {
            refreshData();
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'ImproveProjectStart': {
        API.Project.PostNextAction(slug, {
          nextAction: 'ImproveProjectStart',
        })
          .then((res) => {
            refreshData();
            closeActionModal();
            closeViewModal();
            requestNewList();
            setSelectedAction({
              status: 'RequestMoveWriting',
              seq: slug,
            });
            setViewOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'Document': {
        getNewDcoument(item.projectVersion);
        setDocumentOpen(true);
        break;
      }
      default: {
        setSelectedAction(item.lastProjectDoc.projectDocAction);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
    }
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeViewModal = () => {
    setViewOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeDocumnetModal = () => {
    setDocumentOpen(false);
    setDocumentStep(0);
  };

  // server
  const refreshData = () => {
    API.Project.GetDetail(slug).then(
      (res) => {
        setInitData(res.data);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requestNewList = (init_page) => {
    let params = {
      page: init_page ? init_page : page,
      limit: Handler.VARIABLES.PAGE_LIMIT,
      ordering: ordering,
    };
    API.Project.GetAction(slug, params).then(
      (res) => {
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const handleModalRequest = (body) => {
    API.Project.PostNextAction(slug, body).then(
      (res) => {
        closeActionModal();
        refreshData();
        requestNewList(0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  //project content function
  const onChangeContentMeta = (newValue, key) => {
    setContentMeta({ ...contentMeta, [key]: newValue });
  };

  //document modal content  function
  const getNewDcoument = (version) => {
    API.Project.GetActionDoc(slug, { version: version })
      .then((res) => {
        setSelectedDocument(res.data);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  //render
  if (!initData) {
    return null;
  }

  const renderStatus = (lastProjectDoc) => {
    const { createdAt, projectDocAction } = lastProjectDoc;
    const { status, degree } = projectDocAction;
    let status_text = t(Handler.ENUM.ACTION.STATUS(status));
    if (['AuditLaunch', 'AuditProject', 'WaitingForAuditLaunch', 'WaitingForAuditProject'].includes(status))
      status_text += `(${degree})`;
    return (
      <>
        <span>{status_text}</span>
        <em className='mt-1'>{Handler.getYYYYMMDDByUnix(createdAt)}</em>
      </>
    );
  };

  const renderJob = (item) => {
    const { nextActionTypeList } = item;
    const isMain = (next_action) => {
      if (
        next_action === 'ImproveProjectComplete' ||
        next_action === 'ImproveLaunchComplete' ||
        next_action === 'ImproveProjectStart'
      ) {
        return 'main';
      }
      return null;
    };

    return (
      <div className='button__box'>
        {nextActionTypeList &&
          nextActionTypeList.map((target_action, index) => {
            return (
              <button
                className={isMain(target_action)}
                onClick={(e) => {
                  e.stopPropagation();
                  tableCallback(target_action, item);
                }}
              >
                {t(Handler.ENUM.ACTION.NEXT(target_action))}
              </button>
            );
          })}
        {nextActionTypeList && nextActionTypeList.length === 0 && <span>-</span>}
      </div>
    );
  };

  return (
    <article className='basic-page'>
      <article className='project-header__container'>
        <div className='content__wrapper'>
          <section className='title__box'>
            <h1>{initData.title}</h1>
            <ul className='info'>
              <li>
                <span>{t('PROJECT_ID')}</span>
                <span className='value'>{initData.id}</span>
              </li>
              <li>
                <span>{t('PRICE')}</span>
                <span className='value'>
                  {initData.unitPrice ? Handler.numberWithCommas(initData.unitPrice) : '-'} / tCO2-eq
                </span>
              </li>
            </ul>
          </section>
          <section className='row__box'>
            <ul>
              <li>
                <header>
                  <span>{t('TYPE')}</span>
                </header>
                <div className='value'>
                  <span>{t(renderTypeText(initData.type))}</span>
                </div>
              </li>
              <li>
                <header>
                  <span>{t('VALID_START_DATE')}</span>
                </header>
                <div className='value'>
                  <span>{initData.startDate}</span>
                </div>
              </li>
              <li>
                <header>
                  <span>{t('VALID_END_DATE')}</span>
                </header>
                <div className='value'>
                  <span>{initData.endDate}</span>
                </div>
              </li>
              <li>
                <header>
                  <span>{t('METHODOLOGY')}</span>
                </header>
                <div className='value px-2'>
                  <span>{initData.methodology.title}</span>
                </div>
              </li>
              <li>
                <header>
                  <span>{t('REMAIN_REDUCTIONS')}</span>
                  <em>(tCO2-eq)</em>
                </header>
                <div className='value'>
                  {initData.reductionNow ? Handler.numberWithCommas(initData.reductionNow) : '-'}
                </div>
              </li>
              <li>
                <header>
                  <span>{t('CUMULATE_REDUCTIONS')}</span>
                  <em>(tCO2-eq)</em>
                </header>
                <div className='value'>
                  {initData.reductionReady ? Handler.numberWithCommas(initData.reductionReady) : '-'}
                </div>
              </li>
              <li>
                <header>
                  <span>{t('STATUS')}</span>
                  <em>({t('RECENT_DATE')})</em>
                </header>
                <div className='value'>{renderStatus(initData.lastProjectDoc)}</div>
              </li>
              <li>
                <header>
                  <span>{t('WORK')}</span>
                </header>
                <div className='value'>{renderJob(initData)}</div>
              </li>
            </ul>
          </section>
          <section className='button-list__box'>
            <div>
              <Button
                type='secondary'
                style={{ width: '96px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  history.push('/project/list');
                }}
                label={t('LIST')}
              ></Button>
            </div>
            <div>
              <Button
                disabled={initData.status !== 'LaunchApproved'}
                type='secondary'
                style={{ width: '180px', height: '50px', marginRight: 12 }}
                onClick={() => {
                  API.ReductionCert.PostProject(initData.seq).then(
                    (res) => {
                      let result = res.data;
                      console.log('GetProject result', result);
                      history.push('/reduction/writing/' + result.seq);
                    },
                    (err) => {
                      alert(err.data.message);
                    },
                  );
                }}
                label={HOME_ICON['REDUCTION']}
              ></Button>
              <Button
                disabled={initData.status !== 'LaunchApproved'}
                type='secondary'
                style={{ width: '180px', height: '50px', marginRight: 12 }}
                onClick={() => {
                  history.push('/credit/list');
                }}
                label={`${t('CREDIT')} ${t('STATUS')}`}
              ></Button>
              <Button
                disabled={initData.status !== 'LaunchApproved'}
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  window.open('https://pople.kr');
                }}
                label={t('MARKETPLACE')}
                ico='store'
              ></Button>
            </div>
          </section>
        </div>
      </article>
      <article className='content__wrapper'>
        <div style={{ height: 40 }}></div>
        <BasicTab
          index={tabIndex}
          meta={{
            menus: [t('HISTORY'), t('OVERVIEW'), t('ESTIMATED_DOCUMENT'), t('SUMMARY')],
          }}
          onChange={(value) => {
            setTabIndex(value);
          }}
        ></BasicTab>
        {tabIndex === 0 && (
          <>
            <div style={{ height: 20 }}></div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>{t('HISTORY')}</h2>
              <SelectBox
                value={ordering}
                onChange={(value) => {
                  setOrdering(value);
                }}
                meta={{
                  className: 'round',
                  style: { width: '120px' },
                  menus: [
                    {
                      value: 'DESC',
                      label: `${t('DATE')} ↓`,
                    },
                    {
                      value: 'ASC',
                      label: `${t('DATE')} ↑`,
                    },
                  ],
                }}
              ></SelectBox>
            </div>
            <div style={{ height: 20 }}></div>
            <TableProjectHistory data={list} rowClick={tableRowClick} callback={tableCallback}></TableProjectHistory>
            <div style={{ height: 40 }}></div>
            <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
          </>
        )}
        {tabIndex !== 0 && formData && (
          <ContentProject
            step={step}
            formData={formData}
            formMeta={formMeta}
            formChange={() => {}}
            setFormData={setFormData}
            setFormMeta={setFormMeta}
            contentMeta={contentMeta}
            contentMetaChange={() => {}}
            readOnly={true}
          ></ContentProject>
        )}
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalProjectAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalProjectAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={modalStyle}>
          <ModalProjectView
            action={selectedAction}
            onClose={closeViewModal}
            requestAction={tableCallback}
            actionDate={selectedAction?.adminCommentDate}
          ></ModalProjectView>
        </Box>
      </Modal>
      <Modal open={documentOpen} onClose={closeDocumnetModal}>
        <Box sx={docStyle}>
          <div className='project-document__modal' id='project-document__modal'>
            <section className='header__box'>
              <div>
                <h4>{t('VIEW_DOC')}</h4>
                <span>{selectedDocument && selectedDocument.version}</span>
              </div>
              <div>
                <div
                  className='ico close-small'
                  onClick={() => {
                    closeDocumnetModal();
                  }}
                ></div>
              </div>
            </section>
            <section className='step-left-button__box'>
              <div
                onClick={() => {
                  if (documentStep > 0) {
                    setDocumentStep(documentStep - 1);
                  }
                }}
              >
                <div className='ico forward'></div>
              </div>
            </section>
            <section className='step-right-button__box'>
              <div
                onClick={() => {
                  if (documentStep < 3) {
                    setDocumentStep(documentStep + 1);
                  }
                }}
              >
                <div className='ico backward'></div>
              </div>
            </section>
            <section className='document__box'>
              <Stepper
                meta={{ type: 'round', style: { marginTop: 40 } }}
                value={documentStep}
                label={[t('OVERVIEW'), t('ESTIMATED_DOCUMENT'), t('SUPPORTING_DOC'), t('FINAL')]}
                onChange={() => {}}
              ></Stepper>
              <ContentProject
                step={documentStep}
                formData={selectedFormData}
                formMeta={selectedFormMeta}
                formChange={() => {}}
                setFormData={setSelectedFormData}
                setFormMeta={setSelectedFormMeta}
                contentMeta={selectedContentMeta}
                contentMetaChange={() => {}}
                readOnly={true}
              ></ContentProject>
            </section>
          </div>
        </Box>
      </Modal>
    </article>
  );
};

import React from 'react';

export const PublicLayout = (props) => {
  const { children } = props;

  return (
    <div className='public-layout'>
      <section className='content__box'>{children}</section>
    </div>
  );
};

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { action } = useHistory();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (action === 'POP') return;
    if (document) document.getElementsByTagName('body')[0].scrollTo(0, 0);
  }, [action, pathname, search]);

  return null;
};

export default ScrollToTop;

import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import API from '../../api';
import { PageHeader, TableConsultationHistory } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const ConsultationHistoryListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState([]);
  const { BUTTON, CONSULTATION_HISTORY_PAGE } = useLabel();
  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  //server
  const requestNewList = () => {
    API.Consultation.GetHistoryList()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  //hook
  useEffect(() => {
    requestNewList();
  }, []);

  const tableCallback = (item) => {
    history.push(`/consultation/detail/${item.seq}`);
  };

  return (
    <article className='basic-page'>
      <PageHeader
        type='consultation'
        parentPath='/consultation/list'
        parentText={`${t('LIST')}`}
        detail={CONSULTATION_HISTORY_PAGE['TITLE']}
        title={CONSULTATION_HISTORY_PAGE['TITLE']}
      ></PageHeader>
      <article className='content__wrapper'>
        <section className='w-full flex justify-start text-left'>
          <p>{CONSULTATION_HISTORY_PAGE['DESCRIPTION']}</p>
        </section>
        <div style={{ height: 40 }}></div>
        <TableConsultationHistory
          data={list}
          isKorean={isKorean}
          rowClick={tableRowClick}
          callback={tableCallback}
        ></TableConsultationHistory>
        <article className='py-8'>
          <Button variant='contained' onClick={() => history.push('/consultation/list')}>
            {BUTTON['BACK']}
          </Button>
        </article>
      </article>
    </article>
  );
};

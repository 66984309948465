import styled from '@emotion/styled';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectMuiBox, TextInput } from '../../components';
import { AREAS, CREDIT_STATUS, SUBAREAS, VINTAGES } from '../../constants/menus';
import { useLabel } from '../../hooks';

export const ExcelDownloadButton = styled(Button)`
  border: 1px solid #1bc47d !important;
  span,
  svg {
    color: #1bc47d;
    font-weight: 700;
  }
`;

export const CertCheckbox = styled(Checkbox)`
  padding-right: 4px;
  &:hover {
    background: none;
  }
`;

export const SearchFilter = (props) => {
  const {
    placeholder = 'SEARCH_WORD',
    onChange,
    onSearch,
    onReset,
    onDownload,
    isProject = false,
    isVrc = false,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [areaType, setAreaType] = useState('');
  const [subAreaType, setSubAreaType] = useState('');
  const [mode, setMode] = useState('');
  const [projectLastDocActionStatus, setProjectLastDocActionStatus] = useState('');
  const [vintage, setVintage] = useState('');
  const [unit, setUnit] = useState('');
  const [checkedList, setCheckedList] = useState([]);
  const [isCertification, setIsCertification] = useState('');
  const { t } = useTranslation();
  const { BUTTON, TABLE, FILTER, TOOLTIPS } = useLabel();

  const handleSelectArea = useCallback((e) => {
    setSearchValue(e);
  }, []);

  const resetFilter = useCallback(
    (e) => {
      if (isVrc) {
        setMode('');
        setVintage('');
        setUnit('');
        setIsCertification([]);
        setCheckedList([]);
      }
      if (isProject) {
        setProjectLastDocActionStatus('');
      }
      setSearchValue('');
      setAreaType('');
      setSubAreaType('');
      onReset();
    },
    [isVrc, isProject, onReset],
  );

  useEffect(() => {
    const params = {};
    params.searchValue = searchValue;
    params.areaType = areaType;
    params.subAreaType = subAreaType;
    if (isVrc) {
      params.mode = mode;
      params.vintage = vintage;
      params.unit = unit;
      params.isCertification = isCertification;
    }
    if (isProject) {
      params.projectLastDocActionStatus = projectLastDocActionStatus;
    }
    onChange(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, areaType, subAreaType, mode, projectLastDocActionStatus, vintage, unit, isCertification]);

  const handleCheckbox = (value) => {
    if (checkedList.includes(value)) {
      const _arr = [...checkedList];
      _arr.splice(_arr.indexOf(value), 1);
      setCheckedList(_arr);
    } else {
      setCheckedList([...checkedList, value]);
    }
  };

  useEffect(() => {
    if (checkedList.includes('cert') && checkedList.includes('no-cert')) {
      setIsCertification('');
    } else if (checkedList.includes('cert')) {
      setIsCertification(true);
    } else if (checkedList.includes('no-cert')) {
      setIsCertification(false);
    } else {
      setIsCertification('');
    }
  }, [checkedList]);

  const renderForm = () => {
    if (isVrc) {
      return (
        <>
          <div className='input__box'>
            <TextInput
              value={searchValue}
              meta={{
                placeholder: FILTER[placeholder],
                style: {
                  width: 358,
                },
              }}
              onChange={handleSelectArea}
            />
          </div>
          <div className='flex justify-between w-full items-center'>
            <div className='flex items-center gap-2'>
              <SelectMuiBox
                width={150}
                placeholder={FILTER['AREA']}
                value={areaType}
                menus={AREAS}
                onChange={(val) => setAreaType(val)}
              />
              <SelectMuiBox
                placeholder={FILTER['SUBAREA']}
                value={subAreaType}
                menus={SUBAREAS}
                onChange={(val) => setSubAreaType(val)}
              />
              <SelectMuiBox
                width={150}
                placeholder={FILTER['VINTAGE']}
                value={vintage}
                menus={VINTAGES}
                onChange={(val) => setVintage(val)}
              />
              <SelectMuiBox
                width={150}
                placeholder={FILTER['STATUS']}
                value={mode}
                menus={CREDIT_STATUS}
                onChange={(val) => setMode(val)}
              />
              <div className='ml-4'>
                <FormControl className='flex' component='fieldset' variant='standard'>
                  <FormLabel component='legend'>
                    <Tooltip className='cursor-pointer' title={TOOLTIPS['ADDITIONAL_CERT']} placement='top' arrow>
                      <div className='flex items-center'>
                        <span className='text-sm text-gray-500'>{t('ADDITIONAL_CERT')}</span>
                        <InfoIcon className='-top-2 -right-4 text-gray-400' fontSize='10px' />
                      </div>
                    </Tooltip>
                  </FormLabel>
                  <FormGroup row={true}>
                    <FormControlLabel
                      label={TABLE['YES']}
                      value='start'
                      control={
                        <CertCheckbox
                          size='small'
                          checked={checkedList.includes('cert')}
                          onChange={(e) => handleCheckbox('cert')}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                    />
                    <FormControlLabel
                      label={TABLE['NO']}
                      value='start'
                      control={
                        <CertCheckbox
                          size='small'
                          checked={checkedList.includes('no-cert')}
                          onChange={(e) => handleCheckbox('no-cert')}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <div className='flex items-center gap-2'>
              <Button
                variant='contained'
                size='medium'
                disableElevation={true}
                className='flex items-center py-2 gap-2 h-11 w-24 rounded-md'
                onClick={() => onSearch()}
              >
                <span className='text-white'>{BUTTON['SEARCH']}</span>
              </Button>
              <Button
                variant='outlined'
                size='medium'
                className='flex items-center py-2 gap-2 h-11 w-24 rounded-md'
                onClick={() => resetFilter()}
              >
                <span className='text-primary'>{BUTTON['RESET']}</span>
              </Button>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className='flex justify-between'>
        <div className='input__box'>
          <TextInput
            value={searchValue}
            meta={{
              placeholder: FILTER[placeholder],
              style: {
                width: 358,
              },
            }}
            onChange={handleSelectArea}
          />
        </div>
        <div className='flex items-center gap-2'>
          <SelectMuiBox
            width={150}
            placeholder={FILTER['AREA']}
            value={areaType}
            menus={AREAS}
            onChange={(val) => setAreaType(val)}
          />
          <SelectMuiBox
            placeholder={FILTER['SUBAREA']}
            value={subAreaType}
            menus={SUBAREAS}
            onChange={(val) => setSubAreaType(val)}
          />
          <Button
            variant='contained'
            size='medium'
            disableElevation={true}
            className='flex items-center py-2 gap-2 h-11 w-24 rounded-md'
            onClick={() => onSearch()}
          >
            <span className='text-white'>{BUTTON['SEARCH']}</span>
          </Button>
          <Button
            variant='outlined'
            size='medium'
            className='flex items-center py-2 gap-2 h-11 w-24 rounded-md'
            onClick={() => resetFilter()}
          >
            <span className='text-primary'>{BUTTON['RESET']}</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <article className='w-full mt-8'>
      <form
        onKeyDownCapture={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
      >
        {renderForm()}
      </form>
      <div className='w-full flex justify-end mt-3 py-3 border-t border-solid border-gray-200'>
        {isVrc && (
          <ExcelDownloadButton
            variant='outlined'
            size='medium'
            color='grey'
            className='flex items-center py-2 gap-2 h-10 rounded-md border border-solid border-gray-500  '
            onClick={onDownload}
          >
            <span className='text-sm font-normal'>{BUTTON['LIST_DOWNLOAD']}</span>
            <FileDownloadOutlinedIcon color='grey.100' />
          </ExcelDownloadButton>
        )}
      </div>
    </article>
  );
};

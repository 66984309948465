import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from '../../api';
import { ContentCredit } from '../../components';
import { useForm } from '../../hooks';

const getSmallModeByMode = (mode) => {
  switch (mode) {
    case 'Issue': {
      return 'issue';
    }
    case 'Move': {
      return 'move';
    }
    case 'Offset': {
      return 'offset';
    }
    default: {
      return '';
    }
  }
};

export const PublicCreditPage = (props) => {
  const { seq, version, token, mode } = useParams();

  const history = useHistory();
  const searchObject = queryString.parse(history.location.search);
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      name: '',
      phone: '',
      email: '',
      team: '',
      //move
      assignorName: '',
      assignorPhone: '',
      assignorEmail: '',
      assignorTeam: '',
      assigneeUserSeq: '',
      assigneeName: '',
      assigneePhone: '',
      assigneeEmail: '',
      assigneeTeam: '',
      projectSeq: '',
      amount: '',
      reason: '', //reason은 offset도 포함
      reasonDetail: '',
      files: [],
    },
    {
      name: {
        key: 'name',
        label: '담당자명',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      phone: {
        key: 'phone',
        label: '연락처(휴대폰)',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      team: {
        key: 'team',
        label: '부서명',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      email: {
        key: 'email',
        label: '담당자 이메일',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      //move variables
      assignorName: {
        key: 'assignorName',
        label: '담당자명',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorPhone: {
        key: 'assignorPhone',
        label: '연락처(휴대폰)',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorTeam: {
        key: 'assignorTeam',
        label: '부서명',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      assignorEmail: {
        key: 'assignorEmail',
        label: '담당자 이메일',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeName: {
        key: 'assigneeName',
        label: '담당자명',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneePhone: {
        key: 'assigneePhone',
        label: '연락처(휴대폰)',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeTeam: {
        key: 'assigneeTeam',
        label: '부서명',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeEmail: {
        key: 'assigneeEmail',
        label: '담당자 이메일',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      projectSeq: {
        key: 'projectSeq',
        label: '사업명 선택',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        menus: [],
      },
      amount: {
        key: 'amount',
        label: '크레딧 이전 수량',
        required: true,
        style: {
          minWidth: '420px',
          maxWidth: '420px',
        },
      },
      reason: {
        key: 'reason',
        label: '이전 사유',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: '기업 인수/합병',
            label: '기업 인수/합병',
          },
          {
            value: '사업 이전',
            label: '사업 이전',
          },
          {
            value: '사업 참여에 따른 정산',
            label: '사업 참여에 따른 정산',
          },
          {
            value: '장외 거래',
            label: '장외 거래',
          },
          {
            value: '기타',
            label: '기타',
          },
        ],
      },
      reasonDetail: {
        key: 'reasonDetail',
        label: '이전 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      files: {
        key: 'files',
        label: '파일 첨부',
        style: {
          gridColumn: '1/3',
        },
      },
      //offset
      offsetReason: {
        key: 'reason',
        label: '상쇄 사유',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: '사업 이전',
            label: '사업 이전',
          },
          {
            value: '사업 중단/취소',
            label: '사업 중단/취소',
          },
          {
            value: '기업 인수/합병',
            label: '기업 인수/합병',
          },
          {
            value: '직접 입력',
            label: '직접 입력',
          },
        ],
      },
      reasonCustom: {
        key: 'reasonCustom',
        label: '상쇄 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    //issue
    requestCredit: null,
    //move
    reductionNow: null,
  });
  const [lastDocument, setLastDocument] = useState(null);

  useEffect(() => {
    console.log('searchObject!', searchObject);

    if (seq && version && token) {
      API.Public.GetCredit(seq, { version: version }, token).then(
        (res) => {
          let result = res.data;
          console.log('result', result);
          setLastDocument(result);
        },
        (err) => {
          alert(err.data.message);
        },
      );
    } else {
      // window.close();
    }
  }, []);

  useEffect(() => {
    if (lastDocument) {
      console.log('lastDocument', lastDocument);

      switch (mode) {
        case 'Issue': {
          let new_contentMeta = _.clone(contentMeta);
          new_contentMeta.companyName = lastDocument.zcCredit.project.user.companyName;
          new_contentMeta.businessNumber = lastDocument.zcCredit.project.user.businessNumber;
          new_contentMeta.ceoName = lastDocument.zcCredit.project.user.ceoName;
          new_contentMeta.phone = lastDocument.zcCredit.project.user.phone;
          new_contentMeta.address = lastDocument.zcCredit.project.user.address;
          new_contentMeta.projectTitle = lastDocument.zcCredit.project.title;
          new_contentMeta.monitorDegree = lastDocument.zcCredit.project.monitorDegree;
          new_contentMeta.requestCredit = lastDocument.amount;
          setContentMeta(new_contentMeta);

          let new_formData = _.clone(formData);
          new_formData.name = lastDocument.assignorName;
          new_formData.phone = lastDocument.assignorPhone;
          new_formData.team = lastDocument.assignorTeam;
          new_formData.email = lastDocument.assignorEmail;
          setFormData(new_formData);
          break;
        }
        case 'Move': {
          let new_contentMeta = _.clone(contentMeta);
          new_contentMeta.companyName = lastDocument.zcCredit.project.user.companyName;
          new_contentMeta.businessNumber = lastDocument.zcCredit.project.user.businessNumber;
          new_contentMeta.ceoName = lastDocument.zcCredit.project.user.ceoName;
          new_contentMeta.phone = lastDocument.zcCredit.project.user.phone;
          new_contentMeta.address = lastDocument.zcCredit.project.user.address;
          new_contentMeta.projectTitle = lastDocument.zcCredit.project.title;
          new_contentMeta.companyNameAssignee = lastDocument.assigneeUser.companyName;
          new_contentMeta.businessNumberAssignee = lastDocument.assigneeUser.businessNumber;
          new_contentMeta.ceoNameAssignee = lastDocument.assigneeUser.ceoName;
          new_contentMeta.phoneAssignee = lastDocument.assigneeUser.phone;
          new_contentMeta.addressAssignee = lastDocument.assigneeUser.address;
          setContentMeta(new_contentMeta);

          let new_formData = _.clone(formData);
          new_formData.assignorName = lastDocument.assignorName;
          new_formData.assignorEmail = lastDocument.assignorEmail;
          new_formData.assignorPhone = lastDocument.assignorPhone;
          new_formData.assignorTeam = lastDocument.assignorTeam;
          new_formData.assigneeName = lastDocument.assigneeName;
          new_formData.assigneePhone = lastDocument.assigneePhone;
          new_formData.assigneeEmail = lastDocument.assigneeEmail;
          new_formData.assigneeTeam = lastDocument.assigneeTeam;
          new_formData.files = lastDocument.moveFiles;
          new_formData.amount = lastDocument.amount;
          new_formData.reason = lastDocument.reason;
          new_formData.reasonDetail = lastDocument.reasonDetail;
          setFormData(new_formData);

          break;
        }
        case 'Offset': {
          let new_contentMeta = _.clone(contentMeta);
          new_contentMeta.companyName = lastDocument.zcCredit.project.user.companyName;
          new_contentMeta.businessNumber = lastDocument.zcCredit.project.user.businessNumber;
          new_contentMeta.ceoName = lastDocument.zcCredit.project.user.ceoName;
          new_contentMeta.address = lastDocument.zcCredit.project.user.address;
          new_contentMeta.phone = lastDocument.zcCredit.project.user.phone;
          new_contentMeta.projectTitle = lastDocument.zcCredit.project.title;
          new_contentMeta.amount = lastDocument.amount;

          setContentMeta(new_contentMeta);

          let new_formData = _.clone(formData);
          new_formData.name = lastDocument.assignorName;
          new_formData.email = lastDocument.assignorEmail;
          new_formData.phone = lastDocument.assignorPhone;
          new_formData.team = lastDocument.assignorTeam;
          new_formData.reason = lastDocument.reason;
          new_formData.reasonDetail = lastDocument.reasonDetail;
          setFormData(new_formData);
          break;
        }
        default: {
          console.log('not mode');
        }
      }
    }
  }, [lastDocument]);

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <ContentCredit
          mode={getSmallModeByMode(mode)}
          formData={formData}
          formMeta={formMeta}
          formChange={() => {}}
          setFormData={setFormData}
          setFormMeta={setFormMeta}
          contentMeta={contentMeta}
          contentMetaChange={() => {}}
          readOnly={true}
          type={null}
          callback={() => {}}
        ></ContentCredit>
      </article>
    </article>
  );
};

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'user/';

const Signup = (body) => {
  const url = BASE_URL + PATH + 'sign-up';
  return AxiosInstance.post(url, body);
};

const Signin = (body) => {
  const url = BASE_URL + PATH + 'sign-in';
  return AxiosInstance.post(url, body);
};

const Get = () => {
  const url = BASE_URL + 'user';
  return AxiosInstance.get(url);
};

const Put = (body) => {
  const url = BASE_URL + 'user';
  return AxiosInstance.put(url, body);
};

const FindPassword = (body) => {
  const url = BASE_URL + PATH + 'find-password';
  return AxiosInstance.put(url, body);
};

const ModifyPassword = (body) => {
  const url = BASE_URL + PATH + 'password';
  return AxiosInstance.put(url, body);
};

const GetInquiry = (params) => {
  const url = BASE_URL + 'inquiry' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetInquiryDetail = (seq) => {
  const url = BASE_URL + 'inquiry/' + seq;
  return AxiosInstance.get(url);
};

const PutInquiry = (seq, body) => {
  const url = BASE_URL + 'inquiry/' + seq;
  return AxiosInstance.put(url, body);
};

const DeleteInquiry = (seq) => {
  const url = BASE_URL + 'inquiry/' + seq;
  return AxiosInstance.delete(url);
};

const PostInquiry = (body) => {
  const url = BASE_URL + 'inquiry';
  return AxiosInstance.post(url, body);
};

const PostSignout = (body) => {
  const url = BASE_URL + PATH + 'sign-out';
  return AxiosInstance.post(url, body);
};

const PutSignupEmailResend = (body) => {
  const url = BASE_URL + PATH + 'sign-up/email-resend';
  return AxiosInstance.put(url, body);
};

const PutSignupCertification = (params) => {
  const url = BASE_URL + PATH + 'sign-up/certificate' + Utils.ObjectToParams(params);
  return AxiosInstance.put(url);
};

const PutIntegration = (body) => {
  const url = BASE_URL + PATH + 'integrate';
  return AxiosInstance.put(url, body);
};

// const Get = (slug) => {
//   const url = BASE_URL + PATH + slug;
//   return AxiosInstance.get(url);
// };

// const Post = (body) => {
//   const url = BASE_URL + PATH;
//   return AxiosInstance.post(url, body);
// };

// const Put = (slug, body) => {
//   const url = BASE_URL + PATH + slug;
//   return AxiosInstance.put(url, body);
// };

const User = {
  Signup: Signup,
  Signin: Signin,
  Get: Get,
  Put: Put,
  FindPassword: FindPassword,
  ModifyPassword: ModifyPassword,
  PostInquiry: PostInquiry,
  GetInquiry: GetInquiry,
  GetInquiryDetail: GetInquiryDetail,
  PostSignout: PostSignout,
  PutInquiry: PutInquiry,
  DeleteInquiry: DeleteInquiry,
  PutSignupEmailResend: PutSignupEmailResend,
  PutSignupCertification: PutSignupCertification,
  PutIntegration: PutIntegration,
};

export default User;

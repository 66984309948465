import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { ContentReduction, Stepper } from '../../components';
import { useForm } from '../../hooks';

export const PublicReductionPage = (props) => {
  const { seq, version, token } = useParams();

  const history = useHistory();
  const { t } = useTranslation();
  const searchObject = queryString.parse(history.location.search);
  const [step, setStep] = useState(0);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      isExported: false,
      title: '',
      projectId: '',
      location: null,
      workplaceImages: [],
      area: '',
      subArea: '',
      methodologySeq: '',
      startDate: '',
      endDate: '',
      monitorDegree: 0,
      reductionBase: '',
      reductionBusiness: '',
      reductionLeak: '',
      reduction: '',
      realReductionBase: '',
      realReductionBusiness: '',
      realReductionLeak: '',
      realReduction: '',
      monitoringType: '',
      monitoringPlan: '',
      realMonitorDesc: '',
      beforeImages: [],
      afterImages: [],
      evidence: [],
      additionalEvidence: [],
    },
    {
      isExported: {
        key: 'isExported',
        style: {
          gridColumn: '1/3',
        },
        label: '타 기관 등록(신청) 여부',
        required: true,
        disabled: true,
        menus: [
          {
            text: '없음',
            value: false,
          },
          {
            text: '있음',
            value: true,
          },
        ],
      },
      title: {
        key: 'title',
        label: '사업명',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      projectId: {
        key: 'projectId',
        label: '사업ID',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      area: {
        key: 'area',
        label: '사업 유형',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
      },
      subArea: {
        key: 'subArea',
        label: '사업 유형 상세',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      methodologySeq: {
        key: 'methodologySeq',
        label: '적용 방법론',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
        value: '',
        menus: [],
      },
      location: {
        required: true,
        disabled: true,
        key: 'location',
        label: '사업 시행 장소',
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      workplaceImages: {
        key: 'workplaceImages',
        type: 'image',
        label: '사업장 사진',
        subLabel: '첨부파일은 최대 10MB 가능합니다.',
        path: 'dummy',
        max: 10,
        readOnly: true,
        style: {
          gridColumn: '1/3',
        },
      },
      reductionBase: {
        key: 'reductionBase',
        label: '베이스라인 배출량 (흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionBusiness: {
        key: 'reductionBusiness',
        label: '사업 배출량 (흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionLeak: {
        key: 'reductionLeak',
        label: '누출량',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reduction: {
        key: 'reduction',
        label: '온실가스 감축량 (순흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBase: {
        key: 'realReductionBase',
        label: '베이스라인 배출량 (흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBusiness: {
        key: 'realReductionBusiness',
        label: '사업 배출량 (흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionLeak: {
        key: 'realReductionLeak',
        label: '누출량',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReduction: {
        key: 'realReduction',
        label: '온실가스 감축량 (순흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      monitoringType: {
        key: 'monitoringType',
        style: {
          gridColumn: '1/3',
        },
        label: '모니터링 방식 선택',
        disabled: true,
        required: true,
      },
      monitoringPlan: {
        key: 'monitoringPlan',
        label: '모니터링 계획 설명',
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
        disabled: true,
      },
      realMonitorDesc: {
        key: 'realMonitorDesc',
        label: '모니터링 기간 동안 실제 온실 가스 감축량',
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      beforeImages: {
        key: 'beforeImages',
        type: 'image',
        label: '사업 전 공정도',
        subLabel: '첨부파일은 최대 10MB 가능합니다.',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      afterImages: {
        key: 'afterImages',
        type: 'image',
        label: '사업 후 사진',
        subLabel: '첨부파일은 최대 10MB 가능합니다.',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      proofFile: {
        type: 'file',
        key: 'proofFile',
        label: '사업 인증서',
        path: 'project/editor',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      //new
      monitorDegree: {
        key: 'monitorDegree',
        label: '모니터링 차수',
        maxSize: 500,
        style: {
          maxWidth: '240px',
        },
        required: true,
        disabled: true,
      },
      startDate: {
        required: true,
        key: 'startDate',
        style: {
          maxWidth: '400px',
          minWidth: '400px',
        },
      },
      endDate: {
        required: true,
        key: 'endDate',
        style: {
          minWidth: '400px',
          maxWidth: '400px',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      additionalEvidence: {
        key: 'additionalEvidence',
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    businnessStartDate: '',
    certificationStartDate: '',
    certificationEndDate: '',
    termsAggree: true,
    termsList: [],
    minDate: null,
    maxDate: null,
    month: null,
    day: null,
    monitoringCalcFile: '',
  });
  const [lastDocument, setLastDocument] = useState(null);

  useEffect(() => {
    console.log('searchObject', searchObject);

    if (seq && version && token) {
      API.Public.GetReduction(seq, { version: version }, token).then(
        (res) => {
          let result = res.data;
          console.log('result', result);
          setLastDocument(result);
        },
        (err) => {
          alert(err.data.message);
        },
      );
    } else {
      // window.close();
    }

    API.Common.GetMethodology().then(
      (res) => {
        let result = res.data;
        console.log('GetMethodology result', result);
        setMethodologyInfo(result);
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  useEffect(() => {
    if (lastDocument) {
      console.log('lastDocument', lastDocument);

      //meta update
      let terms_value = Handler.buildTermsValue(lastDocument.reductionCert.project.methodology);
      console.log('terms_value', terms_value);
      setContentMeta({
        ...contentMeta,
        businnessStartDate: lastDocument.reductionCert.project.projectDate,
        certificationStartDate: lastDocument.reductionCert.project.startDate,
        certificationEndDate: lastDocument.reductionCert.project.endDate,
        termsList: terms_value,
        minDate: null,
        maxDate: null,
        monitoringCalcFile: lastDocument.reductionCert.project.methodology.monitoringCalcFile,
      });

      // 모니터링 업데이트
      let origin_monitoring = Handler.buildMonitoringValue(
        lastDocument.evidenceMapper,
        lastDocument.reductionCert.monitoringType,
        null,
        true,
      );
      let origin_monitoring_label = Handler.buildMonitoringLabel(
        lastDocument.evidenceMapper,
        lastDocument.reductionCert.monitoringType,
        null,
        true,
      );
      let new_fromMeta = _.clone(formMeta);
      new_fromMeta.evidence.label = origin_monitoring_label;

      setFormMeta(new_fromMeta);

      setFormData({
        ...formData,
        isExported: lastDocument.reductionCert.project.lastDoc.isExported,
        title: lastDocument.reductionCert.project.title,
        projectId: lastDocument.reductionCert.project.id,
        location: lastDocument.reductionCert.project.lastDoc.location,
        workplaceImages: lastDocument.reductionCert.project.lastDoc.workplaceImages,
        area: lastDocument.reductionCert.project.methodology.area,
        subArea: lastDocument.reductionCert.project.methodology.subArea,
        methodologySeq: lastDocument.reductionCert.project.methodology.title,
        startDate: Handler.dbCommaDateToDashDate(lastDocument.startDate),
        endDate: Handler.dbCommaDateToDashDate(lastDocument.endDate),
        monitorDegree: lastDocument.monitorDegree,
        reductionBase: lastDocument.reductionCert.reductionBase,
        reductionBusiness: lastDocument.reductionCert.reductionBusiness,
        reductionLeak: lastDocument.reductionCert.reductionLeak,
        reduction: lastDocument.reductionCert.reduction,
        realReductionBase: lastDocument.realReductionBase,
        realReductionBusiness: lastDocument.realReductionBusiness,
        realReductionLeak: lastDocument.realReductionLeak,
        realReduction: lastDocument.realReduction,
        monitoringType: lastDocument.monitoringType,
        monitoringPlan: lastDocument.monitoringPlan,
        realMonitorDesc: lastDocument.realMonitorDesc,
        beforeImages: lastDocument.beforeImages ? lastDocument.beforeImages : [],
        afterImages: lastDocument.afterImages ? lastDocument.afterImages : [],
        evidence: origin_monitoring,
        additionalEvidence: lastDocument.additionalEvidence ? lastDocument.additionalEvidence : [],
      });
    }
  }, [lastDocument]);

  return (
    <article className='public-page'>
      <article className='public-button__container'>
        <section className='step-left-button__box'>
          <div
            onClick={() => {
              if (step > 0) {
                setStep(step - 1);
              }
            }}
          >
            <div className='ico prev-big'></div>
          </div>
        </section>
        <section className='step-right-button__box'>
          <div
            onClick={() => {
              if (step < 3) {
                setStep(step + 1);
              }
            }}
          >
            <div className='ico next-big'></div>
          </div>
        </section>
      </article>
      <article className='content__wrapper'>
        <Stepper
          meta={{ type: 'round', style: { marginTop: 40 } }}
          value={step}
          label={['사업 요약 정보 확인', '모니터링 시스템', '기타 증빙자료']}
        ></Stepper>
        <ContentReduction
          step={step}
          formData={formData}
          formMeta={formMeta}
          formChange={formChange}
          setFormData={setFormData}
          setFormMeta={setFormMeta}
          contentMeta={contentMeta}
          contentMetaChange={() => {}}
          readOnly={true}
          type={''}
        ></ContentReduction>
      </article>
    </article>
  );
};

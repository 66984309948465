import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const Stepper = (props) => {
  const { value, label, meta } = props;
  const { t } = useTranslation()

  return (
    <article
      style={meta && meta.style ? { ...meta.style } : {}}
      className={Handler.BuildArticleClassname('form-stepper', meta)}
    >
      <ul>
        {label.map((item, index) => {
          return (
            <li key={item} className={index === value ? 'on' : ''}>
              <span>
                {index + 1}. {t(item)}
              </span>
            </li>
          );
        })}
      </ul>
    </article>
  );
};

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'service/';

const PostContact = (body) => {
  const url = BASE_URL + PATH + 'contact';

  return AxiosInstance.post(url, body);
};

const GetNoticeList = (params) => {
  const url = BASE_URL + 'notices' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetNoticeDetail = (id) => {
  const url = BASE_URL + 'notices/' + id;
  return AxiosInstance.get(url);
};

const GetFaqList = (params) => {
  const url = BASE_URL + 'faqs' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetFaqDetail = (id) => {
  const url = BASE_URL + 'faqs/' + id;
  return AxiosInstance.get(url);
};

const GetFaqInfo = () => {
  const url = BASE_URL + 'faqs/categories';
  return AxiosInstance.get(url);
};

const CS = {
  PostContact: PostContact,
  GetNoticeList: GetNoticeList,
  GetFaqList: GetFaqList,
  GetFaqInfo: GetFaqInfo,
};

export default CS;

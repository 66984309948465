import React from 'react';
import { ContentProvider, DialogProvider, LangProvider, SnackbarProvider, UserProvider } from './providers';

const Provider = (props) => {
  const { children } = props;

  return (
    <LangProvider>
      <SnackbarProvider>
        <DialogProvider>
          <UserProvider>
            <ContentProvider>{children}</ContentProvider>
          </UserProvider>
        </DialogProvider>
      </SnackbarProvider>
    </LangProvider>
  );
};

export default Provider;

import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectWrapper = styled(Select)`
  width: ${(props) => props.width + 'px'};
  height: 50px;
  border-radius: 8px;
  font-size: 14px;
  & > fieldset {
    border-color: #d1d5db;
  }
  em {
    font-size: inherit;
    color: #9ca3af;
  }
`;

export const SelectMuiBox = (props) => {
  const { value, onChange, menus, placeholder, width = 200 } = props;
  const { t } = useTranslation();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const menuListProps = useMemo(() => {
    return {
      PaperProps: {
        style: {
          maxHeight: 220,
          width: width,
          borderRadius: 10,
        },
      },
    };
  }, [width]);

  return (
    <article>
      <SelectWrapper width={width} value={value} displayEmpty onChange={handleChange} MenuProps={menuListProps}>
        <MenuItem disabled value=''>
          <em>{placeholder}</em>
        </MenuItem>
        {menus.map((el) => (
          <MenuItem key={el.value} value={el.value}>
            {t(el.label)}
          </MenuItem>
        ))}
      </SelectWrapper>
    </article>
  );
};

import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { useLabel } from '../../hooks';

export const TableConsultation = (props) => {
  const { data, isKorean, callback } = props;
  const { t } = useTranslation();
  const { REGISTRY_DETAIL, CONSULTATION_CATEGORY, AREA_TYPE, ERROR } = useLabel();

  return (
    <article>
      <ul>
        {data && data.length === 0 && (
          <li className='empty w-full flex justify-center'>
            <span>{ERROR['NO_DATA_FOUND']}</span>
          </li>
        )}

        {data &&
          data.map((item, index) => {
            const {
              seq,
              category,
              title,
              titleEn,
              startDate,
              endDate,
              methodologySeq,
              area,
              subArea,
              projectSeq,
              reductionExpect,
              createdAt,
              updatedAt,
            } = item;
            return (
              <li
                key={`table-row-method-${index}`}
                className='flex flex-col py-12 border-b border-gray-300 border-solid cursor-pointer'
                onClick={() => callback(item)}
              >
                <section className='flex justify-between'>
                  <article>
                    <div className='flex gap-2'>
                      <div className='bg-main-100 text-primary font-bold p-2 rounded-lg'>
                        {CONSULTATION_CATEGORY[category.toUpperCase()]}
                      </div>
                    </div>
                    <div className='title my-4'>
                      <h2>{isKorean ? title : titleEn}</h2>
                    </div>
                  </article>
                  <article>
                    <div className='w-10 text-center bg-black text-white p-2 font-bold text-xs rounded-3xl'>
                      D-{Handler.genDday(dayjs(), endDate)}
                    </div>
                  </article>
                </section>
                <section className=''>
                  {reductionExpect !== null && (
                    <div className='flex py-1'>
                      <span className='w-48 text-gray-500'>{t('ESTIMATED_REDUCTION')}</span>
                      <span className='value mr-1'>{reductionExpect}</span>
                      <span className='text-sm text-gray-500'>tCO₂eq</span>
                    </div>
                  )}
                  {area && subArea && (
                    <div className='flex py-1'>
                      <span className='w-48 text-gray-500'>{REGISTRY_DETAIL['AREA']}</span>
                      <span className='value'>
                        {AREA_TYPE[area.toUpperCase()]}
                        {' > '}
                        {AREA_TYPE[subArea.toUpperCase()]}
                      </span>
                    </div>
                  )}
                  <div className='flex py-1'>
                    <span className='w-48 text-gray-500'>{REGISTRY_DETAIL['CONSULTATION_PERIOD']}</span>
                    <span className='value'>{Handler.geni18nDatePeriod(isKorean, startDate, endDate)}</span>
                  </div>
                </section>
              </li>
            );
          })}
      </ul>
    </article>
  );
};

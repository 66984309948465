import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { BoardAccordion, Button, TablePaging, TextInput } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';

export const CsNoticePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { BUTTON } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [search, setSearch] = useState('');
  const searchObject = queryString.parse(history.location.search);
  const initSeq = searchObject.init;
  const { isKorean } = useContext(LangContext);
  const [reload, setReload] = useState(true);

  console.log('searchObject', searchObject);
  useEffect(() => {
    console.log('page effect', page);
    if (page >= 0) {
      requestNewList();
    }
  }, [page]);

  const requsetSearch = () => {
    requestNewList(0);
  };

  const requestNewList = (init_page) => {
    setReload(true);
    API.CS.GetNoticeList({
      keyword: search,
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
    }).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        setList(result.content);
        // setList([]);
        setTotalPage(result.totalPages);
        setReload(false);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        setReload(false);
      },
    );
  };

  console.log('list', list);

  return (
    <article className='basic-page'>
      {/* <PageHeader type='notice' title={t('NOTICE')} /> */}
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('NOTICE')}</h1>
          </header>
        </section>
        <section className='notice__container'>
          <div className='serach__box'>
            <TextInput
              value={search}
              onChange={(value, key) => {
                setSearch(value);
              }}
              onEnter={() => {
                requsetSearch();
              }}
              meta={{ style: { width: '446px' }, placeholder: t('SEARCH'), className: 'round' }}
            ></TextInput>
            <Button
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requsetSearch();
              }}
              label={BUTTON['SEARCH']}
            ></Button>
          </div>
          <div className='board__box'>
            {!reload && <BoardAccordion data={list} type={'notice'} init={initSeq}></BoardAccordion>}
          </div>
        </section>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
    </article>
  );
};

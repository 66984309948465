import { Button, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, StandardDownload } from '../../components';
import { RULE_FILE_LIST, STANDARD_FILES } from '../../constants';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 400,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
    fontSize: '15px',
  },
  button: {
    borderRadius: '0',
    height: 44,
    width: 215,
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px',
  },
};

export const DocumentPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { DOCUMENT_PAGE } = useLabel();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('');
  const ruleList = useMemo(() => {
    return STANDARD_DOCUMENT_PAGE['RULES']['REQUIREMENTS'].map((el, idx) => {
      return {
        title: el['TITLE'],
        description: el['DESCRIPTION'],
        fileName: isKorean ? RULE_FILE_LIST[idx].fileKo : RULE_FILE_LIST[idx].fileEn,
        downloadLabel: isKorean ? RULE_FILE_LIST[idx].downloadLabelKo : RULE_FILE_LIST[idx].downloadLabelEn,
        additionalFile: isKorean ? RULE_FILE_LIST[idx].additionalFileKo : RULE_FILE_LIST[idx].additionalFileEn,
        additionalDownloadLabel: isKorean
          ? RULE_FILE_LIST[idx].additionalDownloadLabelKo
          : RULE_FILE_LIST[idx].additionalDownloadLabelEn,
      };
    });
  }, [STANDARD_DOCUMENT_PAGE, isKorean]);
  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setActiveLink(sectionId);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.5, rootMargin: '0px -100px 0px 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);
  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 300,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: '700',
  };

  const [selectedTab, setSelectedTab] = useState('프로젝트');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <article className='basic-page'>
      <article className='basic-page bg-[#FAF9FE] h-[203px]'>
        <PageHeader className='flex w-full' type='document'>
          <div className='flex justify- w-full'>
            <h1 style={{ fontWeight: 600 }}>{`${DOCUMENT_PAGE['TITLE']}`}</h1>
          </div>
        </PageHeader>
      </article>
      <div className='py-[80px] px-[1200px]' style={{ display: 'flex', top: '200px' }}>
        <div className='fixed-sidebar mr-[100px]'>
          <nav className='fixed-sidebar' style={styles.sideBar}>
            <div
              style={{
                borderRight: '1px solid #D7DAE2',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                paddingRight: 24,
                width: 200,
              }}
            >
              <Button onClick={() => handleNavClick('requirements')}>{t('요구사항')}</Button>
              <Button
                onClick={() => handleNavClick('manual')}
                style={activeLink === 'manual' ? styles.activeLink : inactiveLinkStyle}
              >
                {t('매뉴얼')}
              </Button>
              <Button
                onClick={() => handleNavClick('policy')}
                style={activeLink === 'policy' ? styles.activeLink : inactiveLinkStyle}
              >
                {t('정책')}
              </Button>
              <Button
                onClick={() => handleNavClick('template')}
                style={activeLink === 'template' ? styles.activeLink : inactiveLinkStyle}
              >
                {t('템플릿 & 양식책')}
              </Button>
              <Button
                onClick={() => handleNavClick('other')}
                style={activeLink === 'other' ? styles.activeLink : inactiveLinkStyle}
              >
                {t('기타')}
              </Button>
            </div>
          </nav>
        </div>
        <div>
          <section className='w-full py-[20px]'>
            <section id='requirements' className='mb-[100px]'>
              <article className='flex '>
                <p className='text-[20px] mb-8'>{STANDARD_DOCUMENT_PAGE['RULES']['TITLE']}</p>
              </article>
              <div className='flex flex-wrap gap-4 w-[894px]'>
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[0].nameKo : STANDARD_FILES[0].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[2].nameKo : RULE_FILE_LIST[2].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />

                <StandardDownload
                  title={isKorean ? RULE_FILE_LIST[3].nameKo : RULE_FILE_LIST[3].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
                {/* {ruleList.map((e, idx) => (
                  <StandardDownload
                    key={`rule-${idx}`}
                    title={e.title}
                    // description={e.description}
                    file={e.fileName}
                    // downloadLabel={e.downloadLabel}
                    // additionalFile={e.additionalFile}
                    // additionalDownloadLabel={e.additionalDownloadLabel}
                  />
                ))} */}
              </div>
            </section>
            <section className='pb-12 mt-5 gap-4' id='manual'>
              <p className='text-[20px] mb-8'>{DOCUMENT_PAGE['MANUAL']['TITLE']}</p>
              <div className='mb-6 flex flex-wrap gap-4 w-[894px]'>
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[2].nameKo : STANDARD_FILES[2].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[1].nameKo : STANDARD_FILES[1].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
              </div>
            </section>
            <section className='mb-[100px] gap-5' id='policy'>
              <div className='mb-10 mt-10'>
                <p className='text-[20px] mb-8'>{DOCUMENT_PAGE['GOVERNANCE']['TITLE']}</p>
                <div className='flex flex-wrap space-between gap-4'>
                  <StandardDownload
                    title={isKorean ? STANDARD_FILES[0].nameKo : STANDARD_FILES[0].nameEn}
                    description={isKorean ? STANDARD_FILES[0].descriptionKo : STANDARD_FILES[0].descriptionEn}
                    file={isKorean ? STANDARD_FILES[0].fileKo : STANDARD_FILES[0].fileEn}
                    downloadLabel={isKorean ? STANDARD_FILES[0].downloadLabelKo : STANDARD_FILES[0].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[0].additionalFileKos : STANDARD_FILES[0].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[0].additionalDownloadLabelKo
                        : STANDARD_FILES[0].additionalDownloadLabelEn
                    }
                  />
                  <StandardDownload
                    title={isKorean ? STANDARD_FILES[1].nameKo : STANDARD_FILES[1].nameEn}
                    description={isKorean ? STANDARD_FILES[1].descriptionKo : STANDARD_FILES[1].descriptionEn}
                    file={isKorean ? STANDARD_FILES[1].fileKo : STANDARD_FILES[1].fileEn}
                    downloadLabel={isKorean ? STANDARD_FILES[1].downloadLabelKo : STANDARD_FILES[1].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[1].additionalFileKo : STANDARD_FILES[1].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[1].additionalDownloadLabelKo
                        : STANDARD_FILES[1].additionalDownloadLabelEn
                    }
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[6].nameKo : RULE_FILE_LIST[6].nameEn}
                    description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                    file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                    downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[2].additionalDownloadLabelKo
                        : STANDARD_FILES[2].additionalDownloadLabelEn
                    }
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[6].nameKo : RULE_FILE_LIST[6].nameEn}
                    description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                    file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                    downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[2].additionalDownloadLabelKo
                        : STANDARD_FILES[2].additionalDownloadLabelEn
                    }
                  />
                  <StandardDownload
                    title={isKorean ? RULE_FILE_LIST[6].nameKo : RULE_FILE_LIST[6].nameEn}
                    description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                    file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                    downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                    additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                    additionalDownloadLabel={
                      isKorean
                        ? STANDARD_FILES[2].additionalDownloadLabelKo
                        : STANDARD_FILES[2].additionalDownloadLabelEn
                    }
                  />
                </div>
              </div>
            </section>
            <section className='mb-[100px] gap-5' id='template'>
              <p className='text-[20px]'>{DOCUMENT_PAGE['TEMPLATE']}</p>
              <div className='flex justify-start mt-5' style={{}}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabClick}
                  aria-label='tabs example'
                  variant='fullWidth'
                  style={{ fontSize: '16px' }}
                >
                  <div
                    className='flex'
                    style={{
                      borderRight: '1px solid gray',
                      height: 20,
                      alignItems: 'center',
                      justifyContent: 'left',
                    }}
                  >
                    <Tab
                      label='프로젝트'
                      className={selectedTab === '프로젝트' ? 'active' : ''}
                      sx={{
                        color: selectedTab === '프로젝트' ? '#5D38E5' : '#999EAD',
                        fontWeight: 'bold',
                        textDecoration: selectedTab === '프로젝트' ? 'underline' : 'none',
                      }}
                      onClick={() => handleTabClick('프로젝트')}
                    ></Tab>
                  </div>

                  <div className='flex' style={{ borderRight: '1px solid gray', height: 20, alignItems: 'center' }}>
                    <Tab
                      label='방법론'
                      className={selectedTab === '방법론' ? 'active' : ''}
                      onClick={() => handleTabClick('방법론')}
                      sx={{
                        color: selectedTab === '방법론' ? '#5D38E5' : '#999EAD',
                        textDecoration: selectedTab === '방법론' ? 'underline' : 'none',
                      }}
                    ></Tab>
                  </div>
                  <div className='flex' style={{ height: 20, alignItems: 'center', fontWeight: 16 }}>
                    <Tab
                      label='그외'
                      className={selectedTab === '그외' ? 'active' : ''}
                      onClick={() => handleTabClick('그외')}
                      sx={{
                        color: selectedTab === '그외' ? '#5D38E5' : '#999EAD',
                        textDecoration: selectedTab === '그외' ? 'underline' : 'none',
                      }}
                    ></Tab>
                  </div>
                </Tabs>
              </div>
              <div className='flex' role='tabpanel' hidden={selectedTab !== '#999EAD'}>
                {selectedTab === '프로젝트' && (
                  <div className='flex mt-2 gap-4'>
                    <StandardDownload
                      title={isKorean ? RULE_FILE_LIST[6].nameKo : RULE_FILE_LIST[6].nameEn}
                      description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                      file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                      downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                      additionalFile={
                        isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn
                      }
                      additionalDownloadLabel={
                        isKorean
                          ? STANDARD_FILES[2].additionalDownloadLabelKo
                          : STANDARD_FILES[2].additionalDownloadLabelEn
                      }
                    />
                    <StandardDownload
                      title={isKorean ? RULE_FILE_LIST[6].nameKo : RULE_FILE_LIST[6].nameEn}
                      description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                      file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                      downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                      additionalFile={
                        isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn
                      }
                      additionalDownloadLabel={
                        isKorean
                          ? STANDARD_FILES[2].additionalDownloadLabelKo
                          : STANDARD_FILES[2].additionalDownloadLabelEn
                      }
                    />
                  </div>
                )}
                <div role='tabpanel' hidden={selectedTab !== '방법론'}>
                  {selectedTab === '방법론' && (
                    <div className='flex gap-4 mt-2'>
                      <StandardDownload
                        title={isKorean ? STANDARD_FILES[2].nameKo : STANDARD_FILES[2].nameEn}
                        description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                        file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                        downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                        additionalFile={
                          isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn
                        }
                        additionalDownloadLabel={
                          isKorean
                            ? STANDARD_FILES[2].additionalDownloadLabelKo
                            : STANDARD_FILES[2].additionalDownloadLabelEn
                        }
                      />
                      <StandardDownload
                        title={isKorean ? STANDARD_FILES[2].nameKo : STANDARD_FILES[2].nameEn}
                        description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                        file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                        downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                        additionalFile={
                          isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn
                        }
                        additionalDownloadLabel={
                          isKorean
                            ? STANDARD_FILES[2].additionalDownloadLabelKo
                            : STANDARD_FILES[2].additionalDownloadLabelEn
                        }
                      />
                    </div>
                  )}
                </div>
                <div role='tabpanel' hidden={selectedTab !== '그외'}>
                  {selectedTab === '그외' && (
                    <div className='flex gap-4 mt-2'>
                      <StandardDownload
                        title={isKorean ? STANDARD_FILES[0].nameKo : STANDARD_FILES[0].nameEn}
                        description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                        file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                        downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                        additionalFile={
                          isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn
                        }
                        additionalDownloadLabel={
                          isKorean
                            ? STANDARD_FILES[2].additionalDownloadLabelKo
                            : STANDARD_FILES[2].additionalDownloadLabelEn
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='flex flex-wrap mb-6 gap-4 w-[894px] '></div>
            </section>
            <section className='pb-12 gap-5' id='other'>
              <p className='text-[20px] mb-8'>{DOCUMENT_PAGE['OTHER']['TITLE']}</p>
              <div className='mb-6'>
                <StandardDownload
                  title={isKorean ? STANDARD_FILES[2].nameKo : STANDARD_FILES[2].nameEn}
                  description={isKorean ? STANDARD_FILES[2].descriptionKo : STANDARD_FILES[2].descriptionEn}
                  file={isKorean ? STANDARD_FILES[2].fileKo : STANDARD_FILES[2].fileEn}
                  downloadLabel={isKorean ? STANDARD_FILES[2].downloadLabelKo : STANDARD_FILES[2].downloadLabelEn}
                  additionalFile={isKorean ? STANDARD_FILES[2].additionalFileKo : STANDARD_FILES[2].additionalFileEn}
                  additionalDownloadLabel={
                    isKorean ? STANDARD_FILES[2].additionalDownloadLabelKo : STANDARD_FILES[2].additionalDownloadLabelEn
                  }
                />
              </div>
            </section>

            {/* <Divider /> */}
          </section>
        </div>
      </div>
    </article>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import { Button, CheckBox, TextInput } from '../../components';
import { LangContext, SnackbarContext, UserContext } from '../../contexts';
import { useForm } from '../../hooks';

export const UserLoginPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { updateAccessToken, updateRefreshToken } = useContext(UserContext);
  const { isKorean } = useContext(LangContext);
  const [formData, formMeta, formChange] = useForm(
    {
      email: '',
      password: '',
      keeping: false,
    },
    {
      email: {
        key: 'email',
        style: {
          marginBottom: 12,
        },
        className: 'round',
        required: true,
        placeholder: 'EMAIL_PLACEHOLDER',
      },
      password: {
        key: 'password',
        type: 'password',
        style: {},
        className: 'round',
        required: true,
        placeholder: 'PASSWORD_PLACEHOLDER',
      },
      keeping: {
        key: 'keeping',
        style: {},
        label: 'LOGGED_IN',
      },
    },
  );

  const requsetLogin = () => {
    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.User.Signin(body).then(
        (res) => {
          let result = res.data;
          updateAccessToken(result.accessToken);
          updateRefreshToken(result.refreshToken);
          Utils.SetStorage('keeping', formData.keeping);
          history.push('/');
        },
        (err) => {
          console.log('err.data', err.data);
          if (err.data.message === 'Invalid user status - Certification') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: isKorean ? '이메일 인증이 처리되지 않았습니다.' : err.data.message,
            });
          } else if (err.data.message === 'Invalid user status - ApproveRequired') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: isKorean ? '관리자 승인이 처리되지 않았습니다.' : err.data.message,
            });
            history.push('/user/signup_complete');
          } else if (err.data.message === 'Invalid user status - Disabled') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: isKorean ? '사용 불가능한 유저 아이디입니다.' : err.data.message,
            });
          } else if (err.data.message === 'Not found user') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: isKorean ? '유효하지 않은 이메일입니다.' : err.data.message,
            });
          } else if (err.data.message === 'Invalid password') {
            updateSnackbar({
              type: 'error',
              open: true,
              message: isKorean ? '비밀번호를 확인하세요.' : err.data.message,
            });
          } else if (
            err.data.message === '존재하지 않는 이메일입니다.' ||
            err.data.message === 'body, parameter, header 값의 길이나 형식을 다시 확인 바랍니다.'
          ) {
            updateSnackbar({
              type: 'error',
              open: true,
              message: isKorean ? err.data.message : 'The email does not exist.',
            });
          } else {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          }
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: isKorean ? '필수정보를 확인해주세요.' : 'Please check the required information.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('LOGIN')}</h1>
          </header>
        </section>
        <section className='login-form__container'>
          <TextInput
            value={formData.email}
            onChange={formChange}
            meta={formMeta.email}
            onEnter={requsetLogin}
          ></TextInput>
          <TextInput
            value={formData.password}
            onChange={formChange}
            meta={formMeta.password}
            onEnter={requsetLogin}
          ></TextInput>
          <Button
            style={{ width: '100%', marginTop: 28 }}
            onClick={() => {
              requsetLogin();
            }}
            label={t('LOGIN')}
          ></Button>
          <div className='option__box'>
            <CheckBox value={formData.keeping} onChange={formChange} meta={formMeta.keeping}></CheckBox>
            <ul>
              <li
                onClick={() => {
                  history.push('/user/find_pw');
                }}
              >
                <span>{t('FIND_PW')}</span>
              </li>
              <li
                onClick={() => {
                  history.push('/user/signup_guide');
                }}
              >
                <span>{t('SIGN_UP')}</span>
              </li>
            </ul>
          </div>
        </section>
      </article>
    </article>
  );
};

export const AREAS = [
  // {
  //   label: 'ALL',
  //   value: 'all',
  // },
  {
    label: 'REMOVAL',
    value: 'Removal',
  },
  {
    label: 'REDUCTION',
    value: 'Reduction',
  },
];

export const SUBAREAS = [
  // {
  //   label: 'ALL',
  //   value: 'all',
  // },
  {
    label: 'FORESTRY',
    value: 'Forestry',
  },
  {
    label: 'BIOCHAR',
    value: 'BioChar',
  },
  {
    label: 'SOIL',
    value: 'SoilCarbon',
  },
  {
    label: 'DAC',
    value: 'DAC',
  },
  {
    label: 'RENEWABLEENERGY',
    value: 'RenewableEnergy',
  },
  {
    label: 'EMOBILITY',
    value: 'EMobility',
  },
  {
    label: 'RECYCLE',
    value: 'Recycle',
  },
  {
    label: 'REPLACEELEMENT',
    value: 'ReplaceElement',
  },
  {
    label: 'ENERGYCONVERSION',
    value: 'EnergyConversion',
  },
  {
    label: 'CHEMICAL',
    value: 'Chemical',
  },
  {
    label: 'AGRICULTURE',
    value: 'Agriculture',
  },
  {
    label: 'CCS',
    value: 'CCS',
  },
  {
    label: 'SECONDHAND',
    value: 'SecondHand',
  },
  {
    label: 'REPLACEVEHICLE',
    value: 'ReplaceVehicle',
  },
  {
    label: 'SERVICE',
    value: 'Service',
  },
  {
    label: 'ETC',
    value: 'Etc',
  },
];

export const PROJECT_STATUS = [
  // {
  //   label: 'ALL',
  //   value: 'all',
  // },
  {
    label: 'NEW',
    value: 'NEW',
  },
  {
    label: 'MOVE',
    value: 'MOVE',
  },
  {
    label: 'RENEWAL',
    value: 'RENEWAL',
  },
];

export const PROJECT_APPROVAL_STATUS = [
  {
    label: 'FIRST_WAITING',
    value: 'WaitingForAuditProject',
  },
  {
    label: 'FIRST_INIT_REVIEW',
    value: 'AuditProject',
  },
  {
    label: 'WAITING_FOR_AUDIT_LAUNCH',
    value: 'WaitingForAuditLaunch',
  },
  {
    label: 'AUDIT_LAUNCH',
    value: 'AuditLaunch',
  },
  {
    label: 'PROGRESS_REVISION',
    value: 'RequestImproveLaunch',
  },
  {
    label: 'COMMENCEMENT_APPROVED',
    value: 'LaunchApproved',
  },
  {
    label: 'STOP_PROJECT',
    value: 'CancelApproved',
  },
];

export const CREDIT_STATUS = [
  // {
  //   label: 'ALL',
  //   value: 'all',
  // },
  /* 
  이전: Move, Transfer
  발행: Issue, Issuance
  상쇄: Offset, Retirement
  철회: Retire, Withdrawal
  */
  {
    label: 'TRANSFER',
    value: 'Move',
  },
  {
    label: 'ISSUE',
    value: 'Issue',
  },
  {
    label: 'RETIREMENT',
    value: 'Offset',
  },
  {
    label: 'WITHDRAWAL',
    value: 'Retire',
  },
];

export const VINTAGES = [
  // {
  //   label: 'ALL',
  //   value: 'all',
  // },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2016',
    value: '2016',
  },
  {
    label: '2015',
    value: '2015',
  },
];

export const UNITS = [
  {
    label: 'VRC',
    value: 'VRC',
  },
];

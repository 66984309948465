import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { Button, FileUploader, TextInput } from '../../components';

export const MoreFile = (props) => {
  const { value, onChange, meta } = props;
  const { t } = useTranslation();

  console.log('MoreFile value', value);
  console.log('MoreFile meta', meta);

  const onCahngeItem = (new_item, index) => {
    let new_evidence = _.clone(value);
    new_evidence[index] = new_item;
    onChange(new_evidence, 'files');
  };

  const rednerTitle = (item, index) => {
    return (
      <div className='title__box'>
        <TextInput
          value={item.title}
          onChange={(value, key) => {
            console.log('value', value);
            console.log('key', key);
            let new_item = _.clone(item);
            new_item.title = value;
            onCahngeItem(new_item, index);
          }}
          meta={{
            style: { width: '100%' },
            key: 'text',
            required: false,
            placeholder: '파일첨부명',
            disabled: meta.disabled,
            readOnly: meta.readOnly,
          }}
        ></TextInput>
        <Button
          disabled={meta.readOnly}
          type={'info-square'}
          style={{ minWidth: '85px', marginLeft: 16 }}
          onClick={() => {
            let new_evidence = _.clone(value);
            _.pullAt(new_evidence, index);
            onChange(new_evidence, 'files');
          }}
          label='삭제'
        ></Button>
      </div>
    );
  };

  const renderFileBox = (item, index) => {
    return (
      <div className='file__box'>
        <FileUploader
          value={item.file}
          onChange={(value, key) => {
            console.log('value', value);
            console.log('item', item);
            let new_item = _.clone(item);
            new_item.file = value;
            onCahngeItem(new_item, index);
          }}
          meta={{
            type: 'file',
            key: 'more-file-item-' + index,
            path: 'dummy',
            style: { marginBottom: 12 },
            required: false,
            disabled: meta.disabled,
            readOnly: meta.readOnly,
          }}
        ></FileUploader>
      </div>
    );
  };

  // const renderTextInputBox = (item, index) => {
  //   return (
  //     <div className='text-input__box'>
  //       <TextInput
  //         value={item.reason}
  //         onChange={(value, key) => {
  //           console.log('value', value);
  //           console.log('key', key);
  //           let new_item = _.clone(item);
  //           new_item.reason = value;
  //           onCahngeItem(new_item, index);
  //         }}
  //         meta={{
  //           key: 'text',
  //           required: false,
  //           placeholder: '증빙서류 사유 입력',
  //         }}
  //       ></TextInput>
  //     </div>
  //   );
  // };

  return (
    <article
      className={Handler.BuildArticleClassname('form-more-file', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{t(meta.label)}</span>
        </header>
      )}
      {value &&
        value.length > 0 &&
        value.map((item, index) => {
          return (
            <div className='file-item'>
              {rednerTitle(item, index)}
              {renderFileBox(item, index)}
            </div>
          );
        })}
      {!meta.readOnly && (
        <div className='add-button__box'>
          <Button
            type={'secondary'}
            style={{ width: '100%' }}
            onClick={() => {
              let new_evidence = _.clone(value);
              new_evidence.push({
                title: '',
                file: '',
              });
              onChange(new_evidence, 'files');
            }}
            label='ADD'
          ></Button>
        </div>
      )}
    </article>
  );
};

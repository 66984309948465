import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';

export const UserSignUpGuidePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('SIGN_UP')}</h1>
          </header>
        </section>
        <section className='singup-select__container'>
          <ul>
            <li>
              <div className='icon signup-1'></div>
              <div className='text'>
                <h2>{t('JOIN_SYSTEM')}</h2>
                <span>{t('BIZ_CREDIT_MANAGEMENT')}</span>
              </div>
            </li>
            <li>
              <div className='icon signup-2'></div>
              <div className='text'>
                <h2>{t('JOIN_MARKETPLACE')}</h2>
                <span>{t('SALES_PURCHASE_CREDIT')}</span>
              </div>
            </li>
          </ul>
        </section>
        <section className='button__container' style={{ marginTop: 80, marginBottom: 280 }}>
          <Button
            style={{ width: '400px' }}
            onClick={() => {
              history.push('/user/signup');
            }}
            label={t('INTEGRATE_REGISTRATION')}
          ></Button>
        </section>
      </article>
    </article>
  );
};

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import Board_2 from '../../styles/images/board_sample_1.png';
import Board_3 from '../../styles/images/board_sample_2.png';
import Board_1 from '../../styles/images/sample_board.png';

const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 400,
    lineHeight: '26px',
  },
  button: {
    'color': '#999EAD',
    'backgroundColor': 'transparent',
    'border': 'none',
    '&:hover': {
      backgroundColor: 'white',
      border: 'none',
    },
  },
};

const boardData = [
  {
    image: Board_1,
    name: '박란희',
    title: 'ESG 전문 미디어 <임팩트온> 대표',
    id: 1,
    description:
      'ESG(환경, 사회, 지배구조)와 지속 가능한 경영 분야에서 풍부한 경험과 전문성을 가진 전문가입니다.\nESG 전략, 공정전환, 그리고 탄소중립을 비롯한 환경 문제 해결에 대한 깊은 관심과 역량을 바탕으로 다양한 기업과 기\n관에 자문을 제공하고 있습니다. 특히, 미디어와 학술 연구에서도 활발히 활동하며, 지속 가능한 사회 구현을 위한 정책\n및 전략 개발에 기여하고 있습니다.',
    resume:
      '• 現 한양대 경영대학원 ESG MBA 겸임교수\n• 現 대통령 직속 2050탄소중립녹색성장위원회 산업전환분과 전문위원회 위원\n• 現 재단법인 한국사회투자 이사, 국제 ESG 협회 비등기이사\n• 現 사회복지공동모금회 모금분과 실행위원\n• 前 조선일보 공익섹션 더나은미래 편집장(이사)\n• 前 환경재단 기획위원',
  },

  {
    image: Board_2,
    name: '이상민',
    title: '중부대학교 석좌교수',
    id: 2,
    description:
      '환경과 지속 가능성 분야에서 폭넓은 경험과 전문성을 가진 전문가로, 환경 정책 수립과 실행에 중요한 역할을 해왔습니다.\n환경부 차관, 한국환경산업기술원 원장에서의 경험을 바탕으로 환경정책과 자원순환 등 다양한 분야에서 국제적 시각을\n확립하고, 지속 가능한 사회 구현을 위한 정책을 주도해왔습니다. 학문적 배경 또한 풍부해 환경경제학과 환경공학 분야\n에서 전문성을 확보하며, ESG 및 환경 정책 관련 연구와 강의에 적극 참여하고 있습니다.\n',
    resume:
      '• 現 중부대학교 석좌교수\n• 現 진앤리법률사무소 고문\n• 前 환경부 차관\n• 前 한국환경산업기술원 원장\n• 前 환경부 고위공무원\n',
  },
  {
    image: Board_3,
    name: '정영희',
    title: '충남대학교 특임교수',
    id: 3,
    description:
      '농업 및 식품 정책 분야에서 깊이 있는 경험을 가진 전문가로, 한국 농업의 발전과 국제 협력을 이끌어 왔습니다. 농림수\n산부 및 농림축산식품부에서 주요 직책을 맡으며 정책 개발과 집행을 주도했고, 미국 캘리포니아 주정부와 WTO 통상대\n책반에서의 활동을 통해, 한국 농업과 식품 산업의 국제적인 경쟁력을 높이는 데 중요한 기여를 했습니다. 또한, 농업 개\n발 및 식품 산업 육성에 기여하며, 공직자 교육과 농촌 정책 수립에도 중요한 역할을 했습니다.',
    resume:
      '• 現 충남대학교 특임교수\n• 前 농림축산식품부 장관\n• 前 농림축산식품부 차관\n• 前 농림축산식품부 차관보\n• 前 농림축산식품부 기획조정실 실장',
  },
];

export const BoardDetailsPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { BOARD_PAGE } = useLabel();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  console.log('Received id:', id);

  const [board, setBoard] = useState(null);
  const handleBack = () => {
    history.push('/cs/board');
  };

  useEffect(() => {
    const boardDetails = boardData.find((board) => board.id === parseInt(id)) || BOARD_PAGE.BOARD_MEMBERS[id];
    if (boardDetails) {
      setBoard(boardDetails);
    }
  }, [id, BOARD_PAGE]);

  if (!board) {
    return <p>Loading...</p>;
  }

  return (
    <article className='content h-full'>
      <article className='basic-page mt-[50px]'>
        <div className='w-[1200px] pl-[120px]' style={{ justifyContent: 'flex-start', border: 'none' }}>
          <Button
            style={{ ...styles.button, fontSize: '20px', fontWeight: 200 }}
            variant='outlined'
            onClick={handleBack}
            className=''
          >
            {<ArrowBackIcon fontSize='medium' />} {''}뒤로 가기
          </Button>
        </div>

        <div className='flex justify-between'>
          <div
            style={{
              margin: '40px',
              justifyContent: 'center',
            }}
          >
            <img src={board.image} alt='이사회' />
          </div>
          <div
            style={{
              marginTop: '40px',
              paddingLeft: '40px',
              // justifyContent: 'flex-start',
              // lineHeight: '20px',
            }}
          >
            <div className='flex mb-6' style={{}}>
              <p className='text-[24px] mr-5'>{board.name}</p>
              <div className='' style={{ borderLeft: '1px solid black' }}></div>
              <p className='flex items-center ml-5' style={{ color: '#332D48', fontSize: '18px', fontWeight: 400 }}>
                {' '}
                {board.title}
              </p>
            </div>

            <div
              className='flex whitespace-pre-wrap'
              style={{ ...styles.paragraph, lineHeight: '28px', fontWeight: 400 }}
            >
              <p>{board.description}</p>
            </div>
            <div className=' mt-10'>
              <p
                className=' mb-3 text-[#606368] font-semibold'
                style={{ fontSize: '15px', lineHeight: '28px', fontWeight: 600 }}
              >
                주요 이력
              </p>
              <div
                className='whitespace-pre-wrap '
                style={{ ...styles.paragraph, lineHeight: '28px', fontWeight: 400 }}
              >
                <p>{board.resume}</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </article>
  );
};

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Handler from '../Handler';
import API from '../api';
import { CS_PATH } from '../constants/routes';
import { LangContext } from '../contexts';
import Cert01 from '../styles/icon/icon_process_01.svg';
import Cert02 from '../styles/icon/icon_process_02.svg';
import Cert03 from '../styles/icon/icon_process_03.svg';
import Cert04 from '../styles/icon/icon_process_04.svg';
import Cert05 from '../styles/icon/icon_process_05.svg';
import Main01 from '../styles/images/main01.png';
import Main02 from '../styles/images/main02.png';
import Main03 from '../styles/images/main03.png';
import Main04 from '../styles/images/main04.png';
import Main05 from '../styles/images/main05.png';
import Main06 from '../styles/images/main06.png';

export const HomePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  const [notice, setNotice] = useState(null);
  const banners = [Main01, Main02, Main03, Main04, Main05, Main06];
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 3000,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    API.CS.GetNoticeList({
      page: 0,
      size: 3,
    }).then(
      (res) => {
        let result = res.data;
        console.log('result', result);
        setNotice(result.content);
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  const HOME = t('HOME_DESCRIPTION', { returnObjects: true });
  const HOME_ICON = t('HOME_ICON_PROCESS', { returnObjects: true });
  const mainBtnList = [
    {
      title: t('METHODOLOGY'),
      content: HOME['METHODOLOGY_CONTENT'],
      link: '/methodology/list',
      icon: '',
    },
    {
      title: t('PROJECT'),
      content: HOME['BUSINESS_CONTENT'],
      link: '/project/list',
      icon: '',
    },
    { title: t('REDUCTION_CERT'), content: HOME['REDUCTION_CONTENT'], link: '/reduction/list', icon: '' },
    {
      title: t('VRC'),
      content: HOME['VRC_CONTENT'],
      link: '/credit/list',
      icon: '',
    },
  ];

  const CertProcess = [
    { title: HOME_ICON['METHODOLOGY'], img: Cert01 },
    { title: HOME_ICON['PROJECT'], img: Cert02 },
    { title: HOME_ICON['REDUCTION'], img: Cert03 },
    { title: HOME_ICON['CREDIT'], img: Cert04 },
    { title: HOME_ICON['MARKETPLACE'], img: Cert05 },
  ];

  const MAIN_LIST = [
    {
      img: Main01,
      mainTitle: "MAIN_TITLE_01",
      subTitle: "MAIN_SUB_TITLE_01",
    },
    {
      img: Main02,
      mainTitle: "MAIN_TITLE_01",
      subTitle: "MAIN_SUB_TITLE_01",
    },
    {
      img: Main03,
      mainTitle: "MAIN_TITLE_01",
      subTitle: "MAIN_SUB_TITLE_01",
    },
    {
      img: Main04,
      mainTitle: "MAIN_TITLE_02",
      subTitle: "MAIN_SUB_TITLE_02",
    },
    {
      img: Main05,
      mainTitle: "MAIN_TITLE_02",
      subTitle: "MAIN_SUB_TITLE_02",
    },
    {
      img: Main06,
      mainTitle: "MAIN_TITLE_02",
      subTitle: "MAIN_SUB_TITLE_02",
    },
  ]

  return (
    <article className='home-page'>
      <section className='banner__container'>
        <div className='slider-container'>
          <Slider {...settings}>
            {MAIN_LIST.map((e, index) => {
              return (
                <div>
                  <div className='title__box'>
                    <h1 className='font-bold whitespace-pre-wrap'>{HOME[e.mainTitle]}</h1>
                    <p className='font-light leading-8 whitespace-pre-wrap'>{HOME[e.subTitle]}</p>
                  </div>
                  <div className='image__box' key={'banner-image' + index}>
                    <img src={e.img} alt='' />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>        
      </section>
      <div className='content__wrapper'>
        <div className='list__container'>
          <ul>
            {mainBtnList.map((item, idx) => {
              return (
                <li key={`${item.title}-${idx}`} className='flex flex-col items-center justify-between'>
                  <div className={`ico home-illust-${idx + 1} pb-2`}></div>
                  <div className='mb-3'>
                    <h3 className='mt-4 mb-2 text-xl font-bold'>{item.title}</h3>
                    <span className='text-gray-600 whitespace-pre-wrap'>{item.content}</span>
                  </div>
                  <Button
                    variant='outlined'
                    size='md'
                    onClick={() => {
                      history.push(item.link);
                    }}
                    endIcon={<ArrowForwardIcon color='primary' />}
                  >
                    {t('GOTO')}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='certification__container'>
          <h2 className='py-2 font-bold'>{HOME['CERTIFICATION_PROCESS']}</h2>
          <p className='text-gray-800'>{HOME['CERTIFICATION_PROCESS_MSG']}</p>
          <ul className='py-8'>
            {CertProcess.map((item) => (
              <li key={item.title}>
                <div className='flex justify-center w-16 p-3 py-4 m-auto bg-slate-100 rounded-2xl'>
                  <img width={40} src={item.img} alt='' />
                </div>
                <span className='text-gray-500'>{item.title}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='box-border w-full p-24 bg-slate-50'>
        <div className='flex justify-between utils__container'>
          <div className='w-1/2 pr-6 notice__box'>
            <div className='flex items-center justify-between'>
              <h3 className='text-2xl font-bold'>{t('NOTICE')}</h3>
              <Button variant='link' onClick={() => history.push('/cs/notice')}>
                {t('MORE')}
              </Button>
            </div>
            <ul className='pr-4 mt-8'>
              {notice &&
                notice.map((item, index) => {
                  return (
                    <li
                      className='flex justify-between mb-4'
                      key={item.title + index}
                      onClick={() => {
                        history.push('/cs/notice?init=' + item.seq);
                      }}
                    >
                      <p className='cursor-pointer hover:underline'>
                        {Handler.textEllipsis(isKorean ? item.title : item.titleEn, 50)}
                      </p>
                      <span className='text-gray-400'>{Handler.geni18nDate(isKorean, item.createdAt * 1000)}</span>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className='w-1/2 pl-6 faq__box'>
            <h3 className='text-2xl font-bold'>{t('SHORTCUT')}</h3>
            <ul className='flex justify-between p-10'>
              {CS_PATH.map((el, idx) => {
                return (
                  <li
                    className='flex flex-col items-center'
                    key={el.text}
                    onClick={() => {
                      history.push(el.path);
                    }}
                  >
                    <div className='flex p-4 bg-slate-200 rounded-3xl'>
                      <div className={`ico home-icon-${idx}`}></div>
                    </div>
                    <span className='mt-3 text-gray-500'>{t(el.text)}</span>
                  </li>
                );
              })}
              <li
                className='flex flex-col items-center'
                onClick={() => {
                  window.open('https://pople.kr', '_blank');
                }}
              >
                <div className='flex p-4 bg-slate-200 rounded-3xl'>
                  <div className='ico home-icon-3'></div>
                </div>
                <span className='mt-3 text-gray-500'>{t('MARKETPLACE')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
};

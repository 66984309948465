import _ from 'lodash';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../../api';
import { Button, CheckBox } from '../../components';
import { SnackbarContext } from '../../contexts';

export const AddTermsPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [step, setStep] = useState(0);
  const [termsChecks, setTermsChecks] = useState([false, false, false, false]);
  const [termsAllCheck, setTermsAllCheck] = useState(false);

  const searchObject = queryString.parse(history.location.search);

  console.log('searchObject', searchObject);
  console.log('history', history);

  useEffect(() => {
    if (termsChecks) {
      let is_all_true = true;

      for (let i in termsChecks) {
        let target = termsChecks[i];

        if (!target) {
          is_all_true = false;
        }
      }

      if (is_all_true) {
        setTermsAllCheck(true);
      } else {
        setTermsAllCheck(false);
      }
    }
  }, [termsChecks]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  const onChangeTermsCheck = (new_value, index) => {
    console.log('onChangeTermsCheck', new_value);
    let new_termsChecks = _.clone(termsChecks);
    new_termsChecks[index] = new_value;
    setTermsChecks(new_termsChecks);
  };

  const requsetSignup = () => {
    console.log('requsetSignup');
    if (history.location.state && history.location.state.integrationCertKey) {
      API.User.PutIntegration({
        integrationCertKey: history.location.state.integrationCertKey,
      }).then(
        (res) => {
          history.push('/user/signup_complete');
        },
        (err) => {
          alert(err.data.message);
        },
      );
    } else {
      history.push('/error');
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>추가 약관 동의</h1>
          </header>
        </section>
        {step === 0 && (
          <section className='user-form__container'>
            <header>
              <h2>인증 시스템 추가 약관 동의</h2>
            </header>
            <ul className='terms__list'>
              {/* <li>
                <header>
                  <CheckBox
                    value={termsChecks[0]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 0);
                    }}
                    meta={{
                      required: true,
                      label: 'POPLE 인증 시스템 이용약관에 동의합니다.',
                      subLabel: '(필수)',
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                <div className='text__viewer'>
                  <p>여러분을 환영합니다.</p>
                </div>
              </li> */}
              <li>
                <header>
                  <CheckBox
                    value={termsChecks[1]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 1);
                    }}
                    meta={{
                      required: true,
                      label: '개인정보 수집 및 이용에 동의합니다.',
                      subLabel: '(필수)',
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                <div className='text__viewer'>
                  <div className='online-terms__box'>
                    <p>개인정보 수집 및 이용동의</p>
                    <p>
                      그리너리(이하 '회사')는 회원에게 서비스를 제공하기 위해 회원가입 단계에서 아래와 같이 개인정보를
                      수집 이용합니다.
                    </p>
                    <p>수집ㆍ이용 목적</p>
                    <p>- 서비스 제공, 이용자 식별 및 본인여부 확인</p>
                    <p>- 광고성 정보 수신</p>
                    <p>수집 항목</p>
                    <p>
                      - 회사명, 사업자등록번호, 아이디, 비밀번호, 이름, 생년월일, 성별, 이메일주소, 휴대전화번호,
                      계좌번호, 주소
                    </p>
                    <p>보유ㆍ이용기간</p>
                    <p>- 회원탈퇴 또는 수집ㆍ이용 목적 달성시까지</p>
                    <p>
                      ※ 귀하는 개인정보 수집ㆍ이용에 동의하지 않을 수 있으나, 본 개인정보는 회사가 서비스 제공을 위해
                      필요한 최소한의 개인정보이므로 동의를 거부할 경우 서비스 이용이 불가능합니다
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <header>
                  <CheckBox
                    value={termsChecks[2]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 2);
                    }}
                    meta={{
                      required: true,
                      label: '구매자 약관에 동의합니다.',
                      subLabel: '(필수)',
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                <div className='text__viewer'>
                  <div className='online-terms__box'>
                    <p>
                      <strong>이용약관</strong>
                    </p>
                    <p>
                      <strong>제 1장 총칙</strong>
                    </p>
                    <p>
                      <strong>제 1조 (목적)</strong>
                    </p>
                    <p>
                      이 약관은 (주)그리너리(이하 &ldquo;회사&rdquo;)가 운영하는 웹 사이트 (https://www.pople.kr, 이하
                      &ldquo;사이트&rdquo;)에서 제공하는 탄소 크레딧 판매 및 상쇄 서비스 및 기타 정보서비스(이하
                      &ldquo;서비스&rdquo;)와 관련하여 회사와 회원 간의 권리, 의무, 책임 사항 및 회원의 서비스
                      이용절차에 관한 사항을 규정함을 목적으로 합니다.
                    </p>
                    <p>
                      <strong>제 2조 (용어의 정의)</strong>
                    </p>
                    <p>
                      1.&ldquo;사이트&rdquo;라 함은 회사가 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를
                      이용하여 탄소 크레딧 등을 거래할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 회원
                      계정(ID 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는 아래의 사이트를 말합니다. 아울러
                      사이트를 운영하는 사업자의 의미로도 사용합니다. (*그리너리: www.pople.kr)
                    </p>
                    <p>
                      2.&ldquo;회원&rdquo;이라 함은 본 약관에 따라 회사와의 이용계약을 체결하고 회사가 운영하는
                      사이트에서 서비스를 이용하는 자를 말합니다.
                    </p>
                    <p>
                      3.&ldquo;아이디(ID)&rdquo;라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는
                      문자와 숫자의 조합을 의미합니다.
                    </p>
                    <p>
                      4.&ldquo;비밀번호(Password)&rdquo;라 함은 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고
                      비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
                    </p>
                    <p>
                      5."사업개발자&rdquo;이라 함은 탄소감축(흡수)사업을 개발, 운영하는 법인으로 탄소 크레딧을 생산하고
                      판매하는 회사를 의미합니다.
                    </p>
                    <p>
                      6."탄소 크레딧&rdquo;이라 함은, 사업 개발자가 탄소감축(흡수) 사업의 결과물로 발생한 온실가스
                      배출량 감축분을 제3자 검증을 거쳐 상쇄(Offset) 크레딧으로 인정받아 거래가능한 단위로 발행된
                      재산권을 의미합니다. 탄소 감축(흡수)량 1톤은 1크레딧과 대등한 가치를 가집니다.
                    </p>
                    <p>
                      7.&ldquo;상쇄&rdquo;라 함은 기업이 사업 중에 배출한 온실가스를 상쇄 크레딧을 구매하여 배출량을
                      보상하는 것을 말합니다.
                    </p>
                    <p>
                      8.&ldquo;해지&rdquo;라 함은 회사 또는 회원이 이용계약의 효력을 장래를 향해 소멸시키는 것을
                      말합니다.
                    </p>
                    <p>
                      9.이 약관에서 사용하는 용어 중 제2조에서 정하지 아니한 것은, 관계 법령 및 서비스별 안내에서 정하는
                      바에 따르며, 그 외에는 일반 거래관행에 따릅니다.
                    </p>
                    <p>
                      <strong>제 3조 (약관의 게시와 개정)</strong>
                    </p>
                    <p>
                      1.회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 다만, 약관의
                      내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                    </p>
                    <p>
                      2.회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
                      &ldquo;정보통신망법&rdquo;) 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                    </p>
                    <p>
                      3.회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에
                      따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지함으로써 그 효력이 발생합니다. 다만,
                      회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.
                      이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
                    </p>
                    <p>
                      4.회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간 내에 의사표시를 하지 않으면
                      의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
                      의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                    </p>
                    <p>
                      5.회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우
                      회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는
                      회사는 이용계약을 해지할 수 있습니다. 이 경우 회사는 제17조 (회원에 대한 통지)에서 정한 방법으로
                      회원에게 통지합니다.
                    </p>
                    <p>
                      6.본 약관은 회사와 회원 간에 성립되는 서비스이용계약의 기본 약정입니다. 회사는 필요한 경우 특정
                      서비스에 관하여 적용될 사항(이하 &ldquo;개별약관&rdquo;)을 정하여 미리 공지할 수 있습니다. 회원이
                      이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 본
                      약관은 보충적인 효력을 갖습니다. 개별약관의 변경에 관해서는 개별약관에 특별한 규정이 없는 한 위 3,
                      4항을 준용합니다. 회원이 사이트에서 탄소 크레딧 구매 및 판매 시 신청절차 상에서 요청되는
                      동의사항(이하 &ldquo;동의사항&rdquo;)은 개별 약관으로 봅니다.
                    </p>
                    <p>
                      <strong>제 4조 (관련 법령과의 관계)</strong>
                    </p>
                    <p>
                      1.본 약관 또는 개별약관에서 정하지 않은 사항은 전기통신사업법, 전자문서 및 전자거래기본법,
                      정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법 등 관련 법령의 규정과 일반적인
                      상관례에 의합니다.
                    </p>
                    <p>
                      2.회사와 회원은 이 서비스를 이용, 제공함에 있어서 전자상거래 등에서의 소비자보호에 관한 법률,
                      전자문서 및 전자거래기본법, 소비자기본법, 표시&middot;광고의 공정화에 관한 법률, 정보통신망법 등
                      관련법령을 준수하여야 합니다.
                    </p>
                    <p>
                      <strong>제 2장 서비스의 이용</strong>
                    </p>
                    <p>
                      <strong>제 5조 (이용계약의 체결)</strong>
                    </p>
                    <p>
                      1.이용계약은 회원이 되고자 하는 자(이하 &ldquo;가입신청자&rdquo;)가 약관의 내용에 대하여 동의를 한
                      다음 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                    </p>
                    <p>
                      2.회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각
                      호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                    </p>
                    <p>
                      ①가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입
                      승낙을 얻은 경우에는 예외로 함. ②실명이 아니거나 타인의 명의를 이용한 경우 ③허위의 정보를
                      기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우 ④회원의 귀책사유로 인하여 승인이
                      불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                    </p>
                    <p>
                      3.제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할
                      수 있습니다.
                    </p>
                    <p>
                      4.회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
                      있습니다.
                    </p>
                    <p>
                      5.제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를
                      가입신청자에게 알리도록 합니다.
                    </p>
                    <p>6.이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
                    <p>
                      7.회원은 개인회원과 법인회원으로 구분되며, 회사의 정책에 따라 회원을 등급별로 구분하여 서비스
                      이용범위나 혜택 또는 서비스 사용료 징수 등을 세분하여 이용에 차등을 둘 수 있습니다.
                    </p>
                    <p>
                      <strong>제 6조 (서비스의 제공 및 중단 등)</strong>
                    </p>
                    <p>1.회사는 회원에게 아래와 같은 서비스를 제공합니다.</p>
                    <p>
                      ①탄소감축(흡수) 사업을 통해 발급된 탄소 크레딧의 상쇄 및 거래 서비스(이하 &ldquo;플랫폼
                      서비스&rdquo;)
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회사는 POPLE Standard (이하 &ldquo;파플 탄소인증 표준&rdquo;) 에 등록된 탄소 감축(흡수)사업의
                        감축량인 탄소 크레딧을 사이트를 통해 회원에게 판매합니다.&nbsp;
                      </li>
                      <li>
                        {' '}
                        회원이 취득하는 탄소 크레딧은 이미 발급된 현물 크레딧의 경우, 본인과 회사의 탄소 배출량을
                        상쇄하는데 사용할 수 있고 향후 발급 예정인 탄소 크레딧을 선도거래로 구매하여 미리 확보할 수도
                        있습니다.&nbsp;
                      </li>
                    </ol>
                    <p>
                      iii. 상쇄는 현물 크레딧 구매 즉시 상쇄되며, 기업이 선도거래로 확보한 크레딧은 미래의 발급 시점에서
                      사업개발자로부터 이전 받아 상쇄 됩니다.&nbsp;
                    </p>
                    <p>
                      iv.회원이 탄소 크레딧을 취득하려면 본 플랫폼 서비스를 통해 구매신청 등을 해야 하며, 회사가 탄소
                      크레딧 판매를 위해 사이트에 공지, 제공하는 각 탄소감축(흡수)사업의 내용, 크레딧 정보, 특성, 절차
                      및 방법에 대한 사항을 사전에 충분히 읽고 이해해야 합니다.
                    </p>
                    <p>
                      ②기타 서비스: 회사의 플랫폼 서비스 이외에 회사의 웹사이트를 통하여 제공하는 홍보 서비스 등 일체의
                      기타 서비스.
                    </p>
                    <p>
                      2.회원은 아이디(ID)와 비밀번호(Password)를 통하여 회사가 제공하는 서비스를 사용할 수 있고, 동
                      서비스를 이용하여 탄소 크레딧을 상쇄하거나 선도구매로 향후 기업회원이 필요한 탄소 크레딧을 미리
                      확보할 수 있습니다.
                    </p>
                    <p>
                      3.회원이 상쇄를 목적으로 탄소 크래딧을 구매한 경우 크레딧은 즉시 상쇄되며 결제금은 거래를 위한
                      일정 수수료를 제외하고 사업개발자에게 지급됩니다. 이에 대해 어떤 이의도 제기할 수 없습니다.
                    </p>
                    <p>
                      4.회사는 회사의 중개 없는 회원 과 회원 간의 직거래 또는 회원과 사업개발자 간의 직거래로 인하여
                      발생한 문제에 대하여 책임지지 않습니다.
                    </p>
                    <p>
                      5.회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는
                      경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제17조 (회원에 대한 통지)에서
                      정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
                      사후에 통지할 수 있습니다.
                    </p>
                    <p>
                      6.회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에
                      공지한 바에 따릅니다.
                    </p>
                    <p>
                      <strong>제 7조 (서비스의 변경)</strong>
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부
                        서비스를 변경할 수 있습니다.
                      </li>
                      <li>
                        {' '}
                        회사는 서비스 변경 시에 그 변경되는 서비스의 내용을 제17조 (회원에 대한 통지)에서 정한 방법으로
                        회원에게 통지합니다.
                      </li>
                      <li>
                        {' '}
                        회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
                        있으며, 이에 대하여 회사는 고의 또는 과실이 없는 한 회원에게 별도의 보상을 하지 않습니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 8조 (서비스 이용계약의 해지, 종료 등)</strong>
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회원은 언제든지 서비스 초기화면의 내 정보 관리 메뉴를 통하여 이용계약 해지 신청을 할 수 있으며,
                        회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
                      </li>
                      <li>
                        {' '}
                        회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를
                        제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 9조 (이용제한 등)</strong>
                    </p>
                    <p>
                      1.회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지,
                      영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.
                    </p>
                    <p>
                      2.회사는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 정보통신망법을 위반한
                      불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는
                      즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지에 따른 각종 불이익에 대해 회사는
                      별도로 보상하지 않습니다.
                    </p>
                    <p>
                      3.회사는 회원이 계속해서 6개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해
                      이용을 제한할 수 있습니다.
                    </p>
                    <p>
                      4.회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의
                      운영정책에서 정하는 바에 의합니다.
                    </p>
                    <p>
                      5.본 조 제1항 및 제2항에 따라 이용제한 된 회원의 탄소 크레딧은 회사가 회원의 최초 매입가에 매입할
                      수 있는 권리가 있습니다. 회사는 회원이 사이트에 제공한 계좌정보로 회원이 취득 시 동의한 수수료를
                      제한 대금을 지급하는 것으로 탄소 크레딧을 다시 환수할 수 있습니다. 회사는 본 조 제1항 및 제2항에
                      따라 이용제한 된 회원이 탄소 크레딧을 이용제한 시점에 보유하고 있음에 따라 발생할 수 있는 향후
                      탄소 크레딧으로 받을 수 있는 금원 정산 및 지급 등의 문제에 대하여 어떠한 책임도 부담하지 않습니다.
                    </p>
                    <ol start='6'>
                      <li>
                        {' '}
                        본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제17조 (회원에 대한
                        통지)에서 정한 방법으로 회원에게 통지합니다.
                      </li>
                      <li>
                        {' '}
                        회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
                        이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 3장 회원 정보의 보호</strong>
                    </p>
                    <p>
                      <strong>제 10조 (회원정보의 변경)</strong>
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만,
                        서비스 관리를 위해 필요한 실명, 아이디(이메일 포함) 등은 수정이 불가능합니다.
                      </li>
                      <li>
                        {' '}
                        회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타
                        방법으로 회사에 대하여 그 변경 사항을 알려야 합니다.
                      </li>
                      <li> 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                    </ol>
                    <p>
                      <strong>제 11조 (개인정보보호 의무)</strong>
                    </p>
                    <p>
                      1.회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
                      개인정보 보호 및 처리에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의
                      공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
                    </p>
                    <p>
                      2.회사는 회원의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를
                      수집합니다.
                    </p>
                    <p>
                      3.회사는 회원의 개인정보를 수집&middot;이용하거나 제3자에게 제공하고자 하는 때에는 회원으로부터
                      동의를 받습니다.
                    </p>
                    <p>
                      4.회사가 제3항에 따라 회원의 동의를 받는 경우, 회사는 개인정보의 수집&middot;이용에 관한
                      사항(수집&middot;이용 목적, 수집하는 개인정보의 항목, 개인정보의 보유&middot;이용기간 등),
                      개인정보의 제3자 제공에 관한 사항(제공받는 자, 제공받는 자의 이용목적, 제공하는 개인정보의 항목,
                      제공받는 자의 보유 및 이용기간 등)을 미리 명시하거나 고지합니다.
                    </p>
                    <p>5.회원은 제3항에 따라 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</p>
                    <p>
                      6.회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 요구정정을 요구할 수 있으며,
                      회사는 이에 대해 지체 없이 필요한 조치를 취할 의무가 있습니다. 회원이 오류의 정정을 요구한
                      경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                    </p>
                    <ol start='7'>
                      <li>
                        {' '}
                        회사는 개인정보의 수집&middot;이용 목적을 달성한 때에는 당해 회원의 개인정보를 지체 없이
                        파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는
                        보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다. 상법 등 관계법령의 규정에 의하여
                        보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                      </li>
                    </ol>
                    <p>
                      ①회원의 탈퇴 신청 등으로 서비스 이용계약이 종료된 경우, 회사는 구매자 보호 및 본 약관이 정한
                      제한을 의도적으로 회피하기 위한 임의해지 방지를 위하여 상당하다고 인정되는 경우에 한하여
                      이용계약종료 후 1년간 아이디(이메일 포함), 성명 또는 상호, 연락처, 거래상세내역, 약관위반 행위자료
                      등 최소한의 필요정보를 보관합니다. ②회사가 이용계약을 해지하거나 회사로부터 서비스 이용제한 조치를
                      받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로
                      이용계약종료 또는 이용제한 조치를 받은 후 1년간 아이디(이메일 포함), 성명 또는 상호, 연락처, 주소,
                      해지와 회원자격정지와 관련된 정보 등 최소한의 필요정보를 보관합니다. ③기타 정보수집에 관한 동의를
                      받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.
                    </p>
                    <ol start='8'>
                      <li>
                        {' '}
                        회사는 회원의 개인정보의 보호 및 처리에 관한 개인정보처리방침을 회원과 회사의 서비스를
                        이용하고자 하는 자가 알 수 있도록 웹 사이트(www.pople.credit)에 공지합니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 12조 (회원의 아이디(이메일 포함) 및 비밀번호(Password)의 관리에 대한 의무)</strong>
                    </p>
                    <p>
                      1.회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안
                      됩니다.
                    </p>
                    <p>
                      2.회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및
                      회사의 운영자로 오인될 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
                    </p>
                    <p>
                      3.회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에
                      통지하고 회사의 안내에 따라야 합니다.
                    </p>
                    <p>
                      4.제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에
                      따르지 않아 발생한 불이익에 대하여 회사는 고의 또는 중과실이 없는 한 책임지지 않습니다.
                    </p>
                    <p>
                      <strong>제 4장 회사 및 회원의 의무</strong>
                    </p>
                    <p>
                      <strong>제 13조 (회사의 의무)</strong>
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회사는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 이 약관이 정하는
                        바에 따라 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                      </li>
                      <li>
                        {' '}
                        회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을
                        갖추어야 하며 개인정보처리방침을 공개하고 준수합니다.
                      </li>
                      <li>
                        {' '}
                        회사는 서비스이용과 관련하여 발생하는 회원의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록
                        필요한 인력 및 시스템을 구비합니다.
                      </li>
                      <li>
                        {' '}
                        회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를
                        처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을
                        통하여 &ldquo;회원&rdquo;에게 처리과정 및 결과를 전달합니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 14조 (회원의 의무)</strong>
                    </p>
                    <ol>
                      <li> 회원은 다음 행위를 하여서는 안 됩니다.</li>
                    </ol>
                    <p>
                      ①신청 또는 변경 시 허위내용의 등록 ②타인의 정보도용 ③회사가 게시한 정보의 변경 ④회사가 정한 정보
                      이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 ⑤회사 기타 제3자의 저작권 등 지적재산권에 대한
                      침해 ⑥회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 ⑦외설 또는 폭력적인 메시지, 화상,
                      음성, 기타 공서양속에 반하는 정보를 사이트에 공개 또는 게시하는 행위 ⑧기타 불법적이거나 부당한
                      행위
                    </p>
                    <ol start='2'>
                      <li>
                        {' '}
                        회원은 관계 법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는
                        사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 5장 기타사항</strong>
                    </p>
                    <p>
                      <strong>제 15조 (서비스이용료의 내용)</strong>
                    </p>
                    <ol>
                      <li> 회사는 서비스 이용과 관련하여 각종 이용료를 회원에게 징수할 수 있습니다.</li>
                      <li>
                        {' '}
                        회사는 재량 내에서 이용료의 추가 및 폐지, 이용료율의 변경을 할 수 있으며, 개정할 경우에는
                        적용일자 및 개정사유를 명시하여 그 적용일자 30일 이전부터 적용일자 전일까지 사이트 화면에
                        게시하거나 기타의 방법으로 회원에게 공지합니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 16조 (책임 제한)</strong>
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의
                        고의 또는 과실이 없는 한 서비스 제공에 관한 책임이 면제됩니다.
                      </li>
                      <li>
                        {' '}
                        회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 회사의 고의 또는 중과실이 없는 한
                        책임을 지지 않습니다.
                      </li>
                      <li>
                        {' '}
                        회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는
                        회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                      </li>
                      <li>
                        {' '}
                        회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지
                        않습니다.
                      </li>
                      <li>
                        {' '}
                        회사는 서비스상에 게재되어 있거나 본 서비스를 통한 제휴업체 등의 판촉활동에 회원이 참여하거나
                        교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 회사의 고의 또는 과실이 없는 한 책임을
                        지지 않습니다. 회원은 서비스 내에 포함되어 있는 링크를 통하여 다른 웹사이트로 옮겨갈 경우,
                        회사는 해당 사이트에서 제공하는 정보내용 및 이로 인한 손해 등에 대하여 회사의 고의 또는 중과실이
                        없는 한 책임을 지지 않습니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 17조 (회원에 대한 통지)</strong>
                    </p>
                    <ol>
                      <li>
                        {' '}
                        회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원 가입 시 제공한 전자우편
                        또는 문자메시지 등으로 할 수 있습니다.
                      </li>
                      <li>
                        {' '}
                        회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 서비스 초기 화면에 게시함으로써 제1항의
                        통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                        대하여는 개별통지를 합니다.
                      </li>
                    </ol>
                    <p>
                      <strong>제 18조 (분쟁조정 및 관할법원)</strong>
                    </p>
                    <ol>
                      <li> 회사와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                      <li>
                        {' '}
                        회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시 회원의 주소를 관할하는 지방법원을
                        전속관할법원으로 하되, 주소가 없는 경우 거소를 관할하는 지방법원을 전속관할법원으로 합니다. 단,
                        제소 당시 회원의 주소 또는 거소가 명확하지 않거나 회원이 외국 거주자인 경우 관할법원은
                        민사소송법에 따라 정합니다.
                      </li>
                    </ol>
                    <p>이 약관은 2022년 04월 22일부터 적용됩니다.</p>
                    <p>
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </li>
              {/* <li>
                <header>
                  <CheckBox
                    value={termsChecks[3]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 3);
                    }}
                    meta={{
                      label: '마케팅 활용 및 광고성 정보 수신에 동의합니다.',
                      subLabel: '(선택)',
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                <div className='text__viewer'>
                  <p>여러분을 환영합니다.</p>
                </div>
              </li> */}
            </ul>
            <section style={{ display: 'flex', marginTop: 32, marginBottom: 32, justifyContent: 'center' }}>
              <CheckBox
                value={termsAllCheck}
                onChange={(new_value) => {
                  if (new_value) {
                    setTermsChecks([true, true, true, true]);
                  } else {
                    setTermsChecks([false, false, false, false]);
                  }
                  setTermsAllCheck(new_value);
                }}
                meta={{
                  label: '전체 동의합니다.',
                  className: 'terms',
                }}
              ></CheckBox>
            </section>
            <section className='button__container'>
              <Button
                style={{ width: '180px', marginRight: 24 }}
                onClick={() => {
                  history.push('/user/signup_guide');
                }}
                label='이전'
                type='secondary'
              ></Button>
              <Button
                style={{ width: '180px' }}
                onClick={() => {
                  if (termsChecks[1] && termsChecks[2]) {
                    requsetSignup();
                  } else {
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: '필수 약관에 동의해주세요.',
                    });
                  }
                }}
                label='다음'
              ></Button>
            </section>
          </section>
        )}
      </article>
    </article>
  );
};

import React, { useContext } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { UserContext } from './contexts';
import { HomeLayout, LoginLayout, MainLayout, PublicLayout } from './layouts';
import {
  AddTermsPage,
  ConsultationDetailPage,
  ConsultationHistoryListPage,
  ConsultationListPage,
  CreditDetailPage,
  CreditListPage,
  CreditRegistryDetailPage,
  CreditRegistryListPage,
  CreditWritingPage,
  CsContactUsPage,
  CsFaqPage,
  CsNoticePage,
  HomePage,
  MethodologyListPage,
  MethodologyRegistryDetailPage,
  MethodologyRegistryListPage,
  MethodologyRequestPage,
  MypageCreditListPage,
  MypageInquiryAddPage,
  MypageInquiryDetailPage,
  MypageInquiryListPage,
  MypageInquiryModifyPage,
  MypageModifyPage,
  MypageSignoutCompletePage,
  MypageSignoutPage,
  NotFoundPage,
  ProgramPage,
  ProjectDetailPage,
  ProjectListPage,
  ProjectRegistryDetailPage,
  ProjectRegistryListPage,
  ProjectRequestPage,
  ProjectWritingPage,
  ProtectPage,
  PublicCreditPage,
  PublicProjectPage,
  PublicReductionPage,
  ReductionDetailPage,
  ReductionListPage,
  ReductionWritingPage,
  RequestCompletePage,
  StandardPage,
  TermsPage,
  UserFindPwPage,
  UserLoginPage,
  UserResetPwPage,
  UserSignUpCompletePage,
  UserSignUpGuidePage,
  UserSignUpPage,
  VerificationPage,
} from './pages';
import { BoardPage } from './pages/cs/Board';
import { BoardDetailsPage } from './pages/cs/BoardDetails';
import { DocumentPage } from './pages/program/DocumentPage';
import { ProgramDetailsPage } from './pages/program/ProgramDetailsPage';
import ScrollToTop from './scrollToTop';

const ROUTER_LIST = [
  {
    path: '/',
    Component: HomePage,
    Layout: HomeLayout,
    props: {
      exact: true,
    },
  },
  {
    path: '/methodology/request',
    Component: MethodologyRequestPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/methodology/list',
    Component: MethodologyListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/request_complete',
    Component: RequestCompletePage,
    Layout: MainLayout,
  },
  {
    path: '/project/request',
    Component: ProjectRequestPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/project/writing/:slug?',
    Component: ProjectWritingPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/project/detail/:slug',
    Component: ProjectDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/project/list',
    Component: ProjectListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/reduction/writing/:slug',
    Component: ReductionWritingPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/reduction/list',
    Component: ReductionListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/reduction/detail/:slug',
    Component: ReductionDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/credit/issue',
    Component: CreditWritingPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/credit/move',
    Component: CreditWritingPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/credit/offset',
    Component: CreditWritingPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/credit/retire',
    Component: CreditWritingPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/credit/list',
    Component: CreditListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/credit/detail/:slug',
    Component: CreditDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/registry/terms',
    Component: TermsPage,
    Layout: MainLayout,
  },
  {
    path: '/registry/methodology/list',
    Component: MethodologyRegistryListPage,
    Layout: MainLayout,
  },
  {
    path: '/registry/methodology/detail/:slug',
    Component: MethodologyRegistryDetailPage,
    Layout: MainLayout,
  },
  {
    path: '/registry/project/list',
    Component: ProjectRegistryListPage,
    Layout: MainLayout,
  },
  {
    path: '/registry/project/detail/:slug',
    Component: ProjectRegistryDetailPage,
    Layout: MainLayout,
  },
  {
    path: '/registry/credit/list',
    Component: CreditRegistryListPage,
    Layout: MainLayout,
  },
  {
    path: '/registry/credit/detail/:slug',
    Component: CreditRegistryDetailPage,
    Layout: MainLayout,
  },
  {
    path: '/consultation/list',
    Component: ConsultationListPage,
    Layout: MainLayout,
  },
  {
    path: '/consultation/histories',
    Component: ConsultationHistoryListPage,
    Layout: MainLayout,
  },
  {
    path: '/consultation/detail/:slug',
    Component: ConsultationDetailPage,
    Layout: MainLayout,
  },
  {
    path: '/program/standard',
    Component: StandardPage,
    Layout: MainLayout,
  },
  {
    path: '/program/information',
    Component: ProgramPage,
    Layout: MainLayout,
  },
  {
    path: '/program/verification',
    Component: VerificationPage,
    Layout: MainLayout,
  },
  {
    path: '/program/program/detail',
    Component: ProgramDetailsPage,
    Layout: MainLayout,
  },
  {
    path: '/program/document',
    Component: DocumentPage,
    Layout: MainLayout,
  },
  {
    path: '/public/project/:seq/:version/:token',
    Component: PublicProjectPage,
    Layout: PublicLayout,
    protect: false,
  },
  {
    path: '/public/reduction/:seq/:version/:token',
    Component: PublicReductionPage,
    Layout: PublicLayout,
    protect: false,
  },
  {
    path: '/public/credit/:seq/:version/:token/:mode',
    Component: PublicCreditPage,
    Layout: PublicLayout,
    protect: false,
  },
  //sub
  {
    path: '/user/signup_complete',
    Component: UserSignUpCompletePage,
    Layout: LoginLayout,
  },
  {
    path: '/user/signup_guide',
    Component: UserSignUpGuidePage,
    Layout: LoginLayout,
  },
  {
    path: '/user/signup',
    Component: UserSignUpPage,
    Layout: LoginLayout,
  },
  {
    path: '/user/login',
    Component: UserLoginPage,
    Layout: LoginLayout,
  },
  {
    path: '/user/find_pw',
    Component: UserFindPwPage,
    Layout: LoginLayout,
  },
  {
    path: '/user/reset_pw',
    Component: UserResetPwPage,
    Layout: LoginLayout,
  },
  {
    path: '/mypage/credit_list',
    Component: MypageCreditListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_list',
    Component: MypageInquiryListPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_add',
    Component: MypageInquiryAddPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_detail/:seq',
    Component: MypageInquiryDetailPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/inquiry_modify/:seq',
    Component: MypageInquiryModifyPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/modify',
    Component: MypageModifyPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/signout',
    Component: MypageSignoutPage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/mypage/signout_complete',
    Component: MypageSignoutCompletePage,
    Layout: MainLayout,
    protect: true,
  },
  {
    path: '/cs/contact_us',
    Component: CsContactUsPage,
    Layout: MainLayout,
  },
  {
    path: '/cs/notice',
    Component: CsNoticePage,
    Layout: MainLayout,
  },
  {
    path: '/cs/board',
    Component: BoardPage,
    Layout: MainLayout,
  },
  {
    path: '/cs/board/details/:id',
    Component: BoardDetailsPage,
    Layout: MainLayout,
  },
  {
    path: '/cs/faq',
    Component: CsFaqPage,
    Layout: MainLayout,
  },
  {
    path: '/protect',
    Layout: MainLayout,
    Component: ProtectPage,
    protect: true,
  },
  {
    path: '/add_terms',
    Layout: LoginLayout,
    Component: AddTermsPage,
  },
  {
    path: '*',
    Layout: LoginLayout,
    Component: NotFoundPage,
  },
];

const Routers = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {ROUTER_LIST.map((item, index) => {
          const { Layout, Component, path, protect, props } = item;
          if (protect) {
            return (
              <PrivateRoute key={'route-' + index} path={path} {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </PrivateRoute>
            );
          } else {
            return (
              <Route key={'route-' + index} path={path} exact {...props}>
                <Layout>
                  <Component></Component>
                </Layout>
              </Route>
            );
          }
        })}
      </Switch>
    </Router>
  );
};

function PrivateRoute({ children, ...rest }) {
  const { accessToken } = useContext(UserContext);

  //accessToken validation;
  let isValidation = false;
  if (accessToken) {
    isValidation = true;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isValidation ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routers;

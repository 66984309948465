import axios from 'axios';
import queryString from 'query-string';
import { BASE_URL } from './Config';

const refreshAxios = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const ObjectToParams = (obj) => {
  const serachString = queryString.stringify(obj);
  //default encodeURIComponent
  return '?' + serachString;
};

const ParamsToObject = (url) => {
  return JSON.parse(
    '{"' + decodeURI(url.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
  );
};

const SetStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

const GetStorage = (key) => {
  return window.localStorage.getItem(key);
};

const RemoveStorage = (key) => {
  return window.localStorage.removeItem(key);
};

const reissueAccessToken = async () => {
  try {
    refreshAxios.defaults.headers['X-AUTH-TOKEN'] = GetStorage('accessToken');
    refreshAxios.defaults.headers['X-AUTH-TOKEN-REFRESH'] = GetStorage('refreshToken');

    const res = await refreshAxios.get(BASE_URL + 'user');
    const reissueAccessToken = res.headers['x-auth-token'];
    SetStorage('accessToken', reissueAccessToken);

    return res;
  } catch (err) {
    console.log('refresh error', err);
    alert('새로운 토큰 발급에 실패하였습니다. 다시 로그인해주세요.');

    RemoveStorage('accessToken');
    RemoveStorage('refreshToken');
    RemoveStorage('keeping');

    window.location.href = '/user/login';

    throw err;
  }
};

const Utils = {
  ObjectToParams: ObjectToParams,
  ParamsToObject: ParamsToObject,
  GetStorage: GetStorage,
  SetStorage: SetStorage,
  RemoveStorage: RemoveStorage,
  reissueAccessToken: reissueAccessToken,
};

export default Utils;

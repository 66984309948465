import { Button } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { DetailDocument, Download, PageHeader } from '../../components';
import { OtherDocument } from '../../components/table/OtherDocument';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';
import Govern from '../../styles/images/Frame 1261155277.svg';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    padding: '10px',
    height: 320,
    width: 874,
  },
  html: {
    scrollBehavior: 'smooth',
  },
  circleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '-3px',
    position: 'relative',
    width: 874,
    height: 130,
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(240, 240, 243)',
    border: 'transparent',
    color: '#999EAD',
    width: '104px',
    height: '104px',
    borderRadius: '50%',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 400,
    transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    mixBlendMode: 'multiply',
  },
  activeCircle: {
    backgroundColor: 'rgba(142, 116, 237, 0.7)',
    color: '#fffff',
  },

  uprightLine: {
    borderLeft: '0px dotted #8E74ED',
    position: 'absolute',
    top: '90%',
  },
  arrowText: {
    color: '#606369',
    display: 'flex',
    alignItems: 'center',
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
  },
  activeLink: {
    color: '#8E74ED',
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 400,
  },
  button: {
    'color': '#999EAD',
    'backgroundColor': 'transparent',
    'border': 'none',
    '&:hover': {
      backgroundColor: 'none',
      border: 'none',
      outline: 'none',
    },
  },
};

const circleDescriptions = [
  'POPLE은 자발적 탄소시장에 참여하는 주체들이 합리적인 판단을 내릴 수 있도록\n프로젝트 및 VRC에 대한 모든 정보는 문서화하여 투명하게 공개한다.',
  '모든 프로젝트는 가장 정확한 데이터를 사용해 온실가스 배출 및 누출량을 산정하며, 과대 또는 과소 평가를 방지한다.',
  '프로젝트 기간 동안 데이터는 일관성을 유지해 상호 비교가 가능해야 한다.',
  '프로젝트는 POPLE 스탠다드의 모든 절차를 따라야 하며,\n각 절차의 모든 정보(문서, 감축량 산정등)에는 누락이 없어야 한다.',
  'POPLE의 온실가스 제거 및 감축 프로젝트는\n프로젝트와 적합한 방법론을 선택하여 온실가스 제거량 및 감축량을 산정한다.\n',
  '프로젝트의 온실가스 제거량 및 감축량 산정은 가장 보수적인 가정과 값을 적용하여 원칙적이고 체계적인 절차를 통해 진행된다.',
  '모든 프로젝트는 실행으로 인한 추가적인 온실가스 제거 및 감축 효과가 있어야 한다.',
  '온실가스 감축 프로젝트를 통하여 정량화 가능한 범위에서 온실가스 제거 및 감축이 이루어져야 한다.\n프로젝트는 활동 수준, 배출 인자 및 기준 시나리오와 같은 다양한 매개변수에 대해 정확하고 신뢰할 수 있는\n데이터 수집과측정이 가능해야 한다.모든 프로젝트는 BAU(Business-As-Usual) 베이스라인 시나리오를 정의해야 한다.',
  '온실가스 배출 제거 및 감축 활동은 재배출 없이 환경적·사회적으로 순기능을 해야 한다.\n프로젝트 영속성을 보장하기 위해 프로젝트 개발자 또는 감축사업자는 리스크 관리 평가를 진행하여 위험 완화 요소를 제시해야 한다.',
];

export const StandardPage = (props) => {
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const [latest, setLatest] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const { STANDARD_PAGE } = useLabel();
  const [activeLink, setActiveLink] = useState(1);
  const requestNewList = (init_page) => {
    API.Documents.GetStandards()
      .then((res) => {
        const result = [...res.data];
        const latest = result.filter((e) => e.type === 'HISTORY').sort((a, b) => b.createdAt - a.createdAt)[0];
        const latestIdx = result.indexOf(latest);
        setLatest(result[latestIdx]);

        result.splice(latestIdx, 1);
        result.sort((a, b) => b.createdAt - a.createdAt);
        setList(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err?.data?.message,
        });
      });
  };

  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      window.scrollTo({
        behavior: 'smooth',
        top: offsetPosition,
      });
      setActiveLink(sectionId);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.5, rootMargin: '0px -100px 0px 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  const circle = [
    '투명성',
    '정확성',
    '일관성',
    '완전성',
    '적절성',
    '보수성',
    '추가성',
    '실제성 및\n측정\n가능성',
    '영속성',
  ];
  const [activeCircle, setActiveCircle] = useState(4);
  const standardList = useMemo(() => {
    if (list?.length > 0) return Handler.genDocumentGroup(list);
    return [];
  }, [list]);

  useEffect(() => {
    requestNewList();
  }, []);

  const handleMouseEnter = (index) => {
    setActiveCircle(index === activeCircle ? index[2] : index);
  };
  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 300,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: '700',
  };

  return (
    <div className='content h-full'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE] h-[517px]'>
          <PageHeader type='standard'>
            <div className='flex justify-between w-full '>
              <div className='mt'>
                <h1 style={{ fontWeight: 600 }} className='text-[36px]'>
                  {t('POPLE_STANDARD')}
                </h1>
              </div>
              <div style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['DESCRIPTION']}
                </p>
                <p className='whitespace-pre-wrap leading-7 mt-7'>{STANDARD_PAGE['PURPOSE'][0]}</p>
                <p className='whitespace-pre-wrap leading-7 mt-7' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PURPOSE'][1]}
                </p>
              </div>
            </div>
          </PageHeader>
        </article>

        <article className='mt-[-100px] bg-white ml-6'>
          {latest && <Download {...latest} isKorean={isKorean} />}{' '}
        </article>
        <div className='row-page-row py-[80px]' style={{ display: 'flex', top: '200px' }}>
          <div className='fixed-sidebar'>
            <nav className='fixed-sidebar flex' style={styles.sideBar}>
              <div
                style={{
                  borderRight: '1px solid #D7DAE2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',

                  paddingRight: 24,
                  width: 170,
                }}
              >
                <li>
                  <Button
                    onClick={() => handleNavClick('principle')}
                    style={activeLink === 'principle' ? styles.activeLink : inactiveLinkStyle}
                    sx={{ ...styles.button }}
                  >
                    {t('원칙')}
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() => handleNavClick('governance')}
                    style={activeLink === 'governance' ? styles.activeLink : inactiveLinkStyle}
                    sx={{ ...styles.button }}
                  >
                    {t('거버넌스')}
                  </Button>
                </li>
                <li>
                  <Button
                    className='scroll-anchor-item'
                    onClick={() => handleNavClick('related-docs')}
                    style={activeLink === 'related-docs' ? styles.activeLink : inactiveLinkStyle}
                    sx={{ ...styles.button }}
                  >
                    {t('관련 문서')}
                  </Button>
                </li>
              </div>
            </nav>
          </div>

          <div
            className='content-section ml-[100px] w-full mt-5'
            style={{
              flexGrow: 1,
              backgroundColor: '#ffffff',
            }}
          >
            <section id='principle' data-anchor='principle' className='pb-12'>
              <p className='mb-9 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                원칙
              </p>
              <div className='text-gray-500 mb-10' style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7 mt-4' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PRINCIPLE'][0]}
                </p>
                <p className='whitespace-pre-wrap leading-7 mt-4' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PRINCIPLE'][1]}
                </p>
                <p className='whitespace-pre-wrap leading-7 mt-4' style={{ ...styles.paragraph }}>
                  {STANDARD_PAGE['PRINCIPLE'][2]}
                </p>
              </div>
              <div style={styles.container}>
                {circle.map((circleName, index) => (
                  <div key={(circleName, index)} style={styles.circleContainer}>
                    <div
                      style={{
                        ...styles.circle,
                        backgroundColor: activeCircle === index ? '#8E74ED' : '#F0F0F3',
                        // fontWeight: activeCircle === index ? '500' : 'normal',
                        fontSize: activeCircle === index ? '18px' : '14px',
                        lineHeight: activeCircle === index ? '14px' : '18px',
                        color: activeCircle === index ? '#ffff' : '#999EAD',
                        transform: activeCircle === index ? 'scale(1.25)' : 'scale(1)',
                        whiteSpace: circleName === '실제성 및\n측정\n가능성' ? 'pre' : 'normal',
                        lineHeight: circleName === '실제성 및\n측정\n가능성' ? '18px' : '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                      onMouseEnter={() => handleMouseEnter(index)}
                    >
                      {circleName}
                    </div>
                    <div
                      style={{
                        ...styles.uprightLine,
                        borderBottom: activeCircle === index ? '3px dotted #8E74ED' : '',
                        borderLeft: activeCircle === index ? '3px dotted #8E74ED' : '',
                        height: activeCircle === index ? '40px' : 0,
                      }}
                    />
                  </div>
                ))}
              </div>

              <div
                className='mt-[-170px] h-[110px] w-[870px]'
                style={{ ...styles.arrowText, borderTop: '1px solid #8E74ED' }}
              >
                <div
                  className='flex justify-center items-center'
                  style={{ paddingRight: 30, paddingLeft: 30, width: '100%' }}
                >
                  {activeCircle !== null && (
                    <p style={{ lineHeight: '26px', fontSize: '16px', textAlign: 'center', whiteSpace: 'pre-line' }}>
                      {circleDescriptions[activeCircle]}
                    </p>
                  )}
                </div>
              </div>
            </section>
            <section id='governance' data-anchor='governance'>
              <p className='mt-10 mb-9 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                거버넌스
              </p>
              <div className='text-gray-400' style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7 mt-4'>{STANDARD_PAGE['GOVERNANCE'][0]}</p>
                <p className='whitespace-pre-wrap leading-7 mt-4'>{STANDARD_PAGE['GOVERNANCE'][1]}</p>
                <p className='whitespace-pre-wrap leading-7 '>{STANDARD_PAGE['GOVERNANCE'][2]}</p>
                <p className='whitespace-pre-wrap leading-7 '>{STANDARD_PAGE['GOVERNANCE'][3]}</p>
                <p className='whitespace-pre-wrap leading-7 mt-4'>{STANDARD_PAGE['GOVERNANCE'][4]}</p>
                <Button
                  variant='outlined'
                  style={{ height: 44, width: 189, borderRadius: 0, marginTop: 20, marginBottom: 10, fontSize: '15px' }}
                  className='mt-3'
                >
                  거버넌스 운영 회칙.pdf
                </Button>
                <img src={Govern} alt='' className='mt-4 mb-[150px] flex justify-center' />
              </div>
            </section>
            <section id='related-docs' data-anchor='related-docs' sx={{ borderRadius: 0 }}>
              <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                {STANDARD_PAGE['DOC_TITLE']}
              </p>
              {standardList && (
                <DetailDocument docData={standardList} type='standard' sx={{ marginBottom: '20px', borderRadius: 0 }} />
              )}
              <p style={{ marginBottom: '20px' }}></p>
              {standardList && <OtherDocument docData={standardList} type='standard' />}
            </section>
          </div>
        </div>
      </article>
    </div>
  );
};

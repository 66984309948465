import React, { useContext, useEffect, useState } from 'react';
import API from '../api';
import Utils from '../api/Utils';
import { SnackbarContext, UserContext } from '../contexts';

export const UserProvider = (props) => {
  const { children } = props;
  const [accessToken, setAccessToken] = useState(Utils.GetStorage('accessToken'));
  const [refreshToken, setRefreshToken] = useState(Utils.GetStorage('refreshToken'));
  const [userInfo, setUserInfo] = useState(null);
  const { updateSnackbar } = useContext(SnackbarContext);

  const clearToken = () => {
    Utils.RemoveStorage('accessToken');
    Utils.RemoveStorage('refreshToken');
    Utils.RemoveStorage('keeping');
    setAccessToken(null);
    setRefreshToken(null);
  };

  const updateAccessToken = (value) => {
    Utils.SetStorage('accessToken', value);
    setAccessToken(value);
  };

  const updateRefreshToken = (value) => {
    Utils.SetStorage('refreshToken', value);
    setRefreshToken(value);
  };

  const updateUserInfo = (value) => {
    setUserInfo(value);
  };

  const refreshUserInfo = () => {
    console.log('refreshUserInfo');
    if (accessToken) {
      API.User.Get().then(
        (res) => {
          let result = res.data;
          console.log('User Info', result);
          updateUserInfo(result);
        },
        (err) => {
          console.log('updateUserInfo --------->', err.data);
          clearToken();
          updateUserInfo(null);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateUserInfo(null);
    }
  };

  // // JWT 토큰 디코딩 및 만료 여부 확인 함수
  // const isTokenExpired = (token) => {
  //   const tokenParts = token.split('.');
  //   if (tokenParts.length !== 3) {
  //     return true; // 유효한 JWT 토큰 형식이 아님
  //   }

  //   const payload = JSON.parse(window.atob(tokenParts[1]));
  //   if (payload && payload.exp) {
  //     const currentTime = Date.now();
  //     const expirationTime = payload.exp * 1000;
  //     const expirationThreshold = expirationTime + 60 * 60 * 1000;
  //     return currentTime >= expirationThreshold; // 만료된지 한 시간 이상 지났다면 True
  //   }

  //   return true; // exp 필드가 없거나 잘못된 경우
  // };

  useEffect(() => {
    const accessToken = Utils.GetStorage('accessToken');

    if (accessToken) {
      refreshUserInfo();
    } else {
      clearToken();
      updateUserInfo(null);
    }
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{
        accessToken: accessToken,
        refreshToken: refreshToken,
        updateAccessToken: updateAccessToken,
        updateRefreshToken: updateRefreshToken,
        clearToken: clearToken,
        userInfo: userInfo,
        updateUserInfo: updateUserInfo,
        refreshUserInfo: refreshUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const ToastViewer = (props) => {
  const { value, meta } = props;
  const { t } = useTranslation();
  const editorRef = useRef();

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  if (!value) {
    return null;
  }

  return (
    <article
      className={Handler.BuildArticleClassname('form-toast-viewer', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>
            {t(meta.label)}
            {renderRequired()}
          </span>
        </header>
      )}
      <div className='editor__box'>
        <Viewer initialValue={value} ref={editorRef} />
      </div>
    </article>
  );
};

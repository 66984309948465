import CS from './resources/CS';
import Common from './resources/Common';
import Consultation from './resources/Consultation';
import Credit from './resources/Credit';
import Documents from './resources/Documents';
import Feedback from './resources/Feedback';
import Methodology from './resources/Methodology';
import Project from './resources/Project';
import Public from './resources/Public';
import ReductionCert from './resources/ReductionCert';
import Registry from './resources/Registry';
import User from './resources/User';

const API = {
  Common: Common,
  User: User,
  CS: CS,
  Methodology: Methodology,
  Project: Project,
  ReductionCert: ReductionCert,
  Credit: Credit,
  Public: Public,
  Registry: Registry,
  Consultation: Consultation,
  Feedback: Feedback,
  Documents: Documents,
};

export default API;

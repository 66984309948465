import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const DropdownInput = (props) => {
  const { value, onChange, menuValue, onMenuChange, meta, onEnter } = props;
  const { t } = useTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState(false);

  const onChangeInput = (e) => {
    let new_value = e.target.value;
    onChange(new_value, meta.key);
  };

  const onChangeMenu = (e) => {
    let new_value = e.target.value;
    onMenuChange(new_value, meta.key);
  };

  const buildInputType = () => {
    switch (meta.type) {
      case 'password': {
        if (passwordShow) {
          return 'text';
        } else {
          return 'password';
        }
      }
      default: {
        return 'text';
      }
    }
  };

  const buildInputClassname = () => {
    if (error) {
      return 'error';
    } else {
      return null;
    }
  };
  console.log('TextInput', error);

  return (
    <article
      className={Handler.BuildArticleClassname('form-dropdown-input', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      <div className='input__box'>
        <select value={menuValue} onChange={onChangeMenu}>
          {meta.menus &&
            meta.menus.map((item, index) => {
              return (
                <option key={`${item.label}-${index}`} value={item.value}>
                  {t(item.label)}
                </option>
              );
            })}
        </select>
        <div className='border-bar'></div>
        <input
          className={buildInputClassname()}
          placeholder={t(meta.placeholder)}
          disabled={meta.disabled}
          value={value}
          onChange={onChangeInput}
          type={buildInputType()}
          onKeyPress={(e) => {
            // console.log(`Pressed keyCode ${e.key}`);
            if (e.key === 'Enter') {
              // Do code here
              e.preventDefault();
              if (onEnter) {
                onEnter();
              }
            }
          }}
        ></input>
      </div>
    </article>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { Button } from '../../components';
import { useLabel } from '../../hooks';

export const TableMethodologyHistory = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const METHODOLOGY = t('METHODOLOGY_STATUS', { returnObjects: true });

  return (
    <article className='table-methodology-history'>
      <ul>
        {data && data.length === 0 && (
          <li className='empty'>
            <span>{ERROR['NO_DATA_FOUND']}</span>
          </li>
        )}
        {data &&
          data.map((item, index) => {
            const {
              area,
              attachedFile,
              createdAt,
              expiredYear,
              groupSeq,
              id,
              introOne,
              introTwo,
              isValid,
              mainImage,
              seq,
              startDate,
              subArea,
              title,
              version,
              versionNumber,
            } = item;
            return (
              <li>
                <header>
                  <h2>{versionNumber === 0 ? '최초 작성' : `${versionNumber}차 개정`}</h2>
                  <Button
                    onClick={() => {
                      window.open(attachedFile, '_blank');
                    }}
                    style={{ width: '144px', height: '38px' }}
                    type='square'
                    label={t('DOWNLOAD')}
                  ></Button>
                </header>
                <ul className='attribute__list'>
                  <li>
                    <header>
                      <span>{t('METHODOLOGY')}</span>
                    </header>
                    <div className='value'>
                      <span>{title}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>{t('DOC_VERSION')}</span>
                    </header>
                    <div className='value'>
                      <span>{version}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>설명</span>
                    </header>
                    <div className='value'>
                      <p>{introOne}</p>
                      <p>{introTwo}</p>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>{METHODOLOGY['AREA']}</span>
                    </header>
                    <div className='value'>
                      <span>
                        {Handler.ENUM.AREA(area)} {'>'} {Handler.ENUM.SUB_AREA(subArea)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>{METHODOLOGY['UNIQUE_NUMBER']}</span>
                    </header>
                    <div className='value'>
                      <span>{id}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>{METHODOLOGY['VALID_START_DATE']}</span>
                    </header>
                    <div className='value'>
                      <span>{Handler.dbDateToYYYYMMDDComma(startDate)}</span>
                    </div>
                  </li>
                  <li>
                    <header>
                      <span>{t('PROJECT_VALID_PERIOD')}</span>
                    </header>
                    <div className='value'>
                      <span>
                        {expiredYear}
                        {t('YEAR')}
                      </span>
                    </div>
                  </li>
                </ul>
              </li>
            );
          })}
      </ul>
    </article>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { buildPaymentTypeText } from '../../Handler';
import { NoDataRow } from './NoDataRow';

export const TablePayment = (props) => {
  const { data, rowClick } = props;
  const { t } = useTranslation();

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 50 }}>
                NO
              </TableCell>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 50 }}>
                {t('BUY_DATE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 160, maxWidth: 160 }}>
                {t('BIZ_TITLE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                {t('PURCHASE_NUMBER')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 120 }}>
                {t('BUYER')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                {t('CREDIT_NO')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 120 }}>
                {t('CREDIT_QUANTITY')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 110 }}>
                {t('CREDIT_PRICE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 160 }}>
                {t('PURCHASE_METHOD')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  <TableCell align='center'>{row.seq}</TableCell>
                  <TableCell align='center'>{row.cert?.Date}</TableCell>
                  <TableCell align='center'>{row.projectSeq.title}</TableCell>
                  <TableCell align='center'>{row.merchantUid}</TableCell>
                  <TableCell align='center'>{row.buyerName}</TableCell>
                  <TableCell align='center'>{row.certNumber}</TableCell>
                  <TableCell align='center'>{row.amount}</TableCell>
                  <TableCell align='center'>{row.unitPrice}</TableCell>
                  <TableCell align='center'>{t(buildPaymentTypeText(row.type))}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

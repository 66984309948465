import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { NoDataRow } from './NoDataRow';

export const TableCreditHistory = (props) => {
  const { data, callback } = props;
  const { t } = useTranslation();

  const renderBuildStatusText = (row) => {
    if (
      ['AuditCommittee', 'WaitingForAuditCommittee', 'AuditZCCredit', 'WaitingForAuditZCCredit'].includes(row.status)
    ) {
      return t(Handler.ENUM.CREDIT_ACTION.STATUS(row.status)) + '(' + row.degree + ')';
    }
    return t(Handler.ENUM.CREDIT_ACTION.STATUS(row.status));
  };

  const renderDetailButton = (row) => {
    if (row.status === 'WaitingForAuditZCCredit' || (row.status === 'WaitingForAuditCommittee' && row.degree === 1))
      return '-';
    return (
      <button
        onClick={() => {
          callback('View', row);
        }}
        style={{ height: 30, minWidth: 56, borderRadius: 8, background: '#fff', border: 'solid 1px #161616' }}
      >
        상세보기
      </button>
    );
  };

  return (
    <article className='table-project-history'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 160 }}>
                NO
              </TableCell>
              <TableCell align='center' style={{ minWidth: 160 }}>
                일자
              </TableCell>
              <TableCell align='center' style={{ minWidth: 430 }}>
                상태
              </TableCell>
              <TableCell align='center' style={{ minWidth: 220 }}>
                문서 버전
              </TableCell>
              <TableCell align='center' style={{ minWidth: 160 }}>
                상세보기
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={`table-row-${row.seq}-${index}`}>
                  <TableCell align='center'>{row.seq}</TableCell>
                  <TableCell align='center'>{Handler.getYYYYMMDDHHMMByUnix(row.createdAt)}</TableCell>
                  <TableCell align='center'>{renderBuildStatusText(row)}</TableCell>
                  <TableCell align='center'>
                    {row.isNewVersion ? (
                      <div>
                        <span style={{ fontSize: 14 }}>{row.version}</span>
                        <button
                          style={{
                            marginLeft: 10,
                            height: 30,
                            minWidth: 56,
                            borderRadius: 8,
                            background: '#fff',
                            border: 'solid 1px #161616',
                          }}
                          onClick={() => {
                            callback('Document', row);
                          }}
                        >
                          상세보기
                        </button>
                      </div>
                    ) : null}
                  </TableCell>
                  <TableCell align='center'>{renderDetailButton(row)}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={5} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

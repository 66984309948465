import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'public/';

const GetProject = (slug, params, token) => {
  const url = BASE_URL + PATH + 'project/' + slug + Utils.ObjectToParams(params);
  const headers = {
    Authorization: 'Bearer ' + token,
  };

  return AxiosInstance.get(url, { headers: headers });
};

const GetReduction = (slug, params, token) => {
  const url = BASE_URL + PATH + 'reduction-cert/' + slug + Utils.ObjectToParams(params);
  const headers = {
    'X-AUTH-TOKEN': token,
  };

  return AxiosInstance.get(url, { headers: headers });
};

const GetCredit = (slug, params, token) => {
  const url = BASE_URL + PATH + 'zc-credit/' + slug + Utils.ObjectToParams(params);
  const headers = {
    Authorization: 'Bearer ' + token,
  };

  return AxiosInstance.get(url, { headers: headers });
};

const GetStandard = () => {
  const url = BASE_URL + 'programs/standard';
  return AxiosInstance.get(url);
};

const Public = {
  GetProject: GetProject,
  GetReduction: GetReduction,
  GetCredit: GetCredit,
  GetStandard,
};

export default Public;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { PageHeader, TablePaging, TablePayment } from '../../components';
import { SnackbarContext } from '../../contexts';
import { usePagination } from '../../hooks';

export const MypageCreditListPage = (props) => {
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [list, setList] = useState(null);
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  useEffect(() => {
    if (lastSearchBody) {
      requestSearch();
    }
  }, [page]);

  //event
  const requestSearch = () => {
    requestPaymentList(0);
  };

  useEffect(() => {
    console.log('page effect', page);
    requestPaymentList(page);
  }, [page]);

  const requestPaymentList = (init_page) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    API.Credit.GetPayment(params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
        return;
      });
  };

  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  const tableCallback = () => {};

  return (
    <article className='basic-page'>
      {/* <Breadcrumbs content='project' title={'크레딧 판매현황'}></Breadcrumbs> */}
      <PageHeader type='project' title={`${t('SALES_STATUS')}`}></PageHeader>
      <article className='content__wrapper'>
        <div style={{ height: 40 }}></div>
        <TablePayment data={list} rowClick={tableRowClick} callback={tableCallback}></TablePayment>
        <div style={{ height: 40 }}></div>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
    </article>
  );
};

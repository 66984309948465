import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Handler, { getModeText } from '../../Handler';
import { SnackbarContext, UserContext } from '../../contexts';
import { useLabel } from '../../hooks';
import { NoDataRow } from './NoDataRow';

export const TableCredit = (props) => {
  const { mode, data, rowClick, callback } = props;
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const { ERROR } = useLabel();

  const renderHistoryButton = (item) => {
    const { lastCreditDoc } = item;
    const { actionSeq } = lastCreditDoc;
    switch (actionSeq?.status) {
      case 'CanceledZCCredit':
      case 'WritingZCCredit': {
        return '-';
      }
      case 'WaitingForAuditZCCredit': {
        if (actionSeq.degree === 1) {
          return '-';
        } else {
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                callback('History', item);
              }}
            >
              {t('HISTORY')}
            </button>
          );
        }
      }

      default: {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              callback('History', item);
            }}
          >
            {t('HISTORY')}
          </button>
        );
      }
    }
  };

  const renderStatus = (item) => {
    const { lastCreditDoc } = item;
    const { actionSeq } = lastCreditDoc;
    let status_text = t(Handler.ENUM.CREDIT_ACTION.STATUS(actionSeq?.status));
    let text_active = false;
    let isView = false;
    let text_style = 'font-bold';

    switch (actionSeq?.status) {
      case 'WritingZCCredit': {
        text_active = true;
        break;
      }
      case 'AuditCommittee':
      case 'WaitingForAuditCommittee':
      case 'AuditZCCredit':
      case 'WaitingForAuditZCCredit': {
        status_text += `(${actionSeq.degree})`;
        break;
      }
      case 'RequestImproveCommittee':
      case 'RequestImproveZCCredit': {
        text_active = true;
        isView = true;
        break;
      }
      case 'LaunchRejected':
      case 'CancelApproved':
      case 'ZCCreditRejected':
      case 'LaunchApproved':
      case 'ZCCreditApproved': {
        text_style = 'text-gray-600 font-bold';
        isView = true;
        break;
      }
      default: {
      }
    }
    return (
      <div className='status__box'>
        {
          <span className={text_active ? `active ${text_style}` : text_style} style={{ marginBottom: 6 }}>
            {t(status_text)}
          </span>
        }
        {isView && (
          <em
            style={{ marginBottom: 6 }}
            onClick={(e) => {
              e.stopPropagation();
              callback('View', item);
            }}
          >
            {t('SHOW')}
          </em>
        )}
        <span className='date'>{Handler.getYYYYMMDDByUnix(actionSeq?.createdAt)}</span>
      </div>
    );
  };

  const renderJob = (item) => {
    const { lastCreditDoc, nextActionTypeList } = item;
    const isMain = (next_action) => {
      if (
        next_action === 'ImproveCommitteeComplete' ||
        next_action === 'ImproveZCCreditComplete' ||
        next_action === 'ImproveCommitteeStart' ||
        next_action === 'ImproveZCCreditStart'
      ) {
        return 'main';
      } else {
        return null;
      }
    };

    return (
      <div className='button__box'>
        {nextActionTypeList &&
          nextActionTypeList?.length > 0 &&
          nextActionTypeList.map((target_action, index) => {
            return (
              <button
                key={'action' + index}
                className={isMain(target_action)}
                onClick={(e) => {
                  e.stopPropagation();
                  callback(target_action, item);
                }}
              >
                {t(Handler.ENUM.CREDIT_ACTION.NEXT(target_action))}
              </button>
            );
          })}
        {nextActionTypeList?.length === 0 &&
          lastCreditDoc.status === 'Approved' &&
          item.cert?.pdfUrl &&
          (mode === 'retire' ? (
            <a className='underline text-blue-500' href={item.cert?.pdfUrl} download>
              {t('REDUCTION_CERTIFICATE')}
            </a>
          ) : (
            <span>-</span>
          ))}
      </div>
    );
  };

  switch (mode) {
    case 'move':
    case 'issue': {
      return (
        <article className='table-project'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 50, maxWidth: 50 }}>
                    NO
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                    {t(getModeText(mode))}
                    <br />
                    {t('CREDIT')}
                  </TableCell>
                  {mode === 'move' && (
                    <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                      {t('TRANSFERER')}/{t('RECEIVING')}
                    </TableCell>
                  )}
                  {mode === 'issue' && (
                    <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                      {t('REMAINING_QUANTITY')}
                    </TableCell>
                  )}
                  <TableCell align='center' style={{ minWidth: 120, maxWidth: 110 }}>
                    {t('CREDIT_PRICE')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 160, maxWidth: 160 }}>
                    {t('BIZ_TITLE')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 70, maxWidth: 70 }}>
                    {t('HISTORY')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 110, maxWidth: 110 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {t('STATUS')}
                      <em style={{ fontSize: 12, color: '#777', marginTop: 5 }}>({t('RECENT_DATE')})</em>
                    </div>
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 83, maxWidth: 100 }}>
                    {t('WORK')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <TableRow
                      key={`table-row-${row.seq}-${index}`}
                      onClick={() => {
                        rowClick(index);
                      }}
                    >
                      <TableCell align='center'>{row.seq}</TableCell>
                      <TableCell align='center'>
                        {Handler.numberWithCommas(row?.lastCreditDoc?.actionSeq?.amount)}
                      </TableCell>
                      {mode === 'move' && (
                        <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                          {userInfo.seq === row.lastCreditDoc?.assigneeUserSeq && <p>{t('RECEIVING')}</p>}
                          {userInfo.seq === row.lastCreditDoc?.assignorUserSeq && <p>{t('TRANSFERER')}</p>}
                        </TableCell>
                      )}
                      {mode === 'issue' && (
                        <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                          {row?.lastCreditDoc?.status === 'Approved'
                            ? Handler.numberWithCommas(row.project.reductionNow)
                            : '-'}
                        </TableCell>
                      )}
                      <TableCell align='center'>
                        {row.lastCreditDoc?.status === 'Approved'
                          ? Handler.numberWithCommas(row.project.unitPrice)
                          : '-'}
                      </TableCell>
                      <TableCell
                        align='left'
                        onClick={() => {
                          const { lastCreditDoc } = row;
                          const { actionSeq } = lastCreditDoc;
                          callback('History', row);
                          switch (actionSeq.status) {
                            case 'CanceledZCCredit':
                            case 'WritingZCCredit': {
                              updateSnackbar({
                                type: 'error',
                                open: true,
                                message: '이력 조회 할 수 없는 사업입니다.',
                              });
                              break;
                            }
                            case 'WaitingForAuditZCCredit': {
                              if (actionSeq.degree === 1) {
                                updateSnackbar({
                                  type: 'error',
                                  open: true,
                                  message: '이력 조회 할 수 없는 사업입니다.',
                                });
                                break;
                              } else {
                                callback('History', row);
                                break;
                              }
                            }
                            default: {
                              callback('History', row);
                            }
                          }
                        }}
                      >
                        <span className='text-xs text-gray-400 mb-1'>ID: {row.project.id}</span>
                        <div className='hover:underline'>{row.project ? row.project.title : ''}</div>
                      </TableCell>
                      <TableCell align='center'>{renderHistoryButton(row)}</TableCell>
                      <TableCell align='center'>{renderStatus(row)}</TableCell>
                      <TableCell align='center'>{renderJob(row)}</TableCell>
                    </TableRow>
                  ))}
                {data && data.length === 0 && <NoDataRow colSpan={12} />}
              </TableBody>
            </Table>
          </TableContainer>
        </article>
      );
    }
    case 'offset':
    case 'retire': {
      return (
        <article className='table-project'>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 50, maxWidth: 50 }}>
                    NO
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 100, maxWidth: 120 }}>
                    {t(getModeText(mode))} {t('CREDIT')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 120, maxWidth: 110 }}>
                    {t('CREDIT_PRICE')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 160, maxWidth: 160 }}>
                    {t('BIZ_TITLE')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 70, maxWidth: 70 }}>
                    {t('HISTORY')}
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 110, maxWidth: 110 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {t('STATUS')}
                      <em style={{ fontSize: 12, color: '#777', marginTop: 5 }}>({t('RECENT_DATE')})</em>
                    </div>
                  </TableCell>
                  <TableCell align='center' style={{ minWidth: 83, maxWidth: 100 }}>
                    {t('WORK')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <TableRow
                      key={`table-row-${row.seq}-${index}`}
                      onClick={() => {
                        rowClick(index);
                      }}
                    >
                      <TableCell align='center'>{row.seq}</TableCell>
                      <TableCell align='center'>
                        {Handler.numberWithCommas(row.lastCreditDoc.actionSeq.amount)}
                      </TableCell>
                      <TableCell align='center'>
                        {row.status}
                        {row.lastCreditDoc.status === 'Approved'
                          ? Handler.numberWithCommas(row.project.unitPrice)
                          : '-'}
                      </TableCell>
                      <TableCell align='left'>
                        <span className='text-xs text-gray-400 mb-1'>ID: {row.project.id}</span>
                        <div className='hover:underline'>{row.project ? row.project.title : ''}</div>
                      </TableCell>
                      <TableCell align='center'>{renderHistoryButton(row)}</TableCell>
                      <TableCell align='center'>{renderStatus(row)}</TableCell>
                      <TableCell align='center'>{renderJob(row)}</TableCell>
                    </TableRow>
                  ))}
                {data && data.length === 0 && <NoDataRow colSpan={12} />}
              </TableBody>
            </Table>
          </TableContainer>
        </article>
      );
    }
    default: {
      return null;
    }
  }
};

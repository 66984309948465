import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import React from 'react';
import Handler from '../../Handler';
import { useLabel } from '../../hooks';
import { NoDataRow } from './NoDataRow';

export const TableCreditRegistry = (props) => {
  const { data, isKorean, column, rowClick } = props;
  const { ERROR } = useLabel();
  const { AREA_TYPE, TABLE, CREDIT_STATUS_LABEL } = useLabel();

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ width: 40 }}>
                <p className='py-1'>{TABLE['VINTAGE']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 40 }}>
                <p className='py-1'>{TABLE['UNIT']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 60 }}>
                <p className='py-1'>{TABLE['STATUS']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 50 }}>
                <p className='py-1'>{TABLE['QUANTITY']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 100 }}>
                <p className='py-1'>{TABLE['SERIAL_NO']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 280 }}>
                <p className='py-1'>{TABLE['PROJECT_NAME']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 50 }}>
                <p className='py-1'>{TABLE['AREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 100 }}>
                <p className='py-1'>{TABLE['SUBAREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 80 }}>
                <p className='py-1'>{TABLE['ADDITIONAL_CERT']}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, idx) => (
                <TableRow
                  key={`table-credit-registry-${idx}`}
                  onClick={() => {
                    rowClick(row);
                  }}
                >
                  <TableCell align='center'>{dayjs(row.reductionStartDate).format('YYYY')}</TableCell>
                  <TableCell align='center'>{'VRC'}</TableCell>
                  <TableCell align='center'>{CREDIT_STATUS_LABEL[row.mode]}</TableCell>
                  <TableCell align='center'>{Handler.numberWithCommas(row.amount)}</TableCell>
                  <TableCell align='left' style={{ width: 280 }}>
                    <Tooltip title={row.serialNumber || ''} placement='top' arrow>
                      <p>{row.serialNumber}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='left' style={{ width: 180 }}>
                    <span className='text-xs text-gray-400 mb-1'>{row.project.id}</span>
                    <Tooltip title={row.project.title || ''} placement='top-start' arrow>
                      <p>{Handler.textEllipsis(isKorean ? row.project.title || '-' : row.project.titleEn || '-')}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>{AREA_TYPE[row.project.methodology.area.toUpperCase()]}</TableCell>
                  <TableCell align='center'>{AREA_TYPE[row.project.methodology.subArea.toUpperCase()]}</TableCell>
                  <TableCell align='center'>{row?.certification ? row?.certification : '-'}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow content={ERROR['SEARCH_DATA_FOUND']} colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { NoDataRow } from './NoDataRow';

export const TableReduction = (props) => {
  const { data, rowClick, callback } = props;
  const { t } = useTranslation();

  const renderHistoryButton = (item) => {
    const { lastDoc } = item;
    const { actionSeq } = lastDoc;

    switch (actionSeq.status) {
      case 'CanceledReductionCert':
      case 'WritingReductionCert': {
        return '-';
      }
      case 'WaitingForAuditReductionCert': {
        if (actionSeq.degree === 1) {
          return '-';
        } else {
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                callback('History', item);
              }}
            >
              {t('HISTORY')}
            </button>
          );
        }
      }

      default: {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              callback('History', item);
            }}
          >
            {t('HISTORY')}
          </button>
        );
      }
    }
  };

  const renderStatus = (item) => {
    const { lastDoc } = item;
    const { actionSeq } = lastDoc;
    let status_text = t(Handler.ENUM.REDUCTION_ACTION.STATUS(actionSeq.status));
    let text_active = false;
    let isView = false;

    switch (actionSeq.status) {
      case 'WritingReductionCert': {
        text_active = true;
        break;
      }
      case 'WaitingForAuditReductionCert':
      case 'AuditReductionCert': {
        status_text += `(${actionSeq.degree})`;
        break;
      }

      case 'RequestImproveReductionCert': {
        text_active = true;
        isView = true;
        break;
      }
      case 'CancelApproved':
      case 'ReductionCertRejected':
      case 'ReductionCertApproved': {
        isView = true;
        break;
      }
      default: {
      }
    }
    return (
      <div className='status__box'>
        <span className={text_active ? 'active' : ''} style={{ marginBottom: 6 }}>
          {status_text}
        </span>
        {isView && (
          <em
            style={{ marginBottom: 6 }}
            onClick={(e) => {
              e.stopPropagation();
              callback('View', item);
            }}
          >
            {t('SHOW')}
          </em>
        )}
        <span className='date'>{Handler.getYYYYMMDDByUnix(actionSeq.createdAt)}</span>
      </div>
    );
  };

  const renderJob = (item) => {
    const { lastDoc } = item;
    const { nextActions } = lastDoc;

    const isMain = (next_action) => {
      if (
        next_action === 'ImproveReductionCertComplete' ||
        next_action === 'ImproveReductionCertStart' ||
        next_action === 'IssueCredit'
      ) {
        return 'main';
      } else {
        return null;
      }
    };

    return (
      <div className='button__box'>
        {nextActions.map((target_action, index) => {
          if (target_action === 'IssueCredit') {
            return (
              <button
                key={`button-target-${index}`}
                style={{ height: '56px', lineHeight: '20px' }}
                className={isMain(target_action)}
                onClick={(e) => {
                  e.stopPropagation();
                  callback(target_action, item);
                }}
              >
                {t('CREDIT_ISSUANCE')}
              </button>
            );
          }
          if (target_action === 'CompleteCredit') {
            return null;
          }
          return (
            <button
              key={`button-target-${index}`}
              className={isMain(target_action)}
              onClick={(e) => {
                e.stopPropagation();
                callback(target_action, item);
              }}
            >
              {t(Handler.ENUM.REDUCTION_ACTION.NEXT(target_action))}
            </button>
          );
        })}
        {nextActions.length === 0 && <span>-</span>}
      </div>
    );
  };

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 50, maxWidth: 50 }}>
                NO
              </TableCell>
              <TableCell align='center' style={{ minWidth: 160 }}>
                {t('PROJECT')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100, maxWidth: 100 }}>
                {t('REDUCTION_MONITORING_PERIOD')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 80, maxWidth: 80 }}>
                {t('REDUCTION_MONITORING_ORDER')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 110, maxWidth: 110 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {t('QUANTITY')}
                  <em style={{ fontSize: 12, color: '#777', marginTop: 5 }}>(tCO2-eq)</em>
                </div>
              </TableCell>
              <TableCell align='center' style={{ minWidth: 70, maxWidth: 70 }}>
                {t('HISTORY')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 108, maxWidth: 108 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {t('STATUS')}
                  <em style={{ fontSize: 12, color: '#777', marginTop: 5 }}>({t('RECENT_DATE')})</em>
                </div>
              </TableCell>
              <TableCell align='center' style={{ minWidth: 90 }}>
                -
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={`table-row-${row.seq}-${index}`}
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  <TableCell align='center'>{row.seq}</TableCell>
                  <TableCell
                    align='left'
                    onClick={() => {
                      const { lastDoc } = row;
                      const { actionSeq } = lastDoc;
                      console.log('actionSeq', actionSeq);
                      callback('History', row);

                      // switch (actionSeq.status) {
                      //   case 'CanceledReductionCert':
                      //   case 'WritingReductionCert': {
                      //     updateSnackbar({
                      //       type: 'error',
                      //       open: true,
                      //       message: '이력 조회 할 수 없는 사업입니다.',
                      //     });
                      //     break;
                      //   }
                      //   case 'WaitingForAuditReductionCert': {
                      //     if (actionSeq.degree === 1) {
                      //       updateSnackbar({
                      //         type: 'error',
                      //         open: true,
                      //         message: '이력 조회 할 수 없는 사업입니다.',
                      //       });
                      //       break;
                      //     } else {
                      //       callback('History', row);
                      //     }
                      //   }
                      //   default: {
                      //     callback('History', row);
                      //   }
                      // }
                    }}
                  >
                    <span className='text-xs text-gray-400 mb-1'>ID: {row.project.id}</span>
                    <div className='hover:underline'>{row.project.title}</div>
                  </TableCell>
                  <TableCell align='center'>
                    {row.startDate}
                    <br />~ {row.endDate}
                  </TableCell>
                  <TableCell align='center'>
                    {row.monitorDegree}
                    {t('ROUND')}
                  </TableCell>
                  <TableCell align='center'>
                    {row.lastDoc && row.lastDoc.realReduction
                      ? Handler.numberWithCommas(row.lastDoc.realReduction)
                      : ''}
                  </TableCell>
                  <TableCell align='center'>{renderHistoryButton(row)}</TableCell>
                  <TableCell align='center'>{renderStatus(row)}</TableCell>
                  <TableCell align='center'>{renderJob(row)}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { Breadcrumbs, Button, CheckBox, ModalPrivacyTerm, TextArea, TextInput } from '../../components';
import { LangContext, SnackbarContext, UserContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

export const CsContactUsPage = (props) => {
  const { kakao } = window;
  const { t } = useTranslation();
  const { MSG } = useLabel();
  const { userInfo } = useContext(UserContext);
  const { isKorean } = useContext(LangContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData] = useForm(
    {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    {
      name: {
        key: 'name',
        label: t('NAME'),
        className: 'point',
        required: true,
      },
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        className: 'point',
        required: true,
      },
      message: {
        key: 'message',
        label: t('CONTENT'),
        maxSize: 500,
        className: 'point',
        required: true,
      },
    },
  );
  const [aggree, setAggree] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setFormData({
        ...formData,
        email: userInfo.email,
        name: userInfo.name,
        phone: userInfo.phone,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    console.log('kakao', kakao);
    var container = document.getElementById('map');
    var options = {
      // center: new kakao.maps.LatLng(127.10172, 37.33831),
      center: new window.kakao.maps.LatLng(37.52336414995826, 126.92344613831285),

      level: 3,
    };

    var map = new kakao.maps.Map(container, options);

    //마커가 표시 될 위치
    let markerPosition = new window.kakao.maps.LatLng(37.52336414995826, 126.92344613831285);

    // 마커를 생성
    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    // 마커를 지도 위에 표시
    marker.setMap(map);

    // 인포윈도우로 장소에 대한 설명을 표시합니다
    var infowindow = new kakao.maps.InfoWindow({
      content:
        '<div style="width:180px;text-align:center;padding:20px 0;font-size : 12px;">' +
        '<p style="font-size: 14px; line-height:1.5; padding: 0 10px; word-break: keep-all;">' +
        t('COMPANY_ADDRESS') +
        '</p>' +
        '</div>',
    });
    infowindow.open(map, marker);
  }, [kakao, isKorean]);

  const requsetSubmit = () => {
    console.log('requsetSubmit');

    if (!aggree) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '개인정보 수집에 동의해주세요.',
      });
      return;
    }
    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.CS.PostContact({ ...body }).then(
        (res) => {
          let result = res.data;
          console.log('CS PostContact result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '문의가 등록되었습니다.',
          });
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>Contact us</h1>
          </header>
          <div className='right__box'>
            <Breadcrumbs type={'small'}></Breadcrumbs>
          </div>
        </section>
        <section className='contact-us-form__container'>
          <ul className='form__box'>
            <div className='guide-phone__box'>
              <header>
                <h3 style={{ marginRight: 17 }}>{t('INQUIRY')}</h3>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3>02-6274-3600</h3>
                  {/* <h3 style={{ marginTop: 4 }}>031-548-2307</h3> */}
                </div>
              </header>
              <div className='time__box'>
                <div className='item'>
                  <header>
                    <div className='ico next'></div>
                    <span>{t('WEEKDAYS')}</span>
                  </header>
                  <span>09:00 ~ 18:00</span>
                </div>
                <div className='item'>
                  <header>
                    <div className='ico next'></div> <span>{t('LUNCH')}</span>
                  </header>
                  <span>12:00 ~ 13:00</span>
                </div>
              </div>
              <div className='illust'></div>
            </div>
            <div className='guide-text__box'>
              <p className='leading-6 text-sm break-keep mx-2'>{t('CONTACT_US_GUIDE')}</p>
            </div>
            <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
            <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
            <TextInput value={formData.phone} onChange={formChange} meta={formMeta.phone}></TextInput>
            <TextArea value={formData.message} onChange={formChange} meta={formMeta.message}></TextArea>
            <div className='aggree__box'>
              <CheckBox
                value={aggree}
                onChange={(value) => {
                  setAggree(value);
                }}
                meta={{ label: MSG['AGREE_PRIVACY_TERM'] }}
              ></CheckBox>
              <div className='option__box'>
                <span
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t('SHOW')}
                </span>
              </div>
            </div>
            <section className='button__box'>
              <Button style={{ width: '100%' }} onClick={requsetSubmit} label={t('INQUIRY')}></Button>
            </section>
          </ul>
          <div className='map__box'>
            <div className='daum-map__box'>
              <div id='map' style={{ width: '665px', height: '665px' }}></div>
            </div>
            <div className='text__box'>
              <span>06050</span>
              <span>{t('COMPANY_ADDRESS')}</span>
              <span>TEL: 02-6274-3600 FAX: 02-6221-0801</span>
              <span>
                <a className='text-primary underline' href='mailto:info@greenery.im'>
                  info@greenery.im
                </a>
              </span>
            </div>
          </div>
        </section>
      </article>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <article className='contact-privacy__modal'>
            <ModalPrivacyTerm
              onClose={() => {
                setOpen(false);
              }}
            />
          </article>
        </Box>
      </Modal>
    </article>
  );
};

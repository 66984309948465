import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const CommaNumberInput = (props) => {
  const { value, onChange, meta, onEnter } = props;
  const { t } = useTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState(false);
  const [viewValue, setViewValue] = useState('');

  useEffect(() => {
    if (value) {
      setViewValue(Handler.numberWithCommas(value));
    } else {
      if (value === 0) {
        setViewValue('0');
      } else {
        setViewValue('');
      }
    }
  }, [value]);

  const onChangeInput = (e) => {
    let new_value = e.target.value;
    let number_value = Number(e.target.value.replaceAll(',', ''));
    if (isNaN(number_value)) {
      console.log('none');
    } else {
      if (Number.isInteger(number_value)) {
        if (new_value === '0') {
          onChange('0', meta.key);
        } else if (number_value > 0) {
          onChange(number_value, meta.key);
        } else {
          onChange('', meta.key);
        }
      } else {
        onChange('', meta.key);
      }
    }
  };

  const buildInputClassname = () => {
    if (error) {
      return 'error';
    } else {
      return null;
    }
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-commma-number-input', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{t(meta.label)}</span>
          {renderRequired()}
        </header>
      )}

      <div className='input__box'>
        <input
          className={buildInputClassname()}
          placeholder={meta.placeholder}
          disabled={meta.disabled || meta.readOnly}
          value={viewValue}
          onChange={onChangeInput}
          type={'text'}
          onKeyPress={(e) => {
            // console.log(`Pressed keyCode ${e.key}`);
            if (e.key === 'Enter') {
              // Do code here
              e.preventDefault();
              if (onEnter) {
                onEnter();
              }
            }
          }}
        ></input>
        {(meta.key === 'password' || meta.key === 'passwordConfirm') && (
          <div className='eye__box'>
            <div
              className={passwordShow ? 'ico eye-off' : 'ico eye'}
              onClick={() => {
                setPasswordShow(!passwordShow);
              }}
            ></div>
          </div>
        )}
        {meta.unitLabel && (
          <div className='unit__box'>
            <span>{meta.unitLabel}</span>
          </div>
        )}
      </div>
    </article>
  );
};

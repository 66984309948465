import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import {
  BasicTab,
  Button,
  ContentReduction,
  ModalReductionAction,
  ModalReductionView,
  SelectBox,
  Stepper,
  TablePaging,
  TableReductionHistory,
} from '../../components';
import { SnackbarContext } from '../../contexts';
import { useForm, useLabel, usePagination } from '../../hooks';
import { docStyle, modalStyle } from '../../styles/style';

export const ReductionDetailPage = (props) => {
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { slug: seq } = useParams();
  const { t } = useTranslation();
  const { PROJECT_PROCESS: PROJECT, REDUCTION_PROCESS: REDUCTION } = useLabel();
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [initData, setInitData] = useState(null);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [step, setStep] = useState(1);
  const [ordering, setOrdering] = useState('DESC');

  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      isExported: false,
      title: '',
      projectId: '',
      location: {
        addr1: '',
        addr2: '',
      },
      workplaceImages: [],
      area: '',
      subArea: '',
      methodologySeq: '',
      startDate: '',
      endDate: '',
      monitorDegree: 0,
      reductionBase: '',
      reductionBusiness: '',
      reductionLeak: '',
      reduction: '',
      realReductionBase: '',
      realReductionBusiness: '',
      realReductionLeak: '',
      realReduction: '',
      monitoringType: '',
      monitoringPlan: '',
      realMonitorDesc: '',
      beforeImages: [],
      afterImages: [],
      evidence: [],
      additionalEvidence: [],
    },
    {
      isExported: {
        key: 'isExported',
        style: {
          gridColumn: '1/3',
        },
        label: PROJECT['OTHER_ORG'],
        required: true,
        disabled: true,
        menus: [
          {
            text: '없음',
            value: false,
          },
          {
            text: '있음',
            value: true,
          },
        ],
      },
      title: {
        key: 'title',
        label: t('BIZ_TITLE'),
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      projectId: {
        key: 'projectId',
        label: 'ID',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      area: {
        key: 'area',
        label: t('BIZ_TYPE'),
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
      },
      subArea: {
        key: 'subArea',
        label: '사업 유형 상세',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      methodologySeq: {
        key: 'methodologySeq',
        label: t('METHODOLOGY'),
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
        value: '',
        menus: [],
      },
      location: {
        required: true,
        disabled: true,
        key: 'location',
        label: t('BIZ_SITE'),
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      workplaceImages: {
        key: 'workplaceImages',
        type: 'image',
        label: t('BIZ_SITE_PHOTO'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        readOnly: true,
        style: {
          gridColumn: '1/3',
        },
      },
      reductionBase: {
        key: 'reductionBase',
        label: PROJECT['BASELINE_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionBusiness: {
        key: 'reductionBusiness',
        label: PROJECT['BIZ_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionLeak: {
        key: 'reductionLeak',
        label: PROJECT['LEAKAGE'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reduction: {
        key: 'reduction',
        label: PROJECT['GHG_REDUCTION_QUANTITY'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBase: {
        key: 'realReductionBase',
        label: PROJECT['BASELINE_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBusiness: {
        key: 'realReductionBusiness',
        label: PROJECT['BIZ_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionLeak: {
        key: 'realReductionLeak',
        label: PROJECT['LEAKAGE'],
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReduction: {
        key: 'realReduction',
        label: PROJECT['GHG_REDUCTION_QUANTITY'],
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      monitoringType: {
        key: 'monitoringType',
        style: {
          gridColumn: '1/3',
        },
        label: PROJECT['MONITORING_PLAN_METHOD'],
        disabled: true,
        required: true,
      },
      monitoringPlan: {
        key: 'monitoringPlan',
        label: REDUCTION['MONITORING_PLAN_EXP'],
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
        disabled: true,
      },
      realMonitorDesc: {
        key: 'realMonitorDesc',
        label: '모니터링 기간 동안 실제 온실 가스 감축량',
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      beforeImages: {
        key: 'beforeImages',
        type: 'image',
        label: t('PRE_BIZ_DIAGRAM'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      afterImages: {
        key: 'afterImages',
        type: 'image',
        label: PROJECT['AFTER_BIZ_IMAGE'],
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      proofFile: {
        type: 'file',
        key: 'proofFile',
        label: PROJECT['BIZ_CERTIFICATION'],
        path: 'project/editor',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      //new
      monitorDegree: {
        key: 'monitorDegree',
        label: t('REDUCTION_MONITORING_ORDER'),
        maxSize: 500,
        style: {
          maxWidth: '240px',
        },
        required: true,
        disabled: true,
      },
      startDate: {
        required: true,
        key: 'startDate',
        style: {
          maxWidth: '400px',
          minWidth: '400px',
        },
      },
      endDate: {
        required: true,
        key: 'endDate',
        style: {
          minWidth: '400px',
          maxWidth: '400px',
        },
      },
      additionalEvidence: {
        key: 'additionalEvidence',
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    businnessStartDate: '',
    certificationStartDate: '',
    certificationEndDate: '',
    termsAggree: true,
    termsList: [],
    minDate: null,
    maxDate: null,
    month: null,
    day: null,
  });
  const [lastDocument, setLastDocument] = useState(null);
  //Modal data
  const [selectedFormData, selectedFormMeta, setSelectedFormChange, setSelectedFormData, setSelectedFormMeta] = useForm(
    {
      isExported: false,
      title: '',
      projectId: '',
      location: null,
      workplaceImages: [],
      area: '',
      subArea: '',
      methodologySeq: '',
      startDate: '',
      endDate: '',
      monitorDegree: 0,
      reductionBase: '',
      reductionBusiness: '',
      reductionLeak: '',
      reduction: '',
      realReductionBase: '',
      realReductionBusiness: '',
      realReductionLeak: '',
      realReduction: '',
      monitoringType: '',
      monitoringPlan: '',
      realMonitorDesc: '',
      beforeImages: [],
      afterImages: [],
      evidence: [],
      additionalEvidence: [],
    },
    {
      isExported: {
        key: 'isExported',
        style: {
          gridColumn: '1/3',
        },
        label: PROJECT['OTHER_ORG'],
        required: true,
        disabled: true,
        menus: [
          {
            text: '없음',
            value: false,
          },
          {
            text: '있음',
            value: true,
          },
        ],
      },
      title: {
        key: 'title',
        label: t('BIZ_TITLE'),
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      projectId: {
        key: 'projectId',
        label: 'ID',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      area: {
        key: 'area',
        label: t('BIZ_TYPE'),
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
      },
      subArea: {
        key: 'subArea',
        label: '사업 유형 상세',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      methodologySeq: {
        key: 'methodologySeq',
        label: t('METHODOLOGY'),
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
        value: '',
        menus: [],
      },
      location: {
        required: true,
        disabled: true,
        key: 'location',
        label: t('BIZ_SITE'),
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      workplaceImages: {
        key: 'workplaceImages',
        type: 'image',
        label: t('BIZ_SITE_PHOTO'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        readOnly: true,
        style: {
          gridColumn: '1/3',
        },
      },
      reductionBase: {
        key: 'reductionBase',
        label: PROJECT['BASELINE_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionBusiness: {
        key: 'reductionBusiness',
        label: PROJECT['BIZ_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionLeak: {
        key: 'reductionLeak',
        label: PROJECT['LEAKAGE'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reduction: {
        key: 'reduction',
        label: PROJECT['GHG_REDUCTION_QUANTITY'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBase: {
        key: 'realReductionBase',
        label: PROJECT['BASELINE_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBusiness: {
        key: 'realReductionBusiness',
        label: PROJECT['BIZ_EMISSION'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionLeak: {
        key: 'realReductionLeak',
        label: PROJECT['LEAKAGE'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReduction: {
        key: 'realReduction',
        label: PROJECT['GHG_REDUCTION_QUANTITY'],
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      monitoringType: {
        key: 'monitoringType',
        style: {
          gridColumn: '1/3',
        },
        label: PROJECT['MONITORING_PLAN_METHOD'],
        disabled: true,
        required: true,
      },
      monitoringPlan: {
        key: 'monitoringPlan',
        label: REDUCTION['MONITORING_PLAN_EXP'],
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
        disabled: true,
      },
      realMonitorDesc: {
        key: 'realMonitorDesc',
        label: '모니터링 기간 동안 실제 온실 가스 감축량',
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      beforeImages: {
        key: 'beforeImages',
        type: 'image',
        label: t('PRE_BIZ_DIAGRAM'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      afterImages: {
        key: 'afterImages',
        type: 'image',
        label: PROJECT['AFTER_BIZ_IMAGE'],
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      proofFile: {
        type: 'file',
        key: 'proofFile',
        label: t('BIZ_CERTIFICATION'),
        path: 'project/editor',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      //new
      monitorDegree: {
        key: 'monitorDegree',
        label: REDUCTION['MONITORING_ORDER'],
        maxSize: 500,
        style: {
          maxWidth: '240px',
        },
        required: true,
        disabled: true,
      },
      startDate: {
        required: true,
        key: 'startDate',
        style: {
          maxWidth: '400px',
          minWidth: '400px',
        },
      },
      endDate: {
        required: true,
        key: 'endDate',
        style: {
          minWidth: '400px',
          maxWidth: '400px',
        },
      },
      additionalEvidence: {
        key: 'additionalEvidence',
      },
    },
  );
  const [selectedContentMeta, setSelectedContentMeta] = useState({
    businnessStartDate: '',
    certificationStartDate: '',
    certificationEndDate: '',
    termsAggree: true,
    termsList: [],
    minDate: null,
    maxDate: null,
    month: null,
    day: null,
  });
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isSelectedDocForm, setIsSelectedDocForm] = useState(false);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [documentStep, setDocumentStep] = useState(0);

  useEffect(() => {
    if (seq && page) {
      requestNewList();
    }
  }, [seq, page]);

  useEffect(() => {
    if (seq && ordering) {
      refreshData(0);
      requestNewList(0);
    }
  }, [seq, ordering]);

  useEffect(() => {
    if (tabIndex === 0) {
      setStep(1);
    } else {
      setStep(tabIndex - 1);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (initData) {
      console.log('initData', initData.version);
      //초기 값 셋팅 후, 최신 버전 도큐멘트 가져오기

      if (initData.lastDoc.version) {
        API.ReductionCert.GetActionDoc(seq, { version: initData.lastDoc.version })
          .then((res) => {
            let result = res.data;
            setLastDocument(result);
          })
          .catch((err) => {
            console.log('err', err);
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
      }
    }
  }, [initData]);

  useEffect(() => {
    if (lastDocument) {
      //meta update
      let terms_value = Handler.buildTermsValue(lastDocument.reductionCert.project.methodology);
      setContentMeta({
        ...contentMeta,
        businnessStartDate: lastDocument.reductionCert.project.projectDate,
        certificationStartDate: lastDocument.reductionCert.project.startDate,
        certificationEndDate: lastDocument.reductionCert.project.endDate,
        termsList: terms_value,
        minDate: null,
        maxDate: null,
      });

      // 모니터링 업데이트
      let origin_monitoring = Handler.buildMonitoringValue(
        lastDocument.evidenceMapper,
        lastDocument.reductionCert.monitoringType,
        null,
        true,
      );
      let origin_monitoring_label = Handler.buildMonitoringLabel(
        lastDocument.evidenceMapper,
        lastDocument.reductionCert.monitoringType,
        null,
        true,
      );
      let new_fromMeta = _.clone(formMeta);
      new_fromMeta.evidence.label = origin_monitoring_label;
      const lastDoc = lastDocument.reductionCert.project.lastDoc;
      setFormMeta(new_fromMeta);
      setFormData({
        ...formData,
        isExported: lastDoc?.isExported || false,
        title: lastDocument.reductionCert.project.title,
        projectId: lastDocument.reductionCert.project.id,
        location: lastDoc?.location,
        workplaceImages: lastDoc?.workplaceImages,
        area: lastDocument.reductionCert.project.methodology.area,
        subArea: lastDocument.reductionCert.project.methodology.subArea,
        methodologySeq: lastDocument.reductionCert.project.methodology.title,
        startDate: Handler.dbCommaDateToDashDate(lastDocument.startDate),
        endDate: Handler.dbCommaDateToDashDate(lastDocument.endDate),
        monitorDegree: lastDocument.monitorDegree,
        reductionBase: lastDocument.reductionCert.reductionBase,
        reductionBusiness: lastDocument.reductionCert.reductionBusiness,
        reductionLeak: lastDocument.reductionCert.reductionLeak,
        reduction: lastDocument.reductionCert.reduction,
        realReductionBase: lastDocument.realReductionBase,
        realReductionBusiness: lastDocument.realReductionBusiness,
        realReductionLeak: lastDocument.realReductionLeak,
        realReduction: lastDocument.realReduction,
        monitoringType: lastDocument.monitoringType,
        monitoringPlan: lastDocument.monitoringPlan,
        realMonitorDesc: lastDocument.realMonitorDesc,
        beforeImages: lastDocument.beforeImages ? lastDocument.beforeImages : [],
        afterImages: lastDocument.afterImages ? lastDocument.afterImages : [],
        evidence: origin_monitoring,
        additionalEvidence: lastDocument.additionalEvidence ? lastDocument.additionalEvidence : [],
      });
    }
  }, [lastDocument]);

  useEffect(() => {
    if (selectedDocument) {
      //meta update
      let terms_value = Handler.buildTermsValue(selectedDocument.reductionCert.project.methodology);
      console.log('terms_value', terms_value);
      setSelectedContentMeta({
        ...selectedContentMeta,
        businnessStartDate: selectedDocument.reductionCert.project.projectDate,
        certificationStartDate: selectedDocument.reductionCert.project.startDate,
        certificationEndDate: selectedDocument.reductionCert.project.endDate,
        termsList: terms_value,
        minDate: null,
        maxDate: null,
      });

      // 모니터링 업데이트
      let origin_monitoring = Handler.buildMonitoringValue(
        selectedDocument.evidenceMapper,
        selectedDocument.reductionCert.monitoringType,
        null,
        true,
      );
      let origin_monitoring_label = Handler.buildMonitoringLabel(
        selectedDocument.evidenceMapper,
        selectedDocument.reductionCert.monitoringType,
        null,
        true,
      );
      let new_fromMeta = _.clone(formMeta);
      new_fromMeta.evidence.label = origin_monitoring_label;

      const lastDoc = selectedDocument.reductionCert.project.lastDoc;
      setSelectedFormMeta(new_fromMeta);
      setSelectedFormData({
        isExported: lastDoc?.isExported || false,
        title: selectedDocument.reductionCert.project.title,
        projectId: selectedDocument.reductionCert.project.id,
        location: lastDoc?.location,
        workplaceImages: lastDoc?.workplaceImages,
        area: selectedDocument.reductionCert.project.methodology.area,
        subArea: selectedDocument.reductionCert.project.methodology.subArea,
        methodologySeq: selectedDocument.reductionCert.project.methodology.seq,
        startDate: Handler.dbCommaDateToDashDate(selectedDocument.startDate),
        endDate: Handler.dbCommaDateToDashDate(selectedDocument.endDate),
        monitorDegree: selectedDocument.monitorDegree,
        reductionBase: selectedDocument.reductionCert.reductionBase,
        reductionBusiness: selectedDocument.reductionCert.reductionBusiness,
        reductionLeak: selectedDocument.reductionCert.reductionLeak,
        reduction: selectedDocument.reductionCert.reduction,
        realReductionBase: selectedDocument.realReductionBase,
        realReductionBusiness: selectedDocument.realReductionBusiness,
        realReductionLeak: selectedDocument.realReductionLeak,
        realReduction: selectedDocument.realReduction,
        monitoringType: selectedDocument.monitoringType,
        monitoringPlan: selectedDocument.monitoringPlan,
        realMonitorDesc: selectedDocument.realMonitorDesc,
        beforeImages: selectedDocument.beforeImages ? selectedDocument.beforeImages : [],
        afterImages: selectedDocument.afterImages ? selectedDocument.afterImages : [],
        evidence: origin_monitoring,
        additionalEvidence: selectedDocument.additionalEvidence ? selectedDocument.additionalEvidence : [],
      });
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (isSelectedDocForm) setDocumentOpen(true);
  }, [selectedFormData, isSelectedDocForm]);

  useEffect(() => {
    if (documentStep > 0) {
      let modal_doc = document.getElementById('project-document__modal');
      if (modal_doc) {
        modal_doc.scrollTop = 0;
      }
    }
  }, [documentStep]);

  //event
  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  const tableCallback = (next_action, item) => {
    switch (next_action) {
      case 'IssueCredit': {
        history.push('/credit/issue');
        return;
      }
      case 'View': {
        setSelectedAction(item);
        setViewOpen(true);
        break;
      }
      case 'ModifyWriting': {
        history.push('/reduction/writing/' + item.seq);
        break;
      }
      case 'RemoveWriting': {
        API.ReductionCert.PostNextAction(item.seq, {
          nextAction: 'RemoveWriting',
        })
          .then((res) => {
            closeActionModal();
            requestNewList();
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'ImproveReductionCertStart': {
        API.ReductionCert.PostNextAction(seq, {
          nextAction: 'ImproveReductionCertStart',
        })
          .then((res) => {
            closeActionModal();
            closeViewModal();
            requestNewList();
            setSelectedAction({
              status: 'RequestMoveWriting',
              seq: seq,
            });
            setViewOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'Document': {
        getNewDocument(item.version);
        setDocumentOpen(true);
        break;
      }
      default: {
        setSelectedAction(item.lastDoc.actionSeq);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
    }
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeViewModal = () => {
    setViewOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeDocumnetModal = () => {
    setDocumentOpen(false);
    setDocumentStep(0);
  };

  // server
  const refreshData = () => {
    API.ReductionCert.GetDetail(seq)
      .then((res) => {
        setInitData(res.data);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const requestNewList = (init_page) => {
    let params = {
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
      ordering: ordering,
    };
    API.ReductionCert.GetAction(seq, params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const handleModalRequest = (body) => {
    API.ReductionCert.PostNextAction(seq, body).then(
      (res) => {
        closeActionModal();
        refreshData(0);
        requestNewList(0);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const getNewDocument = (version) => {
    API.ReductionCert.GetActionDoc(seq, { version: version })
      .then((res) => {
        setSelectedDocument(res.data);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const gotoPopleMarketplace = useCallback(() => {
    window.open('https://pople.kr');
  }, []);

  if (!initData) {
    return null;
  }

  const renderStatus = (actionSeq) => {
    let status_text = t(Handler.ENUM.REDUCTION_ACTION.STATUS(actionSeq.status));
    if (['AuditReductionCert', 'WaitingForAuditReductionCert'].includes(actionSeq.status)) {
      status_text += `(${actionSeq.degree})`;
    }
    return (
      <>
        <span>{status_text}</span>
        <em>{Handler.getYYYYMMDDByUnix(actionSeq.createdAt)}</em>
      </>
    );
  };

  const renderJob = (item) => {
    const { lastDoc } = item;
    const { nextActions } = lastDoc;
    const isMain = (next_action) => {
      if (['ImproveReductionCertComplete', 'ImproveReductionCertStart', 'IssueCredit'].includes(next_action)) {
        return 'main';
      }
      return null;
    };

    return (
      <div className='button__box'>
        {nextActions.map((target_action, index) => {
          if (target_action === 'CompleteCredit') {
            return null;
          }
          return (
            <button
              style={target_action === 'IssueCredit' ? { height: '56px', lineHeight: '20px' } : {}}
              className={isMain(target_action)}
              onClick={(e) => {
                e.stopPropagation();
                tableCallback(target_action, item);
              }}
            >
              {target_action === 'IssueCredit'
                ? '크레딧 발급 신청'
                : t(Handler.ENUM.REDUCTION_ACTION.NEXT(target_action))}
            </button>
          );
        })}
        {nextActions.length === 0 && <span>-</span>}
      </div>
    );
  };

  return (
    <article className='basic-page'>
      <article className='project-header__container'>
        <div className='content__wrapper'>
          <section className='breadcrumb__box'>
            <ul>
              <li
                onClick={() => {
                  history.push('/');
                }}
              >
                Home
              </li>
              <li>감축량 인증 관리</li>
              <li
                onClick={() => {
                  history.push('/reduction/list');
                }}
              >
                감축량 인증 현황
              </li>
              <li className='active'>감축량 인증 현황 상세</li>
            </ul>
          </section>
          <section className='title__box'>
            <h1>{initData.project.title}</h1>
            <ul className='info'>
              <li>
                <span>{t('BIZ_ID')}</span>
                <span className='value'>{initData.project.id}</span>
              </li>
            </ul>
          </section>
          <section className='row__box'>
            <ul>
              <li>
                <header>
                  <span>{t('REDUCTION_MONITORING_ORDER')}</span>
                </header>
                <div className='value'>
                  <span>
                    {initData.monitorDegree}
                    {t('ROUND')}
                  </span>
                </div>
              </li>
              <li style={{ flex: '2' }}>
                <header>
                  <span>{t('REDUCTION_MONITORING_PERIOD')}</span>
                </header>
                <div className='value'>
                  <span>
                    {initData.startDate} ~ {initData.endDate}
                  </span>
                </div>
              </li>
              <li>
                <header>
                  <span>{t('REMAIN_REDUCTIONS')}</span>
                  <em>(tCO2-eq)</em>
                </header>
                <div className='value'>
                  {initData.project.reductionNow ? Handler.numberWithCommas(initData.project.reductionNow) : ''}
                </div>
              </li>
              <li>
                <header>
                  <span>{t('CUMULATE_REDUCTIONS')}</span>
                  <em>(tCO2-eq)</em>
                </header>
                <div className='value'>
                  {initData.project.reductionSum ? Handler.numberWithCommas(initData.project.reductionSum) : ''}
                </div>
              </li>
              <li>
                <header>
                  <span>{t('QUANTITY_REQUESTED')}</span>
                  <em>(tCO2-eq)</em>
                </header>
                <div className='value' style={{ color: '#5d3be5' }}>
                  {initData.lastDoc.realReduction ? Handler.numberWithCommas(initData.lastDoc.realReduction) : ''}
                </div>
              </li>
              <li>
                <header>
                  <span>{t('STATUS')}</span>
                  <em>({t('RECENT_DATE')})</em>
                </header>
                <div className='value'>{renderStatus(initData.lastDoc.actionSeq)}</div>
              </li>
              <li>
                <header>
                  <span>{t('WORK')}</span>
                </header>
                <div className='value'>{renderJob(initData)}</div>
              </li>
            </ul>
          </section>
          <section className='button-list__box'>
            <div>
              <Button
                type='secondary'
                style={{ width: '96px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  history.push('/reduction/list');
                }}
                label={t('LIST')}
              ></Button>
            </div>
            <div>
              <Button
                type='secondary'
                style={{ width: '180px', height: '50px', marginRight: 12 }}
                onClick={() => {
                  history.push('/project/detail/' + initData.project.seq);
                }}
                label={t('BIZ_DETAIL')}
              ></Button>
              <Button
                disabled={initData.lastDoc.actionSeq.status !== 'ReductionCertApproved'}
                type='secondary'
                style={{ width: '180px', height: '50px', marginRight: 12 }}
                onClick={() => {
                  history.push('/credit/list');
                }}
                label={t('CREDIT_ISSUANCE_STATUS')}
              ></Button>
              <Button
                disabled={initData.lastDoc.actionSeq.status !== 'ReductionCertApproved'}
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={gotoPopleMarketplace}
                label='Market Place'
                ico='store'
              ></Button>
            </div>
          </section>
        </div>
      </article>
      <article className='content__wrapper'>
        <div style={{ height: 40 }}></div>
        <BasicTab
          index={tabIndex}
          meta={{
            menus: [t('HISTORY'), t('REDUCTION_MONITORING_SYSTEM'), t('OTHER_DOCUMENTS')],
          }}
          onChange={(value) => {
            setTabIndex(value);
          }}
        ></BasicTab>
        {tabIndex === 0 && (
          <>
            <div style={{ height: 20 }}></div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>{t('HISTORY')}</h2>
              <SelectBox
                value={ordering}
                onChange={(value) => {
                  setOrdering(value);
                }}
                meta={{
                  className: 'round',
                  style: { width: '120px' },
                  menus: [
                    {
                      value: 'DESC',
                      label: `${t('DATE')} ↓`,
                    },
                    {
                      value: 'ASC',
                      label: `${t('DATE')} ↑`,
                    },
                  ],
                }}
              ></SelectBox>
            </div>
            <div style={{ height: 20 }}></div>
            <TableReductionHistory
              data={list}
              rowClick={tableRowClick}
              callback={tableCallback}
            ></TableReductionHistory>
            <div style={{ height: 40 }}></div>
            <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
          </>
        )}
        {tabIndex !== 0 && (
          <ContentReduction
            step={tabIndex}
            formData={formData}
            formMeta={formMeta}
            formChange={() => {}}
            setFormData={setFormData}
            setFormMeta={setFormMeta}
            contentMeta={contentMeta}
            contentMetaChange={() => {}}
            readOnly={true}
          ></ContentReduction>
        )}
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalReductionAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalReductionAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={modalStyle}>
          <ModalReductionView
            action={selectedAction}
            onClose={closeViewModal}
            requestAction={tableCallback}
          ></ModalReductionView>
        </Box>
      </Modal>
      <Modal open={documentOpen} onClose={closeDocumnetModal}>
        <Box sx={docStyle}>
          <div className='project-document__modal' id='project-document__modal'>
            <section className='header__box'>
              <div>
                <h4>{t('VIEW_DOC')}</h4>
                <span>{selectedDocument && selectedDocument.version}</span>
              </div>
              <div>
                <div
                  className='ico close-small'
                  onClick={() => {
                    closeDocumnetModal();
                  }}
                ></div>
              </div>
            </section>
            <section className='step-left-button__box'>
              <div
                onClick={() => {
                  if (documentStep > 0) {
                    setDocumentStep(documentStep - 1);
                  }
                }}
              >
                <div className='ico forward'></div>
              </div>
            </section>
            <section className='step-right-button__box'>
              <div
                onClick={() => {
                  if (documentStep < 2) {
                    setDocumentStep(documentStep + 1);
                  }
                }}
              >
                <div className='ico backward'></div>
              </div>
            </section>
            <section className='document__box'>
              <Stepper
                meta={{ type: 'round', style: { marginTop: 40 } }}
                value={documentStep}
                label={['CHECK_BUSINESS_SUMMARY', 'REDUCTION_MONITORING_SYSTEM', 'OTHER_DOCUMENTS']}
                onChange={() => {}}
              ></Stepper>
              {selectedFormData && selectedFormData.title && (
                <ContentReduction
                  step={documentStep}
                  formData={selectedFormData}
                  formMeta={selectedFormMeta}
                  formChange={setSelectedFormChange}
                  contentMeta={selectedContentMeta}
                  contentMetaChange={() => {}}
                  readOnly={true}
                ></ContentReduction>
              )}
            </section>
          </div>
        </Box>
      </Modal>
    </article>
  );
};

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import {
  Button,
  DropdownInput,
  ModalReductionAction,
  ModalReductionView,
  PageHeader,
  SelectBox,
  TablePaging,
  TableReduction,
} from '../../components';
import { SnackbarContext } from '../../contexts';
import { usePagination } from '../../hooks';
import { modalStyle } from '../../styles/style';

export const ReductionListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const DOC = t('DOC_PROCESS', { returnObjects: true });
  const { updateSnackbar } = useContext(SnackbarContext);
  const [status, setStatus] = useState('SELECT_ALL');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const ROUTE_PATH = t('ROUTE_PATH', { returnObjects: true });

  //hook
  useEffect(() => {
    requsetSearch();
  }, []);

  useEffect(() => {
    if (lastSearchBody) {
      requestNewList();
    }
  }, [page]);

  //event
  const requsetSearch = () => {
    requestNewList(0);
  };

  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  const tableCallback = (next_action, item) => {
    console.log('tableCallback next_action', next_action);
    console.log('tableCallback item', item);
    switch (next_action) {
      case 'IssueCredit': {
        console.log('IssueCredit');
        history.push('/credit/issue');
        return;
      }
      case 'History': {
        console.log('History move');
        history.push('/reduction/detail/' + item.seq);
        break;
      }
      case 'View': {
        setSelectedItem(item);
        setSelectedAction(item.lastDoc.actionSeq);
        setViewOpen(true);
        break;
      }
      case 'ModifyWriting': {
        history.push('/reduction/writing/' + item.seq);
        break;
      }
      case 'RemoveWriting': {
        API.ReductionCert.PostNextAction(item.seq, {
          nextAction: 'RemoveWriting',
        }).then(
          (res) => {
            closeActionModal();
            requestNewList();
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
        break;
      }
      case 'ImproveReductionCertStart': {
        let target_seq = null;
        if (item) {
          target_seq = item.seq;
        }
        if (selectedItem) {
          target_seq = selectedItem.seq;
        }
        API.ReductionCert.PostNextAction(target_seq, {
          nextAction: 'ImproveReductionCertStart',
        })
          .then((res) => {
            closeActionModal();
            closeViewModal();
            requestNewList();
            setSelectedAction({
              status: 'RequestMoveWriting',
              seq: target_seq,
            });
            setViewOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'CancelWaiting': {
        setSelectedItem(item);
        setSelectedAction(item.lastDoc.actionSeq);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
      default: {
        setSelectedItem(item);
        setSelectedAction(item.lastDoc.actionSeq);
        setSelectedNextAction(next_action);
        setActionOpen(true);
      }
    }
  };

  const handleModalRequest = (body) => {
    API.ReductionCert.PostNextAction(selectedItem.seq, body)
      .then((res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        closeActionModal();
        requestNewList(0);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedItem(null);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeViewModal = () => {
    setViewOpen(false);
    setSelectedItem(null);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  //server
  const requestNewList = (init_page) => {
    let params = null;

    if (init_page) {
      params = {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
        searchType: searchType,
        searchValue: search,
      };
      if (status !== 'SELECT_ALL') {
        params.status = status;
      }
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }

    API.ReductionCert.GetList(params)
      .then((res) => {
        let result = res.data;
        setList(result.content);
        setTotalPage(result.totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  return (
    <article className='basic-page'>
      <PageHeader type='reduction' title={t(ROUTE_PATH['REDUCTION_STATUS'])}></PageHeader>
      <article className='content__wrapper'>
        <div className='search__container between'>
          <div className='select__box'>
            <SelectBox
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
              meta={{
                key: 'status',
                style: {
                  // width: 330,
                },
                menus: [
                  {
                    label: t('STATUS') + ' ' + t('ALL'),
                    value: 'SELECT_ALL',
                  },
                  {
                    label: t('TEMP_SAVE'),
                    value: 'Writing',
                  },
                  {
                    label: DOC['WAITING'],
                    value: 'Waiting',
                  },
                  {
                    label: DOC['REVIEW'],
                    value: 'Audit',
                  },
                  {
                    label: DOC['REQ_FOR_REVISION'],
                    value: 'ImprovementRequest',
                  },
                  {
                    label: `${DOC['FINAL_APPROVED']} / ${DOC['COMMENCEMENT_APPROVED']}`,
                    value: 'Approved',
                  },
                  {
                    label: DOC['REJECTED'],
                    value: 'Rejected',
                  },
                  {
                    label: t('CANCEL'),
                    value: 'Canceled',
                  },
                ],
              }}
            ></SelectBox>
          </div>
          <div className='input__box'>
            <DropdownInput
              value={search}
              menuValue={searchType}
              onChange={(value, key) => {
                setSearch(value);
              }}
              onMenuChange={(value, key) => {
                setSearchType(value);
              }}
              onEnter={() => {
                requsetSearch();
              }}
              meta={{
                style: { width: '446px' },
                placeholder: t('KEYWORD'),
                menus: [
                  {
                    value: 'Title',
                    label: t('PROJECT'),
                  },
                  {
                    value: 'Id',
                    label: t('PROJECT_ID'),
                  },
                ],
              }}
            ></DropdownInput>
            <Button
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requsetSearch();
              }}
              label={t('SEARCH')}
            ></Button>
          </div>
        </div>
        <TableReduction data={list} rowClick={tableRowClick} callback={tableCallback}></TableReduction>
        <div style={{ height: 40 }}></div>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalReductionAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalReductionAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={modalStyle}>
          <ModalReductionView
            action={selectedAction}
            onClose={closeViewModal}
            requestAction={tableCallback}
          ></ModalReductionView>
        </Box>
      </Modal>
    </article>
  );
};

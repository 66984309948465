import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { ModalLoading, PageHeader } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';
import Corsia from '../../styles/icon/corsia.png';

export const CreditRegistryDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { slug } = useParams();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [initData, setInitData] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const { LABEL, TABLE, COUNTRY, CREDIT_STATUS_LABEL, MSG, AREA_TYPE, TOOLTIPS } = useLabel();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const downloadRegistryPdf = useCallback(() => {
    handleClose();
    setSpinner(true);
    API.Registry.GetRegistryPdf(slug, { lang: isKorean ? 'ko' : 'en' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${isKorean ? initData?.project.title : initData?.project.titleEn}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setSpinner(false);
        updateSnackbar({
          type: 'success',
          open: true,
          message: `PDF ${MSG['DOWNLOAD_SUCCESS']}`,
        });
      })
      .catch((err) => {
        setSpinner(false);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.message,
        });
      });
  }, [handleClose, updateSnackbar, slug, MSG, isKorean, initData]);

  const getDetailData = () => {
    API.Registry.GetRegistryDetail('credit', slug)
      .then((res) => {
        let result = res.data;
        setInitData(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const country = useMemo(() => {
    if (initData) {
      const location = Handler.jsonParser(initData?.project?.lastProjectDoc?.location);
      return Handler.findCountry(location?.addr1?.address_components);
    }
  }, [initData]);

  const renderSecondRow = (data) => {
    return (
      <section className='flex mt-4'>
        <div className='p-3 w-1/3'>
          <p className='font-bold text-gray-500 mb-2'>{t('TRANSFEROR')}</p>
          <span>
            {initData.mode == 'Move'
              ? isKorean
                ? data?.lastCreditDoc?.assignorUserCompanyName || '-'
                : data?.lastCreditDoc?.assignorUserCompanyNameEn || '-'
              : '-'}
          </span>
        </div>
        <div className='p-3 w-1/3'>
          <p className='font-bold text-gray-500 mb-2'>{t('TRANSTEREE')}</p>
          <span>
            {initData.mode == 'Move'
              ? isKorean
                ? data?.lastCreditDoc?.assigneeUserCompanyName || '-'
                : data?.lastCreditDoc?.assigneeUserCompanyNameEn || '-'
              : '-'}
          </span>
        </div>
        <div className='p-3 w-1/3'>
          <div className='flex'>
            <p className='font-bold text-gray-500 mb-2'>{TABLE['ADDITIONAL_CERT']}</p>
            <Tooltip className='cursor-pointer' title={TOOLTIPS['ADDITIONAL_CERT']} placement='top' arrow>
              <InfoIcon className='-top-2 -right-4 text-gray-400' fontSize='10px' />
            </Tooltip>
          </div>
          {data?.certification ? (
            <>
              <div className='flex items-center'>
                <img src={Corsia} alt='corsia' width={'30px'} height={'30px'} />
                <p className='mx-2'>{`Eligible for ${data?.certification?.type}`}</p>
                <span className='text-sm text-gray-500'>
                  {isKorean ? TABLE['VALID_PERIOD'] : TABLE['ELIGIBLE_PERIOD']}: {data?.certification?.startYear} -{' '}
                  {data?.certification?.endYear}
                </span>
              </div>
            </>
          ) : (
            '-'
          )}
        </div>
      </section>
    );
  };

  useEffect(() => {
    if (slug) {
      getDetailData();
    }
  }, [slug]);

  if (!initData) return <></>;

  return (
    <article className='basic-page'>
      <PageHeader
        title={isKorean ? initData?.project.title : initData?.project.titleEn}
        parentPath='/registry/credit/list'
        parentText={t('CREDIT')}
        detail={TABLE['CREDIT_DETAIL']}
      />
      <Modal open={spinner}>
        <ModalLoading content={'Downloading...'} />
      </Modal>
      <section className='content__wrapper'>
        {/* 추후 반영 예정(24.02.27)
        <article className='w-full'>
          <p>{isKorean ? initData?.project.introOne : initData?.project.introOneEn}</p>
          <p>{isKorean ? initData?.project.introTwo : initData?.project.introTwoEn}</p>
        </article> */}
        <article className='w-full rounded-lg shadow-lg p-8 mt-8 mb-8'>
          <section className='text-left pb-8 border-b border-solid border-gray-200'>
            <div className='w-full flex justify-between'>
              <h2 className='mb-4'>{TABLE['CREDIT_INFO']}</h2>
              <IconButton p={0} onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className='p-3 cursor-pointer' onClick={downloadRegistryPdf}>
                  <p>{isKorean ? 'PDF 다운로드' : 'Download PDF'}</p>
                </div>
              </Popover>
            </div>
            <section className='flex'>
              <div className='p-3 w-1/3'>
                <p className='font-bold text-gray-500 mb-2'>
                  {t('CREDIT')} {t('STATUS')}
                </p>
                <span>{CREDIT_STATUS_LABEL[initData.mode]}</span>
                <span className='ml-5'>{Handler.getYYYYMMDDByUnix(initData.createdAt)}</span>
              </div>
              <div className='p-3 w-1/3'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['SERIAL_NO']}</p>
                <span>{initData.serialNumber}</span>
              </div>
              <div className='p-3 w-1/3'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['CREDIT_QUANTITY']}</p>
                <span>{Handler.numberWithCommas(initData.amount)} VRC</span>
              </div>
            </section>
            {renderSecondRow(initData)}
          </section>
          <section className='mt-10 text-left'>
            <h2 className='mb-4'>{TABLE['PROJECT_INFO']}</h2>
            <section className='flex'>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['PROJECT_ID']}</p>
                <span>{initData.project.id}</span>
              </div>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['ELIGIBLE_PERIOD']}</p>
                <span>{`${initData.project.startDate} ~ ${initData.project.endDate}`}</span>
              </div>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>
                  {TABLE['AREA']}
                  {' / '}
                  {TABLE['SUBAREA']}
                </p>
                <span>
                  {AREA_TYPE[initData?.project.methodology.area.toUpperCase()]} {' / '}
                  {AREA_TYPE[initData?.project.methodology.subArea.toUpperCase()]}
                </span>
              </div>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['ESTIMATED_REDUCTION']}</p>
                <span>{Handler.numberWithCommas(initData.project.reductionExpect)}</span>
                <span className='ml-1'>tCO₂eq</span>
              </div>
            </section>
            <section className='flex mt-4'>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['PROJECT_COUNTRY']}</p>
                <span>{country ? `${COUNTRY[country?.short_name]}(${country?.short_name})` : ''}</span>
              </div>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['PROJECT_COMPANY']}</p>
                <span>{isKorean ? initData?.project?.user?.companyName : initData?.project?.user?.companyNameEn}</span>
              </div>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['METHODOLOGY']}</p>
                <p className='mb-1 text-gray-400'>{initData.project.methodology.id}</p>
                <span className='break-all'>
                  {isKorean ? initData.project.methodology.title : initData.project.methodology.titleEn}
                </span>
              </div>
              <div className='p-3 w-1/4'>
                <p className='font-bold text-gray-500 mb-2'>{TABLE['PROJECT']}</p>
                <div className='bg-slate-50 p-2 w-24 text-center cursor-pointer'>
                  <span
                    className='font-bold text-gray-500'
                    onClick={() => history.push(`/registry/project/detail/${initData.project.seq}`)}
                  >
                    {t('VIEW_DETAIL')}
                  </span>
                </div>
              </div>
            </section>
          </section>
        </article>
        <article>
          <Button color='primary' variant='outlined' onClick={() => history.push('/registry/credit/list')}>
            {t('LIST')}
          </Button>
        </article>
      </section>
    </article>
  );
};

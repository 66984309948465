import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const URL_MAPPING = [
  {
    path: '/',
    text: 'Main',
    active: true,
  },
  {
    path: '/methodology',
    text: '방법론 관리',
    active: false,
  },
  {
    path: '/methodology/request',
    text: '방법론 상담 신청',
    active: true,
  },
  {
    path: '/methodology/list',
    text: '방법론 현황',
    active: true,
  },

  {
    path: '/mypage',
    text: '마이페이지',
    active: false,
  },
  {
    path: '/project',
    text: '사업 관리',
    active: false,
  },
  {
    path: '/project/request',
    text: '사전 상담신청',
    active: false,
  },
  {
    path: '/project/writing',
    text: '사업 신규 신청',
    active: false,
  },
  {
    path: '/project/list',
    text: '사업 현황',
    active: true,
  },
  {
    path: '/reduction',
    text: '감축량 인증 관리',
    active: false,
  },
  {
    path: '/reduction/writing',
    text: '감축량 인증 신청',
    active: false,
  },
  {
    path: '/reduction/list',
    text: '감축량 현황',
    active: true,
  },
  {
    path: '/credit',
    text: '팝플크레딧(VRC) 관리',
    active: false,
  },
  {
    path: '/credit/issue',
    text: '팝플크레딧(VRC) 발행 신청',
    active: false,
  },
  {
    path: '/credit/move',
    text: '팝플크레딧(VRC) 이전 신청',
    active: false,
  },
  {
    path: '/credit/offset',
    text: '팝플크레딧(VRC) 철회 신청',
    active: false,
  },
  {
    path: '/credit/list',
    text: '팝플크레딧(VRC) 현황',
    active: true,
  },

  {
    path: '/mypage/inquiry_list',
    text: '1:1 상담 내역',
    active: true,
  },
  {
    path: '/mypage/inquiry_detail',
    text: '1:1 상담 내역',
    active: false,
  },
  {
    path: '/mypage/inquiry_add',
    text: '1:1 상담 문의',
    active: false,
  },
  {
    path: '/mypage/inquiry_modify',
    text: '1:1 상담 문의 수정',
    active: false,
  },
  {
    path: '/mypage/modify',
    text: 'MODIFY_MYINFO',
    active: true,
  },
  {
    path: '/mypage/signout',
    text: 'RESIGN_MEMBERSHIP',
    active: true,
  },
  {
    path: '/mypage/signout_complete',
    text: 'RESIGN_MEMBERSHIP',
    active: true,
  },
  {
    path: '/cs',
    text: 'CS_CENTER',
    active: false,
  },
  {
    path: '/cs/contact_us',
    text: 'CONTACT_US',
    active: true,
  },
  {
    path: '/cs/notice',
    text: 'NOTICE',
    active: true,
  },
  {
    path: '/cs/board',
    text: 'BOARD',
    active: true,
  },
  {
    path: '/cs/board/details',
    text: 'BOARD_DETAILS',
    active: true,
  },
  {
    path: '/cs/faq',
    text: 'FAQ',
    active: true,
  },
];

const getPahtText = (value) => {
  for (let i in URL_MAPPING) {
    let target = URL_MAPPING[i];
    if (value === target.path) {
      return target.text;
    }
  }
  return null;
};

const getActive = (value) => {
  for (let i in URL_MAPPING) {
    let target = URL_MAPPING[i];
    if (value === target.path) {
      return target.active;
    }
  }
  return false;
};

const renderBreadcrumbs = (history, t) => {
  let pathArray = history.location.pathname.split('/');
  let URL_LIST = [];
  // console.log('pathArray', pathArray);

  for (let i in pathArray) {
    let path_value = '';

    //remove slug용
    if (Number(i) === pathArray.length - 1 && Number.isInteger(Number(pathArray[i]))) {
      break;
    }
    for (let j = 0; j <= i; j++) {
      if (Number(j) === Number(i)) {
        if (Number(j) === 0) {
          path_value += '/';
        } else {
          path_value += pathArray[j];
        }
      } else {
        path_value += pathArray[j] + '/';
      }
    }
    URL_LIST.push({
      path: path_value,
      text: getPahtText(path_value),
      active: getActive(path_value),
    });
  }

  return URL_LIST.map((item, index) => {
    const buildClassname = () => {
      if (history.location.pathname === item.path) {
        return 'on';
      }
    };
    return (
      <li
        key={item.path}
        className={buildClassname()}
        onClick={() => {
          if (item.active) {
            history.push(item.path);
          }
        }}
      >
        <span>{t(item.text)}</span>
      </li>
    );
  });
};

export const Breadcrumbs = (props) => {
  const history = useHistory();
  const { title, type, content } = props;
  const { t } = useTranslation();

  const renderClassname = () => {
    if (content) {
      return 'form-breadcrumbs ' + content;
    } else {
      return 'form-breadcrumbs';
    }
  };

  if (type && type === 'small') {
    return (
      <article className='form-small-breadcrumbs'>
        <ul>{renderBreadcrumbs(history, t)}</ul>
      </article>
    );
  } else {
    return (
      <article className={renderClassname()}>
        <div className='content__wraperr'>
          <section className='breadcrumbs__box'>
            <ul>{renderBreadcrumbs(history, t)}</ul>
          </section>
          <section className='title__box'>
            <h1>{t(title)}</h1>
          </section>
        </div>
      </article>
    );
  }
};

const SUBAREA_KOR = {
    "RenewableEnergy": "",
}

export const AREA = [
    { value: "Removal", label: "탄소제거" },
    { value: "Reduction", label: "탄소감축" }
]

export const SUBAREA = [
    { value: "Forestry", label: "산림" },
    { value: "BioChar", label: "바이오차" },
    { value: "SoilCarbon", label: "토양탄소" },
    { value: "DAC", label: "DAC" },
    { value: "RenewableEnergy", label: "재생에너지" },
    { value: "EMobility", label: "e-mobility" },
    { value: "Recycle", label: "재활용" },
    { value: "ReplaceElement", label: "대체물질" },
    { value: "EnergyConversion", label: "에너지전환" },
    { value: "Chemical", label: "화학공정" },
    { value: "Agriculture", label: "농업" },
    { value: "CCS", label: "CCS" },
    { value: "SecondHand", label: "중고거래" },
    { value: "ReplaceVehicle", label: "이동수단 대체" },
    { value: "Service", label: "서비스" },
    { value: "Etc", label: "기타" },
]
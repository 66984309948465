import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';

export const NotFoundPage = (props) => {
  const { t } = useTranslation();

  return (
    <article className='basic-page'>
      <section className='button__container' style={{ marginTop: 200, marginBottom: 200 }}>
        <h1>{t('PAGE_NOT_FOUND')}</h1>
      </section>
      <section className='button__container'>
        <Button
          style={{ width: '240px' }}
          onClick={() => {
            window.location.href = '/';
          }}
          label={t('GOTO_MAIN')}
        ></Button>
      </section>
    </article>
  );
};

import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { FileUploader, RadioBox, TextInput } from '../../components';

export const SelectEvidence = (props) => {
  const { t } = useTranslation();
  const { value, onChange, meta } = props;
  console.log('SelectEvidence meta', meta);
  console.log('SelectEvidence value', value);
  const onCahngeItem = (new_item, index) => {
    let new_evidence = _.clone(value);
    new_evidence[index] = new_item;
    onChange(new_evidence, 'evidence');
  };

  const renderHeader = (item, index) => {
    console.log('meta ======>', meta);
    console.log('index ======>', index);
    console.log('value ======>', value);
    return (
      <header>
        <span>{meta.label[index] && meta.label[index].title}</span>
        {meta.label[index] && meta.label[index].isForce ? <span className='text-red-600'>*</span> : null}
      </header>
    );
  };
  const renderRadioBox = (item, index) => {
    let target_meta = meta.label[index];
    // console.log('target_meta', target_meta);

    if (target_meta && target_meta.isForce) {
      return null;
    }
    return (
      <div className='radio__box'>
        <RadioBox
          value={item.type}
          onChange={(value, key) => {
            // console.log('value', value);
            // console.log('key', key);
            let new_item = _.clone(item);
            new_item.type = value;
            new_item.data = '';
            onCahngeItem(new_item, index);
          }}
          meta={{
            key: 'radio',
            menus: [
              {
                text: t('DO_SUBMIT'),
                label: t('DO_SUBMIT'),
                value: 'File',
              },
              {
                text: t('NOT_DO_SUBMIT'),
                label: t('NOT_DO_SUBMIT'),
                value: 'Reason',
              },
            ],
            disabled: meta.disabled,
            readOnly: meta.readOnly,
          }}
        ></RadioBox>
      </div>
    );
  };

  const renderFileBox = (item, index) => {
    let target_meta = meta.label[index];
    // console.log('target_meta', target_meta);

    if (item.type === 'File') {
      return (
        <div className='file__box'>
          <FileUploader
            value={item.data}
            onChange={(value, key) => {
              console.log('value', value);
              console.log('key', key);
              let new_item = _.clone(item);
              new_item.data = value;
              onCahngeItem(new_item, index);
            }}
            meta={{
              type: 'file',
              key: 'evidence-item-' + index,
              path: 'dummy',
              required: false,
              disabled: meta.disabled,
              readOnly: meta.readOnly,
            }}
          ></FileUploader>
        </div>
      );
    }
  };

  const renderTextInputBox = (item, index) => {
    if (item.type === 'Reason') {
      return (
        <div className='text-input__box'>
          <TextInput
            value={item.data}
            onChange={(value, key) => {
              console.log('value', value);
              console.log('key', key);
              let new_item = _.clone(item);
              new_item.data = value;
              onCahngeItem(new_item, index);
            }}
            meta={{
              key: 'text',
              required: false,
              disabled: meta.disabled,
              readOnly: meta.readOnly,
              placeholder: t('REASON_FOR_NOT_SUPPORTING_DOC'),
            }}
          ></TextInput>
        </div>
      );
    }
  };

  if (meta && meta.label && meta.label.length === 0) {
    return null;
  }
  if (value && value.length === 0) {
    return null;
  }
  return (
    <article
      className={Handler.BuildArticleClassname('form-select-evidence', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {value &&
        value.length > 0 &&
        value.map((item, index) => {
          return (
            <div className='evidence-item'>
              {renderHeader(item, index)}
              {renderRadioBox(item, index)}
              {renderFileBox(item, index)}
              {renderTextInputBox(item, index)}
            </div>
          );
        })}
    </article>
  );
};

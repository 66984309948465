import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import API from '../../api';

export const UserSignUpCompletePage = (props) => {
  const history = useHistory();
  const searchObject = queryString.parse(history.location.search);

  console.log('searchObject', searchObject);

  useEffect(() => {
    if (searchObject && searchObject.key) {
      API.User.PutSignupCertification({ key: searchObject.key }).then(
        (res) => {
          console.log('res', res);
        },
        (err) => {
          console.log('err', err);
        },
      );
    }
  }, []);

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='user-eamil__container'>
          <section className='mail__box'>
            <div className='image'></div>
            <h1>회원가입이 완료되었습니다.</h1>
          </section>
          <section className='guide__box'>
            <span>관리자 승인 후 서비스를 이용할 수 있습니다.</span>
          </section>
        </section>
      </article>
    </article>
  );
};

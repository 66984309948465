import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import RiskAssess from '../../styles/images/Frame 1.png';
import Process from '../../styles/images/process-arrows.png';

const styles = {
  paragraph: {
    fontSize: '16',
    color: '#606369',
    fontWeight: 400,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
  },
  button: {
    borderRadius: '0',
    height: 44,
    width: 215,
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px',
  },
};
export const ProgramPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('');
  const history = useHistory();
  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setActiveLink(sectionId);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.5, rootMargin: '0px -100px 0px 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);
  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 300,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: '700',
  };
  const goToDetailsPage = () => {
    history.push('/program/program/detail');
  };
  return (
    <article className='content h-full'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE] h-[395px]'>
          <PageHeader type='standard'>
            <div className='flex justify-between w-full'>
              <div>
                <h1 style={{ fontWeight: 600 }} className='text-[36px]'>{`${STANDARD_DOCUMENT_PAGE['TITLE']}`}</h1>
              </div>
              <div className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                  {STANDARD_DOCUMENT_PAGE['DESCRIPTION']}
                </p>
                <p className='description mt-5 whitespace-pre-wrap leading-7'>
                  {STANDARD_DOCUMENT_PAGE['DESCRIPTION_BOTTOM']}
                </p>
                <div className='mt-10'>
                  <Button
                    variant='contained'
                    style={{ borderRadius: '0', height: 56, width: 258, fontSize: '16px' }}
                    onClick={goToDetailsPage}
                  >
                    프로그램 상세 정보 바로가기 {<ArrowForwardIcon fontSize='medium' />}
                  </Button>
                </div>
              </div>
            </div>
          </PageHeader>
        </article>
        <div className='row-page-row py-[80px]' style={{ display: 'flex', top: '200px' }}>
          <div className='fixed-sidebar'>
            <nav className='fixed-sidebar flex' style={styles.sideBar}>
              <div
                style={{
                  borderRight: '1px solid #D7DAE2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingRight: 24,
                  width: 170,
                }}
              >
                <Button
                  onClick={() => handleNavClick('process')}
                  style={activeLink === 'principle' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('프로세스')}
                </Button>
                <Button
                  onClick={() => handleNavClick('safeguard-principle')}
                  style={activeLink === 'safeguard-principle' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('세이프가드 원칙')}
                </Button>
                <Button
                  onClick={() => handleNavClick('conflict')}
                  style={activeLink === 'conflict' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('이해 상충')}
                </Button>
                <Button
                  onClick={() => handleNavClick('list')}
                  style={activeLink === 'list' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('리스크 관리 평가')}
                </Button>
                <Button
                  onClick={() => handleNavClick('fee')}
                  style={activeLink === 'fee' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('수수료')}
                </Button>
                <Button
                  onClick={() => handleNavClick('billing')}
                  style={activeLink === 'billing' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('청구 가이드')}
                </Button>
                <Button
                  onClick={() => handleNavClick('problem-solving')}
                  style={activeLink === 'problem-solving' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('문제 해결')}
                </Button>
                <Button
                  onClick={() => handleNavClick('qa-management')}
                  style={activeLink === 'qa-management' ? styles.activeLink : inactiveLinkStyle}
                >
                  {t('품질관리 및 개선 조치')}
                </Button>
              </div>
            </nav>
          </div>

          <div className='content-section w-full ml-[100px] '>
            <section className='w-full py-[30px]'>
              <section id='process' data-anchor='process'>
                <article className='pb-12 '>
                  <div className='mb-7'>
                    <p className='mb-6 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      프로세스
                    </p>
                    <p className='whitespace-pre-wrap leading-6 mb-10' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['GOVERNANCE']['DESCRIPTION']}
                    </p>
                    <img src={Process} alt='프로세스' className='mb-8' />

                    <div className='whitespace-pre-wrap leading-7 mb-10 ml-1' style={{ ...styles.paragraph }}>
                      <p className='leading-7' style={{ ...styles.paragraph }}>
                        {STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][0]}
                      </p>
                      <p className='leading-7' style={{ ...styles.paragraph }}>
                        {STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][1]}
                      </p>
                      <p className='leading-7 ' style={{ ...styles.paragraph }}>
                        {STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][2]}
                      </p>
                      <p className='leading-7' style={{ ...styles.paragraph }}>
                        {STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][3]}
                      </p>
                      <p className='leading-7 ' style={{ ...styles.paragraph }}>
                        {STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][4]}
                      </p>
                      <p className='leading-7 ' style={{ ...styles.paragraph }}>
                        {STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][5]}
                      </p>
                    </div>
                  </div>
                </article>
              </section>
              <section id='safeguard-principle' data-anchor='safeguard-principle'>
                <div className='mb-12'>
                  <p className='mb-7 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                    세이프가드 원칙
                  </p>
                  <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                    {STANDARD_DOCUMENT_PAGE['SAFEGUARD']['DESCRIPTION']}
                  </p>
                  <div className=' flex justify-start '>
                    <Button variant='outlined' style={{ ...styles.button, width: '247px' }}>
                      세이프가드 원칙 및 가이드라인.pdf
                    </Button>
                  </div>
                </div>
              </section>
              <section id='conflict'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-7 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      이해 상충
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['CONFLICT-OF-INTEREST']['DESCRIPTION']}
                    </p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: '221px' }}>
                        이해 상충 관리 가이드라인.pdf
                      </Button>
                      <Button variant='outlined' style={{ ...styles.button, width: '176px' }}>
                        이해 상충 평가서.docx
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='list'>
                <article className='pb-12 '>
                  <div className='mb-7'>
                    <p className='mb-7 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      리스크 관리 평가
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['SUB_DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['SUB_DESCRIPTION_1']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['SUB_DESCRIPTION_2']}
                    </p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: '277px' }}>
                        비영속성 및 리스크 관리 가이드라인.pdf
                      </Button>
                    </div>
                    <div className='mt-7'>
                      <img src={RiskAssess} alt='리스크 관리 평가' />
                    </div>
                  </div>
                </article>
              </section>
              <section id='fee'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-7 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      수수료
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['FEE']['DESCRIPTION']}
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['FEE']['SUB_DESCRIPTION']}
                    </p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 191 }}>
                        프로그램 수수료 체계.pdf
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='billing'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-7 leading-6 ' style={{ fontWeight: '500', fontSize: '20px' }}>
                      청구 가이드
                    </p>
                    <p className='whitespace-pre-wrap leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][0]}
                    </p>
                    <p className='whitespace-pre-wrap leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][1]}
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][2]}
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][3]}
                    </p>
                    <div className='flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 162 }}>
                        청구 가이드라인.pdf
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='problem-solving'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-7 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      문제 해결
                    </p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['PROBLEM-SOLVING']['DESCRIPTION']}
                    </p>
                    <div className=' flex justify-start '>
                      <Button variant='outlined' style={{ ...styles.button, width: 153 }}>
                        불만 해결 정책.pdf
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='qa-management'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-7 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      품질 관리 및 개선조치
                    </p>
                    <p
                      className='whitespace-pre-wrap text-gray-700 leading-7 mb-5'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['DESCRIPTION'] }}
                    ></p>
                    <p
                      className='whitespace-pre-wrap text-gray-700 leading-7 mb-5'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['SUB_DESCRIPTION_1'] }}
                    ></p>

                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}></p>
                    <p className='whitespace-pre-wrap text-gray-700 leading-7 mb-5' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['SUB_DESCRIPTION_2']}
                      {STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['SUB_DESCRIPTION_3']}
                    </p>
                  </div>
                </article>
              </section>
            </section>
          </div>
        </div>
      </article>
      <article className='bg-[#5D38E5] h-[320px]'>
        <div
          className='flex justify-center w-full '
          style={{ display: 'flex', alignSelf: 'center', justifyItems: 'center' }}
        >
          <h2 style={{ fontSize: '24px', fontWeight: 500, lineHeight: '26px' }} className='flex text-white mt-[80px]'>
            프로그램에 대한 전반적인 정책을 이해하셨나요?
          </h2>
        </div>
        <div className='flex justify-center'>
          <p className='mt-5 text-white' style={{ fontSize: '16px', lineHeight: '26px', fontWeight: 200 }}>
            프로그램의 구체적인 방법과 절차에 대한 상세 정보를 원하신다면 아래 버튼을 클릭해 주세요.
          </p>
        </div>
        <div className='flex justify-center mt-7'>
          <Button
            onClick={goToDetailsPage}
            variant='contained'
            sx={{ borderRadius: 0, width: 298, height: 56, backgroundColor: '#222222', fontSize: '16px' }}
          >
            프로그램 상세 정보 바로가기 {<ArrowForwardIcon fontSize='medium' />}
          </Button>
        </div>
      </article>
    </article>
  );
};

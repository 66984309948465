import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import {
  BasicTab,
  Button,
  ContentCredit,
  ModalCreditAction,
  ModalCreditView,
  SelectBox,
  TableCreditHistory,
  TablePaging,
} from '../../components';
import { SnackbarContext, UserContext } from '../../contexts';
import { useForm, usePagination } from '../../hooks';
import { docStyle, modalStyle } from '../../styles/style';

const getSmallModeByMode = (mode) => mode.toLowerCase();

export const CreditDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const { slug } = useParams();
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [initData, setInitData] = useState(null);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [ordering, setOrdering] = useState('DESC');
  const [formData, formMeta, setFormData, setFormMeta] = useForm(
    {
      name: '',
      phone: '',
      email: '',
      team: '',
      //move
      assignorName: '',
      assignorPhone: '',
      assignorEmail: '',
      assignorTeam: '',
      assigneeUserSeq: '',
      assigneeName: '',
      assigneePhone: '',
      assigneeEmail: '',
      assigneeTeam: '',
      projectSeq: '',
      amount: '',
      reason: '', //reason은 offset도 포함
      reasonDetail: '',
      files: [],
    },
    {
      name: {
        key: 'name',
        label: t('CONTACT_NAME'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      team: {
        key: 'team',
        label: 'DEPARTMENT',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      email: {
        key: 'email',
        label: 'EMAIL',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      //move variables
      assignorName: {
        key: 'assignorName',
        label: '담당자명',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorPhone: {
        key: 'assignorPhone',
        label: t('MOBILE'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorTeam: {
        key: 'assignorTeam',
        label: 'DEPARTMENT',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      assignorEmail: {
        key: 'assignorEmail',
        label: 'EMAIL',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeName: {
        key: 'assigneeName',
        label: '담당자명',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneePhone: {
        key: 'assigneePhone',
        label: t('MOBILE'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeTeam: {
        key: 'assigneeTeam',
        label: '부서명',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeEmail: {
        key: 'assigneeEmail',
        label: 'DEPARTMENT',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      projectSeq: {
        key: 'projectSeq',
        label: '사업명 선택',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        menus: [],
      },
      amount: {
        key: 'amount',
        label: t('QUANTITY'),
        required: true,
        style: {
          minWidth: '420px',
          maxWidth: '420px',
        },
      },
      reason: {
        key: 'reason',
        label: '이전 사유',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: t('BIZ_MERGERS'),
            label: t('BIZ_MERGERS'),
          },
          {
            value: t('BIZ_TRANSFER'),
            label: t('BIZ_TRANSFER'),
          },
          {
            value: t('BIZ_PARTICIPATION'),
            label: t('BIZ_PARTICIPATION'),
          },
          {
            value: t('OVER_TRANSACTION'),
            label: t('OVER_TRANSACTION'),
          },
          {
            value: t('ETC'),
            label: t('ETC'),
          },
        ],
      },
      reasonDetail: {
        key: 'reasonDetail',
        label: '이전 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      files: {
        key: 'files',
        label: t('FILE'),
        style: {
          gridColumn: '1/3',
        },
      },
      //offset
      offsetReason: {
        key: 'reason',
        label: '상쇄 사유',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: t('BIZ_MERGERS'),
            label: t('BIZ_MERGERS'),
          },
          {
            value: t('BIZ_TRANSFER'),
            label: t('BIZ_TRANSFER'),
          },
          {
            value: t('BIZ_PARTICIPATION'),
            label: t('BIZ_PARTICIPATION'),
          },
          {
            value: t('ETC'),
            label: t('ETC'),
          },
        ],
      },
      reasonCustom: {
        key: 'reasonCustom',
        label: '상쇄 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    //issue
    requestCredit: null,
    //move
    reductionNow: null,
  });
  const [lastDocument, setLastDocument] = useState(null);

  const [
    selectedFormData,
    selectedFormMeta,
    selectedFormChange,
    setSelectedFormData,
    setSelectedFormMeta,
    selectedFormReset,
  ] = useForm(
    {
      name: '',
      phone: '',
      email: '',
      team: '',
    },
    {
      name: {
        key: 'name',
        label: t('CONTACT_NAME'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      phone: {
        key: 'phone',
        label: t('CONTACT_MOBILE'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      team: {
        key: 'team',
        label: t('DEPARTMENT'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      email: {
        key: 'email',
        label: t('EMAIL'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      //move variables
      assignorName: {
        key: 'assignorName',
        label: t('CONTACT_NAME'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorPhone: {
        key: 'assignorPhone',
        label: t('CONTACT_MOBILE'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorTeam: {
        key: 'assignorTeam',
        label: t('DEPARTMENT'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorEmail: {
        key: 'assignorEmail',
        label: t('EMAIL'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeName: {
        key: 'assigneeName',
        label: t('CONTACT_NAME'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneePhone: {
        key: 'assigneePhone',
        label: t('CONTACT_MOBILE'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeTeam: {
        key: 'assigneeTeam',
        label: t('DEPARTMENT'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeEmail: {
        key: 'assigneeEmail',
        label: t('EMAIL'),
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      projectSeq: {
        key: 'projectSeq',
        label: '사업명 선택',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        menus: [],
      },
      amount: {
        key: 'amount',
        label: '크레딧 이전 수량',
        required: true,
        style: {
          minWidth: '420px',
          maxWidth: '420px',
        },
      },
      reason: {
        key: 'reason',
        label: '이전 사유',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: t('BIZ_MERGERS'),
            label: t('BIZ_MERGERS'),
          },
          {
            value: t('BIZ_TRANSFER'),
            label: t('BIZ_TRANSFER'),
          },
          {
            value: t('BIZ_PARTICIPATION'),
            label: t('BIZ_PARTICIPATION'),
          },
          {
            value: t('OVER_TRANSACTION'),
            label: t('OVER_TRANSACTION'),
          },
          {
            value: t('ETC'),
            label: t('ETC'),
          },
        ],
      },
      reasonDetail: {
        key: 'reasonDetail',
        label: '이전 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      files: {
        key: 'files',
        label: '파일 첨부',
        style: {
          gridColumn: '1/3',
        },
      },
      //offset
      offsetReason: {
        key: 'reason',
        label: '상쇄 사유',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: '사업 이전',
            label: '사업 이전',
          },
          {
            value: '사업 중단/취소',
            label: '사업 중단/취소',
          },
          {
            value: '기업 인수/합병',
            label: '기업 인수/합병',
          },
          {
            value: '직접 입력',
            label: '직접 입력',
          },
        ],
      },
      reasonCustom: {
        key: 'reasonCustom',
        label: '상쇄 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
    },
  );
  const [selectedContentMeta, setSelectedContentMeta] = useState({
    requestCredit: null,
  });
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentOpen, setDocumentOpen] = useState(false);

  //hooks

  useEffect(() => {
    if (slug && page) {
      requestNewList();
    }
  }, [slug, page]);

  useEffect(() => {
    if (slug && ordering) {
      refreshData(0);
      requestNewList(0);
    }
  }, [slug, ordering]);

  useEffect(() => {
    if (initData) {
      //초기 값 셋팅 후, 최신 버전 도큐멘트 가져오기
      if (initData.lastCreditDoc.version) {
        API.Credit.GetActionDoc(slug, { version: initData.lastCreditDoc.version })
          .then((res) => {
            let result = res.data;
            console.log('GetActionDoc result', result);
            setLastDocument(result);
          })
          .catch((err) => {
            console.log('err', err);
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
      }
    }
  }, [initData]);

  useEffect(() => {
    if (lastDocument) {
      console.log('lastDocument', lastDocument);

      switch (initData.mode) {
        case 'Issue': {
          let new_contentMeta = _.clone(contentMeta);
          new_contentMeta.companyName = lastDocument.credit.project.user.companyName;
          new_contentMeta.businessNumber = lastDocument.credit.project.user.businessNumber;
          new_contentMeta.ceoName = lastDocument.credit.project.user.ceoName;
          new_contentMeta.phone = lastDocument.credit.project.user.phone;
          new_contentMeta.address = lastDocument.credit.project.user.address;
          new_contentMeta.projectTitle = lastDocument.credit.project.title;
          new_contentMeta.monitorDegree = lastDocument.credit.project.monitorDegree;
          new_contentMeta.requestCredit = lastDocument.amount;
          setContentMeta(new_contentMeta);

          let new_formData = _.clone(formData);
          new_formData.name = lastDocument.assignorName;
          new_formData.phone = lastDocument.assignorPhone;
          new_formData.team = lastDocument.assignorTeam;
          new_formData.email = lastDocument.assignorEmail;
          setFormData(new_formData);
          break;
        }
        case 'Move': {
          let new_contentMeta = _.clone(contentMeta);
          new_contentMeta.companyName = lastDocument.credit.project.user.companyName;
          new_contentMeta.businessNumber = lastDocument.credit.project.user.businessNumber;
          new_contentMeta.ceoName = lastDocument.credit.project.user.ceoName;
          new_contentMeta.phone = lastDocument.credit.project.user.phone;
          new_contentMeta.address = lastDocument.credit.project.user.address;
          new_contentMeta.projectTitle = lastDocument.credit.project.title;
          new_contentMeta.companyNameAssignee = lastDocument.assigneeUser?.companyName;
          new_contentMeta.businessNumberAssignee = lastDocument.assigneeUser?.businessNumber;
          new_contentMeta.ceoNameAssignee = lastDocument.assigneeUser?.ceoName;
          new_contentMeta.phoneAssignee = lastDocument.assigneeUser?.phone;
          new_contentMeta.addressAssignee = lastDocument.assigneeUser?.address;
          setContentMeta(new_contentMeta);

          let new_formData = _.clone(formData);
          new_formData.assignorName = lastDocument.assignorName;
          new_formData.assignorEmail = lastDocument.assignorEmail;
          new_formData.assignorPhone = lastDocument.assignorPhone;
          new_formData.assignorTeam = lastDocument.assignorTeam;
          new_formData.assigneeName = lastDocument.assigneeName;
          new_formData.assigneePhone = lastDocument.assigneePhone;
          new_formData.assigneeEmail = lastDocument.assigneeEmail;
          new_formData.assigneeTeam = lastDocument.assigneeTeam;
          new_formData.files = lastDocument.moveFiles;
          new_formData.amount = lastDocument.amount;
          new_formData.reason = lastDocument.reason;
          new_formData.reasonDetail = lastDocument.reasonDetail;
          setFormData(new_formData);

          break;
        }
        case 'Offset': {
          let new_contentMeta = _.clone(contentMeta);
          new_contentMeta.companyName = lastDocument.credit.project.user.companyName;
          new_contentMeta.businessNumber = lastDocument.credit.project.user.businessNumber;
          new_contentMeta.ceoName = lastDocument.credit.project.user.ceoName;
          new_contentMeta.address = lastDocument.credit.project.user.address;
          new_contentMeta.phone = lastDocument.credit.project.user.phone;
          new_contentMeta.projectTitle = lastDocument.credit.project.title;
          new_contentMeta.amount = lastDocument.amount;

          setContentMeta(new_contentMeta);

          let new_formData = _.clone(formData);
          new_formData.name = lastDocument.assignorName;
          new_formData.email = lastDocument.assignorEmail;
          new_formData.phone = lastDocument.assignorPhone;
          new_formData.team = lastDocument.assignorTeam;
          new_formData.reason = lastDocument.reason;
          new_formData.reasonDetail = lastDocument.reasonDetail;
          setFormData(new_formData);

          break;
        }
        default: {
          console.log('not mode');
        }
      }
    }
  }, [lastDocument]);

  useEffect(() => {
    if (selectedDocument) {
      console.log('selectedDocument', selectedDocument);
      console.log('selectedDocument initData', initData);

      switch (initData.mode) {
        case 'Issue': {
          let new_contentMeta = _.clone(selectedContentMeta);
          new_contentMeta.companyName = selectedDocument.credit.project.user.companyName;
          new_contentMeta.businessNumber = selectedDocument.credit.project.user.businessNumber;
          new_contentMeta.ceoName = selectedDocument.credit.project.user.ceoName;
          new_contentMeta.phone = selectedDocument.credit.project.user.phone;
          new_contentMeta.address = selectedDocument.credit.project.user.address;
          new_contentMeta.projectTitle = selectedDocument.credit.project.title;
          new_contentMeta.monitorDegree = selectedDocument.credit.project.monitorDegree;
          new_contentMeta.requestCredit = selectedDocument.amount;

          setSelectedContentMeta(new_contentMeta);

          let new_formData = _.clone(selectedFormData);
          new_formData.name = selectedDocument.assignorName;
          new_formData.phone = selectedDocument.assignorPhone;
          new_formData.team = selectedDocument.assignorTeam;
          new_formData.email = selectedDocument.assignorEmail;
          setSelectedFormData(new_formData);
          break;
        }
        case 'Move': {
          let new_contentMeta = _.clone(selectedContentMeta);
          new_contentMeta.companyName = selectedDocument.credit.project.user.companyName;
          new_contentMeta.businessNumber = selectedDocument.credit.project.user.businessNumber;
          new_contentMeta.ceoName = selectedDocument.credit.project.user.ceoName;
          new_contentMeta.phone = selectedDocument.credit.project.user.phone;
          new_contentMeta.address = selectedDocument.credit.project.user.address;
          new_contentMeta.projectTitle = selectedDocument.credit.project.title;
          new_contentMeta.companyNameAssignee = selectedDocument.assigneeUser.companyName;
          new_contentMeta.businessNumberAssignee = selectedDocument.assigneeUser.businessNumber;
          new_contentMeta.ceoNameAssignee = selectedDocument.assigneeUser.ceoName;
          new_contentMeta.phoneAssignee = selectedDocument.assigneeUser.phone;
          new_contentMeta.addressAssignee = selectedDocument.assigneeUser.address;
          setSelectedContentMeta(new_contentMeta);

          let new_formData = _.clone(selectedFormData);
          new_formData.assignorName = selectedDocument.assignorName;
          new_formData.assignorEmail = selectedDocument.assignorEmail;
          new_formData.assignorPhone = selectedDocument.assignorPhone;
          new_formData.assignorTeam = selectedDocument.assignorTeam;
          new_formData.assigneeName = selectedDocument.assigneeName;
          new_formData.assigneePhone = selectedDocument.assigneePhone;
          new_formData.assigneeEmail = selectedDocument.assigneeEmail;
          new_formData.assigneeTeam = lastDocument.assigneeTeam;
          new_formData.files = selectedDocument.moveFiles;
          new_formData.amount = selectedDocument.amount;
          new_formData.reason = selectedDocument.reason;
          new_formData.reasonDetail = selectedDocument.reasonDetail;
          setSelectedFormData(new_formData);
          break;
        }
        case 'Offset': {
          let new_contentMeta = _.clone(selectedContentMeta);
          new_contentMeta.companyName = selectedDocument.credit.project.user.companyName;
          new_contentMeta.businessNumber = selectedDocument.credit.project.user.businessNumber;
          new_contentMeta.ceoName = selectedDocument.credit.project.user.ceoName;
          new_contentMeta.address = selectedDocument.credit.project.user.address;
          new_contentMeta.phone = selectedDocument.credit.project.user.phone;
          new_contentMeta.projectTitle = selectedDocument.credit.project.title;
          new_contentMeta.amount = selectedDocument.amount;
          setSelectedContentMeta(new_contentMeta);

          let new_formData = _.clone(selectedFormData);
          new_formData.name = selectedDocument.assignorName;
          new_formData.email = selectedDocument.assignorEmail;
          new_formData.phone = selectedDocument.assignorPhone;
          new_formData.team = selectedDocument.assignorTeam;
          new_formData.reason = selectedDocument.reason;
          new_formData.reasonDetail = selectedDocument.reasonDetail;
          setSelectedFormData(new_formData);
          break;
        }

        default: {
          console.log('not mode');
        }
      }
    }
  }, [selectedDocument]);

  //event
  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
  };

  const tableCallback = (next_action, item) => {
    console.log('tableCallback next_action', next_action);
    console.log('tableCallback item', item);
    switch (next_action) {
      case 'View': {
        setSelectedAction(item);
        setViewOpen(true);
        // setOpen(true);
        break;
      }
      case 'ModifyWriting': {
        console.log('ModifyWriting API CALL initData', initData);
        history.push(`/credit/${initData.mode.toLowerCase()}?seq=` + initData.seq);
        break;
      }

      case 'ImproveCommitteeStart':
      case 'ImproveZCCreditStart': {
        console.log('ImproveZCCreditStart API CALL item', initData);

        API.Credit.PostNextAction(slug, {
          nextAction: next_action,
        }).then(
          (res) => {
            closeActionModal();
            closeViewModal();
            requestNewList();
            setSelectedAction({
              status: 'RequestMoveWriting',
              seq: slug,
              mode: initData.mode,
            });
            setViewOpen(true);
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
        break;
      }
      case 'Document': {
        getNewDcoument(item.version);
        setDocumentOpen(true);
        break;
      }
      default: {
        setSelectedAction(item.lastDoc.actionSeq);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
    }
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeViewModal = () => {
    setViewOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeDocumnetModal = () => {
    setDocumentOpen(false);
  };

  // server
  const refreshData = () => {
    API.Credit.GetDetail(slug)
      .then((res) => {
        let result = res.data;
        console.log('getDetail  result', result);
        setInitData(result);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const requestNewList = (init_page) => {
    let params = {
      page: init_page ? init_page : page,
      size: Handler.VARIABLES.PAGE_LIMIT,
      ordering: ordering,
    };
    API.Credit.GetAction(slug, params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const handleModalRequest = (body) => {
    console.log('handleModalRequest', body);
    API.Credit.PostNextAction(slug, body)
      .then((res) => {
        closeActionModal();
        refreshData();
        requestNewList(0);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const getNewDcoument = (version) => {
    API.Credit.GetActionDoc(slug, { version: version })
      .then((res) => {
        let result = res.data;
        setSelectedDocument(result);
      })
      .catch((err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  //render

  if (!initData) {
    return null;
  }

  const renderStatus = (actionSeq) => {
    let status_text = t(Handler.ENUM.CREDIT_ACTION.STATUS(actionSeq.status));
    if (
      [
        'WaitingForAuditCommittee',
        'AuditLaunch',
        'AuditZCCredit',
        'WaitingForAuditLaunch',
        'WaitingForAuditZCCredit',
      ].includes(actionSeq.status)
    ) {
      status_text += `(${actionSeq.degree})`;
    }
    return (
      <>
        <span>{status_text}</span>
        <em>{Handler.getYYYYMMDDByUnix(actionSeq.createdAt)}</em>
      </>
    );
  };

  const renderJob = (item) => {
    const { nextActionTypeList } = item;
    const isMain = (next_action) =>
      next_action === 'ImproveCommitteeComplete' ||
      next_action === 'ImproveCommitteeStart' ||
      next_action === 'ImproveZCCreditComplete' ||
      next_action === 'ImproveZCCreditStart'
        ? 'main'
        : null;

    return (
      <div className='button__box'>
        {nextActionTypeList &&
          nextActionTypeList.map((target_action, index) => {
            return (
              <button
                className={isMain(target_action)}
                onClick={(e) => {
                  e.stopPropagation();
                  tableCallback(target_action, item);
                }}
              >
                {Handler.ENUM.CREDIT_ACTION.NEXT(target_action)}
              </button>
            );
          })}
        {nextActionTypeList?.length === 0 && <span>-</span>}
      </div>
    );
  };

  return (
    <article className='basic-page'>
      <article className='project-header__container'>
        <div className='content__wrapper'>
          <section className='row__box' style={{ marginTop: 20 }}>
            <ul>
              <li>
                <header>
                  <span>신청 유형</span>
                </header>
                <div className='value'>
                  <span>{t(initData.mode.toUpperCase())}</span>
                </div>
              </li>
              <li>
                <header>
                  <span>
                    {t(initData.mode.toUpperCase())} {t('QUANTITY')} (VRC)
                  </span>
                </header>
                <div className='value'>
                  <span>{Handler.numberWithCommas(initData.lastCreditDoc.actionSeq.amount)}</span>
                </div>
              </li>
              <li>
                <header>
                  <span>{t('BIZ_ID')}</span>
                </header>
                <div className='value'>{initData.project.id}</div>
              </li>
              <li style={{ flex: '2' }}>
                <header>
                  <span>{t('BIZ_TITLE')}</span>
                </header>
                <div className='value'>{initData.project.title}</div>
              </li>
              <li>
                <header>
                  <span>{t('STATUS')}</span>
                  <em>(최근일시)</em>
                </header>
                <div className='value'>{renderStatus(initData.lastCreditDoc.actionSeq)}</div>
              </li>
              <li>
                <header>
                  <span>작업</span>
                </header>
                <div className='value'>{renderJob(initData)}</div>
              </li>
            </ul>
          </section>
          <section className='button-list__box'>
            <div>
              <Button
                type='secondary'
                style={{ width: '96px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  history.push('/credit/list');
                }}
                label={t('LIST')}
              ></Button>
            </div>
            <div>
              {userInfo && initData.project.user?.seq === userInfo.seq && (
                <Button
                  type='secondary'
                  style={{ width: '180px', height: '50px', marginRight: 12 }}
                  onClick={() => {
                    history.push('/project/detail/' + initData.project.seq);
                  }}
                  label={t('BIZ_DETAIL')}
                ></Button>
              )}
              <Button
                disabled={
                  initData.lastCreditDoc.actionSeq.status !== 'AuditApproved' &&
                  initData.lastCreditDoc.actionSeq.status !== 'CommitteeApproved'
                }
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  window.open('https://pople.kr');
                }}
                label='Market Place'
                ico='store'
              ></Button>
            </div>
          </section>
        </div>
      </article>
      <article className='content__wrapper'>
        <div style={{ height: 40 }}></div>
        <BasicTab
          index={tabIndex}
          meta={{
            menus: ['이력 조회', '신청 정보'],
          }}
          onChange={(value) => {
            setTabIndex(value);
          }}
        ></BasicTab>
        {tabIndex === 0 && (
          <>
            <div style={{ height: 20 }}></div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>{t('HISTORY')}</h2>
              <SelectBox
                value={ordering}
                onChange={(value) => {
                  setOrdering(value);
                }}
                meta={{
                  className: 'round',
                  style: { width: '120px' },
                  menus: [
                    {
                      value: 'DESC',
                      label: '일자 ↓',
                    },
                    {
                      value: 'ASC',
                      label: '일자 ↑',
                    },
                  ],
                }}
              ></SelectBox>
            </div>
            <div style={{ height: 20 }}></div>
            <TableCreditHistory data={list} rowClick={tableRowClick} callback={tableCallback}></TableCreditHistory>
            <div style={{ height: 40 }}></div>
            <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
          </>
        )}
        {tabIndex !== 0 && (
          <ContentCredit
            mode={getSmallModeByMode(initData.mode)}
            formData={formData}
            formMeta={formMeta}
            formChange={() => {}}
            setFormData={setFormData}
            setFormMeta={setFormMeta}
            contentMeta={contentMeta}
            contentMetaChange={() => {}}
            readOnly={true}
            type={null}
            callback={() => {}}
          ></ContentCredit>
        )}
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalCreditAction
            mode={getSmallModeByMode(initData.mode)}
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalCreditAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={modalStyle}>
          <ModalCreditView
            mode={getSmallModeByMode(initData.mode)}
            action={selectedAction}
            onClose={closeViewModal}
            requestAction={tableCallback}
          ></ModalCreditView>
        </Box>
      </Modal>
      <Modal open={documentOpen} onClose={closeDocumnetModal}>
        <Box sx={docStyle}>
          <div className='project-document__modal'>
            <section className='header__box'>
              <div>
                <h4>{t('VIEW_DOC')}</h4>
                <span>{selectedDocument && selectedDocument.version}</span>
              </div>
              <div>
                <div
                  className='ico close-small'
                  onClick={() => {
                    closeDocumnetModal();
                  }}
                ></div>
              </div>
            </section>
            <section className='document__box'>
              <ContentCredit
                mode={getSmallModeByMode(initData.mode)}
                formData={selectedFormData}
                formMeta={selectedFormMeta}
                formChange={() => {}}
                setFormData={setSelectedFormData}
                setFormMeta={setSelectedFormMeta}
                contentMeta={selectedContentMeta}
                contentMetaChange={() => {}}
                readOnly={true}
                type={null}
              ></ContentCredit>
            </section>
          </div>
        </Box>
      </Modal>
    </article>
  );
};

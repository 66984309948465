import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Button, ContentCredit, ModalCreditAction, ModalCreditAssignee, PageHeader } from '../../components';
import { SnackbarContext, UserContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';
import { modalStyle } from '../../styles/style';

const renderBreadcrumbsTitle = (type) => {
  if (type === 'issue') return 'APPLY_VRC_ISSUE';
  if (type === 'move') return 'TRANSFER_VRC';
  if (type === 'offset') return 'REQ_WITHDRAWAL';
  if (type === 'retire') return 'REQ_DISPOSAL';
  return '';
};

const getModeByUrl = (type) => type.charAt(0).toUpperCase() + type.slice(1);

export const CreditWritingPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { ERROR, PURCHASE_REASON_LIST } = useLabel();
  const searchObject = queryString.parse(history.location.search);
  const slug = searchObject.seq ? Number(searchObject.seq) : null;
  const mode = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const { userInfo } = useContext(UserContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [forceReadOnly] = useState(false);
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      //issue variables
      name: '',
      phone: '',
      email: '',
      team: '',
      reductionCertSeq: '',
      //move
      assignorName: '',
      assignorPhone: '',
      assignorEmail: '',
      assignorTeam: '',
      assignorUserSeq: '',
      assignorUserCompanyName: '',
      assignorUserName: '',
      assigneeUserSeq: '',
      assigneeName: '',
      assigneePhone: '',
      assigneeEmail: '',
      assigneeTeam: '',
      projectSeq: '',
      zcCreditSeq: '',
      amount: 0,
      reason: '', //reason은 offset도 포함
      reasonDetail: '',
      files: [],
      reasonCustom: '',
    },
    {
      //issue variables
      name: {
        key: 'name',
        label: 'RESPONSIBILITY',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      phone: {
        key: 'phone',
        label: 'CONTACT_MOBILE',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      team: {
        key: 'team',
        label: 'DEPARTMENT',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      email: {
        key: 'email',
        label: 'RESPONSIBILITY_EMAIL',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      // issueProjectSeq: {
      //   key: 'issueProjectSeq',
      //   label: 'BIZ_TITLE',
      //   required: true,
      //   style: {
      //     gridColumn: '1/3',
      //   },
      //   menus: [],
      // },
      reductionCertSeq: {
        key: 'reductionCertSeq',
        label: 'BIZ_TITLE',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        menus: [],
      },
      //move variables
      assignorName: {
        key: 'assignorName',
        label: 'RESPONSIBILITY',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorPhone: {
        key: 'assignorPhone',
        label: 'CONTACT_MOBILE',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorTeam: {
        key: 'assignorTeam',
        label: 'DEPARTMENT',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assignorEmail: {
        key: 'assignorEmail',
        label: 'RESPONSIBILITY_EMAIL',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeUserSeq: {
        key: 'assigneeUserSeq',
        label: 'ASSIGNEE_USER',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeName: {
        key: 'assigneeName',
        label: 'RESPONSIBILITY',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneePhone: {
        key: 'assigneePhone',
        label: 'CONTACT_MOBILE',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeTeam: {
        key: 'assigneeTeam',
        label: 'DEPARTMENT',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      assigneeEmail: {
        key: 'assigneeEmail',
        label: 'RESPONSIBILITY_EMAIL',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      projectSeq: {
        key: 'projectSeq',
        label: 'BIZ_TITLE',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        menus: [],
      },
      zcCreditSeq: {
        key: 'zcCreditSeq',
        label: 'CREDIT',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        menus: [],
      },
      amount: {
        key: 'amount',
        label: 'CREDIT_TRANSFER_QUANTITY',
        type: 'number',
        required: true,
        style: {
          minWidth: '420px',
          maxWidth: '420px',
        },
      },
      reason: {
        key: 'reason',
        label: 'CREDIT_TRANSFER_REASON',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: t('BIZ_MERGERS'),
            label: t('BIZ_MERGERS'),
          },
          {
            value: t('BIZ_TRANSFER'),
            label: t('BIZ_TRANSFER'),
          },
          {
            value: t('BIZ_PARTICIPATION'),
            label: t('BIZ_PARTICIPATION'),
          },
          {
            value: t('OVER_TRANSACTION'),
            label: t('OVER_TRANSACTION'),
          },
          {
            value: t('DIRECT_INPUT_SPACE'),
            label: t('DIRECT_INPUT_SPACE'),
          },
        ],
      },
      reasonDetail: {
        key: 'reasonDetail',
        label: 'CREDIT_TRANSFER_REASON_DETAIL',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      files: {
        key: 'files',
        label: 'FILE',
        style: {
          gridColumn: '1/3',
        },
      },
      //offset
      offsetReason: {
        key: 'reason',
        label: 'WITHDRAWAL_REASON',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: [
          {
            value: t('BIZ_MERGERS'),
            label: t('BIZ_MERGERS'),
          },
          {
            value: t('BIZ_TRANSFER'),
            label: t('BIZ_TRANSFER'),
          },
          {
            value: t('BIZ_PARTICIPATION'),
            label: t('BIZ_PARTICIPATION'),
          },
          {
            value: t('OVER_TRANSACTION'),
            label: t('OVER_TRANSACTION'),
          },
          {
            value: t('DIRECT_INPUT_SPACE'),
            label: t('DIRECT_INPUT_SPACE'),
          },
        ],
      },
      reasonCustom: {
        key: 'reasonCustom',
        label: '철회 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      retireReason: {
        key: 'reason',
        label: 'DISPOSAL_REASON',
        required: true,
        style: {
          maxWidth: '420px',
          gridColumn: '1/3',
        },
        menus: PURCHASE_REASON_LIST.map((el) => {
          return { label: el, value: el };
        }),
      },
      retireReasonCustom: {
        key: 'reasonCustom',
        label: '폐기 사유 상세',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    //issue
    requestCredit: null,
    //move
    reductionNow: null,
  });
  const [originData, setOriginData] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [holdUpdate, setHoldUpdate] = useState(false);
  //보완 완료용 모달
  const [actionOpen, setActionOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);

  //hooks
  useEffect(() => {
    API.Credit.GetProject({
      mode: getModeByUrl(mode),
    })
      .then((res) => {
        setProjectList(res.data);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  }, []);

  useEffect(() => {
    if (userInfo) {
      setContentMeta({
        ...contentMeta,
        ...userInfo,
      });
    }
  }, [userInfo]);

  //project server 분기처리
  useEffect(() => {
    if (projectList && userInfo) {
      switch (mode) {
        case 'issue': {
          // 보완 case
          if (slug) {
            API.Credit.GetIssue(slug)
              .then((res) => {
                let result = res.data;
                setOriginData(result);
                setHoldUpdate(true);

                let project_menus = Handler.buildIssueProjectMenus(projectList);
                let new_formMeta = _.clone(formMeta);
                new_formMeta['reductionCertSeq'].menus = project_menus;
                setFormMeta(new_formMeta);

                let new_formData = _.clone(formData);
                new_formData.name = result.assignorName;
                new_formData.email = result.assignorEmail;
                new_formData.phone = result.assignorPhone;
                new_formData.team = result.assignorTeam;
                new_formData.reductionCertSeq = result.zcCredit.reductionCert.seq;
                setFormData(new_formData);
              })
              .catch((err) => {
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              });
          } else {
            let project_menus = Handler.buildIssueProjectMenus(projectList);
            let new_formMeta = _.clone(formMeta);
            new_formMeta['reductionCertSeq'].menus = project_menus;
            setFormMeta(new_formMeta);
          }
          break;
        }
        case 'move': {
          // 보완 case
          if (slug) {
            API.Credit.GetMove(slug)
              .then((res) => {
                let result = res.data;
                let new_contentMeta = _.clone(contentMeta);
                new_contentMeta.companyNameAssignee = result.assigneeUser.companyName;
                new_contentMeta.businessNumberAssignee = result.assigneeUser.businessNumber;
                new_contentMeta.ceoNameAssignee = result.assigneeUser.ceoName;
                new_contentMeta.phoneAssignee = result.assigneeUser.phone;
                new_contentMeta.addressAssignee = result.assigneeUser.address;

                setContentMeta(new_contentMeta);
                setOriginData(result);

                let project_menus = Handler.buildProjectMenus(projectList);
                let new_formMeta = _.clone(formMeta);
                new_formMeta['projectSeq'].menus = project_menus;
                setFormMeta(new_formMeta);

                let new_formData = _.clone(formData);
                new_formData.assignorName = result.assignorName;
                new_formData.assignorEmail = result.assignorEmail;
                new_formData.assignorPhone = result.assignorPhone;
                new_formData.assignorTeam = result.assignorTeam;
                new_formData.assignorUserSeq = result.assignorUserSeq;
                new_formData.assigneeName = result.assigneeName;
                new_formData.assigneePhone = result.assigneePhone;
                new_formData.assigneeEmail = result.assigneeEmail;
                new_formData.assigneeTeam = result.assigneeTeam;
                new_formData.files = result.moveFiles;

                //이전 정보
                new_formData.projectSeq = result.zcCredit.project.seq;
                new_formData.amount = result.amount;
                new_formData.reason = result.reason;
                new_formData.reasonDetail = result.reasonDetail;
                new_formData.assigneeUserSeq = result.assigneeUserSeq;

                setFormData(new_formData);
              })
              .catch((err) => {
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              });
          } else {
            let project_menus = Handler.buildProjectMenus(projectList);
            let new_formMeta = _.clone(formMeta);
            new_formMeta['projectSeq'].menus = project_menus;
            setFormMeta(new_formMeta);
          }
          break;
        }
        case 'offset': {
          // 보완 case
          if (slug) {
            API.Credit.GetOffset(slug)
              .then((res) => {
                let result = res.data;
                setOriginData(res.data);

                let project_menus = Handler.buildProjectMenus(projectList);
                let new_formMeta = _.clone(formMeta);
                new_formMeta['projectSeq'].menus = project_menus;
                setFormMeta(new_formMeta);

                let new_formData = _.clone(formData);
                new_formData.name = result.assignorName;
                new_formData.email = result.assignorEmail;
                new_formData.phone = result.assignorPhone;
                new_formData.team = result.assignorTeam;

                //철회 정보
                new_formData.projectSeq = result.zcCredit.project.seq;
                new_formData.amount = result.amount;
                new_formData.reason = result.reason;
                new_formData.reasonCustom = result.reasonDetail;

                setFormData(new_formData);
              })
              .catch((err) => {
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              });
          } else {
            let project_menus = Handler.buildProjectMenus(projectList);
            let new_formMeta = _.clone(formMeta);
            new_formMeta['projectSeq'].menus = project_menus;
            setFormMeta(new_formMeta);
          }
          break;
        }
        case 'retire': {
          // 보완 case
          if (slug) {
            API.Credit.GetOffset(slug)
              .then((res) => {
                let result = res.data;
                setOriginData(result);

                let project_menus = Handler.buildProjectMenus(projectList);
                let new_formMeta = _.clone(formMeta);
                new_formMeta['projectSeq'].menus = project_menus;
                setFormMeta(new_formMeta);

                let new_formData = _.clone(formData);
                new_formData.name = result.assignorName;
                new_formData.email = result.assignorEmail;
                new_formData.phone = result.assignorPhone;
                new_formData.team = result.assignorTeam;
                //철회 정보
                new_formData.projectSeq = result.zcCredit.project.seq;
                new_formData.amount = result.amount;
                new_formData.reason = result.reason;
                new_formData.reasonCustom = result.reasonDetail;

                setFormData(new_formData);
              })
              .catch((err) => {
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              });
          } else {
            let project_menus = Handler.buildProjectMenus(projectList);
            let new_formMeta = _.clone(formMeta);
            new_formMeta['projectSeq'].menus = project_menus;
            setFormMeta(new_formMeta);
          }
          break;
        }
        default: {
          console.log('default');
        }
      }
    }
  }, [userInfo, projectList]);

  //move hooks
  useEffect(() => {
    let project_seq = formData.projectSeq;
    if (project_seq) {
      switch (mode) {
        case 'move': {
          let project_item = Handler.getProjectBySeq(project_seq, projectList);
          const moveAmount = project_item.amount > 0 ? project_item.amount : project_item.reductionNow;
          /* 이전가능 크레딧 수량 */
          onChangeContentMeta(moveAmount, 'reductionNow');
          break;
        }
        case 'offset':
        case 'retire': {
          let project_item = Handler.getProjectBySeq(project_seq, projectList);
          let new_contentMeta = _.clone(contentMeta);
          /* 폐기, 철회가능 크레딧 수량 */
          new_contentMeta['reductionNow'] = project_item.amount > 0 ? project_item.amount : project_item.reductionNow;
          if (project_item.zcCredit) new_contentMeta['zcCreditSeq'] = project_item.zcCredit; //선택한 크레딧 시퀀스
          new_contentMeta['amount'] = 0;
          setContentMeta(new_contentMeta);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [formData.projectSeq]);

  //offset hooks
  useEffect(() => {
    let reason = formData.reason;
    if (reason && mode === 'offset') {
      if (reason === t('DIRECT_INPUT_SPACE')) {
      } else {
        let new_formData = _.clone(formData);
        new_formData['reasonCustom'] = '';
        setFormData(new_formData);
      }
    }
  }, [formData.reason]);

  //event
  const onChangeContentMeta = (newValue, key) => {
    setContentMeta({ ...contentMeta, [key]: newValue });
  };

  const contentCallback = (type) => {
    if (type === 'search') {
      setOpen(true);
    }
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  //이전 기업 클릭
  const onAssigneeClick = (item) => {
    let new_formData = _.clone(formData);
    new_formData.assigneeUserSeq = item.seq;
    new_formData.assignorUserSeq = contentMeta.seq;
    new_formData.assignorUserCompanyName = contentMeta.companyName;
    new_formData.assignorUserName = contentMeta.ceoName;
    setFormData(new_formData);

    let new_contentMeta = _.clone(contentMeta);
    new_contentMeta.companyNameAssignee = item.companyName;
    new_contentMeta.businessNumberAssignee = item.businessNumber;
    new_contentMeta.ceoNameAssignee = item.ceoName;
    new_contentMeta.phoneAssignee = item.phone;
    new_contentMeta.addressAssignee = item.address;
    setContentMeta(new_contentMeta);
    setOpen(false);
  };
  //server

  const requestSave = (type) => {
    switch (type) {
      case 'issue': {
        const VALIDATION_LIST = ['name', 'phone', 'email', 'team', 'reductionCertSeq'];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }

        const body = {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          team: formData.team,
          reductionCertSeq: formData.reductionCertSeq,
        };

        API.Credit.PostIssue(body)
          .then((res) => {
            history.push('/request_complete?type=credit&mode=issue');
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'move': {
        const VALIDATION_LIST = [
          'assignorName',
          'assignorPhone',
          'assignorEmail',
          'assigneeUserSeq',
          'assigneeName',
          'assigneePhone',
          'assigneeEmail',
          'projectSeq',
          'amount',
          'reason',
          'reasonDetail',
        ];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        const isValid = Handler.formValidationKey(formData, formMeta, VALIDATION_LIST);

        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: `${ERROR['REQUIRED_INFO']}\n- ${ERROR['MISSING_CONTENT']}: ${isValid.inValidKeys
              .map((el) => ` ${t(el)}`)
              .toString()}`,
          });
          return;
        }
        if (Number(formData.amount) > Number(contentMeta.reductionNow)) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['CHECK_CREDIT_MOVE_AMOUNT'],
          });
          return;
        }

        const body = {
          assignorName: formData.assignorName,
          assignorPhone: formData.assignorPhone,
          assignorEmail: formData.assignorEmail,
          assignorUserSeq: Number(formData.assignorUserSeq),
          assignorUserCompanyName: formData.assignorUserCompanyName,
          assignorUserName: formData.assignorUserName,
          assignorTeam: formData.assignorTeam,
          assigneeUserSeq: Number(formData.assigneeUserSeq),
          assigneeName: formData.assigneeName,
          assigneePhone: formData.assigneePhone,
          assigneeEmail: formData.assigneeEmail,
          assigneeTeam: formData.assigneeTeam,
          projectSeq: Number(formData.projectSeq),
          amount: Number(formData.amount),
          reason: formData.reason,
          reasonDetail: formData.reasonDetail,
          files: ['test'] || '',
        };

        API.Credit.PostMove(body)
          .then((res) => {
            history.push('/request_complete?type=credit&mode=move');
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'offset': {
        const VALIDATION_LIST = ['name', 'phone', 'email', 'amount', 'projectSeq', 'reason'];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }
        if (formData.reason === t('DIRECT_INPUT_SPACE') && !formData.reasonCustom) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '사유를 입력해주세요.',
          });
          return;
        }
        let body = {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          team: formData.team,
          amount: Number(formData.amount),
          projectSeq: Number(formData.projectSeq),
          reason: formData.reason,
        };

        if (formData.reason === t('DIRECT_INPUT_SPACE') && formData.reasonCustom) {
          body.reasonDetail = formData.reasonCustom;
        }
        API.Credit.PostOffset(body)
          .then((res) => {
            history.push('/request_complete?type=credit&mode=offset');
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'retire': {
        const VALIDATION_LIST = ['name', 'phone', 'email', 'amount', 'projectSeq', 'reason'];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }
        if (formData.reason === t('DIRECT_INPUT_SPACE') && !formData.reasonCustom) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '사유를 입력해주세요.',
          });
          return;
        }
        let body = {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          team: formData.team,
          amount: Number(formData.amount),
          projectSeq: Number(formData.projectSeq),
          reason: formData.reason,
        };

        if (formData.reason === t('DIRECT_INPUT_SPACE') && formData.reasonCustom) {
          body.reasonDetail = formData.reasonCustom;
        }
        API.Credit.PostRetire(body)
          .then((res) => {
            history.push('/request_complete?type=credit&mode=retire');
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      default: {
        break;
      }
    }
  };

  const requestPut = (type) => {
    switch (type) {
      case 'issue': {
        const VALIDATION_LIST = ['name', 'phone', 'email', 'reductionCertSeq'];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }

        const body = {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          team: formData.team,
          reductionCertSeq: formData.reductionCertSeq,
        };

        API.Credit.PutIssue(slug, body)
          .then((res) => {
            if (originData.actionSeq.status === 'WritingZCCreditForImprove') {
              setSelectedNextAction('ImproveZCCreditComplete');
            } else if (originData.actionSeq.status === 'WritingCommitteeForImprove') {
              setSelectedNextAction('ImproveCommitteeComplete');
            } else {
              return;
            }

            setSelectedAction({
              seq: slug,
            });
            setActionOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'move': {
        const VALIDATION_LIST = [
          'assignorName',
          'assignorPhone',
          'assignorEmail',
          'assigneeUserSeq',
          'assigneeName',
          'assigneePhone',
          'assigneeEmail',
          'projectSeq',
          'amount',
          'reason',
          'reasonDetail',
        ];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }

        if (Number(formData.amount) > Number(contentMeta.reductionNow)) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '이전 수량을 확인해주세요.',
          });
          return;
        }

        const body = {
          assignorName: formData.assignorName,
          assignorPhone: formData.assignorPhone,
          assignorEmail: formData.assignorEmail,
          assignorUserSeq: formData.assignorUserSeq,
          assignorUserCompanyName: formData.assignorUserCompanyName,
          assignorUserName: formData.assignorUserName,
          assignorTeam: formData.assignorTeam,
          assigneeUserSeq: formData.assigneeUserSeq,
          assigneeName: formData.assigneeName,
          assigneePhone: formData.assigneePhone,
          assigneeEmail: formData.assigneeEmail,
          assigneeTeam: formData.assigneeTeam,
          projectSeq: formData.projectSeq,
          amount: formData.amount,
          reason: formData.reason,
          reasonDetail: formData.reasonDetail,
          files: formData.files || '',
        };

        API.Credit.PutMove(slug, body)
          .then((res) => {
            if (originData.actionSeq.status === 'WritingZCCreditForImprove') {
              setSelectedNextAction('ImproveZCCreditComplete');
            } else if (originData.actionSeq.status === 'WritingCommitteeForImprove') {
              setSelectedNextAction('ImproveCommitteeComplete');
            } else {
              return;
            }
            setSelectedAction({
              seq: slug,
            });
            setActionOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'offset': {
        const VALIDATION_LIST = ['name', 'phone', 'email', 'projectSeq', 'reason'];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }
        if (formData.reason === t('DIRECT_INPUT_SPACE') && !formData.reasonCustom) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '사유를 입력해주세요.',
          });
          return;
        }

        let body = {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          team: formData.team,
          amount: Number(formData.amount),
          projectSeq: Number(formData.projectSeq),
          reason: formData.reason,
        };
        if (formData.reason === t('DIRECT_INPUT_SPACE') && formData.reasonCustom) {
          body.reasonDetail = formData.reasonCustom;
        }

        API.Credit.PutOffset(slug, body)
          .then((res) => {
            if (originData.actionSeq.status === 'WritingZCCreditForImprove') {
              setSelectedNextAction('ImproveZCCreditComplete');
            } else if (originData.actionSeq.status === 'WritingCommitteeForImprove') {
              setSelectedNextAction('ImproveCommitteeComplete');
            } else {
              return;
            }

            setSelectedAction({
              seq: slug,
            });
            setActionOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'retire': {
        const VALIDATION_LIST = ['name', 'phone', 'email', 'projectSeq', 'reason'];
        let isValidation = Handler.formValidation(formData, VALIDATION_LIST);
        if (!isValidation) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: ERROR['REQUIRED_INFO'],
          });
          return;
        }
        if (formData.reason === t('DIRECT_INPUT_SPACE') && !formData.reasonCustom) {
          updateSnackbar({
            type: 'error',
            open: true,
            message: '사유를 입력해주세요.',
          });
          return;
        }
        let body = {
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          team: formData.team,
          amount: Number(formData.amount),
          projectSeq: Number(formData.projectSeq),
          reason: formData.reason,
        };

        if (formData.reason === t('DIRECT_INPUT_SPACE') && formData.reasonCustom) {
          body.reasonDetail = formData.reasonCustom;
        }
        API.Credit.PutOffset(slug, body)
          .then((res) => {
            if (originData.actionSeq.status === 'WritingZCCreditForImprove') {
              setSelectedNextAction('ImproveZCCreditComplete');
            } else if (originData.actionSeq.status === 'WritingCommitteeForImprove') {
              setSelectedNextAction('ImproveCommitteeComplete');
            } else {
              return;
            }

            setSelectedAction({
              seq: slug,
            });
            setActionOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleModalRequest = (body) => {
    API.Credit.PostNextAction(slug, body)
      .then((res) => {
        closeActionModal();
        history.push('/credit/list');
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  if (!userInfo) {
    return null;
  }

  return (
    <article className='basic-page'>
      <PageHeader type='credit' title={t(renderBreadcrumbsTitle(mode))}></PageHeader>
      <article className='content__wrapper'>
        <ContentCredit
          mode={mode} //issue, move, offset 구분
          formData={formData}
          formMeta={formMeta}
          formChange={formChange}
          setFormData={setFormData}
          setFormMeta={setFormMeta}
          contentMeta={contentMeta}
          contentMetaChange={onChangeContentMeta}
          readOnly={forceReadOnly}
          type={null}
          callback={contentCallback}
        ></ContentCredit>
        <section className='button__container' style={{}}>
          <Button
            style={{ width: '240px' }}
            onClick={() => {
              if (searchObject.seq) {
                requestPut(mode);
              } else {
                requestSave(mode);
              }
            }}
            label={searchObject.seq ? t('COMPLEMENT_APPLY') : t('DO_APPLY')}
          ></Button>
        </section>
      </article>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={modalStyle}>
          <ModalCreditAssignee
            onClose={() => {
              setOpen(false);
            }}
            onClick={onAssigneeClick}
          ></ModalCreditAssignee>
        </Box>
      </Modal>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalCreditAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalCreditAction>
        </Box>
      </Modal>
    </article>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import { Button, FileUploader, TextArea, TextInput } from '../../components';

export const ModalCreditView = (props) => {
  const { mode, action, onClose, requestAction } = props;
  const history = useHistory();
  const { t } = useTranslation();

  if (!action) {
    return null;
  }

  const renderInfo = () => {
    switch (mode) {
      case 'issue': {
        return (
          <section className='info__container'>
            <header>
              <h2>신청정보</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'projectTitle',
                  label: t('BIZ_TITLE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'projectId',
                  label: t('BIZ_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  key: 'amount',
                  label: t('QUANTITY'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  key: 'createdAt',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
        );
      }
      case 'move': {
        return (
          <section className='info__container'>
            <header>
              <h2>신청정보</h2>
            </header>
            <ul className='form-list one'>
              <TextInput
                value={action.assignorName}
                meta={{
                  key: 'assignorName',
                  label: t('TRANSFEROR'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  key: 'amount',
                  label: t('QUANTITY'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.assigneeName}
                meta={{
                  key: 'assigneeName',
                  label: t('TRANSTEREE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>

              <TextInput
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  key: 'createdAt',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
        );
      }
      case 'offset': {
        return (
          <section className='info__container'>
            <header>
              <h2>신청정보</h2>
            </header>
            <ul className='form-list one'>
              <TextInput
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  key: 'title',
                  label: t('QUANTITY'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderCancel = () => {
    switch (mode) {
      case 'issue': {
        return (
          <section className='info__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'projectTitle',
                  label: t('BIZ_TITLE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'projectId',
                  label: t('BIZ_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  key: 'amount',
                  label: t('QUANTITY'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>

              <TextInput
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  key: 'createdAt',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.ENUM.ACTION.STOP_REASON(action.stopReason)}
                meta={{
                  key: 'stopReason',
                  label: '구분',
                  disabled: true,
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                    borderRight: 'none',
                  },
                }}
              ></TextInput>
              <TextArea
                value={action.userComment}
                meta={{
                  key: 'userComment',
                  label: '사유',
                  disabled: true,
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                    borderRight: 'none',
                  },
                }}
              ></TextArea>
              {action.userCommentFile && (
                <FileUploader
                  value={action.userCommentFile}
                  meta={{
                    key: 'userCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                    style: {
                      gridColumn: '1/3',
                      borderRight: 'none',
                    },
                  }}
                ></FileUploader>
              )}
            </ul>
          </section>
        );
      }
      default: {
        return null;
      }
    }
  };

  switch (action.status) {
    case 'RequestMoveWriting': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>보완 수락</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container' style={{ marginBottom: 0 }}>
            <span>보완을 수락하였습니다.</span>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 10 }}>
            <span style={{ color: '#777' }}>보완하기 위해 페이지를 이동합니다.</span>
          </section>

          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginRight: 12 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={() => {
                history.push(`${action.mode.toLowerCase()}?seq=${action.seq}`);
              }}
              label='이동'
            ></Button>
          </section>
        </article>
      );
    }
    case 'RequestImproveCommittee':
    case 'RequestImproveZCCredit': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.CREDIT_ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderInfo()}
          <section className='comment__container bg'>
            <header>
              <h2>{t('COMMENT')}</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'adminComment',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'adminCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}

              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'adminCommentDate',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginRight: 12 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  if (action.status === 'RequestImproveZCCredit') {
                    requestAction('ImproveZCCreditStart', null);
                  } else if (action.status === 'RequestImproveCommittee') {
                    requestAction('ImproveCommitteeStart', null);
                  }
                }}
                label='보완 수락'
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'AuditCancel': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.CREDIT_ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderCancel()}
        </article>
      );
    }
    case 'CanceledProject': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>심사 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderInfo()}
          <section className='button__container' style={{ marginTop: 0, marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={'CLOSE'}
            ></Button>
          </section>
        </article>
      );
    }
    case 'CommitteeGiveUp':
    case 'ZCCreditGiveUp': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.CREDIT_ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderInfo()}
          <section className='comment__container'>
            <header>
              <h2>{t('COMMENT')}</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'adminComment',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'adminCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'adminCommentDate',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Reply</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.userComment}
                meta={{
                  key: 'userComment',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.userCommentFile && (
                <FileUploader
                  value={action.userCommentFile}
                  meta={{
                    key: 'userCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.userCommentDate}
                meta={{
                  key: 'userCommentDate',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={'CLOSE'}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'ZCCreditRejected': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.CREDIT_ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderInfo()}
          <section className='comment__container bg'>
            <header>
              <h2>{t('COMMENT')}</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'adminComment',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'adminCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'adminCommentDate',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'CancelApproved': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>심사 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderInfo()}
          <section className='comment__container bg'>
            <header>
              <h2>{t('COMMENT')}</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'adminComment',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'adminCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}

              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'adminCommentDate',
                  label: '처리일',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    default: {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.CREDIT_ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          {renderInfo()}
          <section className='comment__container bg'>
            <header>
              <h2>{t('COMMENT')}</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'adminComment',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'adminCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}

              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'adminCommentDate',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
  }
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';
import { UserContext } from '../../contexts';

export const MypageSignoutCompletePage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userInfo } = useContext(UserContext);

  if (!userInfo) {
    return null;
  }
  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('RESIGN_MEMBERSHIP')}</h1>
          </header>
          {/* <div className='right__box'><Breadcrumbs type={'small'}></Breadcrumbs></div> */}
        </section>
        <section className='user-form__container' style={{ alignItems: 'center' }}>
          <h1 style={{ color: '#5d3be5', marginTop: 120 }}>
            {t('MEMBERSHIP_WITHDRAWAL', { returnObjects: true })['SUCCESS']}
          </h1>
          <h2 style={{ marginTop: 16, fontWeight: 'normal' }}>운영자의 승인 후, 탈퇴가 완료됩니다.</h2>
          <div
            style={{
              marginTop: 36,
              marginBottom: 16,
              height: 64,
              background: '#f7f7f9',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2 style={{ color: '#5d3be5', fontWeight: 'bold' }}>{userInfo.email}</h2>
          </div>
          {/* <span style={{ color: '#161616' }}>가입일 : {userInfo.confirmDate}</span> */}
        </section>
        <section className='button__container'>
          <Button
            style={{ width: '180px' }}
            onClick={() => {
              history.push('/');
            }}
            label={t('GOTO_MAIN')}
          ></Button>
        </section>
      </article>
    </article>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import { PageHeader, SearchFilter, TableMethodologyRegistry, TablePaging } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';
export const MethodologyRegistryListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { TABLE } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [list, setList] = useState([]);
  const [areaType, setAreaType] = useState('');
  const [subAreaType, setSubAreaType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const getRegistryList = (init_page, isReset = false) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
        searchValue: isReset ? '' : searchValue,
        areaType: isReset ? '' : areaType,
        subAreaType: isReset ? '' : subAreaType,
        lang: isKorean ? 'ko' : 'en',
      };
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    replaceSearchQuery(params);
    API.Registry.GetRegistryList('methodology', params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  useEffect(() => {
    if (lastSearchBody) {
      getRegistryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (location.search) {
      const params = {
        ...Utils.ParamsToObject(history.location.search),
      };
      setLastSearchBody({
        ...params,
      });
      setPage(Number(params.page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    requestSearch();
  }, []);

  const replaceSearchQuery = (params) => {
    history.replace({
      search: Utils.ObjectToParams({
        ...params,
        page: page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
      }),
    });
  };

  const handleSearchFilter = (params) => {
    if ('searchValue' in params) {
      setSearchValue(params.searchValue);
    }
    if ('areaType' in params) {
      setAreaType(params.areaType === 'all' ? '' : params.areaType);
    }
    if ('subAreaType' in params) {
      setSubAreaType(params.subAreaType === 'all' ? '' : params.subAreaType);
    }
  };

  const resetFilter = (e) => {
    setSearchValue('');
    setAreaType('');
    setSubAreaType('');
    setPage(0);
    getRegistryList(0, true);
  };

  const requestSearch = () => {
    getRegistryList(0);
  };

  const tableRowClick = (item) => {
    history.push('/registry/methodology/detail/' + item.seq);
  };

  return (
    <article className='basic-page'>
      <PageHeader type='methodology-registry' title={`${TABLE['METHODOLOGY_TITLE']}`}></PageHeader>
      <article className='content__wrapper'>
        <SearchFilter
          placeholder='SEARCH_METHODOLOGY'
          onChange={(params) => handleSearchFilter(params)}
          onSearch={requestSearch}
          onReset={resetFilter}
        />
        <TableMethodologyRegistry data={list} isKorean={isKorean} rowClick={tableRowClick} />
        <div className='my-6'>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </div>
      </article>
    </article>
  );
};

import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { ConsultationFileInput, TextArea, TextInput } from '../../components';
import { useForm, useLabel } from '../../hooks';

const ModalWrapper = styled.article`
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  height: 750px !important;
  max-height: 750px !important;
`;

export const WriteConsultation = (props) => {
  const { category, onClose, request } = props;
  const { t } = useTranslation();
  const { CONSULTATION_FORM, PLACEHOLDER, BUTTON } = useLabel();
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      title: '',
      attachedFiles: [],
      content: '',
    },
    {
      title: {
        key: 'title',
        label: CONSULTATION_FORM['TITLE'],
        placeholder: PLACEHOLDER['TITLE'],
        required: true,
        regexp: Handler.REGEXP.CONSULTATION_FEEDBACK,
        // maxLength: 30,
      },
      attachedFiles: {
        key: 'attachedFiles',
        type: 'file',
        label: CONSULTATION_FORM['ATTATCHMENT'],
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
      },
      content: {
        key: 'content',
        type: 'textarea',
        label: CONSULTATION_FORM['CONTENT'],
        required: true,
        regexp: Handler.REGEXP.CONSULTATION_FEEDBACK,
      },
    },
  );

  return (
    <ModalWrapper className='project-action-modal relative rounded-2xl'>
      <section className='py-6 px-2'>
        <h2>{CONSULTATION_FORM['FORM_TITLE']}</h2>
        <p className='my-2'>{CONSULTATION_FORM['FORM_DESCRIPTION']}</p>
      </section>
      <section className='overflow-hidden' style={{ marginBottom: 0 }}>
        <div className='overflow-y-scroll w-full mb-0 p-2'>
          <ul className='form-list'>
            <div>
              <p className='text-sm mr-2'>{CONSULTATION_FORM['CATEGORY']}</p>
              <div className='flex max-w-xs text-sm mr-2 p-1 font-bold'>{t(category.toUpperCase())}</div>
            </div>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <ConsultationFileInput
              value={formData.attachedFiles}
              onChange={formChange}
              meta={formMeta.attachedFiles}
            ></ConsultationFileInput>
            <TextArea value={formData.content} onChange={formChange} meta={formMeta.content}></TextArea>
          </ul>
          <article className='flex justify-center gap-4 text-center my-8'>
            <Button variant='outlined' onClick={onClose}>
              {BUTTON['CANCEL']}
            </Button>
            <Button
              variant='contained'
              disabled={!formData.title || !formData.content}
              onClick={() => request(formData)}
            >
              {BUTTON['CONSULTATION_SUBMIT']}
            </Button>
          </article>
        </div>
        <div></div>
        <div className='flex flex-col fixed pb-2 bg-white bottom-0 items-center w-full'></div>
      </section>
    </ModalWrapper>
  );
};

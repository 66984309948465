import React from 'react';
import { useHistory } from 'react-router-dom';

export const LoginHeader = (props) => {
  const history = useHistory();

  return (
    <article className='login-header'>
      <section className='left__container'>
        <section className='logo__box'>
          <div
            className='ico logo'
            onClick={() => {
              history.push('/');
            }}
          ></div>
        </section>
      </section>
      <section className='right__container'></section>
    </article>
  );
};

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Button, CheckBox, RadioBox, TextArea } from '../../components';
import { SnackbarContext, UserContext } from '../../contexts';
import { useForm } from '../../hooks';

export const MypageSignoutPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { clearToken } = useContext(UserContext);
  const [formData, formMeta, formChange] = useForm(
    {
      scope: '기타',
      reason: '',
    },
    {
      scope: {
        key: 'scope',
        style: {},
        label: 'REASON',
        menus: [
          {
            text: '사용 빈도 낮음',
            value: '사용 빈도 낮음',
            label: 'INFREQUENT',
          },
          {
            text: '서비스/시스템 불만',
            value: '서비스/시스템 불만',
            label: 'COMPLAINTS',
          },
          {
            text: '고객응대 불편',
            value: '고객응대 불편',
            label: 'INCONVENIENCE',
          },
          {
            text: '기타',
            value: '기타',
            label: 'ETC',
          },
        ],
        className: 'column',
      },
      reason: {
        key: 'reason',
        label: 'REASON',
        maxSize: 500,
        style: {},
        required: true,
      },
    },
  );
  const [aggree, setAggree] = useState(false);

  console.log('formData', formData);

  const requsetSignout = () => {
    if (!aggree) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '유의사항에 동의해주세요.',
      });
    }
    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetSignout body', body);

    if (body) {
      API.User.PostSignout(body).then(
        (res) => {
          let result = res.data;
          console.log('User PostSignout result', result);
          clearToken();
          history.push('/mypage/signout_complete');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('RESIGN_MEMBERSHIP')}</h1>
          </header>
          {/* <div className='right__box'>
            <Breadcrumbs type={'small'}></Breadcrumbs>
          </div> */}
        </section>

        <section className='user-signout-form__container'>
          <div className='grid__box'>
            <ul className='form__list'>
              <article className='guide__box'>
                <h2>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['WITHDRAWAL_QUESTION']}</h2>
                <span>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['AFTER_APPROVE_MSG']}</span>
              </article>
              <RadioBox value={formData.scope} onChange={formChange} meta={formMeta.scope}></RadioBox>
              <TextArea value={formData.reason} onChange={formChange} meta={formMeta.reason}></TextArea>
            </ul>
            <div className='info__box'>
              <h3>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_TITLE']}</h3>
              <ul>
                <li>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_01']}</li>
                <li>
                  {t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_02']}
                  <br />
                  {t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_03']}
                </li>
                <li>
                  {t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_04']}
                  <br />
                  {t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_05']}
                </li>
                <li>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_06']}</li>
                <li>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_07']}</li>
                <li>
                  {t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_08']}
                  <br />
                  {t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_09']}
                </li>
                <li>{t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['NOTICE_10']}</li>
              </ul>
            </div>
          </div>
        </section>

        <section className='button__container column'>
          <CheckBox
            value={aggree}
            onChange={(value, key) => {
              setAggree(value);
            }}
            meta={{
              label: t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['TERM_AGREE'],
              style: {
                marginBottom: 36,
              },
              className: 'square',
            }}
          ></CheckBox>
          <Button
            disabled={!aggree}
            style={{ width: '360px' }}
            onClick={() => {
              requsetSignout();
            }}
            label={t('WITHDRAWAL_MEMBERSHIP', { returnObjects: true })['WITHDRAWAL_APPLICATION']}
          ></Button>
        </section>
      </article>
    </article>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { Button, TablePaging } from '../../components';
import { SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';

export const ModalCreditAssignee = (props) => {
  const { onClose, onClick } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const { ERROR } = useLabel();

  const requsetSearch = () => {
    requestNewList(0);
  };

  const companyAddress = (address) => {
    const location = Handler.jsonParser(address);
    if (location?.daum) return location.daum.roadAddress;
    if (location?.addr1) return location.formatted_address;
    return '-';
  };

  const requestNewList = (init_page) => {
    if (init_page) {
      let params = {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
        searchValue: search,
      };
      setLastSearchBody(params);

      API.Credit.GetAssignee(params)
        .then((res) => {
          const { content, totalPages, totalElements } = res.data;
          setList(content);
          setTotalPage(totalPages);
          setTotalCount(totalElements);
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    } else {
      if (lastSearchBody) {
        API.Credit.GetAssignee({ ...lastSearchBody, page: page })
          .then((res) => {
            const { content, totalPages, totalElements } = res.data;
            setList(content);
            setTotalPage(totalPages);
            setTotalCount(totalElements);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
      } else {
        API.Credit.GetAssignee({
          size: Handler.VARIABLES.PAGE_LIMIT,
          searchValue: search,
          page: page,
        })
          .then((res) => {
            const { content, totalPages, totalElements } = res.data;
            setList(content);
            setTotalPage(totalPages);
            setTotalCount(totalElements);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
      }
    }
  };

  useEffect(() => {
    requsetSearch();
  }, []);

  useEffect(() => {
    requestNewList();
  }, [page]);

  return (
    <article className='modal-credit-assignee'>
      <header>
        <h4>{t('SELECT_OPTION')}</h4>
        <div
          className='ico close-small'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='searh__box'>
        <input
          placeholder={`${t('COMPANY_NAME')}, ${t('BIZ_NUMBER')}`}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              requsetSearch();
            }
          }}
        ></input>
        <Button
          style={{ width: '94px', height: '50px', marginLeft: 12 }}
          onClick={() => {
            requsetSearch();
          }}
          label={t('SEARCH')}
        ></Button>
      </section>
      <section className='table__box'>
        <header>
          {lastSearchBody && lastSearchBody.searchValue ? (
            <span>
              “{lastSearchBody.searchValue}” {Handler.numberWithCommas(totalCount)}
            </span>
          ) : (
            <span>{Handler.numberWithCommas(totalCount)}</span>
          )}
        </header>
        <ul className='header__list'>
          <li>
            <span>No</span>
          </li>
          <li>
            <span>{t('COMPANY_NAME')}</span>
          </li>
          <li>
            <span>{t('ADDRESS')}</span>
          </li>
        </ul>
        {list && list.length === 0 && (
          <ul className='empty__list'>
            <span>{ERROR['NO_DATA_FOUND']}</span>
          </ul>
        )}
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <ul
                className='value__list'
                key={'val' + index}
                onClick={() => {
                  onClick(item);
                }}
              >
                <li>{item.seq}</li>
                <li>{item.companyName}</li>
                <li>
                  <p>{companyAddress(item.address)}</p>
                </li>
              </ul>
            );
          })}
      </section>
      <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      <section className='button__box'>
        <Button
          type='info'
          style={{ width: '180px', height: '50px', marginLeft: 0 }}
          onClick={onClose}
          label={t('CLOSE')}
        ></Button>
      </section>
    </article>
  );
};

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useContext } from 'react';
import { SnackbarContext } from '../contexts';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const SnackbarLayer = (props) => {
  const { snackbar, updateSnackbar } = useContext(SnackbarContext);

  const handleClose = (event, reason) => {
    console.log('handleClose event', event);
    console.log('handleClose reason', reason);

    if (reason === 'clickaway') {
      return;
    }

    updateSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={handleClose}
      message=''
    >
      <Alert onClose={handleClose} severity={snackbar.type} sx={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
          {snackbar.message}
        </div>
      </Alert>
    </Snackbar>
  );
};

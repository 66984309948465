import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { spinnerStyle } from '../../styles/style';

export const ModalLoading = (props) => {
  const { title, content, okLabel, cancelLabel, requestAction, onClose } = props;
  return (
    <div className='flex flex-col items-center justify-center bg-slate-50 rounded-lg p-6' style={spinnerStyle}>
      <p className='text-gray-600 mb-4'>{content}</p>
      <CircularProgress />
    </div>
  );
};

import React, { useState } from 'react';
import Handler from '../../Handler';
import { Button, FileUploader, RadioBox, TextArea, TextInput } from '../../components';

export const ModalReductionAction = (props) => {
  const { action, nextAction, requestAction, onClose } = props;
  const [comment, setComment] = useState('');
  const [commentFile, setCommentFile] = useState('');
  const [stopReason, setStopReason] = useState(null);

  console.log('ModalProjectAction action', action);
  console.log('ModalProjectAction nextAction', nextAction);

  const renderNextActionTitle = (next_action) => {
    switch (next_action) {
      case 'ImproveReductionCertStart': {
        return '보완 요청 수락';
      }
      case 'ImproveReductionCertComplete': {
        return '보완 완료';
      }
      case 'ImproveReductionCertGiveUp': {
        return '보완 요청 포기';
      }
      case 'ImproveLaunchGiveUp': {
        return '보완 요청 포기';
      }
      default: {
        return 'none';
      }
    }
  };

  const renderNextActionButton = (next_action) => {
    switch (next_action) {
      case 'ImproveReductionCertStart': {
        return '보완 요청 수락';
      }
      case 'ImproveReductionCertComplete': {
        return '보완 완료 신청';
      }
      case 'ImproveReductionCertGiveUp': {
        return '보완 요청 포기';
      }
      case 'ImproveLaunchGiveUp': {
        return '보완 요청 포기';
      }
      default: {
        return 'none';
      }
    }
  };

  switch (nextAction) {
    case 'ImproveReductionCertComplete': {
      return (
        <article className='reduction-action-modal'>
          <header>
            <h4>보완 완료</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container' style={{ marginBottom: 0 }}>
            <span>보완을 완료하셨나요?</span>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 10 }}>
            <span style={{ color: '#777' }}>심사를 신청하시겠습니까?</span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label='닫기'
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: nextAction,
                });
              }}
              label='보완 완료 신청'
            ></Button>
          </section>
        </article>
      );
    }
    case 'CancelWaiting': {
      return (
        <article className='reduction-action-modal'>
          <header>
            <h4>심사 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container'>
            <span style={{ lineHeight: 1.4 }}>
              아래의 감축량 인증 신청에 대해 심사 대기중입니다. <br />
              신청을 중단하시겠습니까?
            </span>
          </section>
          <section className='dialogue-em__container' style={{ flexDirection: 'column' }}>
            <h3 style={{ marginBottom: 5 }}>사업명 : {action.projectTitle}</h3>
            <h3>
              모니터링 차수 : <em style={{ color: '#5d3be5' }}>{action.monitoringDegree}차</em>
            </h3>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 30 }}>
            <span style={{ color: '#777' }}>* 취소 후에는 재신청이 불가능하니 신중히 생각하시기 바랍니다.</span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label='닫기'
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: nextAction,
                  stopReason: stopReason,
                  comment: comment,
                  commentFile: commentFile,
                });
              }}
              label='심사 중단 신청'
            ></Button>
          </section>
        </article>
      );
    }
    case 'CanceledReductionCert': {
      return (
        <article className='reduction-action-modal'>
          <header>
            <h4>심사 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='guide-text__container'>
            <b>진행중인 감축량 인증 신청을 중단하시겠습니까?</b>
            <span>
              * 담당자가 감축량 인증 심사 중단 가능 여부를 확인한 후, 중단 처리가 진행됩니다. 빠른 시간 안에 연락
              드리도록 하겠습니다.
            </span>
          </section>
          <section className='launch__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: '사업명',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: '사업ID',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.monitoringDegree + '차'}
                meta={{
                  key: 'title',
                  label: '모니터링',
                  disabled: true,
                  className: 'action main-color',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
              ></TextInput>
              <RadioBox
                value={stopReason}
                onChange={(value, key) => {
                  setStopReason(value);
                }}
                meta={{
                  key: 'radio',
                  label: '구분',
                  required: true,
                  menus: [
                    {
                      label: '사업 진행 불가능',
                      value: 'CanNotProceed',
                    },
                    {
                      label: '회원 탈퇴',
                      value: 'UserSignout',
                    },
                    {
                      label: '기타',
                      value: 'Etc',
                    },
                  ],
                  style: {
                    gridColumn: '1/3',
                  },
                  className: 'action',
                }}
              ></RadioBox>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: '사유',
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: '첨부파일',
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label='닫기'
              ></Button>
              <Button
                disabled={!stopReason}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestAction({
                    nextAction: nextAction,
                    stopReason: stopReason,
                    comment: comment,
                    commentFile: commentFile,
                  });
                }}
                label='심사 중단 신청'
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    default: {
      //ImproveReductionCertGiveUp
      return (
        <article className='reduction-action-modal'>
          <header>
            <h4>{renderNextActionTitle(nextAction)}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container' style={{ paddingBottom: 0 }}>
            <header>
              <h2>사업정보</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: '사업명',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: '사업ID',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <div className='monitor__box'>
                <TextInput
                  value={action.monitorDegree + '차'}
                  meta={{
                    key: 'title',
                    label: '모니터링',
                    disabled: true,
                    className: 'action',
                  }}
                ></TextInput>
                <TextInput
                  value={action.projectVersion}
                  meta={{
                    key: 'title',
                    label: '버전',
                    disabled: true,
                    className: 'action',
                  }}
                ></TextInput>
              </div>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(action.createdAt)}
                meta={{
                  key: 'title',
                  label: '신청일',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: '의견',
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: '첨부파일',
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: '처리일',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Reply</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: '사유',
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: '첨부파일',
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label='닫기'
              ></Button>
              <Button
                disabled={!comment}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestAction({ comment: comment, commentFile: commentFile, nextAction: nextAction });
                }}
                label={renderNextActionButton(nextAction)}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
  }
};

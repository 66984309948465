import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import React, { useState } from 'react';
import { Button } from 'react-scroll';
export const StandardDownload = (props) => {
  const { title, description, file, downloadLabel, additionalFile, additionalDownloadLabel, subject } = props;
  const [previewed, setPreviewed] = useState(false);

  const handleDownload = () => {
    window.open(file, '_blank');
    setPreviewed(true);
    const link = document.createElement('a');
    link.href = file;
    link.download = file.split('/').pop();
    link.click();
  };

  return (
    <div className='flex border border-solid border-gray-300' style={{ height: '298px', width: '284px' }}>
      <section style={{ padding: '24px' }}>
        <div className='h-[90px]'>
          <p className='flex items-center justify-left' style={{ fontSize: 20, fontWeight: 'semi-bold' }}>
            {title}
          </p>
          {/* <a className='flex items-center' href={file} download={file} target='_blank' rel='noreferrer'>
            <span className='text-md text-gray-500 mr-1 font-bold'>{downloadLabel}</span>
          </a> */}
        </div>

        <div style={{ borderTop: '1px solid #D7DAE2', margin: '16px 0', height: 236 }}>
          <div>
            <div className='flex justify-start items-center gap-3 mt-3'>
              <PersonRoundedIcon className='font-semibold' style={{ color: '#5D38E5', fontSize: '18px' }} />
              <span className='text-[#606369] mt-1 text-[13px]'>{title}</span>
            </div>

            <div className='flex justify-start items-center gap-3 mt-3'>
              <CalendarTodayOutlinedIcon className='font-semibold' style={{ color: '#5D38E5', fontSize: '18px' }} />
              <span className='text-[#606369] text-[13px] mt-1'> 2024.01.01 </span>
            </div>
          </div>
          <div className='mt-8'>
            <Button
              variant='contained'
              className='flex '
              onClick={handleDownload}
              style={{
                height: 44,
                width: 236,
                borderRadius: 0,
                background: 'black',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <span className='text-white'>다운로드</span>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

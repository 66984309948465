import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { ModalPdfView } from '../../components';
import { useLabel } from '../../hooks';
import { NoDataRow } from './NoDataRow';

export const TableConsultationHistory = (props) => {
  const { data, isKorean, rowClick, callback } = props;
  const [showModal, setShowModal] = useState(false);
  const [document, setDocument] = useState(null);
  const { t } = useTranslation();
  const { TABLE } = useLabel();

  const handleDocumentModal = useCallback((doc) => {
    setDocument(doc);
    setShowModal(true);
  }, []);

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 43, maxWidth: 43, lineHeight: 1.2 }}>
                {TABLE['CONSULTATION_HISTORY_CATEGORY']}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 240, maxWidth: 240 }}>
                {TABLE['CONSULTATION_HISTORY']}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 95, maxWidth: 95, lineHeight: 1.2 }}>
                {t('PREVIEW')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 95, maxWidth: 95, lineHeight: 1.2 }}>
                {t('DOWNLOAD')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100, maxWidth: 100, width: 100 }}>
                {TABLE['REGISTRATION_DATE']}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={`table-row-${row.seq}-${index}`}
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  <TableCell align='center'>{t(row.category.toUpperCase())}</TableCell>
                  <TableCell align='center'>{row.title}</TableCell>
                  <TableCell align='center'>
                    <PageviewOutlinedIcon
                      color='primary'
                      className='cursor-pointer'
                      onClick={() => handleDocumentModal(row.summaryFileLink)}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <div className='flex justify-center'>
                      <a href={row.summaryFileLink} download={row.summaryFileLink}>
                        <FileDownloadOutlinedIcon color='primary' />
                      </a>
                    </div>
                  </TableCell>
                  <TableCell align='center'>{Handler.geni18nDate(isKorean, row.summaryFileUploadAt * 1000)}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ModalPdfView url={document} onClose={() => setShowModal(false)} />
      </Modal>
    </article>
  );
};

import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Dropzone from 'react-dropzone';

import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const ConsultationFileInput = (props) => {
  const { value, onChange, meta } = props;
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const { BUTTON, MSG } = useLabel();
  const [loading, setLoading] = useState(false);
  const { updateSnackbar } = useContext(SnackbarContext);

  const uploadFile = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', meta.path);
    return await API.Common.PostFile(formData);
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  const thumbs = files.map((file, idx) => (
    <div
      key={file.name}
      className='group flex gap-2 items-center border relative cursor-pointer border-solid border-gray-400 rounded-xl p-2'
    >
      <UploadFileIcon />
      <div>
        <span className='text-sm'>{Handler.genFileName(file.name, 16)}</span>
      </div>
      <div className='group-hover:block hidden cursor-pointer absolute -top-2 -right-2'>
        <CancelIcon
          onClick={() => {
            const cur = _.clone(files);
            cur.splice(idx, 1);
            setFiles(cur);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    if (files?.length > 0) {
      files.filter((e) => e.url);
      onChange(
        files.map((e) => e.url),
        meta.key,
      );
    } else {
      onChange([], meta.key);
    }
  }, [files]);

  return (
    <article className='mt-4' style={meta && meta.style ? { ...meta.style } : {}}>
      <header>
        <div className='label__box'>
          <span className='text-sm'>
            {t(meta.label)}
            {renderRequired()}
          </span>
          <span className='text-xs'>({files?.length + '/' + meta.max})</span>
        </div>
      </header>
      <div className='py-2'>
        <Dropzone
          maxFiles={10}
          maxSize={2e7}
          onError={(msg) => {}}
          validator={(msg) => {}}
          onDropRejected={(msg) => {
            if (msg[0]?.errors[0]?.code === 'too-many-files') {
              updateSnackbar({
                type: 'error',
                open: true,
                message: MSG['MAX_10_FILES'],
              });
              return;
            }
          }}
          disabled={files.length >= 10}
          onDrop={(acceptedFiles) => {
            if (files.length >= 10) {
              updateSnackbar({
                type: 'error',
                open: true,
                message: MSG['MAX_10_FILES'],
              });
              return;
            }
            let attached_files = [];
            const uploadPromises = []; // 일괄처리 Promise
            acceptedFiles.forEach((el) => {
              const uploadPromise = uploadFile(el)
                .then((res) => {
                  attached_files.push({
                    name: el.name,
                    url: res.data.cloudUrl,
                  });
                })
                .catch((err) => {
                  updateSnackbar({
                    type: 'error',
                    open: true,
                    message: err.data.message,
                  });
                });
              uploadPromises.push(uploadPromise);
            });

            Promise.all(uploadPromises)
              .then(() => {
                setLoading(false);
                if (files?.length > 0) {
                  /* 추가된 파일 있을 경우 */
                  setFiles([..._.clone(files), ...attached_files]);
                } else {
                  setFiles(attached_files);
                }
              })
              .catch((error) => {
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: error,
                });
              });
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className='p-4 bg-slate-100 rounded-md cursor-pointer'>
                <input {...getInputProps()} />

                {files.length >= 10 ? (
                  <p className='text-sm'>{MSG['MAX_10_FILES']}</p>
                ) : (
                  <div className='flex'>
                    <p className='text-sm underline text-blue-500 mr-1'>{BUTTON['ADD_FILES']}</p>
                    <p className='text-sm'>{BUTTON['DRAG_AND_DROPS']}</p>
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
        <article className='grid grid-cols-5 gap-2 my-2'>{thumbs}</article>
      </div>
    </article>
  );
};

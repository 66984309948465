import dayjs from 'dayjs';
import i18next from 'i18next';
import moment from 'moment-timezone';

const REGEXP = {
  // PASSWORD: '^(?=.*[A-Z])(?=.*[0-9]).{6,11}$', //숫자, 영문 6~12
  PASSWORD: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()+=~_-]).{8,12}$',
  CONSULTATION_FEEDBACK: '^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣 `~!@#$%^&*()\\-_=+\\[{\\]}\\\\|;:\'",<.>/?//s]{0,100}$',
};

const VARIABLES = {
  PAGE_LIMIT: 10,
};

const translateMsg = (parent, child) => {
  return i18next.t(parent)[child];
};

const GET_DEFAULT_IMAGE = (type) => {
  const COMPNAY_LIST = [
    'https://d1sjg004kl9yle.cloudfront.net/default/default_company_1.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_company_2.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_company_3.png',
  ];

  const USER_LIST = [
    'https://d1sjg004kl9yle.cloudfront.net/default/default_user_1.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_user_2.png',
    'https://d1sjg004kl9yle.cloudfront.net/default/default_user_3.png',
  ];

  let target_index = getRandomInt(0, 3);
  console.log('target_index', target_index);
  if (type === 'company') {
    return COMPNAY_LIST[target_index];
  } else {
    return USER_LIST[target_index];
  }
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const ENUM = {
  ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingReductionCert':
        case 'WritingProject': {
          return 'TEMP_SAVE';
          // return '임시저장';
        }
        case 'WaitingForAuditReductionCert':
        case 'WaitingForAuditProject': {
          return 'FIRST_WAITING';
          // return '1차 심사 대기';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return 'FIRST_INIT_REVIEW';
          // return '1차 심사중';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return 'REQ_FOR_REVISION';
          // return '보완요청';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return 'FINAL_APPROVED';
          // return '최종 승인';
        }
        case 'WaitingForAuditLaunch': {
          return '착수 심사 대기';
          // return '착수 심사 대기';
        }
        case 'AuditLaunch': {
          return '착수 심사중';
          // return '착수 심사중';
        }
        case 'LaunchApproved': {
          return 'COMMENCEMENT_APPROVED';
          // return '착수 승인';
        }
        case 'AuditCancel': {
          return 'STOP_PROJECT_REQUEST';
          // return '사업 중단 요청';
        }
        case 'CancelApproved': {
          return 'STOP_PROJECT';
          // return '사업 중단';
        }
        case 'WritingReductionCertForImprove':
        case 'WritingProjectForImprove': {
          return 'PROGRESS_REVISION';
          // return '보완 진행중';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
          // return '착수 심사 반려';
        }
        case 'RequestImproveLaunch': {
          return 'REQ_FOR_REVISION';
          // return '보완 요청';
        }
        case 'CanceledReductionCert':
        case 'CanceledProject': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'ProjectGiveUp':
        case 'LaunchGiveUp':
        case 'ReductionCertGiveUp': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'ModifyWriting': {
          return 'MODIFY';
          // return '수정';
        }
        case 'RemoveWriting': {
          return 'DELETE';
          // return '삭제';
        }
        case 'CanceledProject': {
          return 'STOP_PROJECT';
          // return '사업 중단';
        }
        case 'CancelWaiting': {
          return 'CANCEL';
          // return '취소';
        }
        case 'ImproveProjectStart': {
          return 'COMPLEMENT_ACCEPT';
          // return '보완 수락';
        }
        case 'ImproveProjectGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'LaunchProject': {
          return 'START_REPORT';
          // return '착수 신고';
        }
        case 'ModifyProject': {
          return 'CHANGE';
          // return '변경';
        }
        case 'ImproveProjectComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'ImproveLaunchComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'ImproveLaunchGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'ImproveReductionCertGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'ImproveReductionCertStart': {
          return 'COMPLEMENT_ACCEPT';
          // return '보완 수락';
        }
        case 'ImproveReductionCertComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'CanceledReductionCert': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'IssueCredit': {
          return 'APPLY_CREDIT_ISSUANCE';
          // return '크레딧 발급 신청';
        }
        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return 'CANNOT_PROCEED';
          // return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return 'RESIGN_MEMBERSHIP';
          // return '회원 탈퇴';
        }
        case 'Etc': {
          return 'ETC';
          // return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  REDUCTION_ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WritingReductionCert':
        case 'WritingProject': {
          return 'TEMP_SAVE';
          // return '임시저장';
        }
        case 'WaitingForAuditReductionCert':
        case 'WaitingForAuditProject': {
          return 'FIRST_WAITING';
          // return '1차 심사 대기';
        }
        case 'AuditReductionCert':
        case 'AuditProject': {
          return 'FIRST_INIT_REVIEW';
          // return '1차 심사중';
        }
        case 'RequestImproveReductionCert':
        case 'RequestImproveProject': {
          return 'REQ_FOR_REVISION';
          // return '보완요청';
        }
        case 'ReductionCertRejected':
        case 'ProjectRejected': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        case 'ReductionCertApproved':
        case 'ProjectApproved': {
          return 'FINAL_APPROVED';
          // return '최종 승인';
        }
        case 'WaitingForAuditLaunch': {
          return 'WAITING_FOR_AUDIT_LAUNCH';
          // return '착수 심사 대기';
        }
        case 'AuditLaunch': {
          return 'AUDIT_LAUNCH';
          // return '착수 심사중';
        }
        case 'LaunchApproved': {
          return 'COMMENCEMENT_APPROVED';
          // return '착수 승인';
        }
        case 'AuditCancel': {
          return 'REQ_FOR_STOP';
          // return '심사 중단 요청';
        }
        case 'CancelApproved': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'WritingReductionCertForImprove':
        case 'WritingProjectForImprove': {
          return 'PROGRESS_REVISION';
          // return '보완 진행중';
        }
        case 'LaunchRejected': {
          return '착수 심사 반려';
          // return '착수 심사 반려';
        }
        case 'RequestImproveLaunch': {
          return 'PROGRESS_REVISION';
          // return '보완 요청';
        }
        case 'CanceledReductionCert':
        case 'CanceledProject': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'ProjectGiveUp': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        case 'LaunchGiveUp': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        case 'ReductionCertGiveUp': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'ModifyWriting': {
          return 'MODIFY';
          // return '수정';
        }
        case 'RemoveWriting': {
          return 'CANCEL';
          // return '삭제';
        }
        case 'CanceledProject': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'CancelWaiting': {
          return 'CANCEL';
          // return '취소';
        }
        case 'ImproveProjectStart': {
          return 'COMPLEMENT_ACCEPT';
          // return '보완 수락';
        }
        case 'ImproveProjectGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'LaunchProject': {
          return 'START_REPORT';
          // return '착수 신고';
        }
        case 'ModifyProject': {
          return 'CHANGE';
          // return '변경';
        }
        case 'ImproveProjectComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'ImproveLaunchComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'ImproveLaunchGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'ImproveReductionCertGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'ImproveReductionCertStart': {
          return 'COMPLEMENT_ACCEPT';
          // return '보완 수락';
        }
        case 'ImproveReductionCertComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'CanceledReductionCert': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'IssueCredit': {
          return 'APPLY_CREDIT_ISSUANCE';
          // return '크레딧 발급 신청';
        }
        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return 'CANNOT_PROCEED';
          // return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return 'RESIGN_MEMBERSHIP';
          // return '회원 탈퇴';
        }
        case 'Etc': {
          return 'ETC';
          // return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  CREDIT_ACTION: {
    STATUS: (value) => {
      switch (value) {
        case 'WaitingForAuditZCCredit': {
          return 'FIRST_WAITING';
          // return '1차 심사 대기';
        }
        case 'AuditZCCredit': {
          return 'FIRST_INIT_REVIEW';
          // return '1차 심사중';
        }
        case 'RequestImproveZCCredit': {
          return 'REQ_FOR_REVISION';
          // return '보완요청';
        }
        case 'ZCCreditRejected': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        case 'ZCCreditApproved': {
          return 'FIRST_REVIEW_COMPLETED';
          // return '1차 심사 완료';
        }
        case 'AuditApproved': {
          return 'FIRST_FINAL_APPROVED';
          // return '1차 최종 승인';
        }
        case 'WaitingForAuditCommittee': {
          return 'SECOND_WAITING';
          // return '2차 심사 대기';
        }
        case 'AuditCommittee': {
          return 'SECOND_INIT_REVIEW';
          // return '2차 심사중';
        }
        case 'CommitteeApproved': {
          return 'SECOND_FINAL_APPROVED';
          // return '2차 최종 승인';
        }
        case 'AuditCancel': {
          return 'REQ_FOR_STOP';
          // return '심사 중단 요청';
        }
        case 'CancelApproved': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'WritingCommitteeForImprove':
        case 'WritingZCCreditForImprove': {
          return 'PROGRESS_REVISION';
          // return '보완 진행중';
        }
        case 'CommitteeRejected': {
          return 'SECOND_REJECTED';
          // return '2차 심사 반려';
        }
        case 'RequestImproveCommittee': {
          return 'PROGRESS_REVISION';
          // return '보완 요청';
        }
        case 'CanceledZCCredit': {
          return 'REVIEW_SUSPENDED';
          // return '심사 중단';
        }
        case 'CommitteeGiveUp':
        case 'CommitteeRejected':
        case 'ZCCreditGiveUp': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        case 'CommitteeGiveUp': {
          return 'FINAL_REJECTION';
          // return '최종 반려';
        }
        default: {
          return 'none';
        }
      }
    },
    NEXT: (value) => {
      switch (value) {
        case 'None': {
          return 'None';
        }
        case 'ModifyWriting': {
          return 'MODIFY';
          // return '수정';
        }
        case 'RemoveWriting': {
          return 'DELETE';
          // return '삭제';
        }
        case 'CanceledZCCredit': {
          return '심사 중단';
        }
        case 'CancelWaiting': {
          return 'CANCEL';
          // return '취소';
        }
        case 'ImproveCommitteeStart':
        case 'ImproveZCCreditStart': {
          return 'COMPLEMENT_ACCEPT';
          // return '보완 수락';
        }
        case 'ImproveCommitteeGiveUp':
        case 'ImproveZCCreditGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'CommitteeZCCredit': {
          return 'START_REPORT';
        }
        case 'ModifyZCCredit': {
          return 'CHANGE';
          // return '변경';
        }
        case 'ImproveCommitteeComplete':
        case 'ImproveZCCreditComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'ImproveCommitteeComplete': {
          return 'SUPPLEMENTAION_COMPLETE';
          // return '보완 완료';
        }
        case 'ImproveCommitteeGiveUp': {
          return 'ABANDONMENT';
          // return '보완 포기';
        }
        case 'IssueCredit': {
          return 'APPLY_CREDIT_ISSUANCE';
          // return '크레딧 발급 신청';
        }
        default: {
          return 'none';
        }
      }
    },
    STOP_REASON: (value) => {
      console.log('stopReason', value);
      switch (value) {
        case 'CanNotProceed': {
          return 'CANNOT_PROCEED';
          // return '사업 진행 불가능';
        }
        case 'UserSignout': {
          return 'RESIGN_MEMBERSHIP';
          // return '회원 탈퇴';
        }
        case 'Etc': {
          return 'ETC';
          // return '기타';
        }
        default: {
          return 'none';
        }
      }
    },
  },
  AREA: (value) => {
    switch (value) {
      case 'Removal': {
        return 'CARBON_REMOVAL';
        // return '탄소제거';
      }
      case 'Reduction': {
        return 'CARBON_REDUCTION';
        // return '탄소감축';
      }
      default: {
        return 'none';
      }
    }
  },
  SUB_AREA: (value) => {
    switch (value) {
      case 'Forestry': {
        return 'FORESTRY';
        // return '산림';
      }
      case 'BioChar': {
        return 'BIOCHAR';
        // return '바이오차';
      }
      case 'SoilCarbon': {
        return 'SOIL';
        // return '토양탄소';
      }
      case 'DAC': {
        return 'DAC';
        // return 'DAC';
      }
      case 'RenewableEnergy': {
        return 'RENEWABLEENERGY';
        // return '재생에너지';
      }
      case 'EMobility': {
        return 'EMOBILITY';
        // return 'e-mobility';
      }
      case 'Recycle': {
        return 'RECYCLE';
        // return '재활용';
      }
      case 'ReplaceElement': {
        return 'REPLACEELEMENT';
        // return '대체물질';
      }
      case 'EnergyConversion': {
        return 'ENERGYCONVERSION';
        // return '에너지전환';
      }
      case 'Chemical': {
        return 'CHEMICAL';
        // return '화학공정';
      }
      case 'Agriculture': {
        return 'AGRICULTURE';
        // return '농업';
      }
      case 'CCS': {
        return 'CCS';
        // return 'CCS';
      }
      case 'SecondHand': {
        return 'SECONDHAND';
        // return '중고거래';
      }
      case 'ReplaceVehicle': {
        return 'REPLACEVEHICLE';
        // return '이동수단 대체';
      }
      case 'Service': {
        return 'SERVICE';
      }
      case 'Etc': {
        return 'ETC';
      }
      default: {
        return 'none';
      }
    }
  },
  // SUB_AREA: (value) => {
  //   switch (value) {
  //     case 'RenewableEnergy': {
  //       return '재생 에너지';
  //     }
  //     case 'MicroGrid': {
  //       return '마이크로그리드';
  //     }
  //     case 'FuelConversion': {
  //       return '연료전환';
  //     }
  //     case 'WaterTreatment': {
  //       return '수처리';
  //     }
  //     case 'Forestry': {
  //       return '산림 조성';
  //     }
  //     case 'ForestManagement': {
  //       return '산림 관리';
  //     }
  //     case 'BioChar': {
  //       return 'BioChar';
  //     }
  //     case 'DAC': {
  //       return 'DAC';
  //     }
  //     case 'EMobility': {
  //       return 'e-mobility';
  //     }
  //     case 'EnergyConversion': {
  //       return '에너지 전환';
  //     }
  //     case 'SmartCity': {
  //       return '스마트 시티';
  //     }
  //     default: {
  //       return 'none';
  //     }
  //   }
  // },
};

const formToBody = (formData, formMeta) => {
  console.log('formToBody formData', formData);
  console.log('formToBody formMeta', formMeta);
  let isValidation = true;
  Object.keys(formMeta).forEach((key) => {
    console.log('key', key);
    let target = formMeta[key];
    if (target.required && !formData[key]) {
      // console.log('[[[[[required MISSING target key]]]]]]', key);
      isValidation = false;
    }
  });

  if (isValidation) {
    return formData;
  } else {
    return null;
  }
};

export const getModeText = (mode) => {
  switch (mode) {
    case 'issue': {
      return 'ISSUED_QUANTITY';
      // return '발행 수량';
    }
    case 'move': {
      return 'TRANSFERRED_QUANTITY';
      // return '이전 수량';
    }
    case 'offset': {
      return 'WITHDRAWALED_QUANTITY';
      // return '철회 수량';
    }
    case 'retire': {
      return 'DISPOSALED_QUANTITY';
      // return '폐기 수량';
    }
    default: {
      return null;
    }
  }
};

export const getModeLabel = (mode) => {
  switch (mode) {
    case 'issue': {
      return 'ISSUE';
      // return '발행 수량';
    }
    case 'move': {
      return 'TRANSFER';
      // return '이전 수량';
    }
    case 'offset': {
      return 'WITHDRAWAL';
      // return '철회 수량';
    }
    case 'retire': {
      return 'DISPOSAL';
      // return '폐기 수량';
    }
    default: {
      return null;
    }
  }
};

export const buildPaymentTypeText = (type) => {
  switch (type) {
    case 'CARD': {
      return 'CREDIT_CARD';
      // return '신용카드';
    }
    case 'BANK': {
      return 'BANK_TRANSFER';
      // return '계좌이체';
    }
    case 'VBANK': {
      return 'VIRTUAL_ACCOUNT';
      // return '가상계좌';
    }
    case 'MOBILE': {
      return 'MOBILE';
      // return '휴대폰';
    }
    case 'TAXBILL': {
      return 'TAXBILL';
      // return '세금계산서\n발행';
    }
    default: {
      return 'ETC';
      // return '기타';
    }
  }
};

export const renderProjectTypeText = (type) => {
  if (type === 'New') return 'NEW';
  if (type === 'Move') return 'MOVE';
  return type;
};

export const renderProjectSubTypeText = (type) => {
  if (type === 'New') return 'NEW';
  if (type === 'Modify') return 'MODIFY';
  return type;
};

const formToBodyKey = (formData, formMeta) => {
  console.log('formToBody formData', formData);
  console.log('formToBody formMeta', formMeta);
  let key_list = [];

  Object.keys(formMeta).forEach((key) => {
    console.log('key', key);
    let target = formMeta[key];
    if (target.required && !formData[key]) {
      console.log('[[[[[required MISSING target key]]]]]]', key);
      key_list.push(key);
    }
  });
  return key_list;
};

const formValidation = (formData, validationList) => {
  console.log('formValidation formData', formData);
  console.log('formValidation validationList', validationList);

  let isValidation = true;
  Object.keys(formData).forEach((key) => {
    let target = formData[key];
    if (validationList.includes(key)) {
      console.log('target', target);
      if (!target) {
        console.log('[[[[[required MISSING target key]]]]]]', key);

        isValidation = false;
      }
    }
  });

  return isValidation;
};

const formValidationKey = (formData, formMeta, validationList) => {
  let isValidation = true;
  let inValidKeys = [];
  Object.keys(formData).forEach((key) => {
    let target = formData[key];
    if (validationList.includes(key)) {
      if (!target) {
        inValidKeys.push(formMeta[key].label);
        console.log('[[[[[required MISSING target key]]]]]]', key);
        isValidation = false;
      }
    }
  });

  return {
    isValid: isValidation,
    inValidKeys: inValidKeys,
  };
};

// const addressToDbData = (value) => {
//   if (value) {
//     return {
//       post: value.daum.zonecode,
//       address: value.daum.roadAddress,
//       addressDetail: value.detail,
//     };
//   } else {
//     return null;
//   }
// };

// const dbDataToAddress = (db_data) => {
//   if (db_data.address && db_data.addressDetail && db_data.post) {
//     return {
//       daum: {
//         zonecode: db_data.post,
//         roadAddress: db_data.address,
//       },
//       detail: db_data.addressDetail,
//     };
//   } else {
//     return null;
//   }
// };

const numberWithCommas = (x) => {
  // console.log('numberWithCommas x', x);
  if (!x) {
    return '0';
  }
  if (isNaN(Number(x))) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const textEllipsis = (text, limit = 40) => {
  if (!text) return '';
  let adjustedLength = 0;
  for (let i = 0; i < text.length; i++) {
    // 한글인 경우 가중치 적용
    if (text.charCodeAt(i) > 255) {
      adjustedLength += 2;
    } else {
      adjustedLength += 1;
    }

    // 조정된 길이가 제한을 초과하는 경우
    if (adjustedLength > limit) {
      console.log('`${text.substring(0, i)}...`:', `${text.substring(0, i)}...`);
      return `${text.substring(0, i)}...`;
    }
  }

  return text;
};

const genFileName = (str, limit = 20) => {
  return `${textEllipsis(str.normalize(), limit)}.${str.substring(str.lastIndexOf('.'))}`;
};

const isImage = (url) => {
  return (
    url &&
    ['.jpg', '.jpeg', '.png', '.gif', '.svg'].includes(url.substring(url.lastIndexOf('.'), url.length).toLowerCase())
  );
};

const isPdf = (url) => {
  return url && url.toLowerCase().endsWith('pdf');
};

const getYYYYMMDDByUnix = (unix) => {
  //server에서 second로 떨어짐
  // console.log('unix', unix);
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD');
  return temp_moment;
};

const getYYYYMMDDHHMMByUnix = (unix) => {
  //server에서 second로 떨어짐
  let temp_moment = moment(unix * 1000).format('YYYY.MM.DD HH:mm');
  return temp_moment;
};

const dbDateToYYYYMMDDHHMMSS = (date) => {
  if (date) {
    return date.slice(0, 19).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDD = (date) => {
  if (date) {
    return date.slice(0, 10).replace('T', ' ');
  } else {
    return null;
  }
};

const dbDateToYYYYMMDDComma = (date) => {
  if (date) {
    return date.slice(0, 10).replace('T', ' ').replaceAll('-', '.');
  } else {
    return null;
  }
};

const dbDashDateToCommaDate = (date) => {
  if (date) {
    return date.replaceAll('-', '.');
  } else {
    return null;
  }
};

const dbCommaDateToDashDate = (date) => {
  if (date) {
    return date.replaceAll('.', '-');
  } else {
    return null;
  }
};

// const getYYYYMMDDByTimestamp = (timestamp) => {
//   //yyyy-mm-dd
//   let parsed_timestampe = String(timestamp).substring(0, 19);
//   var d = new Date(parsed_timestampe),
//     month = '' + (d.getMonth() + 1),
//     day = '' + d.getDate(),
//     year = d.getFullYear();
//   if (month.length < 2) month = '0' + month;
//   if (day.length < 2) day = '0' + day;

//   return [year, month, day].join('-');
// };

// const getTimebyTimestamp = (timestamp) => {
//   let parsed_timestampe = String(timestamp).substring(0, 19);
//   var t1 = new Date().getTime();
//   var t2 = Date.parse(parsed_timestampe);

//   var dif = t1 - t2;
//   // console.log('dif', dif);
//   var dif_second = Math.floor(dif / 1000);

//   //시간
//   //시간, 분
//   //day
//   //30일

//   if (dif_second > 2592000) {
//     //30일
//     return Math.floor(dif_second / 2592000) + '달전';
//   } else if (dif_second > 86400) {
//     //하루보다 크면
//     return Math.floor(dif_second / 86400) + '일전';
//   } else if (dif_second > 3600) {
//     return Math.floor(dif_second / 3600) + '시간전';
//   } else {
//     return Math.floor(dif_second / 60) + '분전';
//   }
// };

//component

const BuildArticleClassname = (value, meta) => {
  if (!value || !meta) {
    return '';
  }
  // console.log('meta', meta);
  let return_classname = value;

  if (meta.className) {
    return_classname += ' ' + meta.className;
  }
  if (meta.type) {
    return_classname += ' ' + meta.type;
  }
  if (meta.disabled) {
    return_classname += ' ' + 'disabled';
  }
  if (meta.readOnly) {
    return_classname += ' ' + 'readOnly';
  }
  return return_classname;
};

//content

const buildMethodologyMenus = (methodology_list, area, sub_area) => {
  let return_menus = [];
  console.log('buildMethodologyMenus', methodology_list);
  for (let i in methodology_list) {
    let target = methodology_list[i];
    if (target.isValid && target.area === area && target.subArea === sub_area) {
      return_menus.push({
        value: target.seq,
        label: target.title,
      });
    }
  }
  return return_menus;
};

const getMethodologyBySeq = (methodology_list, seq) => {
  let return_value = null;

  if (methodology_list.length === 0 || !seq) {
    return null;
  }

  for (let i in methodology_list) {
    let target = methodology_list[i];
    if (target.seq === Number(seq)) {
      return_value = target;
    }
  }

  return return_value;
};

const buildTermsValue = (methodology) => {
  let reutrn_value = [];
  console.log('buildTermsValue methodology', methodology);
  let terms_mapper = methodology.termsMapper;

  for (let i in terms_mapper) {
    let target = terms_mapper[i];
    reutrn_value.push({
      title: target.terms.title,
      file: target.terms.termsFile,
    });
  }

  return reutrn_value;
};

//from server mapper to view data. 이미 생성된 방법론의 경우, 밸리드 체크하지 않는다.
const getEvidenceByMapper = (mapper, valid_check) => {
  let return_value = [];

  console.log('getEvidenceByMapper', mapper);
  for (let i in mapper) {
    let target = mapper[i];
    if (valid_check) {
      if (target.status === 'Valid') {
        return_value.push({
          seq: target.evidenceSeq,
          type: target.type,
          data: target.data,
        });
      }
    } else {
      return_value.push({
        seq: target.evidenceSeq,
        type: target.type,
        data: target.data,
      });
    }
  }
  return return_value;
};

// // map height
// const getHeight = (deviceWidth) => {
//   if (deviceWidth < 1120)
//   return deviceWidth
// };

// 유효한 빈값 생성용, 방법론 셀렉트 박스 변경시 사용됨, 항상 valid 체크
const buildEvidenceValue = (methodology) => {
  let reutrn_value = [];
  for (let i in methodology.evidenceMapper) {
    let target_mapper = methodology.evidenceMapper[i];
    console.log('buildEvidenceValue target_mapper', target_mapper);
    if (target_mapper.evidence.status === 'Valid') {
      reutrn_value.push({
        seq: target_mapper.evidence.seq,
        type: 'File',
        data: '',
      });
    }
  }

  return reutrn_value;
};

//서버에서 초기 mapper가 있는 경우, 밸리드 체크하지 않는다.
const buildEvidenceLabel = (methodology, valid_check) => {
  console.log('buildEvidenceLabel methodology', methodology);
  let reutrn_value = [];
  for (let i in methodology.evidenceMapper) {
    let target_mapper = methodology.evidenceMapper[i];
    if (valid_check) {
      console.log(' buildEvidenceLabel target_mapper', target_mapper);
      if (target_mapper.evidence.status === 'Valid') {
        reutrn_value.push({
          title: target_mapper.evidence.title,
          isForce: target_mapper.isForce,
        });
      }
    } else {
      reutrn_value.push({
        title: target_mapper.evidence.title,
        isForce: target_mapper.isForce,
      });
    }
  }

  return reutrn_value;
};

const buildSubAreaMenus = (business_list, area) => {
  let return_value = [];

  if (area && business_list) {
    let sub_area_list = business_list[area];
    for (let i in sub_area_list) {
      let target = sub_area_list[i];
      return_value.push({
        value: target,
        label: Handler.ENUM.SUB_AREA(target),
      });
    }
  }

  return return_value;
};

// monitoring evidence
const buildMonitoringValue = (origin_mapper, type, monitoringInfo, only_mapper) => {
  let return_value = [];

  if (only_mapper) {
    // view용으로 mapper에 있는 값만 보여줄때 사용
    console.log('origin_mapper', origin_mapper);
    for (let i in origin_mapper) {
      let target = origin_mapper[i];
      return_value.push({
        seq: target.seq,
        file: target.file,
        reason: target.reason,
      });
    }
  } else {
    if (origin_mapper) {
      console.log('origin_mapper', origin_mapper);
      for (let i in monitoringInfo) {
        let target = monitoringInfo[i];
        if (target.status === 'Valid' && (target.type === 'All' || target.type === type)) {
          let is_exist_in_mapper = false;
          let selected_mapper = null;
          for (let j in origin_mapper) {
            let mapper_target_seq = origin_mapper[j].evidence.seq;
            if (mapper_target_seq === target.seq) {
              is_exist_in_mapper = true;
              selected_mapper = origin_mapper[j];
            }
          }
          if (is_exist_in_mapper) {
            return_value.push({
              seq: target.seq,
              file: selected_mapper.file,
              reason: selected_mapper.reason,
            });
          } else {
            return_value.push({
              seq: target.seq,
              file: '',
              reason: '',
            });
          }
        }
      }
    } else {
      for (let i in monitoringInfo) {
        let target = monitoringInfo[i];
        if (target.status === 'Valid' && (target.type === 'All' || target.type === type)) {
          return_value.push({
            seq: target.seq,
            file: '',
            reason: '',
          });
        }
      }
    }
  }

  return return_value;
};

const buildMonitoringLabel = (origin_mapper, type, monitoringInfo, only_mapper) => {
  let return_value = [];
  if (only_mapper) {
    // view용으로 mapper에 있는 값만 보여줄때 사용
    for (let i in origin_mapper) {
      let target = origin_mapper[i];
      console.log('targettarget,', target);
      return_value.push({
        title: target.evidence.title,
        isForce: target.evidence.isForce,
      });
    }
  } else {
    for (let i in monitoringInfo) {
      let target = monitoringInfo[i];
      if (target.status === 'Valid' && (target.type === 'All' || target.type === type)) {
        return_value.push({
          title: target.title,
          isForce: target.isForce,
        });
      }
    }
  }

  return return_value;
};

//credit issue
const buildProjectMenus = (project_list) => {
  let return_value = [];

  for (let i in project_list) {
    let target = project_list[i];
    return_value.push({
      value: target.seq,
      label: target.title,
    });
  }
  return return_value;
};

const buildIssueProjectMenus = (project_list) => {
  let return_value = [];

  for (let i in project_list) {
    let target = project_list[i];
    if (target?.reductionCert) {
      return_value.push({
        value: target?.reductionCert?.seq,
        label: `${target?.title} (No: ${target?.reductionCert?.seq}, ${target?.reductionCert?.monitorDegree}D)`,
      });
    }
  }
  return return_value;
};

const getProjectBySeq = (seq, project_list) => {
  let return_value = null;

  for (let i in project_list) {
    let target = project_list[i];
    if (Number(seq) === target.seq) {
      return_value = target;
    }
  }
  return return_value;
};
const buildMonitorDegreeMenus = (reduction_list) => {
  let return_value = [];

  for (let i in reduction_list) {
    let target = reduction_list[i];
    return_value.push({
      value: target.seq,
      label: target.monitoringDegree + '차',
    });
  }
  return return_value;
};

const getCreditValue = (seq, reduction_list) => {
  let return_value = null;

  for (let i in reduction_list) {
    let target = reduction_list[i];
    if (Number(seq) === target.seq) {
      return_value = target.lastDoc.realReduction;
    }
  }
  return return_value;
};

const jsonParser = (value) => {
  try {
    if (typeof value === 'string') {
      return typeof JSON.parse(value) === 'string' ? JSON.parse(JSON.parse(value)) : JSON.parse(value);
    }
    return value;
  } catch (err) {
    return null;
  }
};

const genDday = (start, end) => {
  if (!start || !end) return null;
  const day = Math.floor(dayjs(end).diff(dayjs(start), 'day', true));
  return day > 0 ? day : 'Day';
};

const findCountry = (list) => {
  if (!list || list.length === 0) return '';
  return list.find((el) => el.types.includes('country'));
};

const genDocumentGroup = (data) => {
  if (!data || data?.length === 0) return [];
  return data.reduce((acc, cur, idx) => {
    const { type } = cur;
    if (acc[type]) acc[type].push(cur);
    else acc[type] = [cur];
    return acc;
  }, {});
};

const geni18nDate = (isKorean = true, date) => {
  const dateFormat = isKorean ? 'YYYY.MM.DD' : 'MMM.DD.YYYY';
  return dayjs(date).format(dateFormat);
};

const geni18nDatePeriod = (isKorean = true, start, end) => {
  return `${geni18nDate(isKorean, start)} ~ ${geni18nDate(isKorean, end)}`;
};

const Handler = {
  REGEXP: REGEXP,
  VARIABLES: VARIABLES,
  ENUM: ENUM,
  formToBody: formToBody,
  formToBodyKey: formToBodyKey,
  formValidation: formValidation,
  formValidationKey: formValidationKey,
  numberWithCommas: numberWithCommas,
  BuildArticleClassname: BuildArticleClassname,
  getYYYYMMDDByUnix: getYYYYMMDDByUnix,
  getYYYYMMDDHHMMByUnix: getYYYYMMDDHHMMByUnix,
  geni18nDate: geni18nDate,
  geni18nDatePeriod: geni18nDatePeriod,
  dbDateToYYYYMMDDHHMMSS: dbDateToYYYYMMDDHHMMSS,
  dbDateToYYYYMMDD: dbDateToYYYYMMDD,
  dbDateToYYYYMMDDComma: dbDateToYYYYMMDDComma,
  buildMethodologyMenus: buildMethodologyMenus,
  getMethodologyBySeq: getMethodologyBySeq,
  getEvidenceByMapper: getEvidenceByMapper,
  getModeLabel: getModeLabel,
  renderProjectTypeText: renderProjectTypeText,
  renderProjectSubTypeText: renderProjectSubTypeText,
  buildEvidenceValue: buildEvidenceValue,
  buildEvidenceLabel: buildEvidenceLabel,
  buildTermsValue: buildTermsValue,
  buildSubAreaMenus: buildSubAreaMenus,
  buildMonitoringValue: buildMonitoringValue,
  buildMonitoringLabel: buildMonitoringLabel,
  dbDashDateToCommaDate: dbDashDateToCommaDate,
  dbCommaDateToDashDate: dbCommaDateToDashDate,
  buildProjectMenus: buildProjectMenus,
  buildIssueProjectMenus: buildIssueProjectMenus,
  getProjectBySeq: getProjectBySeq,
  buildMonitorDegreeMenus: buildMonitorDegreeMenus,
  getCreditValue: getCreditValue,
  textEllipsis: textEllipsis,
  genFileName: genFileName,
  genDday: genDday,
  isImage: isImage,
  isPdf: isPdf,
  findCountry: findCountry,
  GET_DEFAULT_IMAGE: GET_DEFAULT_IMAGE,
  translateMsg: translateMsg,
  jsonParser: jsonParser,
  genDocumentGroup: genDocumentGroup,
};

export default Handler;

import styled from '@emotion/styled';
import { Autocomplete, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import debounce from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
const center = {
  lat: -3.745,
  lng: -38.523,
};

const MapContainer = styled.section`
  min-width: 560px;
  width: 100%;
  height: 500px;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #ddd;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-sizing: border-box;
`;

const SearchBox = styled(Autocomplete)`
  display: block;
  position: relative;
  z-index: 1;
  &.none {
    display: none;
  }
`;

export const GoogleMapWrapper = (props) => {
  const { address, onChange, meta } = props;
  const { t } = useTranslation();
  const ref = useRef(null);
  const location = useLocation();
  const [libraries] = useState(['places']);
  const [addrValue, setAddrValue] = useState('서울시');
  const [showMap, setShowMap] = useState(false);
  const [hideSearchBox, setHideSearchBox] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.prod ? process.env.REACT_APP_GOOGLE_MAP_KEY : '',
    libraries,
  });

  const [map, setMap] = useState(null);
  const onLoad = useCallback(
    (map, addrValue) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addrValue }, (results, status) => {
        if (status === 'OK') {
          map.setCenter(results[0].geometry.location);
          map.setZoom(13);
          console.log(results[0]);
          console.log(results[0].geometry.location.lat());
          console.log(results[0].geometry.location.lng());
          onChange(results[0]);
          var marker = new window.google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
          });
        }
      });
      setMap(map);
      return () => {
        const pac = document.querySelectorAll('.pac-container');
        pac.forEach((e) => {
          e.remove();
        });
      };
    },
    [addrValue],
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const changeHandler = (e) => {
    if (e.target.value.length === 0) {
      setShowMap(false);
    }
  };

  const handleInputChange = useCallback((e) => {
    debounce(changeHandler(e), 1000);
  }, []);

  useEffect(() => {
    return () => {
      const pac = document.querySelectorAll('.pac-container');
      pac.forEach((e) => {
        e.remove();
      });
    };
  }, []);

  const onPlaceChanged = () => {
    setShowMap(false);
    setAddrValue(ref.current.value);
    setShowMap(true);
  };

  useEffect(() => {
    if (address) {
      setAddrValue(address);
      setShowMap(true);
    }
  }, [address]);

  useEffect(() => {
    if (meta) meta?.readOnly || meta?.disabled ? setHideSearchBox(true) : setHideSearchBox(false);
  }, []);

  return isLoaded ? (
    <MapContainer>
      <SearchBox className={hideSearchBox ? 'none' : ''} onPlaceChanged={onPlaceChanged} style={{ zIndex: 9999 }}>
        <input
          type='text'
          ref={ref}
          onChange={(e) => handleInputChange(e)}
          placeholder={address ? address : t('ADDRESS_PLACEHOLDER')}
          style={{
            boxSizing: `border-box`,
            border: `1px solid #ccc`,
            position: `absolute`,
            top: `10px`,
            left: `10px`,
            width: `500px`,
            height: `60px`,
            padding: `0 12px`,
            borderRadius: `8px`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
      {showMap && (
        <GoogleMap
          mapContainerStyle={{
            width: location.pathname.includes('signup') || location.pathname.includes('mypage') ? '560px' : '945px',
            height: '500px',
          }}
          center={center}
          onLoad={(map) => onLoad(map, addrValue)}
          onUnmount={onUnmount}
        ></GoogleMap>
      )}
    </MapContainer>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMap);

import styled from '@emotion/styled';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import Handler from '../../Handler';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const ModalWrapper = styled.article`
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 720px !important;
  max-height: 750px !important;
  width: 720px;
  height: 750px;
  .react-pdf__Page {
    position: relative;
  }
  .react-pdf__Page__annotations.annotationLayer {
    width: 720px !important;
    height: 750px !important;
    max-width: 720px;
    max-width: 750px;
  }
  .pdf-viewer {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(203 213 225);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: rgb(226 232 240);
    }
  }
  canvas {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    object-position: top;
    background: rgb(248 250 252);
  }
  .image-box {
    min-height: 750px;
  }
`;

export const ModalPdfView = (props) => {
  const pdfRef = useRef();
  const { url, onClose } = props;
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (args) => {
    setNumPages(args.numPages);
  };

  const isPdf = useMemo(() => {
    return Handler.isPdf(url);
  }, [url]);

  const pdfFile = useMemo(() => {
    return {
      url: new URL(url),
    };
  }, [url]);

  return (
    <ModalWrapper className='project-action-modal relative'>
      <header className='border-b border-solid border-gray-200'>
        <h4>{t('PREVIEW')}</h4>
        <div
          className='ico close-small'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='overflow-scroll pdf-viewer' style={{ marginBottom: 60 }}>
        <div className='w-full bg-slate-50 mb-0 box-border relative'>
          {isPdf && pdfFile ? (
            <Document
              inputRef={pdfRef}
              file={pdfFile}
              orientation={'landscape'}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.log('Inside Error', error)}
              loading={
                <div
                  className='w-full h-full flex flex-col justify-center items-center'
                  style={{ width: '720px', height: '720px' }}
                >
                  <CircularProgress />
                </div>
              }
            >
              <Page
                pageNumber={pageNumber}
                loading={<></>}
                style={{ width: '100%' }}
                renderTextLayer={false}
                orientation='portrait'
              />
            </Document>
          ) : (
            <div className='w-full flex flex-col items-start relative image-box'>
              <img src={url} alt={url} className='absolute top-0 left-0 w-full h-full object-contain' />
            </div>
          )}
        </div>
        <div className='flex flex-col fixed pb-2 bg-white bottom-0 items-center w-full border-t border-solid border-gray-200'>
          <div className='flex justify-center gap-2 py-2'>
            <IconButton size='small' disabled={pageNumber <= 1} onClick={() => setPageNumber((prev) => prev + -1)}>
              <ArrowBackIosNewIcon color={pageNumber <= 1 ? 'info' : 'primary'} fontSize='small' />
            </IconButton>
            <div className='flex gap-1 justify-center items-center py-4 text-sm w-30'>
              <span className='text-gray-500 text-sm'>Page</span>
              <span className='text-primary font-bold mx-2'>{pageNumber}</span>
              <span className='text-gray-500 text-sm mr-1'>/</span>
              <span className='text-gray-500 text-sm'>{numPages}</span>
            </div>
            <IconButton
              size='small'
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber((prev) => prev + +1)}
            >
              <ArrowForwardIosIcon color={pageNumber >= numPages ? 'info' : 'primary'} fontSize='small' />
            </IconButton>
          </div>
        </div>
      </section>
    </ModalWrapper>
  );
};

import { Box, Modal } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IsoCert from '../../styles/icon/iso_cert.svg';
import { modalStyle } from '../../styles/style';
import { ModalPrivacyTerm, ModalUsageTerm } from '../modal';

export const MainFooter = (props) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { t } = useTranslation();

  const SITE_LIST = [
    {
      text: 'MARKETPLACE',
      url: 'https://pople.kr',
    },
    {
      text: 'GREENERY_WEBSITE',
      url: 'https://www.greenery.im/',
    },
  ];

  return (
    <>
      <article className='main-footer m-auto'>
        <section className='left__container py-6'>
          <div className='caption__box'>
            <span className='mb-3 font-bold'>{`${t('GREENERY')}`}</span>
            <div>
              <span>{t('BIZ_NUMBER')}: 401-88-02247</span>
              <div className='mx-2 border-gray-400 border-l border-solid m-1'></div>
              <span>{`${t('CEO')}: ${t('YSH')}`}</span>
              <div className='mx-2 border-gray-400 border-l border-solid m-1'></div>
              <span>{t('EMAIL')}: info@greenery.im</span>
            </div>
            <div>
              <span>
                {t('BIZ_SELLER')}: {t('BIZ_SELLER_NUMBER')}
              </span>
              <div className='mx-2 border-gray-400 border-l border-solid m-1'></div>
              <span>{`${t('ADDRESS')}: ${t('COMPANY_ADDRESS')}`}</span>
            </div>
          </div>
        </section>
        <section className='right__container'>
          <select
            value='none'
            onChange={(e) => {
              window.open(e.target.value, '_blank');
            }}
          >
            <option value='none'>{t('FAMILY_SITE')}</option>
            {SITE_LIST.map((item, index) => {
              return (
                <option key={item.text + index} value={item.url}>
                  {t(item.text)}
                </option>
              );
            })}
          </select>
        </section>
      </article>
      <article className='w-full flex flex-col justify-center bg-slate-50'>
        <article className='main-footer m-auto'>
          <section className='w-full flex justify-between'>
            <div className='flex gap2 items-center'>
              {/* <div className='mr-4'>
                <img src={IETA} alt='ieta' />
              </div> */}
              <div className='mr-4'>
                <img src={IsoCert} alt='iso-certification' />
              </div>
              <div>
                <p className='text-sm text-gray-400'>{`${t('SCOPE')}: ${t('SCOPE_TITLE')}`}</p>
                <p className='text-sm text-gray-400 mt-2'>{`${t('EXPIRE_DATE')}: ${t('EXPIRE_DATE_STR')}`}</p>
              </div>
            </div>
            <div>
              <div className='flex gap-2 justify-end'>
                <span className='cursor-pointer text-gray-400 text-sm' onClick={() => setOpen(true)}>
                  {t('USE_TERM')}
                </span>
                <div className='border-gray-400 border-l border-solid m-1'></div>
                <span className='cursor-pointer text-gray-400 text-sm' onClick={() => setOpen2(true)}>
                  {t('TERM_PRIVACY')}
                </span>
              </div>
              <div className='mt-2'>
                <span className='text-sm text-gray-400'>ⓒ Greenery Inc. ALL RIGHTS RESERVED.</span>
              </div>
            </div>
          </section>
        </article>
        <Modal open={open}>
          <Box sx={modalStyle}>
            <ModalUsageTerm onClose={() => setOpen(false)} />
          </Box>
        </Modal>
        <Modal open={open2}>
          <Box sx={modalStyle}>
            <ModalPrivacyTerm onClose={() => setOpen2(false)} />
          </Box>
        </Modal>
      </article>
    </>
  );
};

import React from 'react';
import { useLabel } from '../../hooks';

export const PrivacyTerm = (props) => {
  const { PRIVACY_TERM, TransPrivacy } = useLabel();

  const renderTable = (item) => {
    return (
      'TABLE' in item &&
      item['TABLE'].map((t, idx) => {
        return (
          <article key={`table-${idx}`} className='my-8'>
            <h4 className='mb-4'>{t['TITLE']}</h4>
            <div className='flex'>
              {t['ROWS'].map((r, idx) => {
                return (
                  <ul key={`table-row-${idx}`} className='w-1/2 mb-1 border-b border-solid border-gray-300'>
                    <li className='bg-slate-200 font-bold text-center py-1'>{r['KEY']}</li>
                    <li className='p-2'>{r['VALUE']}</li>
                  </ul>
                );
              })}
            </div>
          </article>
        );
      })
    );
  };

  const renderSubList = (item) => {
    return (
      'SUB_LIST' in item &&
      item['SUB_LIST'].map((el, sub_list_idx) => (
        <div key={`sublist-${sub_list_idx}`} className='mb-3'>
          <h5 className='my-4'>{el['TITLE']}</h5>
          {el['LIST'].map((row, row_idx) => (
            <p key={`sublist-row-${row_idx}`}>{row}</p>
          ))}
        </div>
      ))
    );
  };

  return (
    <section className='overflow-y-auto text-left pt-8 pb-36 whitespace-pre-wrap p-4'>
      <p>{TransPrivacy('PRIVACY_DESCRIPTION')}</p>
      {PRIVACY_TERM.map((part, content_list_idx) => {
        return (
          <article key={`content-list-${content_list_idx}`} className='mb-12'>
            <h2 className='my-4'>{part['TITLE']}</h2>
            <div>
              {part['CONTENT'].map((item, item_idx) => {
                return (
                  <div key={`content-${item_idx}`}>
                    <h3 className='my-2'>{item['SUB_TITLE']}</h3>
                    {item['CONTENT_LIST'].map((p, content_list_idx) => (
                      <p key={`content-list-${content_list_idx}`}>{p}</p>
                    ))}
                    {renderSubList(item)}
                    {renderTable(item)}
                  </div>
                );
              })}
            </div>
            <p>{'DESCRIPTION_END' in part && part['DESCRIPTION_END']}</p>
          </article>
        );
      })}
      <p>{TransPrivacy('PRIVACY_END')}</p>
    </section>
  );
};

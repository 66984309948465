import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import API from '../../api';
import Utils from '../../api/Utils';
import { PageHeader, TableConsultation } from '../../components';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel } from '../../hooks';
import NoData from '../../styles/icon/no_data.svg';

export const ConsultationListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const { CONSULTATION_PAGE } = useLabel();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const tableRowClick = (index) => {
    console.log('tableRowClick index', index);
    let target_seq = list[index].seq;
    console.log('tableRowClick target_seq', target_seq);
  };

  //server
  const requestNewList = () => {
    API.Consultation.GetList()
      .then((res) => {
        setList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  //hook
  useEffect(() => {
    requestNewList();
  }, []);

  const tableCallback = (item) => {
    const params = {
      title: isKorean ? item.title : item.titleEn,
      category: item.category.toLowerCase(),
    };
    if (item.category === 'Methodology') {
      params.registrySeq = item.methodologySeq;
    }
    if (item.category === 'Project') {
      params.registrySeq = item.projectSeq;
    }
    history.push(`/consultation/detail/${item.seq}${Utils.ObjectToParams(params)}`);
  };

  const goToConsultationHistory = useCallback(() => history.push('/consultation/histories'), []);

  const renderConsultationList = () => {
    if (loading) {
      return (
        <section className='w-full'>
          <CircularProgress size={30} />
        </section>
      );
    }
    return list && list?.length > 0 ? (
      <section className='w-full'>
        <TableConsultation
          data={list}
          isKorean={isKorean}
          rowClick={tableRowClick}
          callback={tableCallback}
        ></TableConsultation>
        <div style={{ height: 40 }}></div>
      </section>
    ) : (
      <section className='w-full h-96 py-16 text-center flex flex-col justify-center'>
        <article className='py-8'>
          <img src={NoData} alt='no-data' />
          <p className='mt-8 leading-8 whitespace-pre-wrap'>{CONSULTATION_PAGE['NO_DATA']}</p>
        </article>
        <article>
          <Button variant='contained' onClick={goToConsultationHistory}>
            {CONSULTATION_PAGE['GOTO_HISTORY']}
          </Button>
        </article>
      </section>
    );
  };

  return (
    <article className='basic-page'>
      <PageHeader type='consultation' title={CONSULTATION_PAGE['TITLE']}></PageHeader>
      <article className='content__wrapper'>
        <section className='w-full flex justify-start text-left'>
          <p>{CONSULTATION_PAGE['DESCRIPTION']}</p>
        </section>
        <div style={{ height: 40 }}></div>
        {renderConsultationList()}
      </article>
    </article>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const SelectBox = (props) => {
  const { value, onChange, meta, onEnter } = props;
  const { t } = useTranslation();

  const onChangeMenu = (e) => {
    let new_value = e.target.value;
    onChange(new_value, meta.key);
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-select-box', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{t(meta.label)}</span>
          {renderRequired()}
        </header>
      )}

      {meta.subLabel && (
        <div className='sub-label__box'>
          <p>{t(meta.subLabel)}</p>
        </div>
      )}

      <div className='select__box'>
        <select disabled={meta.disabled} value={value} onChange={onChangeMenu} placeholder={'test'}>
          <option defaultValue value=''>
            {t('SELECT_OPTION')}
          </option>
          {meta.menus &&
            meta.menus.map((item, index) => {
              return (
                <option key={item.label + index} value={item.value}>
                  {t(item.label)}
                </option>
              );
            })}
        </select>
      </div>
    </article>
  );
};

import React from 'react';
import { LoginHeader, MainFooter } from '../components';

export const LoginLayout = (props) => {
  const { children } = props;

  return (
    <div className='login-layout'>
      <section className='header__box'>
        <LoginHeader></LoginHeader>
      </section>
      <section className='content__box'>{children}</section>
      <section className='footer__box'>
        <MainFooter></MainFooter>
      </section>
    </div>
  );
};

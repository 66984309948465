import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import { Button, FileUploader, TextArea, TextInput } from '../../components';

export const ModalProjectView = (props) => {
  const { action, actionDate, onClose, requestAction } = props;
  const history = useHistory();
  const { t } = useTranslation();

  console.log('View Modal action.status', action.status);
  switch (action.status) {
    case 'RequestMoveWriting': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t('COMPLEMENT_ACCEPT')}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container' style={{ marginBottom: 0 }}>
            <span>보완을 수락하였습니다.</span>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 10 }}>
            <span style={{ color: '#777' }}>보완하기 위해 stpe1 페이지로 이동합니다.</span>
          </section>

          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginRight: 12 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={() => {
                console.log('action', action.seq);
                history.push('/project/writing/' + action.seq);
              }}
              label={t('MOVE')}
            ></Button>
          </section>
        </article>
      );
    }
    case 'RequestImproveProject': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container'>
            <header>
              <h2>{t('PROJECT_INFORMATION')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('DOC_VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}

              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: '처리일',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginRight: 12 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={() => {
                  requestAction('ImproveProjectStart', null);
                }}
                label='보완 수락'
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'AuditCancel': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.ENUM.ACTION.STOP_REASON(action.stopReason)}
                meta={{
                  key: 'title',
                  label: t('TYPE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextArea
                value={action.userComment}
                meta={{
                  key: 'title',
                  label: t('REASON'),
                  disabled: true,
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                    borderRight: 'none',
                  },
                }}
              ></TextArea>
              {action.userCommentFile && (
                <FileUploader
                  value={action.userCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                    style: {
                      gridColumn: '1/3',
                      borderRight: 'none',
                    },
                  }}
                ></FileUploader>
              )}
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'CanceledProject': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t('REVIEW_SUSPENDED')}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container'>
            <header>
              <h2>{t('PROJECT_INFORMATION')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='button__container' style={{ marginTop: 0, marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
          </section>
        </article>
      );
    }
    case 'LaunchGiveUp':
    case 'ProjectGiveUp': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container'>
            <header>
              <h2>{t('PROJECT_INFORMATION')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container'>
            <header>
              <h2>{t('COMMENTS')}</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Reply</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.userComment}
                meta={{
                  key: 'title',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.userCommentFile && (
                <FileUploader
                  value={action.userCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.userCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'WaitingForAuditLaunch': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t('WAITING')}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='launch__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectLaunchDate}
                meta={{
                  key: 'title',
                  label: t('PROJECT_START_DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.userCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextArea
                value={action.userComment}
                meta={{
                  key: 'title',
                  label: t('MEMO'),
                  disabled: true,
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
              ></TextArea>
              {action.userCommentFile && (
                <FileUploader
                  value={action.userCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                    style: {
                      gridColumn: '1/3',
                    },
                  }}
                ></FileUploader>
              )}
            </ul>
          </section>
          <section className='button__container' style={{ marginTop: 0, marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
          </section>
        </article>
      );
    }
    case 'ProjectRejected': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container'>
            <header>
              <h2>{t('PROJECT_INFORMATION')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    default: {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t(Handler.ENUM.ACTION.STATUS(action.status))}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container'>
            <header>
              <h2>사업정보</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}

              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', backgroundColor: '#fff', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
  }
};

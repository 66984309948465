import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddressLocation,
  Button,
  CheckBox,
  CommaNumberInput,
  DatePicker,
  FileMultipleUploader,
  FileUploader,
  RadioBox,
  SelectBox,
  SelectEvidence,
  TextArea,
  TextInput,
  ToastEditor,
} from '../../components';

export const ContentProject = (props) => {
  const {
    step,
    formData,
    formMeta,
    formChange,
    setFormData,
    setFormMeta,
    contentMeta,
    contentMetaChange,
    readOnly,
    type,
  } = props;
  console.log('ContentProject formData', formData);
  console.log('ContentProject formMeta', formMeta);
  console.log('ContentProject contentMeta', contentMeta);
  console.log('ContentProject contentMeta', contentMeta);

  const { t } = useTranslation();

  const buildMeta = (meta) => {
    let meta_value = { ...meta };
    if (readOnly) {
      meta_value.readOnly = true;
    }
    return meta_value;
  };

  // console.log('item', item);
  // const { title, file } = item;
  // const [htmlString, setHtmlString] = useState(null);
  // if (file) {
  //   axios.get(file).then(
  //     (res) => {
  //       setHtmlString(res.data);
  //     },
  //     (err) => {
  //       console.log('err', err);
  //     },
  //   );
  // }

  const [renderedTerms, setRenderedTerms] = useState([]);

  const getTermsFile = async (file) => await axios.get(file).then((res) => res.data);

  const RenderTerms = (title, htmlString) => {
    return (
      <li>
        <Accordion>
          <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
            <div className='title__box'>
              <span>{title}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className='content__box'>
              <div dangerouslySetInnerHTML={{ __html: htmlString }} />
            </div>
          </AccordionDetails>
        </Accordion>
      </li>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const terms = await Promise.all(
        contentMeta.termsList.map(async (item) => {
          const html = await getTermsFile(item.file);
          return RenderTerms(item.title, html);
        }),
      );
      setRenderedTerms(terms);
    };

    fetchData();
  }, [contentMeta?.termsList?.length]);

  return (
    <article className='content-project' key={'project-component'}>
      {/* step 1 */}
      {(step === 0 || step === 2) && (
        <>
          <section className='section__container'>
            <header>
              <h2>{t('APPLICANT')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={formData.companyName}
                meta={buildMeta(formMeta.companyName)}
                onChange={formChange}
              ></TextInput>
              <TextInput value={formData.ceoName} meta={buildMeta(formMeta.ceoName)} onChange={formChange}></TextInput>
              <TextInput
                value={formData.businessNumber}
                meta={buildMeta(formMeta.businessNumber)}
                onChange={formChange}
              ></TextInput>
              <FileUploader
                value={formData.businessFile}
                onChange={formChange}
                meta={buildMeta(formMeta.businessFile)}
              ></FileUploader>
              <AddressLocation
                value={formData.address}
                hideMap={true}
                onChange={formChange}
                meta={buildMeta(formMeta.address)}
              ></AddressLocation>
              <CheckBox
                meta={{
                  disabled: readOnly ? true : false,
                  label: 'SAME_PROJECT_SITE',
                  style: {
                    marginTop: '-20px',
                    gridColumn: '1/3',
                  },
                  className: 'square',
                }}
                value={contentMeta.isSameAddress}
                onChange={(new_value, key) => {
                  contentMetaChange(new_value, 'isSameAddress');
                }}
              ></CheckBox>
              <TextInput value={formData.site} meta={buildMeta(formMeta.site)} onChange={formChange}></TextInput>
              <TextInput value={formData.fax} meta={buildMeta(formMeta.fax)} onChange={formChange}></TextInput>
            </ul>
            <div style={{ width: 944, marginTop: 40, marginBottom: 40 }} className='border-grey-200'></div>

            <ul className='form-list'>
              <TextInput value={formData.name} meta={buildMeta(formMeta.name)} onChange={formChange}></TextInput>
              <TextInput value={formData.phone} meta={buildMeta(formMeta.phone)} onChange={formChange}></TextInput>
              <TextInput
                value={formData.department}
                meta={buildMeta(formMeta.department)}
                onChange={formChange}
              ></TextInput>
              <TextInput value={formData.email} meta={buildMeta(formMeta.email)} onChange={formChange}></TextInput>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <h2>{t('BIZ_INFO')}</h2>
            </header>
            <ul className='form-list'>
              <RadioBox
                value={formData.isExported}
                onChange={formChange}
                meta={buildMeta(formMeta.isExported)}
              ></RadioBox>
              {formData.isExported && (
                <>
                  <TextInput
                    value={formData.exportedTitle}
                    meta={buildMeta({
                      required: true,
                      key: 'exportedTitle',
                      label: 'OTHER_ORG_NAME',
                      type: 'content',
                      style: {
                        gridColumn: '1/3',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={formData.exportedOrgan}
                    meta={buildMeta({
                      required: true,
                      key: 'exportedOrgan',
                      label: 'REGISTRAR',
                      type: 'content',
                      style: {
                        maxWidth: '420px',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <DatePicker
                    value={formData.exportedDate}
                    meta={buildMeta({
                      required: true,
                      key: 'exportedDate',
                      label: 'REGISTRATION_DATE',
                      className: 'project',
                      style: {
                        maxWidth: '420px',
                        minWidth: '420px',
                        marginTop: '16px',
                      },
                    })}
                    onChange={formChange}
                  ></DatePicker>
                </>
              )}
            </ul>
            <div style={{ width: 944, marginTop: 40, marginBottom: 40 }} className='border-grey-200'></div>
            <ul className='form-list'>
              <TextInput value={formData.title} onChange={formChange} meta={buildMeta(formMeta.title)}></TextInput>
              {readOnly || type === 'Modify' ? (
                <>
                  <SelectBox value={formData.area} onChange={formChange} meta={buildMeta(formMeta.area)}></SelectBox>
                  <SelectBox
                    value={formData.subArea}
                    onChange={formChange}
                    meta={buildMeta(formMeta.subArea)}
                  ></SelectBox>
                  <SelectBox
                    value={formData.methodologySeq}
                    onChange={formChange}
                    meta={buildMeta(formMeta.methodologySeq)}
                  ></SelectBox>
                  <DatePicker
                    value={formData.reductionStartDate}
                    meta={buildMeta(formMeta.reductionStartDate)}
                    onChange={formChange}
                  ></DatePicker>
                  <DatePicker
                    value={formData.reductionEndDate}
                    meta={buildMeta(formMeta.reductionEndDate)}
                    onChange={formChange}
                  ></DatePicker>
                </>
              ) : (
                <>
                  <DatePicker
                    value={formData.reductionStartDate}
                    meta={buildMeta(formMeta.reductionStartDate)}
                    onChange={formChange}
                  ></DatePicker>
                  <DatePicker
                    value={formData.reductionEndDate}
                    meta={buildMeta(formMeta.reductionEndDate)}
                    onChange={formChange}
                  ></DatePicker>
                  <SelectBox value={formData.area} onChange={formChange} meta={buildMeta(formMeta.area)}></SelectBox>
                  <SelectBox
                    value={formData.subArea}
                    onChange={formChange}
                    meta={buildMeta(formMeta.subArea)}
                  ></SelectBox>
                  <SelectBox
                    value={formData.methodologySeq}
                    onChange={formChange}
                    meta={buildMeta(formMeta.methodologySeq)}
                  ></SelectBox>
                </>
              )}
              <TextArea value={formData.purpose} onChange={formChange} meta={buildMeta(formMeta.purpose)}></TextArea>
              <ToastEditor
                value={formData.content}
                onChange={formChange}
                meta={buildMeta(formMeta.content)}
              ></ToastEditor>
              <AddressLocation
                maker={true}
                value={formData.location}
                onChange={formChange}
                meta={buildMeta(formMeta.location)}
                isDomestic={formData.isDomestic}
                isSameAddress={contentMeta.isSameAddress}
              ></AddressLocation>
              <FileMultipleUploader
                value={formData.workplaceImages}
                onChange={formChange}
                meta={buildMeta(formMeta.workplaceImages)}
              ></FileMultipleUploader>
              <article className='caption__box'>
                <ul>
                  <li className='gap-2'>
                    <header>
                      <span>{t('BIZ_START_DATE')}</span>
                    </header>
                    <span>{t('BIZ_START_DATE_MSG')}</span>
                  </li>
                  <li className='gap-2'>
                    <header>
                      <span>{t('CERT_START_DATE')}</span>
                    </header>
                    <span>{t('BIZ_START_DATE_MSG')}</span>
                  </li>
                  <li className='gap-2'>
                    <header>
                      <span>{t('CERT_END_DATE')}</span>
                    </header>
                    <span>{t('BIZ_START_DATE') + ' ' + t('VALID_PERIOD')}</span>
                  </li>
                </ul>
              </article>
            </ul>
          </section>
          {contentMeta?.termsList?.length > 0 && (
            <section className='section__container'>
              <header>
                <h2>{t('AGREE_TO_TERM')}</h2>
              </header>
              <ul className='terms--list'>
                <header>
                  <CheckBox
                    value={contentMeta.termsAggree}
                    onChange={(new_value) => {
                      contentMetaChange(new_value, 'termsAggree');
                    }}
                    meta={{
                      disabled: readOnly ? true : false,
                      label: 'REVIEWED_AND_AGREE',
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                {renderedTerms}
              </ul>
            </section>
          )}
        </>
      )}

      {/* step 2 */}
      {(step === 1 || step === 2) && (
        <>
          <section className='section__container'>
            <header>
              <h2>{t('GHG_REDUCTION')}</h2>
            </header>
            <ul className='form-list'>
              <CommaNumberInput
                value={formData.reductionBase}
                meta={buildMeta(formMeta.reductionBase)}
                onChange={formChange}
              ></CommaNumberInput>
              <CommaNumberInput
                value={formData.reductionBusiness}
                meta={buildMeta(formMeta.reductionBusiness)}
                onChange={formChange}
              ></CommaNumberInput>
              <CommaNumberInput
                value={formData.reductionLeak}
                meta={buildMeta(formMeta.reductionLeak)}
                onChange={formChange}
              ></CommaNumberInput>
              <CommaNumberInput
                value={formData.reduction}
                meta={buildMeta(formMeta.reduction)}
                onChange={formChange}
              ></CommaNumberInput>
            </ul>
            <div className='download__box'>
              <header>
                <b>{t('CALC_QUESTION')}</b>
                <span>{t('DOWNLOAD_INFORMATION')}</span>
              </header>
              <Button
                style={{ width: 240, height: 38 }}
                type='secondary-square'
                label={t('DOWNLOAD_FILE')}
                onClick={() => {
                  window.open(contentMeta.calculateFile, '_blank');
                }}
              ></Button>
            </div>
          </section>
          <>
            <section className='section__container'>
              <header>
                <h2>{t('BEFORE_AFTER_IMAGE')}</h2>
              </header>
              <ul className='form-list'>
                <FileMultipleUploader
                  value={formData.beforeImages}
                  onChange={formChange}
                  meta={buildMeta(formMeta.beforeImages)}
                ></FileMultipleUploader>
                <FileMultipleUploader
                  value={formData.afterImages}
                  onChange={formChange}
                  meta={buildMeta(formMeta.afterImages)}
                ></FileMultipleUploader>
              </ul>
            </section>
            {formData?.evidence?.length > 0 && (
              <section className='section__container'>
                <header>
                  <h2>{t('SUPPORTING_DOC')}</h2>
                </header>
                <ul className='evidence-list'>
                  <SelectEvidence
                    value={formData.evidence}
                    onChange={formChange}
                    meta={buildMeta(formMeta.evidence)}
                  ></SelectEvidence>
                </ul>
              </section>
            )}
            {formData.isExported && (
              <section className='section__container'>
                <header>
                  <h2>{t('THIRD_PARTY_REGISTRATION_CERT')}</h2>
                </header>
                <ul className='form-list'>
                  <FileUploader
                    value={formData.proofFile}
                    onChange={formChange}
                    meta={buildMeta(formMeta.proofFile)}
                  ></FileUploader>
                </ul>
              </section>
            )}
          </>
          <section className='section__container'>
            <header>
              <h2>{t('MONITORING_PLAN_INPUT')}</h2>
            </header>
            <ul className='form-list'>
              {readOnly || type === 'Modify' ? (
                <TextInput
                  value={formData.monitoringType === 'Document' ? t('PAPER_MONITORING') : t('SYSTEM_MONITORING')}
                  onChange={() => {}}
                  meta={buildMeta(formMeta.monitoringType)}
                ></TextInput>
              ) : (
                <SelectBox
                  value={formData.monitoringType}
                  onChange={formChange}
                  meta={buildMeta(formMeta.monitoringType)}
                ></SelectBox>
              )}

              <ToastEditor
                value={formData.monitoringPlan}
                onChange={formChange}
                meta={buildMeta(formMeta.monitoringPlan)}
              ></ToastEditor>
            </ul>
          </section>
        </>
      )}
    </article>
  );
};

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataRow } from './NoDataRow';

const renderTypeText = (value) => {
  switch (value) {
    case 'Member': {
      return 'SIGNUP_WITHDRAWAL';
    }
    case 'Methodology': {
      return 'METHODOLOGY';
    }
    case 'Project': {
      return 'MANAGEMENT';
    }
    case 'ReductionCert': {
      return 'REDUCTION_CERT';
    }
    case 'ZCCredit': {
      return 'POP_CREDIT_VRC';
    }
    case 'Etc': {
      return 'ETC';
    }
    default: {
      return value;
    }
  }
};

const renderStatusText = (value) => {
  switch (value) {
    case 'Inquiry': {
      return '답변대기';
    }
    case 'Complete': {
      return '답변완료';
    }
    default: {
      return value;
    }
  }
};

export const TableInquiry = (props) => {
  const { data, rowClick } = props;
  const { t } = useTranslation();
  return (
    <article className='table-inquiry'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ minWidth: 100 }}>
                NO
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100 }}>
                {t('CATEGORY')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 600 }}>
                {t('TITLE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100 }}>
                {t('DATE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 100 }}>
                {t('STATUS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={`table-row-${row.seq}-${index}`}
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  <TableCell align='center'>{row.seq}</TableCell>
                  <TableCell align='center'>{t(renderTypeText(row.type))}</TableCell>
                  <TableCell align='left'>{row.title}</TableCell>
                  <TableCell align='center'>{row.inquiryDate}</TableCell>
                  <TableCell align='center' className={row.status}>
                    {t(row.status == 'Inquiry' ? 'AWAITING_RESPONSE' : 'RESPONSE_CONPLETED')}
                  </TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={5} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

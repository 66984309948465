import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { useLabel } from '../../hooks';
import { NoDataRow } from './NoDataRow';

export const TableMethodologyRegistry = (props) => {
  const { data, isKorean, rowClick } = props;

  const { t } = useTranslation();
  const { TABLE, STANDARD_TYPE, AREA_TYPE, ERROR } = useLabel();

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' colSpan={2} style={{ width: 360 }}>
                <p className='py-1'>{TABLE['METHODOLOGY_NAME']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 100 }}>
                <p className='py-1'>{TABLE['AREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 120 }}>
                <p className='py-1'>{TABLE['SUBAREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 155 }}>
                <p className='py-1'>{TABLE['START_END_DATE']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 102 }}>
                <p className='py-1'>{isKorean ? TABLE['VALID_PERIOD'] : TABLE['ELIGIBLE_PERIOD']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 102 }}>
                <p className='py-1'>{TABLE['METHODOLOGY_STANDARD']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 102 }}>
                <p className='py-1'>{TABLE['METHODOLOGY_ID']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 102 }}>
                <p className='py-1'>{TABLE['VERSION']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 102 }}>
                <p className='py-1'>{TABLE['APPROVE_STATUS']}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow
                  key={`table-methodology-registry-${row.seq}`}
                  className='cursor-pointer'
                  onClick={() => {
                    rowClick(row);
                  }}
                >
                  <TableCell align='center' style={{ width: 80 }}>
                    <div className='w-full h-16 overflow-hidden relative bg-slate-100'>
                      {Handler.isImage(row.mainImage) && (
                        <img className='object-cover absolute top-0 left-0 w-full h-full' src={row.mainImage} alt='' />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align='left' style={{ width: 280 }}>
                    <Tooltip title={isKorean ? row?.title : row?.titleEn || ''} placement='top-start' arrow>
                      <p>{Handler.textEllipsis(isKorean ? row?.title || '-' : row?.titleEn || '-', 95)}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>{AREA_TYPE[row.area.toUpperCase()]}</TableCell>
                  <TableCell align='center'>{AREA_TYPE[row.subArea.toUpperCase()]}</TableCell>
                  <TableCell align='center'>
                    {row.startDate && row.expiredYear
                      ? Handler.geni18nDatePeriod(
                          isKorean,
                          row.startDate,
                          dayjs(row.startDate).add(row.expiredYear, 'y'),
                        )
                      : '-'}
                  </TableCell>
                  <TableCell align='center'>{isKorean ? row.expiredYear + '년' : row.expiredYear + 'years'}</TableCell>
                  <TableCell align='center'>{STANDARD_TYPE[row.standard]}</TableCell>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell align='center'>{row.version}</TableCell>
                  <TableCell align='center'>{row.isValid ? TABLE['APPROVE'] : ''}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow content={ERROR['SEARCH_DATA_FOUND']} colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'project/';

const PostNew = (body) => {
  const url = BASE_URL + 'project';
  return AxiosInstance.post(url, body);
};

const GetWriting = (seq) => {
  const url = BASE_URL + PATH + seq + '/writing';
  return AxiosInstance.get(url);
};

const GetMethodology = () => {
  const url = BASE_URL + PATH + 'methodology';
  return AxiosInstance.get(url);
};

const PutWriting = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/writing';
  return AxiosInstance.put(url, body);
};

const PutComplete = (slug, body) => {
  const url = BASE_URL + PATH + slug + '/complete';
  return AxiosInstance.put(url, body);
};

const GetList = (params) => {
  const url = BASE_URL + 'project' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostNextAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/next-action' + Utils.ObjectToParams(params);
  return AxiosInstance.post(url);
};

const PostModify = (seq) => {
  const url = BASE_URL + PATH + 'changeable/' + seq;
  return AxiosInstance.post(url);
};

const Get = (params) => {
  const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const GetAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetActionDoc = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action/doc' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Put = (slug, body) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.put(url, body);
};

const Delete = (slug) => {
  const url = BASE_URL + PATH + 'inquiry/' + slug;
  return AxiosInstance.delete(url);
};

const Post = (body) => {
  const url = BASE_URL + PATH;
  return AxiosInstance.post(url, body);
};

const Project = {
  PostNew: PostNew,
  GetWriting: GetWriting,
  PutComplete: PutComplete,
  GetMethodology: GetMethodology,
  PutWriting: PutWriting,
  GetList: GetList,
  PostNextAction: PostNextAction,
  PostModify: PostModify,
  GetDetail: GetDetail,
  GetAction: GetAction,
  GetActionDoc: GetActionDoc,
};

export default Project;

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';

const GetStandards = () => {
  const url = BASE_URL + 'standard/documents';
  return AxiosInstance.get(url);
};

const GetUsageTerm = () => {
  const url = BASE_URL + 'terms/documents';
  return AxiosInstance.get(url);
};

const GetDocuments = (category) => {
  const url = BASE_URL + category + '/documents';
  return AxiosInstance.get(url);
};

const Documents = {
  GetStandards: GetStandards,
  GetUsageTerm: GetUsageTerm,
  GetDocuments: GetDocuments,
};

export default Documents;

import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { Button } from '../../components';
import { SnackbarContext } from '../../contexts';

const acceptFIleList = (type) => {
  if (type === 'image') {
    return 'image/gif, image/jpeg, image/png, image/gif';
  } else if (type === 'sound') {
    return 'audio/mp3';
  } else if (type === 'video') {
    return 'video/mp4';
  } else {
    return '*';
  }
};

const renderPreview = (value, meta) => {
  const fileName = value ? value.split('/')[value.split('/').length - 1] : '';

  if (!value) {
    return <div className='empty__box'></div>;
  } else {
    if (meta.type === 'image') {
      return (
        <div className='img__box'>
          <img
            onClick={() => {
              window.open(value, '_blank');
            }}
            src={value}
          ></img>
        </div>
      );
    } else {
      return (
        <div className='file__box'>
          <span
            onClick={() => {
              window.open(value, '_blank');
            }}
          >
            {fileName}
          </span>
        </div>
      );
    }
  }
};

export const FileMultipleUploader = (props) => {
  const { value, onChange, meta } = props;
  const [file, setFile] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { updateSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (file) {
      console.log('file', file);
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('path', meta.path);
      console.log('formData', formData);

      API.Common.PostFile(formData).then(
        (res) => {
          console.log('res', res);
          setLoading(false);
          let new_value;
          if (value) {
            new_value = _.clone(value);
          } else {
            new_value = [];
          }
          new_value.push(res.data.cloudUrl);
          onChange(new_value, meta.key);
          removeFileInput();
        },
        (err) => {
          console.log('err', err);
          setLoading(false);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
          removeFileInput();
        },
      );
    }
  }, [file]);

  const removeFileInput = () => {
    document.getElementById(meta.key + '-file-uploader-input').value = '';
    setFile(null);
  };

  const removeFileS3 = (value, new_value) => {
    console.log('value', value);
    onChange(new_value, meta.key);
    removeFileInput();
    // let body = {
    //   url: value,
    // };
    // console.log('removeFile', body);

    // API.Common.DeleteFile(body).then(
    //   (res) => {
    //     console.log('file remove res', res);
    //     onChange(new_value, meta.key);
    //     removeFileInput();
    //   },
    //   (err) => {
    //     console.log('file remove err', err);
    //     onChange(new_value, meta.key);
    //     removeFileInput();
    //   },
    // );
  };

  const requestRemove = (index) => {
    console.log('requestRemove', index);

    let new_value = _.clone(value);
    var pulled = _.pullAt(new_value, index);

    console.log('requestRemove new_value', new_value);
    console.log('requestRemove pulled', pulled);
    removeFileS3(pulled[0], new_value);
  };

  //value 항상 어레이로 유지.

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  const renderAddButton = () => {
    if (meta.type === 'file') {
      if (value && value?.length < meta.max && !meta.readOnly) {
        return (
          <li
            className='add'
            onClick={() => {
              if (!meta.readOnly) {
                document.getElementById(meta.key + '-file-uploader-input').click();
              }
            }}
          >
            <Button
              type='info-square'
              loading={loading}
              style={{ width: '100%', height: '50px' }}
              label={t('FILE_CHOOSE')}
            ></Button>
          </li>
        );
      }
    } else {
      if (value && value?.length < meta.max && !meta.readOnly) {
        return (
          <li
            className={loading ? 'add-image loading' : 'add-image'}
            onClick={() => {
              if (!meta.readOnly) {
                document.getElementById(meta.key + '-file-uploader-input').click();
              }
            }}
          >
            {loading ? (
              <CircularProgress size={30}></CircularProgress>
            ) : (
              <div className='button__box'>
                <span>{t('FILE_CHOOSE')}</span>
              </div>
            )}
          </li>
        );
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-file-multiple-uploader', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      <header>
        <div className='label__box'>
          <span className='label'>
            {t(meta.label)}
            {renderRequired()}
          </span>
          <em>({value?.length + '/' + meta.max})</em>
        </div>
        {meta.subLabel && (
          <div className='sub-label__box'>
            <span className='sub-label'>{t(meta.subLabel)}</span>
          </div>
        )}
      </header>
      <ul className={`list__box ${meta.type}`}>
        {value &&
          value.map((item, index) => {
            return (
              <li className='render'>
                {renderPreview(item, meta, index, requestRemove)}
                {!meta.readOnly && (
                  <div className='delete__box'>
                    <ClearIcon
                      onClick={() => {
                        requestRemove(index);
                      }}
                    ></ClearIcon>
                  </div>
                )}
              </li>
            );
          })}
        {renderAddButton()}
      </ul>
      <div className='input__box'>
        <input
          type='file'
          accept={acceptFIleList(meta.type)}
          id={meta.key + '-file-uploader-input'}
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        ></input>
      </div>
    </article>
  );
};

import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import { useLabel } from '../../hooks';
import { ModalPdfView } from '../modal';

export const Download = (props) => {
  const { isKorean, title, titleEn, attachedFile, attachedFileEn } = props;
  const [showModal, setShowModal] = useState(false);
  const { TABLE_HEADER } = useLabel();

  return (
    <section className=' w-[1200px]'>
      <article
        className='flex justify-between items-center border-gray-300 box-border w-full h-[124px] py-[48px] px-[60px]'
        style={{ boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)' }}
      >
        <div className='flex items-center text-[18px]'>
          <p className='text-[#5D38E5] font-bold  mr-1'>New</p>
          <p>
            {''}
            {isKorean ? title : titleEn}
          </p>
        </div>
        <div className='flex gap-2 '>
          <Button
            variant='outlined'
            onClick={() => setShowModal(true)}
            style={{ height: 44, width: 120, borderRadius: 0, borderColor: 'black' }}
          >
            <span className='text-large text-black'>{TABLE_HEADER['PREVIEW']}</span>
          </Button>

          <Button variant='contained' style={{ height: 44, width: 120, borderRadius: 0, background: 'black' }}>
            <a
              className='flex items-center'
              target='_blank'
              rel='noreferrer'
              href={isKorean ? attachedFile : attachedFileEn}
              download={isKorean ? attachedFile : attachedFileEn}
            >
              <span className='text-large text-white'>{TABLE_HEADER['DOWNLOAD']}</span>
            </a>
          </Button>
        </div>
      </article>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          <ModalPdfView url={isKorean ? attachedFile : attachedFileEn} onClose={() => setShowModal(false)} />
        </Box>
      </Modal>
    </section>
  );
};

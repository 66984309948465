import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Button, FileMultipleUploader, PageHeader, RadioBox, TextArea, TextInput } from '../../components';
import { SnackbarContext } from '../../contexts';
import { useForm } from '../../hooks';

export const MypageInquiryAddPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange] = useForm(
    {
      type: 'Member',
      title: '',
      content: '',
      files: [],
    },
    {
      type: {
        key: 'type',
        style: {
          gridColumn: '1/3',
        },
        label: 'CATEGORY',
        menus: [
          {
            label: 'SIGNUP_WITHDRAWAL',
            value: 'Member',
          },
          {
            label: 'METHODOLOGY',
            value: 'Methodology',
          },
          {
            label: 'MANAGEMENT',
            value: 'Project',
          },
          {
            label: 'REDUCTION_CERT',
            value: 'ReductionCert',
          },
          {
            label: 'POP_CREDIT_VRC',
            value: 'ZCCredit',
          },
          {
            label: 'ETC',
            value: 'ETC',
          },
        ],
        className: 'board',
      },
      title: {
        key: 'title',
        label: 'TITLE',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
      content: {
        required: true,
        key: 'content',
        label: 'CONTENT',
        maxSize: 500,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
      files: {
        key: 'files',
        type: 'file',
        label: 'FILE',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
        className: 'board',
      },
    },
  );

  const requsetSubmit = () => {
    console.log('requsetSubmit');

    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.User.PostInquiry({ ...body }).then(
        (res) => {
          let result = res.data;
          console.log('User PostInquiry result', result);
          history.push('/request_complete?type=mypage');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: Handler.translateMsg('ERROR', 'REQUIRED_INFO'),
      });
    }
  };

  return (
    <article className='basic-page'>
      <PageHeader type='credit' title={`${t('ONETOONE_INQUIRY')}`}></PageHeader>
      {/* <Breadcrumbs content='credit' title={'1:1 상담 문의'}></Breadcrumbs> */}
      <article className='content__wrapper'>
        <section className='form__container'>
          <ul className='grid board'>
            <RadioBox value={formData.type} onChange={formChange} meta={formMeta.type}></RadioBox>
            <TextInput value={formData.title} onChange={formChange} meta={formMeta.title}></TextInput>
            <TextArea value={formData.content} onChange={formChange} meta={formMeta.content}></TextArea>
            <FileMultipleUploader
              value={formData.files}
              onChange={formChange}
              meta={formMeta.files}
            ></FileMultipleUploader>
          </ul>
        </section>
        <section className='button__container' style={{ marginTop: 0 }}>
          <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('SUBMIT')}></Button>
        </section>
      </article>
    </article>
  );
};

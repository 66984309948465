import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';

export const TablePaging = (props) => {
  const { page, totalPage, onChange, customButton } = props;
  const { t } = useTranslation();

  const prevIcon = () => {
    return (
      <div className='prev'>
        <ArrowBackIosNewIcon fontSize='16px' />
      </div>
    );
  };
  const nextIcon = () => {
    return (
      <div className='next'>
        <ArrowForwardIosIcon fontSize='16px' />
      </div>
    );
  };
  return (
    <article className='table-paging'>
      <div className='page__box'>
        <Stack spacing={4}>
          <Pagination
            count={totalPage}
            color='primary'
            page={page + 1}
            onChange={onChange}
            renderItem={(item) => {
              if (item.page === Number(page + 1)) {
                item.selected = true;
              }
              return (
                <PaginationItem
                  components={{
                    previous: prevIcon,
                    next: nextIcon,
                  }}
                  {...item}
                />
              );
            }}
          />
        </Stack>
      </div>
      {customButton && (
        <div className='button__box'>
          <Button
            onClick={() => {
              if (customButton.onClick) {
                customButton.onClick();
              }
            }}
            style={customButton.style ? { ...customButton.style } : null}
            label={customButton.label}
          ></Button>
        </div>
      )}
    </article>
  );
};

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Breadcrumbs, Button, ContentReduction, ModalReductionAction, Stepper } from '../../components';
import { ContentContext, SnackbarContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';
import { modalStyle } from '../../styles/style';

export const ReductionWritingPage = (props) => {
  const history = useHistory();
  const { slug } = useParams();
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const HOME_ICON_PROCESS = t('HOME_ICON_PROCESS', { returnObjects: true });
  const { methodologyInfo, monitoringInfo } = useContext(ContentContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [step, setStep] = useState(0);
  const [forceReadOnly] = useState(false);
  const [originData, setOriginData] = useState(null);
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      isExported: false,
      title: '',
      projectId: '',
      location: {
        addr1: '',
        addr2: '',
      },
      workplaceImages: [],
      area: '',
      subArea: '',
      methodologySeq: '',
      startDate: '',
      endDate: '',
      monitorDegree: 0,
      reductionBase: '',
      reductionBusiness: '',
      reductionLeak: '',
      reduction: '',
      realReductionBase: '',
      realReductionBusiness: '',
      realReductionLeak: '',
      realReduction: '',
      realMonitorDesc: '',
      beforeImages: [],
      afterImages: [],
      evidence: [],
      additionalEvidence: [],
    },
    {
      isExported: {
        key: 'isExported',
        style: {
          gridColumn: '1/3',
        },
        label: 'OTHER_ORG',
        required: true,
        disabled: true,
        menus: [
          {
            label: 'NO',
            value: false,
          },
          {
            label: 'OTHER_ORG_REQUIRED',
            value: true,
          },
        ],
      },
      title: {
        key: 'title',
        label: 'PROJECT',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      projectId: {
        key: 'projectId',
        label: 'PROJECT_ID',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      area: {
        key: 'area',
        label: 'BIZ_TYPE',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
      },
      subArea: {
        key: 'subArea',
        label: 'BIZ_TYPE_DETAIL',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      methodologySeq: {
        key: 'methodologySeq',
        label: 'METHODOLOGY',
        required: true,
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
        value: '',
        menus: [],
      },
      location: {
        required: true,
        disabled: true,
        key: 'location',
        label: t('BIZ_SITE'),
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      workplaceImages: {
        key: 'workplaceImages',
        type: 'image',
        label: t('BIZ_SITE_PHOTO'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        readOnly: true,
        style: {
          gridColumn: '1/3',
        },
      },
      reductionBase: {
        key: 'reductionBase',
        label: 'BASELINE_EMISSION',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionBusiness: {
        key: 'reductionBusiness',
        label: 'BIZ_EMISSION',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reductionLeak: {
        key: 'reductionLeak',
        label: 'LEAKAGE',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      reduction: {
        key: 'reduction',
        label: 'GHG_REDUCTION_QUANTITY',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBase: {
        key: 'realReductionBase',
        label: 'BASELINE_EMISSION',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionBusiness: {
        key: 'realReductionBusiness',
        label: 'BIZ_EMISSION',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReductionLeak: {
        key: 'realReductionLeak',
        label: 'LEAKAGE',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      realReduction: {
        key: 'realReduction',
        label: 'GHG_REDUCTION_QUANTITY',
        type: 'number',
        className: 'unit',
        unitLabel: 'tCO2-eq',
        required: true,
        disabled: true,
        style: {
          maxWidth: '420px',
          minWidth: '420px',
          marginBottom: 40,
        },
      },
      beforeImages: {
        key: 'beforeImages',
        type: 'image',
        label: t('PRE_BIZ_DIAGRAM'),
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      afterImages: {
        key: 'afterImages',
        type: 'image',
        label: 'BEFORE_BIZ_IMAGE',
        subLabel: t('MAXIMUM_ATTACHMENTS'),
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      proofFile: {
        type: 'file',
        key: 'proofFile',
        label: 'BIZ_CERTIFICATION',
        path: 'project/editor',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      //new
      monitorDegree: {
        key: 'monitorDegree',
        label: 'REDUCTION_MONITORING_ORDER',
        maxSize: 500,
        style: {
          maxWidth: '240px',
        },
        required: true,
        disabled: true,
      },
      startDate: {
        required: true,
        label: 'START_DATE',
        key: 'startDate',
        style: {
          maxWidth: '400px',
          minWidth: '400px',
        },
      },
      endDate: {
        required: true,
        label: 'END_DATE',
        key: 'endDate',
        style: {
          minWidth: '400px',
          maxWidth: '400px',
        },
      },
      additionalEvidence: {
        key: 'additionalEvidence',
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    businnessStartDate: '',
    certificationStartDate: '',
    certificationEndDate: '',
    termsAggree: true,
    termsList: [],
    minDate: null,
    maxDate: null,
    month: null,
    day: null,
  });

  //보완 완료용 모달
  const [actionOpen, setActionOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);

  //hooks
  useEffect(() => {
    if (slug && monitoringInfo && methodologyInfo) {
      API.ReductionCert.GetWriting(slug)
        .then((res) => {
          setOriginData(res.data);
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    }
  }, [slug, monitoringInfo, methodologyInfo]);

  useEffect(() => {
    if (originData && monitoringInfo) {
      //meta update
      let terms_value = Handler.buildTermsValue(originData.reductionCert.project.methodology);
      setContentMeta({
        ...contentMeta,
        businnessStartDate: originData.reductionCert.project.projectDate,
        certificationStartDate: originData.reductionCert.project.startDate,
        certificationEndDate: originData.reductionCert.project.endDate,
        termsList: terms_value,
        minDate: Handler.dbCommaDateToDashDate(originData.minDate),
        maxDate: Handler.dbCommaDateToDashDate(originData.maxDate),
        monitoringCalcFile: originData.reductionCert.project.methodology.monitoringCalcFile,
        projectSeq: originData.reductionCert.project.seq,
      });

      let origin_monitoring = Handler.buildMonitoringValue(
        originData.evidenceMapper,
        originData.reductionCert.monitoringType,
        monitoringInfo,
        false,
      );

      let origin_monitoring_label = Handler.buildMonitoringLabel(
        originData.evidenceMapper,
        originData.reductionCert.monitoringType,
        monitoringInfo,
        false,
      );

      let new_fromMeta = _.clone(formMeta);
      new_fromMeta.evidence.label = origin_monitoring_label;
      const lastDoc = originData.reductionCert.project.lastDoc;
      setFormMeta(new_fromMeta);
      setFormData({
        ...formData,
        isExported: lastDoc.isExported,
        title: originData.reductionCert.project.title,
        projectId: originData.reductionCert.project.id,
        location: lastDoc.location,
        workplaceImages: lastDoc.workplaceImages,
        area: originData.reductionCert.project.methodology.area,
        subArea: originData.reductionCert.project.methodology.subArea,
        methodologySeq: originData.reductionCert.project.methodology.title,
        startDate: Handler.dbCommaDateToDashDate(originData.startDate),
        endDate: Handler.dbCommaDateToDashDate(originData.endDate),
        monitorDegree: originData.reductionCert.monitorDegree,
        reductionBase: originData.reductionCert.reductionBase,
        reductionBusiness: originData.reductionCert.reductionBusiness,
        reductionLeak: originData.reductionCert.reductionLeak,
        reduction: originData.reductionCert.reduction,
        realReductionBase: originData.realReductionBase,
        realReductionBusiness: originData.realReductionBusiness,
        realReductionLeak: originData.realReductionLeak,
        realReduction: originData.realReduction,
        beforeImages: lastDoc.beforeImages ? lastDoc.beforeImages : [],
        afterImages: lastDoc.afterImages ? lastDoc.afterImages : [],
        evidence: origin_monitoring,
        additionalEvidence: originData.additionalEvidence ? originData.additionalEvidence : [],
      });
    }
  }, [originData, monitoringInfo]);

  useEffect(() => {
    if (formData.startDate && formData.endDate) {
      let new_startDate = new Date(formData.startDate).getTime();
      let new_endDate = new Date(formData.endDate).getTime();
      if (new_endDate > new_startDate) {
        let diff_time = new_endDate - new_startDate;
        console.log('diff_time', diff_time);
        let one_day = 86400000;
        let one_month = 30;
        let total_day = Math.floor(diff_time / one_day);
        let month = 0;
        let day = total_day;
        if (total_day > one_month) {
          month = Math.floor(total_day / one_month);
          day = Math.floor(total_day - one_month * month);
        }
        setContentMeta({
          ...contentMeta,
          month: month,
          day: day,
        });
      } else {
        updateSnackbar({
          type: 'error',
          open: true,
          message: '모니터링 기간을 확인해주세요.',
        });
        setContentMeta({
          ...contentMeta,
          month: null,
          day: null,
        });
      }
    } else {
      setContentMeta({
        ...contentMeta,
        month: null,
        day: null,
      });
    }
  }, [formData.startDate, formData.endDate]);

  useEffect(() => {
    if (formData.realReductionBase && formData.realReductionBusiness && formData.realReductionLeak) {
      if (
        Number.isInteger(Number(formData.realReductionBase)) &&
        Number.isInteger(Number(formData.realReductionBusiness)) &&
        Number.isInteger(Number(formData.realReductionLeak))
      ) {
        formChange(
          Number(formData.realReductionBase) -
            Number(formData.realReductionBusiness) -
            Number(formData.realReductionLeak),
          'realReduction',
        );
      } else {
        formChange('', 'realReduction');
      }
    } else {
      formChange('', 'realReduction');
    }
  }, [formData.realReductionBase, formData.realReductionBusiness, formData.realReductionLeak]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  const onChangeContentMeta = (newValue, key) => {
    setContentMeta({ ...contentMeta, [key]: newValue });
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  //server
  const requestSave = (type) => {
    const VALIDATION_LIST = [
      [],
      ['startDate', 'endDate', 'realReductionBase', 'realReductionBusiness', 'realReductionLeak'],
      [],
    ];
    console.log('requestSave step', step);

    let isValidation = Handler.formValidation(formData, VALIDATION_LIST[step]);
    const isValid = Handler.formValidationKey(formData, formMeta, VALIDATION_LIST[step]);

    if (!isValidation) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: `${ERROR['REQUIRED_INFO']}\n- ${ERROR['MISSING_CONTENT']}: ${isValid.inValidKeys
          .map((el) => ` ${t(el)}`)
          .toString()}`,
      });
      return;
    }

    if (step === 1) {
      let new_startDate = new Date(formData.startDate).getTime();
      let new_endDate = new Date(formData.endDate).getTime();
      if (new_endDate < new_startDate) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: `${t('PLEASE_VERIFY_MONITORING')}`,
        });
        return;
      }
    }

    if (step === 2) {
      for (let i in formMeta.evidence.label) {
        let target = formMeta.evidence.label[i];
        if (target.isForce) {
          if (!formData.evidence[i].file) {
            updateSnackbar({
              type: 'error',
              open: true,
              message: `${t('PLEASE_VERIFY_MANDATORY')}`,
            });
            return;
          }
        }
        // else {
        //   if (!formData.evidence[i].file && !formData.evidence[i].reason) {
        //     updateSnackbar({
        //       type: 'error',
        //       open: true,
        //       message: `${t('PLEASE_VERIFY_OPTIONAL')}`,
        //     });
        //     return;
        //   }
        // }
      }

      if (formData.additionalEvidence && formData.additionalEvidence.length > 0) {
        for (let i in formData.additionalEvidence) {
          let target = formData.additionalEvidence[i];
          if (!target.title || !target.file || !target.reason) {
            updateSnackbar({
              type: 'error',
              open: true,
              message: `${t('PLEASE_VERIFY_ADDITIONAL')}`,
            });
            return;
          }
        }
      }
    }

    let body = {
      startDate: Handler.dbDashDateToCommaDate(formData.startDate),
      endDate: Handler.dbDashDateToCommaDate(formData.endDate),
      realReductionBase: formData.realReductionBase,
      realReductionBusiness: formData.realReductionBusiness,
      realReductionLeak: formData.realReductionLeak,
      realReduction: formData.realReduction,
      realMonitorDesc: formData.realMonitorDesc,
      beforeImages: formData.beforeImages,
      afterImages: formData.afterImages,
      evidenceMapper: formData.evidence,
      additionalEvidence: formData.additionalEvidence,
    };

    if (step === 2 && type === 'next') {
      API.ReductionCert.PutWriting(slug, body)
        .then((res) => {
          if (originData.type === 'Improve') {
            setSelectedAction({
              seq: slug,
            });
            setSelectedNextAction('ImproveReductionCertComplete');
            setActionOpen(true);
          } else {
            API.ReductionCert.PostNextAction(slug, {
              nextAction: 'ReductionCertSubmit',
            })
              .then((res) => {
                history.push('/request_complete?type=reduction');
              })
              .catch((err) => {
                updateSnackbar({
                  type: 'error',
                  open: true,
                  message: err.data.message,
                });
              });
          }
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    } else {
      API.ReductionCert.PutWriting(slug, body)
        .then((res) => {
          if (type === 'next') {
            setStep(step + 1);
          } else {
            updateSnackbar({
              type: 'success',
              open: true,
              message: t('SAVED'),
            });
          }
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    }
  };

  const handleModalRequest = (body) => {
    API.ReductionCert.PostNextAction(slug, body)
      .then((res) => {
        closeActionModal();
        history.push('/reduction/list');
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  return (
    <article className='basic-page'>
      <Breadcrumbs content='reduction' title={HOME_ICON_PROCESS['REDUCTION']}></Breadcrumbs>
      <article className='content__wrapper'>
        <Stepper
          meta={{ type: 'round-writing', style: { marginTop: 40 } }}
          value={step}
          label={['CHECK_BUSINESS_SUMMARY', 'REDUCTION_MONITORING_SYSTEM', 'OTHER_DOCUMENTS']}
        ></Stepper>
        <ContentReduction
          step={step}
          formData={formData}
          formMeta={formMeta}
          formChange={formChange}
          setFormData={setFormData}
          setFormMeta={setFormMeta}
          contentMeta={contentMeta}
          contentMetaChange={onChangeContentMeta}
          readOnly={forceReadOnly}
          type={originData ? originData.reductionCert.type : ''}
          setStep={setStep}
        ></ContentReduction>
        {step !== 0 && (
          <section className='button__container' style={{}}>
            <div className='prev__box'>
              <Button
                disabled={step === 0}
                type={'secondary'}
                style={{ width: '96px', marginRight: 12 }}
                onClick={() => {
                  setStep(step - 1);
                }}
                label={t('PREV')}
              ></Button>
            </div>
            <Button
              type={'secondary'}
              style={{ width: '240px', marginRight: 12 }}
              onClick={() => {
                requestSave();
              }}
              label={t('TEMP_SAVE')}
            />
            {originData && originData.type === 'Improve' ? (
              <Button
                style={{ width: '240px' }}
                onClick={() => {
                  requestSave('next');
                }}
                label={step === 2 ? t('COMPLEMENT_APPLY') : t('TEMP_SAVE_NEXT')}
              />
            ) : (
              <Button
                style={{ width: '240px' }}
                onClick={() => {
                  requestSave('next');
                }}
                label={step === 2 ? t('REDUCTION_SUBMIT') : t('TEMP_SAVE_NEXT')}
              />
            )}
          </section>
        )}
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalReductionAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalReductionAction>
        </Box>
      </Modal>
    </article>
  );
};

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';

const PATH = 'consultations';

const GetConsultationList = (params) => {
  const url = BASE_URL + `${PATH}`;
  return AxiosInstance.get(url);
};

const GetConsultationHistoryList = () => {
  const url = BASE_URL + `${PATH}/histories`;
  return AxiosInstance.get(url);
};

const GetConsultationDetail = (seq) => {
  const url = BASE_URL + `${PATH}/${seq}`;
  return AxiosInstance.get(url);
};

const PostConsultationFeedback = (seq, body) => {
  const url = BASE_URL + `${PATH}/${seq}/feedback`;
  return AxiosInstance.post(url, body);
};

const Consultation = {
  GetList: GetConsultationList,
  GetHistoryList: GetConsultationHistoryList,
  GetDetail: GetConsultationDetail,
  PostFeedback: PostConsultationFeedback,
};

export default Consultation;

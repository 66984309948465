import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLabel } from '../../hooks';
import { termModalStyle } from '../../styles/style';
import { PrivacyTerm } from '../content';

export const ModalPrivacyTerm = (props) => {
  const { onClose, showHeader = true } = props;
  const { t } = useTranslation();
  const { TransPrivacy } = useLabel();

  return (
    <article className='flex flex-col overflow-hidden' style={termModalStyle}>
      {showHeader && (
        <header className='top-0 flex justify-between w-full bg-white py-4 px-6 box-border border-b border-solid border-gray-200'>
          <h1 className='text-2xl'>{TransPrivacy('PRIVACY_TITLE')}</h1>
          <div
            className='ico close-small'
            onClick={() => {
              onClose();
            }}
          ></div>
        </header>
      )}
      <PrivacyTerm />
      <section className='absolute w-full bottom-0 flex justify-center border-t border-solid border-gray-200 bg-white py-6'>
        <Button variant='contained' style={{ width: '180px', height: '50px', marginLeft: 0 }} onClick={onClose}>
          {t('CLOSE')}
        </Button>
      </section>
    </article>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { Button } from '../../components';
import { useLabel } from '../../hooks';

export const TableMethodology = (props) => {
  const { data, callback } = props;
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const METHODOLOGY = t('METHODOLOGY_STATUS', { returnObjects: true });

  return (
    <article className='table-methodology'>
      <ul>
        {data && data.length === 0 && (
          <li className='empty w-full flex justify-center'>
            <span>{ERROR['NO_DATA_FOUND']}</span>
          </li>
        )}
        {data &&
          data.map((item, index) => {
            const {
              area,
              attachedFile,
              createdAt,
              expiredYear,
              groupSeq,
              id,
              introOne,
              introTwo,
              isValid,
              mainImage,
              seq,
              startDate,
              subArea,
              title,
              version,
              versionNumber,
            } = item;
            return (
              <li key={`table-row-method-${index}`}>
                <section className='img__box'>
                  <img src={mainImage}></img>
                </section>
                <section className='caption__box'>
                  <div className='title'>
                    <h2>{title}</h2>
                    <div className='version'>
                      {versionNumber === 0 && <span className='new'>{t('NEW')}</span>} <span>{version}</span>
                    </div>
                  </div>
                  <div className='intro'>
                    <p>{introOne}</p>
                    <p>{introTwo}</p>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>{t('BIZ_AREA')}</span>
                    </header>
                    <span className='value'>
                      {t(area.toUpperCase())} {'>'} {t(subArea.toUpperCase())}
                    </span>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>{METHODOLOGY['UNIQUE_NUMBER']}</span>
                    </header>
                    <span className='value'>{id}</span>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>{t('VALID_START_DATE')}</span>
                    </header>
                    <span className='value'>{Handler.dbDateToYYYYMMDDComma(startDate)}</span>
                  </div>
                  <div className='attribute'>
                    <header>
                      <span>{t('PROJECT_VALID_PERIOD')}</span>
                    </header>
                    <span className='value'>
                      {expiredYear}
                      {t('YEAR')}
                    </span>
                  </div>
                </section>
                <section className='button__box'>
                  {versionNumber > 0 && (
                    <Button
                      style={{ width: '144px', height: '38px', marginRight: 12 }}
                      type='secondary-square'
                      label={METHODOLOGY['REVISION_HISTORY']}
                      onClick={(e) => {
                        e.stopPropagation();
                        callback('selected_slug', seq);
                      }}
                    ></Button>
                  )}
                  {attachedFile && (
                    <Button
                      onClick={() => {
                        window.open(attachedFile, '_blank');
                      }}
                      style={{ width: '144px', height: '38px' }}
                      type='square'
                      label={t('DOWNLOAD')}
                    ></Button>
                  )}
                </section>
              </li>
            );
          })}
      </ul>
    </article>
  );
};

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Button, TextInput } from '../../components';
import { SnackbarContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';

export const UserResetPwPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { ERROR } = useLabel();
  const [formData, formMeta, formChange] = useForm(
    {
      password: '',
    },
    {
      password: {
        key: 'password',
        type: 'password',
        style: {},
        className: 'round',
        required: true,
        placeholder: t('PASSWORD_PLACEHOLDER'),
        regexp: Handler.REGEXP.PASSWORD,
      },
    },
  );
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const requsetRestPw = () => {
    if (formData.password !== passwordConfirm) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '비밀번호가 일치하지 않습니다.',
      });
      return;
    }

    let targetRegexp = new RegExp(Handler.REGEXP.PASSWORD, 'g');
    const isValidtion = targetRegexp.test(formData.password);
    console.log('isValidtion', isValidtion);

    if (!isValidtion) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '비밀번호를 확인해주세요.',
      });
      return;
    }

    let body = Handler.formToBody(formData, formMeta);
    console.log('requsetLogin', body);

    if (body) {
      console.log('body', body);

      API.User.ModifyPassword(body).then(
        (res) => {
          let result = res.data;
          console.log('User ModifyPassword result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '비밀번호가 변경되었습니다.',
          });
          history.push('/');
        },
        (err) => {
          console.log('err.data', err.data);
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('CHANGE_PASSWORD')}</h1>
          </header>
        </section>
        <section className='login-form__container'>
          <div className='guide__text'>
            <span>{ERROR['INPUT_NEW_PASSWORD']}</span>
          </div>
          <TextInput value={formData.password} onChange={formChange} meta={formMeta.password}></TextInput>
          <TextInput
            value={passwordConfirm}
            onChange={(new_value) => {
              setPasswordConfirm(new_value);
            }}
            meta={{
              key: 'passwordConfirm',
              type: 'password',
              style: { marginTop: 12 },
              className: 'round',
              required: true,
              placeholder: 'PASSWORD_CONFIRM',
              equal: formData.password,
            }}
          ></TextInput>

          <Button
            style={{ width: '100%', marginTop: 28 }}
            onClick={() => {
              requsetRestPw();
            }}
            label='CHANGE_PASSWORD'
          ></Button>
          <Button
            style={{ width: '100%', marginTop: 14 }}
            type='secondary'
            onClick={() => {
              history.push('/');
            }}
            label='GOTO_MAIN'
          ></Button>
        </section>
      </article>
    </article>
  );
};

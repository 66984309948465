import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { GoogleMapWrapper } from '../../components';

export const AddressLocation = (props) => {
  const { value, onChange, meta, hideMap } = props;
  const [addressValue, setAddressValue] = useState(value)
  const { t } = useTranslation()

  const handleGoogleAddress = (data) => {
    let new_value = _.clone(addressValue);
    new_value.addr1 = data
    onChange(new_value, meta.key)
  }

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>
      } else {
        return <span className='text-red-600'>*</span>
      }
    }
  }

  const mapAddress = useMemo(() => {
    if (addressValue && addressValue.addr1 && addressValue.addr1.formatted_address) {
      return addressValue.addr1.formatted_address
    } else if (addressValue && addressValue.hasOwnProperty('daum') && addressValue?.daum) {
      return addressValue.daum.address
    }
    return ''
  }, [addressValue])

  useEffect(() => {
    const addressObject = Handler.jsonParser(value)
    setAddressValue(addressObject)
  }, [value])

  return (
    <article
      className={Handler.BuildArticleClassname('form-address', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{t(meta.label)}</span>
          {renderRequired()}
        </header>
      )}
      <>
        {!hideMap && (
          <section className='google-map__box'>
            {
              <GoogleMapWrapper
                address={addressValue && addressValue.addr1 && addressValue.addr1.formatted_address}
                meta={meta}
                onChange={(data) => handleGoogleAddress(data)}
              ></GoogleMapWrapper>
            }
          </section>
        )}
        <section className='address-value__box'>
          <div className='address'>
            <input readOnly placeholder={t('ADDRESS')} value={mapAddress}></input>
          </div>
          <div className='detail'>
            <input
              disabled={meta.disabled}
              value={(addressValue && addressValue.addr2) || ''}
              placeholder={t('ADDRESS_DETAIL')}
              onChange={(e) => {
                let new_value = _.clone(addressValue)
                new_value.addr2 = e.target.value
                onChange(new_value, meta.key)
              }}
            ></input>
          </div>
        </section>
      </>
    </article>
  )
};

import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';

export const RequestCompletePage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const searchObject = queryString.parse(history.location.search);
  const requsetSubmit = () => {
    switch (searchObject.type) {
      case 'project': {
        history.push('/project/list');
        return;
      }
      case 'reduction': {
        history.push('/reduction/list');
        return;
      }
      case 'credit': {
        history.push('/credit/list');
        return;
      }
      case 'mypage': {
        history.push('/mypage/inquiry_list');
        return;
      }
      default: {
        return null;
      }
    }
  };

  const renderCreditTitle = () => {
    switch (searchObject.mode) {
      case 'issue': {
        return `${t('APPLY_VRC_ISSUE')}`;
      }
      case 'move': {
        return `${t('TRANSFER_VRC')}`;
      }
      case 'offset': {
        return `${t('REQ_WITHDRAWAL')}`;
      }
      case 'retire': {
        return `${t('REQ_DISPOSAL')}`;
      }
      default: {
        return null;
      }
    }
  };

  const contactSection = () => {
    return (
      <section className='complete-phone__container'>
        <div className='img__box'>
          <div className='ico phone'></div>
        </div>
        <div className='text__box'>
          <span>{t('CONSULTATION_INQUIRY')}</span>
          <h2 style={{ marginBottom: 0 }}>02-6274-3600</h2>
          {/* <h2>031-548-2307</h2> */}
          <span className='whitespace-pre-wrap'>{t('CONTACT_PHONE_TIME')}</span>
        </div>
      </section>
    );
  };

  switch (searchObject.type) {
    case 'project': {
      return (
        <article className='basic-page'>
          <article className='content__wrapper'>
            <section className='complete-guide__container'>
              <h1>{t('COMPLETE_MSG')}</h1>
              <span>{t('PROJECT_RESULT')}</span>
              <div className='img__box'>
                <div className='illust'></div>
              </div>
            </section>
            {contactSection()}
            <section className='button__container'>
              <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('VIEW_STATUS')}></Button>
            </section>
          </article>
        </article>
      );
    }
    case 'reduction': {
      return (
        <article className='basic-page'>
          <article className='content__wrapper'>
            <section className='complete-guide__container'>
              <h1>{t('REDUCTION_SUBMIT_COMPLETE')}</h1>
              <span>{t('REDUCTION_RESULT')}</span>
              <div className='img__box'>
                <div className='illust'></div>
              </div>
            </section>
            {contactSection()}
            <section className='button__container'>
              <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('REDUCTION_VIEW_STATUS')}></Button>
            </section>
          </article>
        </article>
      );
    }
    case 'credit': {
      return (
        <article className='basic-page'>
          <article className='content__wrapper'>
            <section className='complete-guide__container'>
              <h1>
                {renderCreditTitle()}
                {t('HAS_BEEN_COMPLETED')}
              </h1>
              <span>{t('CREDIT_RESULT')}</span>
              <div className='img__box'>
                <div className='illust'></div>
              </div>
            </section>
            {contactSection()}
            <section className='button__container'>
              <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('CREDIT_VIEW_STATUS')}></Button>
            </section>
          </article>
        </article>
      );
    }
    case 'mypage': {
      return (
        <article className='basic-page'>
          <article className='content__wrapper'>
            <section className='complete-guide__container'>
              <h1>{t('CONSULTATION_SUBMIT_COMPLETE')}</h1>
              <span>{t('CONSULTATION_RESULT')}</span>
              <div className='img__box'>
                <div className='illust'></div>
              </div>
            </section>
            {contactSection()}
            <section className='button__container'>
              <Button style={{ width: '240px' }} onClick={requsetSubmit} label={t('ONETOONE_HISTORY')}></Button>
            </section>
          </article>
        </article>
      );
    }
    default: {
      return null;
    }
  }
};

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'methodology';

const GetList = (params) => {
  const url = BASE_URL + PATH + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetHistoryList = (slug, params) => {
  const url = BASE_URL + PATH + '/' + slug + '/history' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const Methodology = {
  GetList: GetList,
  GetHistoryList: GetHistoryList,
};

export default Methodology;

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'reduction-cert/';

const GetProject = () => {
  const url = BASE_URL + PATH + 'project';
  return AxiosInstance.get(url);
};

const PostProject = (seq) => {
  const url = BASE_URL + PATH + 'project/' + seq;
  return AxiosInstance.post(url);
};

const GetWriting = (seq) => {
  const url = BASE_URL + PATH + seq + '/writing';
  return AxiosInstance.get(url);
};

const GetValidation = (seq) => {
  const url = BASE_URL + PATH + seq + '/validation';
  return AxiosInstance.get(url);
};

const PutWriting = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/writing';
  return AxiosInstance.put(url, body);
};

const GetList = (params) => {
  const url = BASE_URL + 'reduction-cert' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostNextAction = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/next-action';
  return AxiosInstance.post(url, body);
};

const GetAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetActionDoc = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/doc' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + seq;
  return AxiosInstance.get(url);
};

const ReductionCert = {
  GetProject: GetProject,
  PostProject: PostProject,
  GetWriting: GetWriting,
  GetValidation: GetValidation,
  PutWriting: PutWriting,
  GetList: GetList,
  PostNextAction: PostNextAction,
  GetDetail: GetDetail,
  GetAction: GetAction,
  GetActionDoc: GetActionDoc,
};

export default ReductionCert;

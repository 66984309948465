import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import {
  Button,
  DropdownInput,
  ModalConfirm,
  ModalProjectAction,
  ModalProjectView,
  PageHeader,
  SelectBox,
  TablePaging,
  TableProject,
} from '../../components';
import { SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';
import { modalStyle } from '../../styles/style';

export const ProjectListPage = (props) => {
  const { updateSnackbar } = useContext(SnackbarContext);
  const history = useHistory();
  const location = useLocation();
  const { ROUTE_PATH, DOC_PROCESS: DOC } = useLabel();
  const { t } = useTranslation();
  const [type, setType] = useState('SELECT_ALL');
  const [status, setStatus] = useState('SELECT_ALL');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState({
    title: '',
    content: '',
    okLabel: '',
    cancelLabel: '',
    requestAction: null,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedActionDate, setSelectedActionDate] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const tableCallback = (next_action, item) => {
    switch (next_action) {
      case 'History': {
        console.log('History move');
        history.push('/project/detail/' + item.seq);
        break;
      }
      case 'View': {
        setSelectedItem(item);
        setSelectedAction(item.projectDocAction);
        setSelectedActionDate(item.createdAt);
        setViewOpen(true);
        break;
      }
      case 'ModifyWriting': {
        history.push('/project/writing/' + item.seq);
        break;
      }
      case 'RemoveWriting': {
        setSelectedItem(item);
        setConfirmModalContent({
          title: '프로젝트 삭제',
          content: '프로젝트를 삭제하시겠습니까?',
          okLabel: t('DELETE'),
          cancelLabel: t('CANCEL'),
          requestAction: () => deleteProject(item.seq),
        });
        setConfirmOpen(true);
        break;
      }
      case 'CancelWaiting': {
        setSelectedItem(item);
        setSelectedAction(item.lastProjectDoc.projectDocAction);
        setSelectedActionDate(item.createdAt);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
      case 'ModifyProject': {
        API.Project.PostModify(item.seq)
          .then((res) => {
            requestNewList();
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'ImproveProjectStart': {
        let target_seq = null;
        if (item) {
          target_seq = item.seq;
        }
        if (selectedItem) {
          target_seq = selectedItem.seq;
        }

        API.Project.PostNextAction(target_seq, {
          nextAction: 'ImproveProjectStart',
        })
          .then((res) => {
            closeActionModal();
            closeViewModal();
            requestNewList();
            setSelectedAction({
              status: 'RequestMoveWriting',
              seq: target_seq,
            });
            setViewOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      default: {
        setSelectedItem(item);
        setSelectedAction(item.lastProjectDoc.projectDocAction);
        setSelectedActionDate(item.createdAt);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
    }
  };

  const deleteProject = (seq) => {
    API.Project.PostNextAction(seq, {
      nextAction: 'RemoveWriting',
    })
      .then((res) => {
        closeConfirmModal();
        requestNewList();
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  const requestNextAction = (seq, body) => {
    API.Project.PostNextAction(seq, body)
      .then((res) => {
        closeConfirmModal();
        requestNewList();
        updateSnackbar({
          type: 'success',
          open: true,
          message: '신청이 완료되었습니다.',
        });
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const handleModalRequest = (body) => {
    requestNextAction(selectedItem.seq, body);
    closeActionModal();
    requestNewList(0);
  };

  const initItemAndAction = () => {
    setSelectedItem(null);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeConfirmModal = () => {
    setConfirmOpen(false);
    initItemAndAction();
  };

  const closeActionModal = () => {
    setActionOpen(false);
    initItemAndAction();
  };

  const closeViewModal = () => {
    setViewOpen(false);
    initItemAndAction();
  };

  //server
  const requestNewList = (init_page) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
        searchType: searchType,
        searchValue: search,
      };
      if (type !== 'SELECT_ALL') {
        params.type = type;
      }
      if (status !== 'SELECT_ALL') {
        params.status = status;
      }
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    replaceSearchQuery(params);
    API.Project.GetList(params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  const replaceSearchQuery = (params) => {
    history.replace({
      search: Utils.ObjectToParams({
        ...params,
        page: page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      }),
    });
  };

  //hook
  useEffect(() => {
    if (lastSearchBody) requestNewList(page);
  }, [page]);

  useEffect(() => {
    if (location.search) {
      const params = {
        ...Utils.ParamsToObject(history.location.search),
      };
      setLastSearchBody({
        ...params,
      });
      setPage(Number(params.page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    requestSearch();
  }, []);

  //event
  const requestSearch = () => {
    requestNewList(0);
  };

  return (
    <article className='basic-page'>
      <PageHeader type='project' title={t(ROUTE_PATH['PROJECT_STATUS'])}></PageHeader>
      <article className='content__wrapper'>
        <div className='search__container between'>
          <div className='select__box'>
            <SelectBox
              value={type}
              onChange={(value) => {
                setType(value);
              }}
              meta={{
                key: 'type',
                style: {
                  marginRight: 12,
                  width: 180,
                },
                menus: [
                  {
                    label: t('STATUS') + ' ' + t('ALL'),
                    value: 'SELECT_ALL',
                  },
                  {
                    label: t('NEW'),
                    value: 'New',
                  },
                  {
                    label: t('MODIFY'),
                    value: 'Modify',
                  },
                ],
              }}
            ></SelectBox>
            <SelectBox
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
              meta={{
                key: 'status',
                menus: [
                  {
                    label: t('STATUS') + ' ' + t('ALL'),
                    value: 'SELECT_ALL',
                  },
                  {
                    label: t('TEMP_SAVE'),
                    value: 'Writing',
                  },
                  {
                    label: DOC['WAITING'],
                    value: 'Waiting',
                  },
                  {
                    label: DOC['REVIEW'],
                    value: 'Audit',
                  },
                  {
                    label: DOC['REQ_FOR_REVISION'],
                    value: 'ImprovementRequest',
                  },
                  {
                    label: `${DOC['FINAL_APPROVED']} / ${DOC['COMMENCEMENT_APPROVED']}`,
                    value: 'Approved',
                  },
                  {
                    label: DOC['REJECTED'],
                    value: 'Rejected',
                  },
                  {
                    label: t('CANCEL'),
                    value: 'Canceled',
                  },
                ],
              }}
            ></SelectBox>
          </div>
          <div className='input__box'>
            <DropdownInput
              value={search}
              menuValue={searchType}
              onChange={(value, key) => {
                setSearch(value);
              }}
              onMenuChange={(value, key) => {
                setSearchType(value);
              }}
              onEnter={() => {
                requestSearch();
              }}
              meta={{
                style: { width: '446px' },
                placeholder: t('KEYWORD'),
                menus: [
                  {
                    value: 'Title',
                    label: t('BIZ_TITLE'),
                  },
                  {
                    value: 'Id',
                    label: t('BIZ_ID'),
                  },
                ],
              }}
            ></DropdownInput>
            <Button
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestSearch();
              }}
              label={t('SEARCH')}
            ></Button>
          </div>
        </div>
        <TableProject data={list} rowClick={tableRowClick} callback={tableCallback}></TableProject>
        <div style={{ height: 40 }}></div>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalProjectAction
            action={selectedAction}
            nextAction={selectedNextAction}
            actionDate={selectedActionDate}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          />
        </Box>
      </Modal>
      <Modal open={confirmOpen} onClose={closeConfirmModal}>
        <Box sx={modalStyle}>
          <ModalConfirm
            title={confirmModalContent.title}
            content={confirmModalContent.content}
            okLabel={confirmModalContent.okLabel}
            onClose={closeConfirmModal}
            requestAction={confirmModalContent.requestAction}
          />
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={modalStyle}>
          <ModalProjectView
            action={selectedAction}
            actionDate={selectedActionDate}
            onClose={closeViewModal}
            requestAction={tableCallback}
          />
        </Box>
      </Modal>
    </article>
  );
};

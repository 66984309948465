import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import formEn from '../constants/i18n/form/en.json';
import formKo from '../constants/i18n/form/ko.json';
import msgEn from '../constants/i18n/message/en.json';
import msgKo from '../constants/i18n/message/ko.json';
import standardEn from '../constants/i18n/standard/en.json';
import standardKo from '../constants/i18n/standard/ko.json';
import privacyTermEn from '../constants/i18n/terms/privacy_en.json';
import privacyTermKo from '../constants/i18n/terms/privacy_ko.json';
import usageTermEn from '../constants/i18n/terms/usage_en.json';
import usageTermKo from '../constants/i18n/terms/usage_ko.json';
import tranEn from '../constants/i18n/translation_en.json';
import tranKo from '../constants/i18n/translation_ko.json';

const resources = {
  en: {
    translation: tranEn,
    usageTerm: usageTermEn,
    privacyTerm: privacyTermEn,
    standard: standardEn,
    form: formEn,
    msg: msgEn,
  },
  ko: {
    translation: tranKo,
    usageTerm: usageTermKo,
    privacyTerm: privacyTermKo,
    standard: standardKo,
    form: formKo,
    msg: msgKo,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'ko',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { FileUploader, TextInput } from '../../components';

export const SelectMonitoring = (props) => {
  const { value, onChange, meta } = props;
  const { t } = useTranslation();

  console.log('SelectMonitoring value', value);
  console.log('SelectMonitoring meta', meta);

  const onCahngeItem = (new_item, index) => {
    let new_evidence = _.clone(value);
    new_evidence[index] = new_item;
    onChange(new_evidence, 'evidence');
  };

  const renderHeader = (item, index) => {
    return (
      <header>
        <span>{meta.label[index].title}</span>
        {meta.label[index].isForce ? <span className='text-red-600'>*</span> : null}
      </header>
    );
  };

  const renderFileBox = (item, index) => {
    return (
      <div className='file__box'>
        <FileUploader
          value={item.file}
          onChange={(value, key) => {
            console.log('value', value);
            console.log('item', item);
            let new_item = _.clone(item);
            new_item.file = value;
            onCahngeItem(new_item, index);
          }}
          meta={{
            type: 'file',
            key: 'monitoring-item-' + index,
            path: 'dummy',
            style: { marginBottom: 12 },
            required: false,
            disabled: meta.disabled,
            readOnly: meta.readOnly,
          }}
        ></FileUploader>
      </div>
    );
  };

  const renderTextInputBox = (item, index, meta) => {
    console.log('renderTextInputBox meta', meta);
    if (meta.isForce) {
    } else {
      return (
        <div className='text-input__box'>
          <TextInput
            value={item.reason}
            onChange={(value, key) => {
              console.log('value', value);
              console.log('key', key);
              let new_item = _.clone(item);
              new_item.reason = value;
              onCahngeItem(new_item, index);
            }}
            meta={{
              key: 'text',
              required: false,
              placeholder: t('REASON_FOR_NOT_SUPPORTING_DOC'),
              disabled: meta.disabled,
              readOnly: meta.readOnly,
            }}
          ></TextInput>
        </div>
      );
    }
  };

  if (meta && meta.label && meta.label.length === 0) {
    return null;
  }
  if (value && value.length === 0) {
    return null;
  }
  return (
    <article
      className={Handler.BuildArticleClassname('form-select-monitoring', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {value &&
        value.length > 0 &&
        value.map((item, index) => {
          return (
            <div className='monitoring-item'>
              {renderHeader(item, index)}
              {renderFileBox(item, index)}
              {renderTextInputBox(item, index, meta.label[index])}
            </div>
          );
        })}
    </article>
  );
};

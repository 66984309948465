import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { Button, FileUploader, RadioBox, TextArea, TextInput } from '../../components';

export const ModalCreditAction = (props) => {
  const { mode, action, nextAction, requestAction, onClose } = props;
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [commentFile, setCommentFile] = useState('');
  const [stopReason, setStopReason] = useState(null);

  const renderTitleByMode = (mode) => {
    return `VRC ${t(mode.toUpperCase())}`;
  };

  const renderAmountByMode = (mode) => {
    if (mode === 'issue') return `${t('ISSUE')} ${t('QUANTITY')}`;
    if (mode === 'move') return `${t('MOVE')} ${t('QUANTITY')}`;
    if (mode === 'offset') return `${t('OFFSET')} ${t('QUANTITY')}`;
    return '';
  };

  const renderNextActionLabel = (next_action) => {
    switch (next_action) {
      case 'ImproveCommitteeStart':
      case 'ImproveZCCreditStart': {
        return '보완 요청 수락';
      }
      case 'ImproveZCCreditComplete': {
        return '보완 완료';
      }
      case 'ImproveCommitteeGiveUp':
      case 'ImproveZCCreditGiveUp': {
        return '보완 요청 포기';
      }
      case 'ImproveLaunchGiveUp': {
        return '보완 요청 포기';
      }
      default: {
        return 'none';
      }
    }
  };

  switch (nextAction) {
    case 'ImproveCommitteeComplete':
    case 'ImproveZCCreditComplete': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>보완 완료</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container' style={{ marginBottom: 0 }}>
            <span>보완을 완료하셨나요?</span>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 10 }}>
            <span style={{ color: '#777' }}>심사를 신청하시겠습니까?</span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: nextAction,
                });
              }}
              label='보완 완료 신청'
            ></Button>
          </section>
        </article>
      );
    }
    case 'CancelWaiting': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>심사 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container'>
            <span style={{ lineHeight: 1.4 }}>
              아래의 {renderTitleByMode(mode)} 신청에 대해 심사 대기중입니다. <br />
              신청을 중단하시겠습니까?
            </span>
          </section>
          <section className='dialogue-em__container' style={{ flexDirection: 'column' }}>
            <h3 style={{ marginBottom: 5 }}>사업명 : {action.projectTitle}</h3>
            <h3>
              {renderAmountByMode(mode)} :{' '}
              <em style={{ color: '#5d3be5' }}>{Handler.numberWithCommas(action.amount)}</em>
            </h3>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 30 }}>
            <span style={{ color: '#777' }}>* 취소 후에는 재신청이 불가능하니 신중히 생각하시기 바랍니다.</span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label='닫기'
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: nextAction,
                  stopReason: stopReason,
                  comment: comment,
                  commentFile: commentFile,
                });
              }}
              label='심사 중단 신청'
            ></Button>
          </section>
        </article>
      );
    }
    case 'CanceledZCCredit': {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>심사 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='guide-text__container'>
            <b>진행중인 {renderTitleByMode(mode)}을 중단하시겠습니까?</b>
            <span>
              * 담당자가 {renderTitleByMode(mode)} 심사 중단 가능 여부를 확인한 후, 중단 처리가 진행됩니다. 빠른 시간
              안에 연락 드리도록 하겠습니다.
            </span>
          </section>
          <section className='launch__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'projectTitle',
                  label: t('BIZ_TITLE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'projectId',
                  label: t('BIZ_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  key: 'amount',
                  label: renderAmountByMode(mode),
                  disabled: true,
                  className: 'action main-color',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
              ></TextInput>
              <RadioBox
                value={stopReason}
                onChange={(value, key) => {
                  setStopReason(value);
                }}
                meta={{
                  key: 'radio',
                  label: '구분',
                  required: true,
                  menus: [
                    {
                      text: '사업 진행 불가능',
                      value: 'CanNotProceed',
                    },
                    {
                      text: '회원 탈퇴',
                      value: 'UserSignout',
                    },
                    {
                      text: '기타',
                      value: 'Etc',
                    },
                  ],
                  style: {
                    gridColumn: '1/3',
                  },
                  className: 'action',
                }}
              ></RadioBox>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: '사유',
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: t('FILE'),
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={'CLOSE'}
              ></Button>
              <Button
                disabled={!stopReason}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestAction({
                    nextAction: nextAction,
                    stopReason: stopReason,
                    comment: comment,
                    commentFile: commentFile,
                  });
                }}
                label='심사 중단 신청'
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    default: {
      return (
        <article className='credit-action-modal'>
          <header>
            <h4>{renderNextActionLabel(nextAction)}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container' style={{ paddingBottom: 0 }}>
            <header>
              <h2>사업정보</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'projectTitle',
                  label: t('BIZ_TITLE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'projectId',
                  label: t('BIZ_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.numberWithCommas(action.amount)}
                meta={{
                  key: 'amount',
                  label: renderAmountByMode(mode),
                  disabled: true,
                  className: 'action main-color',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'adminComment',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'adminCommentFile',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'adminCommentDate',
                  label: '처리일',
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Reply</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: '사유',
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: t('FILE'),
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                disabled={!comment}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestAction({ comment: comment, commentFile: commentFile, nextAction: nextAction });
                }}
                label={renderNextActionLabel(nextAction)}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
  }
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { Button, DatePicker, FileUploader, RadioBox, TextArea, TextInput } from '../../components';
import { useLabel } from '../../hooks';

export const ModalProjectAction = (props) => {
  const { action, nextAction, actionDate, requestAction, onClose } = props;
  const { t } = useTranslation();
  const { DOC_PROCESS: DOC } = useLabel();
  const [comment, setComment] = useState('');
  const [commentFile, setCommentFile] = useState('');
  const [launchDate, setLaunchDate] = useState(null);
  const [stopReason, setStopReason] = useState(null);

  console.log('ModalProjectAction action', action);
  console.log('ModalProjectAction nextAction', nextAction);

  const renderNextActionTitle = (next_action) => {
    switch (next_action) {
      case 'ImproveProjectStart': {
        return 'REQ_FOR_REVISION_ACCEPT';
      }
      case 'ImproveProjectComplete': {
        return 'REQ_FOR_REVISION_COMPLETE';
      }
      case 'ImproveProjectGiveUp': {
        return 'REQ_FOR_REVISION_DROP';
      }
      case 'ImproveLaunchGiveUp': {
        return 'REQ_FOR_REVISION_DROP';
      }
      default: {
        return 'none';
      }
    }
  };

  const renderNextActionButton = (next_action) => {
    switch (next_action) {
      case 'ImproveProjectStart': {
        return 'REQ_FOR_REVISION_ACCEPT';
      }
      case 'ImproveProjectComplete': {
        return 'REQ_FOR_REVISION_COMPLETE_APPLY';
      }
      case 'ImproveProjectGiveUp': {
        return 'REQ_FOR_REVISION_DROP';
      }
      case 'ImproveLaunchGiveUp': {
        return 'REQ_FOR_REVISION_DROP';
      }
      default: {
        return 'none';
      }
    }
  };

  switch (nextAction) {
    case 'RequestProjectConfirm': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>사업 신청 확인</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container' style={{ marginBottom: 0, flexDirection: 'column' }}>
            <span>작성한 내용으로 사업을 신청하시겠습니까?</span>
            <span style={{ marginTop: 8 }}>[제출확정]을 선택하시면 운영자의 심사가 진행됩니다.</span>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 24, flexDirection: 'column' }}>
            <span style={{ color: '#777' }}>* 운영자의 심사가 진행되면 수정은 불가능합니다.</span>
            <span style={{ color: '#777', paddingLeft: 10, marginTop: 8 }}>
              입력한 내용을 꼼꼼히 확인하신 후 '제출확정'하시기 바랍니다.
            </span>
            <span style={{ color: '#777', marginTop: 8 }}>* 수정이 필요하신 경우 [이전] 을 선택하시기 바랍니다. </span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={t('CANCEL')}
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: 'RequestProjectComplete',
                });
              }}
              label='제출 확정'
            ></Button>
          </section>
        </article>
      );
    }
    case 'ImproveProjectComplete': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t('SUPPLEMENTAION_COMPLETE')}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container' style={{ marginBottom: 0 }}>
            <span>보완을 완료하셨나요?</span>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 10 }}>
            <span style={{ color: '#777' }}>심사를 신청하시겠습니까?</span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: nextAction,
                });
              }}
              label={t('REQ_FOR_REVISION_COMPLETE_APPLY')}
            ></Button>
          </section>
        </article>
      );
    }
    case 'ImproveLaunchComplete': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>착수 보완 완료</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container' style={{ paddingBottom: 0 }}>
            <header>
              <h2>{t('PROJECT_INFORMATION')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: t('COMMENT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Reply</h2>
              <span>
                요청 사항에 따른 보완 후, 보완 완료를 신청해주시기 바랍니다. 보완 완료를 신청 하시면 재심사가
                진행됩니다.
              </span>
            </header>
            <ul className='form-list'>
              <DatePicker
                value={launchDate}
                meta={{
                  required: true,
                  key: 'launchDate',
                  label: t('PROJECT_START_DATE'),
                  className: 'action',
                  placeholder: 'test',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(e) => {
                  setLaunchDate(e);
                }}
              ></DatePicker>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: t('COMMENTS'),
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: t('FILE'),
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                disabled={!launchDate}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  if (launchDate) {
                    requestAction({
                      nextAction: nextAction,
                      launchDate: launchDate.replaceAll('-', '.'),
                      comment: comment,
                      commentFile: commentFile,
                    });
                  } else {
                    alert('사업착수일시는 필수값입니다.');
                  }
                }}
                label={t('REQ_FOR_REVISION_COMPLETE_APPLY')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'CancelWaiting': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t('REVIEW_SUSPENDED')}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='dialogue-text__container'>
            <span>아래의 사업이 심사 대기중입니다. 심사를 취소하시겠습니까?</span>
          </section>
          <section className='dialogue-em__container'>
            <h3>사업명 : {action.projectTitle}</h3>
          </section>
          <section className='dialogue-text__container' style={{ marginTop: 30 }}>
            <span style={{ color: '#777' }}>* 취소 후에는 재신청이 불가능하니 신중히 생각하시기 바랍니다.</span>
          </section>
          <section className='button__container' style={{ marginBottom: 40 }}>
            <Button
              type='info'
              style={{ width: '180px', height: '50px', marginLeft: 0 }}
              onClick={onClose}
              label={t('CLOSE')}
            ></Button>
            <Button
              style={{ width: '180px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestAction({
                  nextAction: nextAction,
                  stopReason: stopReason,
                  comment: comment,
                  commentFile: commentFile,
                });
              }}
              label={t('REQ_FOR_STOP')}
            ></Button>
          </section>
        </article>
      );
    }
    case 'CanceledProject': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>사업 중단</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='guide-text__container'>
            <b>진행중인 사업을 중단하시겠습니까?</b>
            <span>
              * 담당자가 사업 중단 가능 여부를 확인한 후, 중단 처리가 진행됩니다. 빠른 시간 안에 연락 드리도록
              하겠습니다.
            </span>
          </section>
          <section className='launch__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <RadioBox
                value={stopReason}
                onChange={(value, key) => {
                  console.log('value', value);
                  setStopReason(value);
                }}
                meta={{
                  key: 'radio',
                  label: '구분',
                  required: true,
                  menus: [
                    {
                      label: DOC['CANNOT_PROCEED'],
                      value: 'CanNotProceed',
                    },
                    {
                      label: '회원 탈퇴',
                      value: 'UserSignout',
                    },
                    {
                      label: '기타',
                      value: 'Etc',
                    },
                  ],
                  style: {
                    gridColumn: '1/3',
                  },
                  className: 'action',
                }}
              ></RadioBox>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: t('REASON'),
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: t('FILE'),
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                disabled={!stopReason}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestAction({
                    nextAction: nextAction,
                    stopReason: stopReason,
                    comment: comment,
                    commentFile: commentFile,
                  });
                }}
                label={t('STOP_PROJECT_REQUEST')}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    case 'LaunchProject': {
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{t('INIT_REVIEW')}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='guide-text__container' style={{ border: 'none', paddingBottom: 0 }}>
            <b>{t('INIT_REVIEW_MSG')}</b>
          </section>
          <section className='launch__container'>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <DatePicker
                value={launchDate}
                meta={{
                  required: true,
                  key: 'launchDate',
                  label: t('PROJECT_START_DATE'),
                  className: 'action',
                  placeholder: 'test',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(e) => {
                  setLaunchDate(e);
                }}
              ></DatePicker>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: t('MEMO'),
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: t('FILE'),
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                disabled={!launchDate}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  if (launchDate) {
                    requestAction({
                      nextAction: nextAction,
                      launchDate: launchDate.replaceAll('-', '.'),
                      comment: comment,
                      commentFile: commentFile,
                    });
                  } else {
                    alert('사업착수일시는 필수값입니다.');
                  }
                }}
                label='착수 심사 신청'
              ></Button>
            </section>
          </section>
        </article>
      );
    }
    default: {
      //ImproveProjectGiveUp
      //ImproveLaunchGiveUp
      return (
        <article className='project-action-modal'>
          <header>
            <h4>{renderNextActionTitle(nextAction)}</h4>
            <div
              className='ico close-small'
              onClick={() => {
                onClose();
              }}
            ></div>
          </header>
          <section className='info__container' style={{ paddingBottom: 0 }}>
            <header>
              <h2>{t('PROJECT_INFORMATION')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={action.projectTitle}
                meta={{
                  key: 'title',
                  label: t('PROJECT'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectId}
                meta={{
                  key: 'title',
                  label: t('PROJECT_ID'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={action.projectVersion}
                meta={{
                  key: 'title',
                  label: t('VERSION'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
              <TextInput
                value={Handler.getYYYYMMDDByUnix(actionDate)}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container'>
            <header>
              <h2>Comment</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={action.adminComment}
                meta={{
                  key: 'title',
                  label: t('COMMENTS'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextArea>
              {action.adminCommentFile && (
                <FileUploader
                  value={action.adminCommentFile}
                  meta={{
                    key: 'title',
                    label: t('FILE'),
                    disabled: true,
                    className: 'action',
                    type: 'file',
                  }}
                ></FileUploader>
              )}
              <TextInput
                value={action.adminCommentDate}
                meta={{
                  key: 'title',
                  label: t('DATE'),
                  disabled: true,
                  className: 'action',
                }}
              ></TextInput>
            </ul>
          </section>
          <section className='comment__container bg'>
            <header>
              <h2>Reply</h2>
            </header>
            <ul className='form-list'>
              <TextArea
                value={comment}
                meta={{
                  key: 'comment',
                  label: t('REASON'),
                  className: 'action',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setComment(value);
                }}
              ></TextArea>
              <FileUploader
                value={commentFile}
                meta={{
                  key: 'commentFile',
                  label: t('FILE'),
                  className: 'action',
                  type: 'file',
                  style: {
                    gridColumn: '1/3',
                  },
                }}
                onChange={(value) => {
                  setCommentFile(value);
                }}
              ></FileUploader>
            </ul>
            <section className='button__container'>
              <Button
                type='info'
                style={{ width: '180px', height: '50px', marginLeft: 0 }}
                onClick={onClose}
                label={t('CLOSE')}
              ></Button>
              <Button
                disabled={!comment}
                style={{ width: '180px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  requestAction({ comment: comment, commentFile: commentFile, nextAction: nextAction });
                }}
                label={renderNextActionButton(nextAction)}
              ></Button>
            </section>
          </section>
        </article>
      );
    }
  }
};

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import {
  AddressLocation,
  Button,
  CheckBox,
  CommaNumberInput,
  DatePicker,
  FileMultipleUploader,
  ModalConfirm,
  MoreMonitoring,
  SelectMonitoring,
  TextInput,
} from '../../components';
import { modalStyle } from '../../styles/style';

export const ContentReduction = (props) => {
  const history = useHistory();
  const { step, formData, formMeta, formChange, contentMeta, contentMetaChange, readOnly, setStep } = props;
  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmModalContent, setConfirmModalContent] = useState({
    title: t('REDUCTION_REQ_FOR_BIZ_CHANGE'),
    content: t('REDUCTION_REQ_FOR_BIZ_CHANGE_CONFIRM_MSG'),
    okLabel: t('REQUEST'),
    cancelLabel: t('CANCEL'),
  });
  const [renderedTerms, setRenderedTerms] = useState([]);

  const getTermsFile = async (file) => {
    const term = await axios.get(file).then((res) => res.data);
    return term;
  };

  const RenderTerms = (title, htmlString) => {
    return (
      <li>
        <Accordion>
          <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
            <div className='title__box'>
              <span>{title}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className='content__box'>
              <div dangerouslySetInnerHTML={{ __html: htmlString }} />
            </div>
          </AccordionDetails>
        </Accordion>
      </li>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const terms = await Promise.all(
        contentMeta.termsList.map(async (item) => {
          const html = await getTermsFile(item.file);
          return RenderTerms(item.title, html);
        }),
      );
      setRenderedTerms(terms);
    };

    fetchData();
  }, [contentMeta.termsList.length]);

  const buildMeta = (meta) => {
    let meta_value = { ...meta };

    if (readOnly) {
      meta_value.readOnly = true;
    }
    return meta_value;
  };

  const closeConfirmModal = () => {
    setConfirmOpen(false);
  };

  const requestAction = () => {
    API.Project.PostModify(contentMeta.projectSeq)
      .then((res) => {
        history.push('/project/writing/' + contentMeta.projectSeq);
      })
      .catch((err) => {
        alert(err.data.message);
      });
  };

  return (
    <article className='content-project'>
      {/* step 1 */}
      {step === 0 && (
        <>
          <section className='section__container'>
            <header>
              <h2>{t('BIZ_INFO')}</h2>
            </header>

            <ul className='form-list'>
              {/* <RadioBox
                value={formData.isExported}
                onChange={formChange}
                meta={buildMeta(formMeta.isExported)}
              ></RadioBox> */}
              <TextInput value={formData.title} onChange={formChange} meta={buildMeta(formMeta.title)}></TextInput>
              <TextInput
                value={formData.projectId}
                onChange={formChange}
                meta={buildMeta(formMeta.projectId)}
              ></TextInput>
              <AddressLocation
                maker={true}
                value={formData.location}
                onChange={formChange}
                meta={buildMeta(formMeta.location)}
              ></AddressLocation>
              <FileMultipleUploader
                value={formData.workplaceImages}
                onChange={formChange}
                meta={buildMeta(formMeta.workplaceImages)}
              ></FileMultipleUploader>
              <TextInput
                value={t(Handler.ENUM.AREA(formData.area))}
                onChange={formChange}
                meta={buildMeta(formMeta.area)}
              ></TextInput>
              <TextInput
                value={t(Handler.ENUM.SUB_AREA(formData.subArea))}
                onChange={formChange}
                meta={buildMeta(formMeta.subArea)}
              ></TextInput>
              <article className='caption__box'>
                <ul>
                  <li className='gap-2'>
                    <header>
                      <span>{t('BIZ_START_DATE')}</span>
                    </header>
                    <span>{contentMeta.businnessStartDate}</span>
                  </li>
                  <li className='gap-2'>
                    <header>
                      <span>{t('CERT_START_DATE')}</span>
                    </header>
                    <span>{contentMeta.certificationStartDate}</span>
                  </li>
                  <li className='gap-2'>
                    <header>
                      <span>{t('CERT_END_DATE')}</span>
                    </header>
                    <span>{contentMeta.certificationEndDate}</span>
                  </li>
                </ul>
              </article>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <h2>{t('METHODOLOGY')}</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={formData.methodologySeq}
                onChange={formChange}
                meta={buildMeta(formMeta.methodologySeq)}
              ></TextInput>
            </ul>
          </section>
          {contentMeta.termsList.length > 0 && (
            <section className='section__container'>
              <header>
                <h2>{t('AGREE_TO_TERM')}</h2>
              </header>
              <ul className='terms--list'>
                <header>
                  <CheckBox
                    disabled={true}
                    value={contentMeta.termsAggree}
                    onChange={(new_value) => {
                      contentMetaChange(new_value, 'termsAggree');
                    }}
                    meta={{
                      disabled: true,
                      label: t('REVIEWED_AND_AGREE'),
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                {renderedTerms}
              </ul>
            </section>
          )}
          {!readOnly && (
            <section className='reduction-modify__container'>
              <div className='text__box'>
                <b style={{ marginBottom: 12 }}>{t('REDUCTION_CHANGE_CHECK_MSG')}</b>
                <span style={{ marginBottom: 12 }}>{t('REDUCTION_CHANGE_REDUCTION_MSG')}</span>
                <span style={{ color: '#777777' }}>
                  {t('REDUCTION_IF_WANT_TO_CHECK')}
                  <em
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push('/project/detail/' + contentMeta.projectSeq);
                    }}
                  >
                    {t('DETAILED_BUSI_DESCRIPTION')}
                  </em>
                </span>
              </div>
              <div className='button__box'>
                <div className='button__item' style={{ marginRight: 12 }}>
                  <span>{t('CHANGE_MADE')}</span>
                  <Button
                    onClick={() => setConfirmOpen(true)}
                    style={{ width: '240px' }}
                    type='secondary'
                    label={t('REDUCTION_REQ_FOR_BIZ_CHANGE')}
                  ></Button>
                </div>
                <div className='button__item'>
                  <span>{t('NO_CHANGE')}</span>
                  <Button
                    onClick={() => {
                      setStep(1);
                    }}
                    style={{ width: '240px' }}
                    type=''
                    label={t('NEXT_REQUEST')}
                  ></Button>
                </div>
              </div>
            </section>
          )}
        </>
      )}

      {/* step 2 */}
      {step === 1 && (
        <>
          <section className='section__container'>
            <header>
              <h2>{t('REDUCTION_MONITORING_INFO')}</h2>
            </header>
            <ul className='form-list'>
              <article style={{ display: 'flex', flexDirection: 'column', width: '100%', gridColumn: '1/3' }}>
                <div style={{ marginBottom: 14 }}>
                  <span style={{ fontSize: 14 }}>
                    {t('REDUCTION_MONITORING_PERIOD')}
                    <span className='text-red-600'>*</span>
                  </span>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <DatePicker
                    value={formData.startDate}
                    meta={buildMeta(formMeta.startDate)}
                    onChange={formChange}
                  ></DatePicker>
                  <span style={{ marginTop: 45, marginLeft: 10, marginRight: 10, color: '#777' }}>~</span>
                  <DatePicker
                    value={formData.endDate}
                    meta={buildMeta(formMeta.endDate)}
                    onChange={formChange}
                  ></DatePicker>
                  {contentMeta.day && (
                    <span style={{ marginTop: 45, marginLeft: 10, color: '#777' }}>
                      {contentMeta.month ? contentMeta.month + t('MONTHS') : null} {contentMeta.day}
                      {t('DAYS')}
                    </span>
                  )}
                </div>
              </article>
              <article style={{ display: 'flex' }}>
                <TextInput
                  value={formData.monitorDegree}
                  meta={buildMeta(formMeta.monitorDegree)}
                  onChange={formChange}
                ></TextInput>
                <span style={{ marginTop: 63, marginLeft: 10, color: '#777' }}>{t('ROUND')}</span>
              </article>
            </ul>
          </section>
          <section className='co2__container'>
            <header>
              <h2>{t('GHG_REDUCTION')}</h2>
            </header>
            <div className='co2__box'>
              <section className='left'>
                <header>
                  <h4>{t('BIZ_PLAN')}</h4>
                </header>
                <CommaNumberInput
                  value={formData.reductionBase}
                  meta={buildMeta(formMeta.reductionBase)}
                  onChange={formChange}
                ></CommaNumberInput>
                <CommaNumberInput
                  value={formData.reductionBusiness}
                  meta={buildMeta(formMeta.reductionBusiness)}
                  onChange={formChange}
                ></CommaNumberInput>
                <CommaNumberInput
                  value={formData.reductionLeak}
                  meta={buildMeta(formMeta.reductionLeak)}
                  onChange={formChange}
                ></CommaNumberInput>
                <CommaNumberInput
                  value={formData.reduction}
                  meta={buildMeta(formMeta.reduction)}
                  onChange={formChange}
                ></CommaNumberInput>
              </section>
              <section className='right'>
                <header>
                  <h4>{t('REDUCTION_ACTUAL_RESULT')}</h4>
                </header>
                <CommaNumberInput
                  value={formData.realReductionBase}
                  meta={buildMeta(formMeta.realReductionBase)}
                  onChange={formChange}
                ></CommaNumberInput>
                <CommaNumberInput
                  value={formData.realReductionBusiness}
                  meta={buildMeta(formMeta.realReductionBusiness)}
                  onChange={formChange}
                ></CommaNumberInput>
                <CommaNumberInput
                  value={formData.realReductionLeak}
                  meta={buildMeta(formMeta.realReductionLeak)}
                  onChange={formChange}
                ></CommaNumberInput>
                <CommaNumberInput
                  value={formData.realReduction}
                  meta={buildMeta(formMeta.realReduction)}
                  onChange={formChange}
                ></CommaNumberInput>
              </section>
            </div>
            <div className='download__box full'>
              <header>
                <b>{t('REDUCTION_MONITORING_CALC_MSG')}</b>
                <span>{t('DOWNLOAD_INFORMATION')}</span>
              </header>
              <Button
                style={{ width: 240, height: 38 }}
                type='secondary-square'
                label={t('DOWNLOAD')}
                onClick={() => {
                  window.open(contentMeta.monitoringCalcFile, '_blank');
                }}
              ></Button>
            </div>
          </section>
          {/* <section className='section__container'>
            <header>
              <h2>모니터링 계획 입력</h2>
            </header>
            <ul className='form-list'>
              <TextInput
                value={formData.monitoringType === 'Document' ? '서면 모니터링' : '시스템 모니터링'}
                onChange={() => {}}
                meta={buildMeta(formMeta.monitoringType)}
              ></TextInput>

              <ToastViewer
                value={formData.monitoringPlan}
                onChange={formChange}
                meta={buildMeta(formMeta.monitoringPlan)}
              ></ToastViewer>
              <ToastEditor
                value={formData.realMonitorDesc}
                onChange={formChange}
                meta={buildMeta(formMeta.realMonitorDesc)}
              ></ToastEditor>
            </ul>
          </section> */}
        </>
      )}

      {step === 2 && (
        <>
          <section className='section__container'>
            <header>
              <h2>{t('BEFORE_AFTER_IMAGE')}</h2>
            </header>
            <ul className='form-list'>
              <FileMultipleUploader
                value={formData.beforeImages}
                onChange={formChange}
                meta={buildMeta(formMeta.beforeImages)}
              ></FileMultipleUploader>
              <FileMultipleUploader
                value={formData.afterImages}
                onChange={formChange}
                meta={buildMeta(formMeta.afterImages)}
              ></FileMultipleUploader>
            </ul>
          </section>
          <section className='section__container'>
            <header>
              <h2>{t('SUPPORTING_DOC')}</h2>
            </header>
            <ul className='evidence-list'>
              <SelectMonitoring
                value={formData.evidence}
                onChange={formChange}
                meta={buildMeta(formMeta.evidence)}
              ></SelectMonitoring>
            </ul>
            <ul className='evidence-list'>
              <MoreMonitoring
                value={formData.additionalEvidence}
                onChange={formChange}
                meta={buildMeta(formMeta.additionalEvidence)}
              ></MoreMonitoring>
            </ul>
          </section>
        </>
      )}

      <Modal open={confirmOpen} onClose={closeConfirmModal}>
        <Box sx={modalStyle}>
          <ModalConfirm
            title={confirmModalContent.title}
            content={confirmModalContent.content}
            okLabel={confirmModalContent.okLabel}
            cancelLabel={confirmModalContent.cancelLabel}
            onClose={closeConfirmModal}
            requestAction={() => requestAction()}
          />
        </Box>
      </Modal>
    </article>
  );
};

import { Box, Modal } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import { ModalLoading, PageHeader, SearchFilter, TablePaging } from '../../components';
import { TableCreditRegistry } from '../../components/table/CreditRegistry';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';

export const CreditRegistryListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { CREDIT_STATUS, MSG, ERROR } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [list, setList] = useState(null);
  const [areaType, setAreaType] = useState('');
  const [subAreaType, setSubAreaType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [mode, setMode] = useState('');
  const [vintage, setVintage] = useState('');
  const [unit, setUnit] = useState('');
  const [isCertification, setIsCertification] = useState('');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const getRegistryList = (init_page, isReset = false) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
        searchValue: isReset ? '' : searchValue,
        areaType: isReset ? '' : areaType,
        subAreaType: isReset ? '' : subAreaType,
        mode: isReset ? '' : mode,
        vintage: isReset ? '' : vintage,
        unit: isReset ? '' : unit,
        isCertification: isReset ? '' : isCertification,
        lang: isKorean ? 'ko' : 'en',
      };
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    replaceSearchQuery(params);
    API.Registry.GetRegistryList('credit', params)
      .then((res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });
  };

  useEffect(() => {
    if (lastSearchBody) {
      getRegistryList();
    }
  }, [page]);

  const handleExcelDownload = useCallback(() => {
    if (!list || list?.length === 0) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: ERROR['NO_DATA_FOUND'],
      });
      return;
    }
    setSpinner(true);
    const params = {
      searchValue: lastSearchBody.searchValue || searchValue,
      areaType: lastSearchBody.areaType || areaType,
      subAreaType: lastSearchBody.subAreaType || subAreaType,
      mode: lastSearchBody.mode || mode,
      vintage: lastSearchBody.vintage || vintage,
      unit: lastSearchBody.unit || unit,
      isCertification: lastSearchBody.isCertification || isCertification,
      lang: isKorean ? 'ko' : 'en',
    };
    API.Registry.GetRegistryExcel(params)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `[POPLE] ${isKorean ? '크레딧_현황' : 'Credit_List'}_${Handler.geni18nDate(isKorean, dayjs())}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setSpinner(false);
        updateSnackbar({
          type: 'success',
          open: true,
          message: `Excel ${MSG['DOWNLOAD_SUCCESS']}`,
        });
      })
      .catch((err) => {
        setSpinner(false);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.message,
        });
      });
  }, [
    lastSearchBody,
    searchValue,
    areaType,
    subAreaType,
    mode,
    vintage,
    unit,
    isCertification,
    list,
    isKorean,
    updateSnackbar,
    ERROR,
    MSG,
  ]);

  useEffect(() => {
    if (location.search) {
      const params = {
        ...Utils.ParamsToObject(history.location.search),
      };
      setLastSearchBody({
        ...params,
      });
      setPage(Number(params.page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    requestSearch();
  }, []);

  const requestSearch = () => {
    getRegistryList(0);
  };

  const replaceSearchQuery = (params) => {
    history.replace({
      search: Utils.ObjectToParams({
        ...params,
        page: page,
        limit: Handler.VARIABLES.PAGE_LIMIT,
      }),
    });
  };

  const handleSearchFilter = (params) => {
    if ('searchValue' in params) {
      setSearchValue(params.searchValue);
    }
    if ('areaType' in params) {
      setAreaType(params.areaType === 'all' ? '' : params.areaType);
    }
    if ('subAreaType' in params) {
      setSubAreaType(params.subAreaType === 'all' ? '' : params.subAreaType);
    }
    if ('mode' in params) {
      setMode(params.mode === 'all' ? '' : params.mode);
    }
    if ('vintage' in params) {
      setVintage(params.vintage === 'all' ? '' : params.vintage);
    }
    if ('unit' in params) {
      setUnit(params.unit);
    }
    if ('isCertification' in params) {
      setIsCertification(params.isCertification);
    }
  };

  const resetFilter = (e) => {
    setSearchValue('');
    setAreaType('');
    setSubAreaType('');
    setMode('');
    setVintage('');
    setUnit('');
    setIsCertification('');
    setPage(0);
    getRegistryList(0, true);
  };

  const tableRowClick = (item) => {
    history.push('/registry/credit/detail/' + item.seq);
  };

  return (
    <article className='basic-page'>
      <PageHeader type='credit-registry' title={`${CREDIT_STATUS['STATUS']}`}></PageHeader>
      <article className='content__wrapper'>
        <SearchFilter
          isVrc={true}
          onChange={(params) => handleSearchFilter(params)}
          onSearch={requestSearch}
          onReset={resetFilter}
          onDownload={handleExcelDownload}
        />
        <TableCreditRegistry data={list} isKorean={isKorean} rowClick={tableRowClick} />
        <div className='my-6'>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </div>
      </article>
      <Modal open={spinner}>
        <Box>
          <ModalLoading content={'Downloading...'} />
        </Box>
      </Modal>
    </article>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import API from '../../api';
import { BoardInquiry, Button, PageHeader } from '../../components';
import { DialogContext, SnackbarContext } from '../../contexts';

export const MypageInquiryDetailPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateDialog } = useContext(DialogContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [data, setData] = useState(null);
  const { seq } = useParams();

  useEffect(() => {
    if (seq) {
      refreshData();
    }
  }, [seq]);

  const refreshData = () => {
    API.User.GetInquiryDetail(seq).then(
      (res) => {
        let result = res.data;
        console.log('refreshData result', result);
        setData(result);
      },
      (err) => {
        console.log('err', err);
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const requsetModify = () => {
    console.log('requsetModify');
    history.push('/mypage/inquiry_modify/' + seq);
  };
  const requsetDelete = () => {
    console.log('requsetDelete');
    updateDialog({
      type: 'error',
      open: true,
      title: '상담 내역 삭제',
      description: '삭제 후 되돌릴 수 없습니다. 그래도 진행하시겠습니까?',
      onConfirm: onDialogConfirm,
    });
  };

  const onDialogConfirm = () => {
    API.User.DeleteInquiry(seq).then(
      (res) => {
        let result = res.data;
        console.log('User DeleteInquiry result', result);
        history.push('/mypage/inquiry_list');
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  return (
    <article className='basic-page'>
      {/* <Breadcrumbs content='credit' title={'1:1 상담 내역'}></Breadcrumbs> */}
      <PageHeader type='credit' title={`${t('ONETOONE_HISTORY')}`}></PageHeader>
      <article className='content__wrapper'>
        <section className='board__container'>
          <BoardInquiry data={data}></BoardInquiry>
        </section>
        <section className='button__container space-between'>
          <Button
            type={'info'}
            style={{ width: '180px' }}
            onClick={() => {
              history.push('/mypage/inquiry_list');
            }}
            label={t('LIST')}
          ></Button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              disabled={data && data.status === 'Completed'}
              type={'info'}
              style={{ width: '180px', marginRight: 12 }}
              onClick={requsetModify}
              label={t('MODIFY')}
            ></Button>
            <Button
              disabled={data && data.status === 'Completed'}
              type={'info'}
              style={{ width: '180px' }}
              onClick={requsetDelete}
              label={t('DELETE')}
            ></Button>
          </div>
        </section>
      </article>
    </article>
  );
};

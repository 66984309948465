import React from 'react';
import { useLabel } from '../../hooks';
export const UsageTerm = (props) => {
  const { USAGE_TERM, TransUsage } = useLabel();

  return (
    <section className='overflow-y-auto text-left pt-8 pb-36 whitespace-pre-wrap p-4'>
      {USAGE_TERM.map((part, idx) => {
        return (
          <article key={`term-part-${idx}`} className='mb-12'>
            <h2 className='my-4'>{part['TITLE']}</h2>
            <div>
              {part['CONTENT'].map((item, item_idx) => {
                return (
                  <div key={`term-part-content-${item_idx}`} className='mb-12'>
                    <h3 className='my-2'>{item['SUB_TITLE']}</h3>
                    {item['CONTENT_LIST'].map((p, list_idx) => (
                      <p key={`term-part-content-list-${list_idx}`} className='leading-6'>
                        {p}
                      </p>
                    ))}
                  </div>
                );
              })}
            </div>
            <p>{'DESCRIPTION_END' in part && part['DESCRIPTION_END']}</p>
          </article>
        );
      })}
      <p>{TransUsage('USAGE_END')}</p>
    </section>
  );
};

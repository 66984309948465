import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import Handler from '../../Handler';
import { Button } from '../../components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 2,
};

export const Address = (props) => {
  const { maker, value, onChange, meta } = props;
  const [open, setOpen] = useState(false);
  const { kakao } = window;

  const companyAddress = (address) => {
    const location = Handler.jsonParser(address);
    if (location?.daum) return location.daum.roadAddress;
    if (location?.addr1) return location.formatted_address;
    return '-';
  };

  useEffect(() => {
    if (value && value.daum && value.daum.address && maker) {
      console.log('Address value', value);

      var geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(value.daum.address, function (result, status) {
        if (status === kakao.maps.services.Status.OK) {
          console.log('result', result);
          var container = document.getElementById('kakao-map');
          var options = {
            // center: new kakao.maps.LatLng(127.10172, 37.33831),
            center: new window.kakao.maps.LatLng(result[0].y, result[0].x),
            level: 3,
          };

          var map = new kakao.maps.Map(container, options);

          //마커가 표시 될 위치
          let markerPosition = new kakao.maps.LatLng(result[0].y, result[0].x);

          // 마커를 생성
          let marker = new kakao.maps.Marker({
            position: markerPosition,
          });

          // 마커를 지도 위에 표시
          marker.setMap(map);
        } else {
          console.log('status', status);
        }
      });
    }
  }, [value, kakao]);

  const handleComplete = (daum_data) => {
    console.log('daum_data', daum_data);

    let new_value = _.clone(value);
    new_value.daum = daum_data;
    onChange(new_value, meta.key);
    setOpen(false);
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  if (maker) {
    return (
      <article
        className={Handler.BuildArticleClassname('form-address', meta)}
        style={meta && meta.style ? { ...meta.style } : {}}
      >
        {meta.label && (
          <header>
            <span>{meta.label}</span>
            {renderRequired()}
          </header>
        )}
        <section className='address-value__box'>
          {meta && meta.type === 'content' && (
            <Button
              disabled={meta.disabled}
              style={{ width: '96px', height: '50px', marginRight: 8 }}
              onClick={() => {
                if (!meta.readOnly) {
                  setOpen(true);
                }
              }}
              label='주소검색'
              type='secondary-square'
            ></Button>
          )}

          <div className='zonecode'>
            <input readOnly value={value.daum ? value.daum.zonecode : ''}></input>
            {meta && meta.type === 'mypage' && (
              <Button
                disabled={meta.disabled}
                style={{ width: '80px', height: '50px', marginLeft: 12 }}
                onClick={() => {
                  setOpen(true);
                }}
                label='우편번호'
                type='info-square'
              ></Button>
            )}
          </div>
          <div className='address'>
            <input readOnly value={value.daum ? value.daum.roadAddress : ''}></input>
          </div>
          <div className='detail'>
            <input
              disabled={meta.disabled}
              value={value.detail}
              placeholder={'상세주소'}
              onChange={(e) => {
                let new_value = _.clone(value);
                new_value.detail = e.target.value;
                onChange(new_value, meta.key);
              }}
            ></input>
          </div>
        </section>
        <section className='kakao-map__box'>
          <div id='kakao-map'></div>
        </section>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Box sx={style}>
            <DaumPostcode onComplete={handleComplete} />
          </Box>
        </Modal>
      </article>
    );
  }
  return (
    <article
      className={Handler.BuildArticleClassname('form-address', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>{meta.label}</span>
          {renderRequired()}
        </header>
      )}
      <section className='address-value__box'>
        {meta && meta.type === 'content' && (
          <Button
            disabled={meta.disabled}
            style={{ width: '96px', height: '50px', marginRight: 8 }}
            onClick={() => {
              if (!meta.readOnly) {
                setOpen(true);
              }
            }}
            label='주소검색'
            type='secondary-square'
          ></Button>
        )}

        <div className='zonecode'>
          <input readOnly value={value.daum ? value.daum.zonecode : ''}></input>
          {meta && meta.type === 'mypage' && (
            <Button
              disabled={meta.disabled}
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                setOpen(true);
              }}
              label='우편번호'
              type='info-square'
            ></Button>
          )}
        </div>
        <div className='address'>
          <input readOnly value={companyAddress(value)}></input>
        </div>
        <div className='detail'>
          <input
            disabled={meta.disabled}
            value={value.detail}
            placeholder={'상세주소'}
            onChange={(e) => {
              let new_value = _.clone(value);
              new_value.detail = e.target.value;
              onChange(new_value, meta.key);
            }}
          ></input>
        </div>
      </section>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <DaumPostcode onComplete={handleComplete} />
        </Box>
      </Modal>
    </article>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const TextArea = (props) => {
  const { value, onChange, meta } = props;
  const { t } = useTranslation();
  const [textSize, setTextSize] = useState(0);

  const onChangeTextArea = (e) => {
    if (meta.maxSize) {
      if (e.target.value.length <= meta.maxSize) {
        onChange(e.target.value, meta.key);
        setTextSize(e.target.value.length);
      } else {
      }
    } else {
      onChange(e.target.value, meta.key);
      setTextSize(e.target.value.length);
    }
  };

  const renderRequired = () => {
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-text-area', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      {meta.label && (
        <header>
          <span>
            {t(meta.label)}
            {renderRequired()}
          </span>
          {meta.maxSize && (
            <div className='max-size'>
              {textSize} / {meta.maxSize}
            </div>
          )}
        </header>
      )}
      <div className='textarea__box'>
        <textarea disabled={meta.disabled} value={value || ''} onChange={onChangeTextArea}></textarea>
      </div>
    </article>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import Board_2 from '../../styles/images/board_sample_1.png';
import Board_3 from '../../styles/images/board_sample_2.png';
import Board_1 from '../../styles/images/sample_board.png';

const styles = {
  paragraph: {
    fontSize: '16',
    color: '#606369',
    fontWeight: 400,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
  },
  button: {
    borderRadius: '0',
    height: 44,
    width: 215,
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px',
  },
  imageContainer: {
    position: 'relative',
    height: 380,
    width: 300,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  image: {
    transition: 'filter 0.3s ease',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontSize: '24px',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
};

export const BoardPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { BOARD_PAGE } = useLabel();
  const { t } = useTranslation();
  const history = useHistory();

  const handleImageClick = (id) => {
    if (id) {
      history.push(`/cs/board/details/${id}`);
    } else {
      console.log('invalid id', id);
    }
  };
  const boardData = [
    { image: Board_1, name: '박란희', title: 'ESG 전문 미디어 <임팩트온> 대표', id: 1 },
    { image: Board_2, name: '이상민', title: '중부대학교 석좌교수', id: 2 },
    { image: Board_3, name: '정영희', title: '충남대학교 특임교수', id: 3 },
  ];

  return (
    <article className='content h-full'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE] h-[442px]'>
          <PageHeader type='standard' showBreadcrumbs={false}>
            {/* {!props.showBreadcrumbs && (
              <div className='whitespace-pre-wrap leading-7 ' style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph, color: '#606369' }}>
                  이사회 소개
                </p>
              </div>
            )} */}
            <div className='flex justify-between w-[1200px]'>
              <div className='pl-10'>
                <h1 className='text-[36px]'>{`${BOARD_PAGE['TITLE']}`}</h1>
              </div>

              <div className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                <p className='whitespace-pre-wrap leading-7' style={{ ...styles.paragraph }}>
                  {BOARD_PAGE['DESCRIPTION']}
                </p>
                <p className='description mt-5 whitespace-pre-wrap leading-7'>{BOARD_PAGE['SUB_DESCRIPTION']}</p>
                <p className='description mt-5 whitespace-pre-wrap leading-7'>{BOARD_PAGE['SUB_DESCRIPTION_1']}</p>
              </div>
            </div>
          </PageHeader>
        </article>
        <div className='row-page-row mt-[50px]' style={{ display: 'flex', height: '562px', width: '1200px' }}>
          {boardData.map((board) => (
            <div
              style={{
                borderLeft: '1px solid #D7DAE2',
                margin: '40px',
                height: '458px',
                width: '330px',
                justifyContent: 'flex-start',
              }}
            >
              <div className='flex flex-col pl-[20px]'>
                <p className='mb-3 text-[#606369] text-[16px]'>{board.title}</p>
                <p className='mb-4 text-[24px]'>{board.name}</p>
                <div
                  style={styles.imageContainer}
                  onClick={() => handleImageClick(board.id)}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector('.overlay').style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector('img').style.filter = 'blur(0)';
                    e.currentTarget.querySelector('.overlay').style.opacity = 0;
                  }}
                >
                  <img
                    src={board.image}
                    alt={`Board ${board.id}`}
                    style={styles.image}
                    onError={(e) => (e.target.src = '/cs/board')}
                  />
                  <div className='overlay' style={styles.overlay}>
                    <p className='text-[36px]'>View</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </article>
    </article>
  );
};

import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'credit/';

const GetProject = (params) => {
  const url = BASE_URL + PATH + 'project' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetPayment = (params) => {
  const url = BASE_URL + PATH + 'payment' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetProjectCredit = (params) => {
  const url = BASE_URL + PATH + 'project' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostIssue = (body) => {
  const url = BASE_URL + PATH + 'issue';
  return AxiosInstance.post(url, body);
};

const PutIssue = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/issue';
  return AxiosInstance.put(url, body);
};

const GetIssue = (seq) => {
  const url = BASE_URL + PATH + seq + '/issue';
  return AxiosInstance.get(url);
};

const PostMove = (body) => {
  const url = BASE_URL + PATH + 'move';
  return AxiosInstance.post(url, body);
};

const PutMove = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/move';
  return AxiosInstance.put(url, body);
};

const GetMove = (seq) => {
  const url = BASE_URL + PATH + seq + '/move';
  return AxiosInstance.get(url);
};

const GetAssignee = (params) => {
  const url = BASE_URL + PATH + 'move/assignee' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostOffset = (body) => {
  const url = BASE_URL + PATH + 'offset';
  return AxiosInstance.post(url, body);
};

const PutOffset = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/offset';
  return AxiosInstance.put(url, body);
};

const GetOffset = (seq) => {
  const url = BASE_URL + PATH + seq + '/offset';
  return AxiosInstance.get(url);
};

const PostRetire = (body) => {
  const url = BASE_URL + PATH + 'retire';
  return AxiosInstance.post(url, body);
};

const GetList = (params) => {
  const url = BASE_URL + 'credit' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetStat = () => {
  const url = BASE_URL + PATH + 'state';
  return AxiosInstance.get(url);
};

const GetDetail = (seq) => {
  const url = BASE_URL + PATH + +seq;
  return AxiosInstance.get(url);
};

const GetAction = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetActionDoc = (seq, params) => {
  const url = BASE_URL + PATH + seq + '/action/doc' + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const PostNextAction = (seq, body) => {
  const url = BASE_URL + PATH + seq + '/next-action';
  return AxiosInstance.post(url, body);
};

const Credit = {
  PostIssue: PostIssue,
  PostMove: PostMove,
  PutMove: PutMove,
  PutIssue: PutIssue,
  PutOffset: PutOffset,
  GetAssignee: GetAssignee,
  PostOffset: PostOffset,
  PostRetire: PostRetire,
  GetStat: GetStat,
  GetProject: GetProject,
  GetPayment: GetPayment,
  GetProjectCredit: GetProjectCredit,
  GetList: GetList,
  PostNextAction: PostNextAction,
  GetDetail: GetDetail,
  GetAction: GetAction,
  GetActionDoc: GetActionDoc,
  GetIssue: GetIssue,
  GetMove: GetMove,
  GetOffset: GetOffset,
};

export default Credit;

import React, { useContext } from 'react';
import { PageHeader, StandardDownload, TableAgencyList } from '../../components';
import { AGENCY_LIST, APPLICANTS } from '../../constants';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const VerificationPage = (props) => {
  const { STANDARD_THIRD_PARTY_PAGE } = useLabel();
  const { isKorean } = useContext(LangContext);

  return (
    <article className='basic-page'>
      <PageHeader
        type='document'
        title={STANDARD_THIRD_PARTY_PAGE['TITLE']}
        description={STANDARD_THIRD_PARTY_PAGE['DESCRIPTION']}
      >
        <div></div>
      </PageHeader>
      <article className='content__wrapper'>
        <section className='w-full'>
          <article className='py-8'>
            <h2 className='text-md mb-2'>{STANDARD_THIRD_PARTY_PAGE['REQUIREMENTS']['TITLE']}</h2>
            {STANDARD_THIRD_PARTY_PAGE['REQUIREMENTS']['CONTENT'].map((rc, idx) => (
              <p key={`requirements-content-${idx}`} className='whitespace-pre-wrap text-gray-700 leading-6'>
                {rc}
              </p>
            ))}
          </article>
          <article className='py-8'>
            <h2 className='text-md mb-2'>{STANDARD_THIRD_PARTY_PAGE['PROCESS']['TITLE']}</h2>
            {STANDARD_THIRD_PARTY_PAGE['PROCESS']['CONTENT'].map((p, idx) => (
              <p
                key={`process-content-${idx}`}
                className='whitespace-pre-wrap text-gray-700 leading-6'
                dangerouslySetInnerHTML={{ __html: p }}
              ></p>
            ))}
            <StandardDownload
              title={STANDARD_THIRD_PARTY_PAGE['APPLICANT']['TITLE']}
              description={STANDARD_THIRD_PARTY_PAGE['APPLICANT']['DESCRIPTION']}
              file={isKorean ? APPLICANTS.fileKo : APPLICANTS.fileEn}
            />
          </article>
          <article className='py-8'>
            <h2 className='text-md mb-2'>{STANDARD_THIRD_PARTY_PAGE['AGENCY_LIST']}</h2>
            <div className='pt-4'>
              <TableAgencyList data={AGENCY_LIST} isKorean={isKorean} rowClick={() => true} />
            </div>
          </article>
        </section>
      </article>
    </article>
  );
};

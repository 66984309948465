import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const CheckBox = (props) => {
  const { t } = useTranslation();
  const { value, onChange, required, label, meta, noOption } = props;

  const renderRequired = () => {
    if (meta.subLabel) {
      return <em className={meta.required ? 'required' : ''}>{meta.subLabel}</em>;
    }
    if (meta && meta.required) {
      if (meta.className === 'point') {
        return <div className='ico required'></div>;
      } else {
        return <span className='text-red-600'>*</span>;
      }
    }
  };

  return (
    <article
      className={Handler.BuildArticleClassname('form-check-box', meta)}
      style={meta && meta.style ? { ...meta.style } : {}}
    >
      <section className='ico__box'>
        <div
          onClick={() => {
            onChange(!value, meta.key);
          }}
          className={
            !value
              ? 'ico check-square'
              : meta.disabled || meta.readOnly
              ? 'ico check-square-disabled'
              : 'ico check-square-on'
          }
        ></div>
      </section>
      <section
        className='label__box'
        onClick={() => {
          onChange(!value, meta.key);
        }}
      >
        <span>{t(meta.label)}</span>
        {renderRequired()}
      </section>
    </article>
  );
};

import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Arrow from '../../styles/icon/arrow.svg';

export const Button = (props) => {
  const { label, onClick, style, type, loading, disabled, ico } = props;
  const { t } = useTranslation();

  const renderClassname = () => {
    if (type) {
      return type + '-button';
    } else {
      return 'basic-button';
    }
  };

  return (
    <article className={renderClassname()} style={{ ...style }}>
      <button disabled={loading || disabled} onClick={onClick}>
        {!loading && ico && <div className={'ico ' + ico} style={{ marginRight: 8 }}></div>}
        {loading ? <CircularProgress size={14}></CircularProgress> : t(label)}
      </button>
      <img src={Arrow} width={'20px'} alt='' />
    </article>
  );
};

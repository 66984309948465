import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { ModalPdfView } from '..';
import { LangContext } from '../../contexts';
import Handler from '../../Handler';
import { useLabel } from '../../hooks';

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 13rem 1fr 120px 120px 120px;
  padding: 10px;
  border: solid 1px;
`;

const TableSubRow = styled.div`
  display: grid;
  grid-template-columns: 360px 120px 120px 120px;
`;

export const OtherDocument = (props) => {
  const { docData, type } = props;
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [document, setDocument] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { isKorean } = useContext(LangContext);
  const { TABLE_HEADER, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD, ERROR } = useLabel();
  const [showMore, setShowMore] = useState(false);
  const handleDocumentModal = useCallback((doc) => {
    setDocument(doc);
    setShowModal(true);
  }, []);

  const documentType = useMemo(() => {
    if (type === 'methodology') return DOCUMENT_METHODOLOGY;
    if (type === 'project') return DOCUMENT_PROJECT;
    if (type === 'standard') return DOCUMENT_STANDARD;
    return DOCUMENT_STANDARD;
  }, [type, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD]);

  const docTypeKeys = useMemo(() => {
    if (type === 'methodology') return ['BASIC', 'RESULT', 'CONSULTATION'];
    if (type === 'project') return ['BASIC', 'MONITORING', 'VERIFY', 'RESULT', 'CONSULTATION'];
    if (type === 'standard') return ['HISTORY', 'CONSULTATION'];
    return ['HISTORY', 'CONSULTATION'];
  }, [type]);

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };
  const noDataRow = (type, idx) => (
    <TableSubRow key={`doc-no-data-sub-row-${idx}`} className={`w-full items-center`}>
      {/* <div className='text-gray-400 leading-6 text-center'>{ERROR['NO_DOCUMENTS']}</div> */}
    </TableSubRow>
  );

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      style={{
        'border': '1px solid #ccc',
        'borderRadius': 0,
        'borderBottom': 0,
        '&.Mui-expanded': {
          borderColor: '#333',
          borderRadius: 0,
        },
      }}
    >
      <AccordionSummary
        className='flex bg-slate-100 w-full'
        style={{ height: 84 }}
        expandIcon={<ExpandMoreIcon fontSize='large' />}
      >
        <div style={{ borderRadius: 0 }}>
          {/* <div className='flex justify-center'>{TABLE_HEADER['TYPE']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['DOC_TITLE']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['CREATED_DATE']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['PREVIEW']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['DOWNLOAD']}</div> */}
          {/* <div className='flex justify-center items-center text-center leading-6'>{documentType}</div> */}
          <div className='flex flex-col m-3 mr-0 pb-2'>
            <div className='flex justify-start mt-3'>
              <p className=' text-black mr-2'> 그 외 문서 </p>
              <div className=' text-gray-500'>|</div>
              <p className='ml-2 text-gray-500'> 기타 </p>
            </div>
            {isExpanded && <div className='divider w-[840px] h-px bg-gray-300 mt-7 mb-0'></div>}
          </div>
        </div>
      </AccordionSummary>
      {docTypeKeys.map((el, idx) => {
        return (
          <AccordionDetails
            key={`doc-data-item-${idx}`}
            aria-controls={`panel${idx}-content`}
            className='w-full border-s black'
          >
            <div className='flex justify-between flex-col gap-2'>
              {docData[el]?.length > 0 ? (
                docData[el].map((doc, idx) => {
                  const file = isKorean ? doc.attachedFile : doc.attachedFileEn;
                  const title = isKorean ? doc.title : doc.titleEn;
                  const date = Handler.geni18nDate(isKorean, doc.createdAt * 1000);
                  return (
                    <TableSubRow key={`doc-data-sub-row-${idx}`} className='w-[830px] justify-between'>
                      {/* <div className='flex text-center ml-4 text-gray-500'>{title}</div>
                        <div className='flex text-center leading-6'>{date}</div>
                        <div className='flex ml-14'>
                          <div>
                            <Button
                              variant='outlined'
                              style={{
                                height: 44,
                                width: 120,
                                borderRadius: 0,
                                color: 'black',
                                borderColor: 'black',
                                padding: 0,
                              }}
                              className='mt-2'
                              onClick={() => handleDocumentModal(file)}
                            >
                              미리보기
                            </Button>
                          </div>
                          <div className='ml-2'>
                            <a href={file} download={file} target='_blank' rel='noreferrer'>
                              <Button
                                variant='contained'
                                style={{
                                  height: 44,
                                  width: 120,
                                  borderRadius: 0,
                                  background: 'black',
                                  borderColor: 'black',
                                }}
                              >
                                다운로드
                              </Button>
                            </a>
                          </div>
                        </div> */}
                    </TableSubRow>
                  );
                })
              ) : (
                <div className='text-center py-0 mb-[80px] align-items text-gray-500'>등록된 문서가 없습니다.</div>
              )}
            </div>
          </AccordionDetails>
        );
      })}
      <Modal ref={ref} open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          <ModalPdfView url={document} onClose={() => setShowModal(false)} />
        </Box>
      </Modal>
    </Accordion>
  );
};

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useLabel } from '../../hooks';

export const NoDataRow = (props) => {
  const { colSpan = 5, content } = props;
  const { ERROR } = useLabel();

  return (
    <TableRow>
      <TableCell align='center' colSpan={colSpan}>
        {content || ERROR['NO_DOCUMENTS']}
      </TableCell>
    </TableRow>
  );
};

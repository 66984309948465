import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { FileMultipleViewer, ToastViewer } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

export const BoardAccordion = (props) => {
  const { data, type, init } = props;
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  const { ERROR } = useLabel();

  const renderSummary = (data) => {
    if (type === 'notice') {
      return (
        <div className={'notice-summary'}>
          {/* <div className='seq'>{data.seq}</div> */}
          <div className='title'>
            <span className='ellipsis-text'>{isKorean ? data.title : data.titleEn}</span>
          </div>
          <div className='date'>{Handler.getYYYYMMDDByUnix(data.createdAt)}</div>
        </div>
      );
    } else if (type === 'faq') {
      return (
        <div className={'notice-summary'}>
          {/* <div className='seq'>{data.seq}</div> */}
          <div className='category'>{isKorean ? data.category : data.categoryEn}</div>
          <div className='title'>
            <span className='ellipsis-text'>{data.title}</span>
          </div>
          <div className='date'>{Handler.getYYYYMMDDByUnix(data.createdAt)}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <article className='board-accordion'>
      <div className='accordion__box'>
        {data && data.length === 0 && (
          <div className='empty__box'>
            <span>{ERROR['NO_DATA_FOUND']}</span>
          </div>
        )}
        {data &&
          data.map((item, index) => {
            let isInit = false;

            if (init && Number(init) === item.seq) {
              isInit = true;
            }
            return (
              <Accordion className={item.isTop ? 'top' : ''} defaultExpanded={isInit}>
                <AccordionSummary expandIcon={<div className='ico arrow-down'></div>}>
                  {renderSummary(item)}
                </AccordionSummary>
                <AccordionDetails>
                  <ToastViewer value={isKorean ? item.content : item.contentEn} meta={{}}></ToastViewer>
                  {isKorean
                    ? item.files &&
                      item.files.length > 0 && (
                        <FileMultipleViewer value={item.files} meta={{ label: '첨부파일' }}></FileMultipleViewer>
                      )
                    : item.filesEn &&
                      item.filesEn.length > 0 && (
                        <FileMultipleViewer value={item.filesEn} meta={{ label: 'Attachment' }}></FileMultipleViewer>
                      )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </article>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { Address, AddressLocation, Button, MoreFile, SelectBox, TextArea, TextInput } from '../../components';

export const ContentCredit = (props) => {
  const { mode, formData, formMeta, formChange, contentMeta, readOnly, callback } = props;
  const { t } = useTranslation();
  const buildMeta = (meta) => {
    let meta_value = { ...meta };

    if (readOnly) {
      meta_value.readOnly = true;
    }
    return meta_value;
  };

  const infoByMode = (mode) => {
    switch (mode) {
      case 'issue': {
        return (
          <section className='section__container'>
            <header>
              <h2>{t('INFO_ISSUING_CREDIT')}</h2>
            </header>
            <ul className='form-list'>
              {readOnly ? (
                <>
                  <TextInput
                    value={contentMeta.projectTitle}
                    meta={buildMeta({
                      key: 'projectTitle',
                      label: 'BIZ_TITLE',
                      disabled: true,
                      required: true,
                      style: {
                        gridColumn: '1/3',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={contentMeta.monitorDegree + t('ROUND')}
                    meta={buildMeta({
                      key: 'monitorDegree',
                      label: 'REDUCTION_MONITORING_ORDER',
                      disabled: true,
                      required: true,
                      style: {
                        gridColumn: '1/3',
                        maxWidth: '420px',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={contentMeta.requestCredit ? Handler.numberWithCommas(contentMeta.requestCredit) : ''}
                    meta={buildMeta({
                      key: 'requestCredit',
                      label: '크레딧 발행 수량',
                      className: 'unit',
                      unitLabel: '팝플크레딧(VRC)',
                      disabled: true,
                      required: true,
                      style: {
                        maxWidth: '420px',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                </>
              ) : (
                <>
                  <SelectBox
                    value={formData.reductionCertSeq}
                    onChange={formChange}
                    meta={buildMeta(formMeta.reductionCertSeq)}
                  ></SelectBox>
                  {/* <SelectBox
                    value={formData.reductionCertSeq}
                    onChange={formChange}
                    meta={buildMeta(formMeta.reductionCertSeq)}
                  ></SelectBox> */}
                  {/* <TextInput
                    value={contentMeta.requestCredit ? Handler.numberWithCommas(contentMeta.requestCredit) : ''}
                    meta={buildMeta({
                      key: 'requestCredit',
                      label: t('QUANTITY_ISSUING_CREDIT'),
                      className: 'unit',
                      unitLabel: t('POP_CREDIT_VRC'),
                      disabled: true,
                      required: true,
                      style: {
                        maxWidth: '420px',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput> */}
                </>
              )}
            </ul>
          </section>
        );
      }
      case 'move': {
        return (
          <>
            <section className='section__container'>
              <header>
                <h2>{t('TRANSTEREE_INFO')}</h2>
              </header>
              <ul className='form-list'>
                <div style={{ display: 'flex', width: '100%', alignItems: 'flex-end' }}>
                  <TextInput
                    value={contentMeta.companyNameAssignee}
                    meta={buildMeta({
                      key: 'companyName',
                      label: t('COMPANY_NAME'),
                      disabled: true,
                      required: true,
                      style: {
                        width: '100%',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <Button
                    disabled={readOnly}
                    onClick={() => {
                      callback('search');
                    }}
                    type='secondary-square'
                    style={{ width: '120px', marginLeft: 16 }}
                    label={t('SEARCH')}
                  ></Button>
                </div>

                <TextInput
                  value={contentMeta.businessNumberAssignee}
                  meta={buildMeta({
                    key: 'businessNumber',
                    label: t('BIZ_NUMBER'),
                    disabled: true,
                    required: true,
                    style: {
                      maxWidth: '420px',
                    },
                  })}
                  onChange={formChange}
                ></TextInput>
                <TextInput
                  value={contentMeta.ceoNameAssignee}
                  meta={buildMeta({
                    key: 'ceoName',
                    label: t('CEO_NAME'),
                    disabled: true,
                    required: true,
                    style: {
                      maxWidth: '420px',
                    },
                  })}
                  onChange={formChange}
                ></TextInput>
                <TextInput
                  value={contentMeta.phoneAssignee}
                  meta={buildMeta({
                    key: 'phone',
                    label: t('MOBILE'),
                    disabled: true,
                    required: true,
                    style: {
                      maxWidth: '420px',
                    },
                  })}
                  onChange={formChange}
                ></TextInput>
                <AddressLocation
                  value={contentMeta.addressAssignee || { daum: null, detail: '' }}
                  onChange={formChange}
                  hideMap={true}
                  meta={buildMeta({
                    required: true,
                    disabled: true,
                    key: 'address',
                    label: t('HEADQ_ADDRESS'),
                    type: 'content',
                    style: {
                      gridColumn: '1/3',
                    },
                  })}
                ></AddressLocation>
              </ul>
              <div style={{ width: 944, marginTop: 40, marginBottom: 40 }} className='border-grey-200'></div>
              <ul className='form-list'>
                <TextInput
                  value={formData.assigneeName}
                  meta={buildMeta(formMeta.assigneeName)}
                  onChange={formChange}
                ></TextInput>
                <TextInput
                  value={formData.assigneePhone}
                  meta={buildMeta(formMeta.assigneePhone)}
                  onChange={formChange}
                ></TextInput>
                <TextInput
                  value={formData.assigneeTeam}
                  meta={buildMeta(formMeta.assigneeTeam)}
                  onChange={formChange}
                ></TextInput>
                <TextInput
                  value={formData.assigneeEmail}
                  meta={buildMeta(formMeta.assigneeEmail)}
                  onChange={formChange}
                ></TextInput>
              </ul>
            </section>
            <section className='section__container'>
              <header>
                <h2>{t('CREDIT_TRANSFER_INFO')}</h2>
              </header>
              <ul className='form-list'>
                {readOnly ? (
                  <>
                    <TextInput
                      value={contentMeta.projectTitle}
                      meta={buildMeta({
                        key: 'projectTitle',
                        label: t('BIZ_NAME'),
                        disabled: true,
                        required: true,
                        style: {
                          gridColumn: '1/3',
                        },
                      })}
                      onChange={formChange}
                    ></TextInput>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gridColumn: '1/3' }}>
                      <TextInput
                        value={Handler.numberWithCommas(formData.amount)}
                        meta={buildMeta(formMeta.amount)}
                        onChange={formChange}
                      ></TextInput>
                    </div>

                    <TextInput
                      value={formData.reason}
                      meta={buildMeta(formMeta.reason)}
                      onChange={formChange}
                    ></TextInput>
                    <TextArea
                      value={formData.reasonDetail}
                      onChange={formChange}
                      meta={buildMeta(formMeta.reasonDetail)}
                    ></TextArea>
                    <MoreFile value={formData.files} onChange={formChange} meta={buildMeta(formMeta.files)}></MoreFile>
                  </>
                ) : (
                  <>
                    <SelectBox
                      value={formData.projectSeq}
                      onChange={formChange}
                      meta={buildMeta(formMeta.projectSeq)}
                    ></SelectBox>
                    <div>
                      <TextInput
                        value={formData.amount}
                        meta={buildMeta(formMeta.amount)}
                        onChange={(value, meta) => {
                          const max = parseInt(contentMeta.reductionNow);
                          const val = value >= max ? max : value;
                          formChange(val, meta);
                        }}
                      ></TextInput>
                      <span style={{ paddingTop: 12, marginLeft: 12, fontSize: 14, display: 'block' }}>
                        * {t('TRANSFERABLE_CREDIT_AMOUNT')} :{' '}
                        <em style={{ color: '#5d3be5' }}>{Handler.numberWithCommas(contentMeta.reductionNow)}</em> VRC
                        {t('CREDIT')}
                      </span>
                    </div>
                    <SelectBox
                      value={formData.reason}
                      onChange={formChange}
                      meta={buildMeta(formMeta.reason)}
                    ></SelectBox>
                    <TextArea
                      value={formData.reasonDetail}
                      onChange={formChange}
                      meta={buildMeta(formMeta.reasonDetail)}
                    ></TextArea>
                    <MoreFile value={formData.files} onChange={formChange} meta={buildMeta(formMeta.files)}></MoreFile>
                  </>
                )}
              </ul>
            </section>
          </>
        );
      }
      case 'offset': {
        return (
          <section className='section__container'>
            <header>
              <h2>{t('WITHDRAWAL_INFO')}</h2>
            </header>
            <ul className='form-list'>
              {readOnly ? (
                <>
                  <TextInput
                    value={contentMeta.projectTitle}
                    meta={buildMeta({
                      key: 'projectTitle',
                      label: 'BIZ_TITLE',
                      disabled: true,
                      required: true,
                      style: {
                        gridColumn: '1/3',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={Handler.numberWithCommas(contentMeta.amount)}
                    meta={buildMeta({
                      key: 'requestCredit',
                      label: 'WITHDRAWAL_QUANTITY',
                      className: 'unit',
                      unitLabel: 'POP_CREDIT_VRC',
                      disabled: true,
                      required: true,
                      style: {
                        maxWidth: '420px',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={formData.reason}
                    meta={buildMeta(formMeta.offsetReason)}
                    onChange={formChange}
                  ></TextInput>
                  {formData.reason === t('DIRECT_INPUT_SPACE') && (
                    <TextInput
                      value={formData.reasonDetail}
                      meta={buildMeta(formMeta.reasonCustom)}
                      onChange={formChange}
                    ></TextInput>
                  )}
                </>
              ) : (
                <>
                  <SelectBox
                    value={formData.projectSeq}
                    onChange={formChange}
                    meta={buildMeta(formMeta.projectSeq)}
                  ></SelectBox>
                  <div>
                    <TextInput
                      value={formData.amount}
                      meta={buildMeta({
                        key: 'amount',
                        label: 'WITHDRAWAL_QUANTITY',
                        className: 'unit',
                        unitLabel: 'POP_CREDIT_VRC',
                        required: true,
                        style: {
                          maxWidth: '490px',
                        },
                      })}
                      onChange={(value, meta) => {
                        const max = parseInt(contentMeta.reductionNow);
                        const val = value >= max ? max : value;
                        formChange(val, meta);
                      }}
                    ></TextInput>
                    <span style={{ paddingTop: 12, marginLeft: 12, fontSize: 14, display: 'block' }}>
                      * {t('WITHDRAWABLE_QUANTITY')} :{' '}
                      <em style={{ color: '#5d3be5' }}>{Handler.numberWithCommas(contentMeta.reductionNow)}</em> VRC
                      {t('CREDIT')}
                    </span>
                  </div>
                  <SelectBox
                    value={formData.reason}
                    onChange={formChange}
                    meta={buildMeta(formMeta.offsetReason)}
                  ></SelectBox>
                  {formData.reason === t('DIRECT_INPUT_SPACE') && (
                    <TextInput
                      value={formData.reasonCustom}
                      meta={buildMeta(formMeta.reasonCustom)}
                      onChange={formChange}
                    ></TextInput>
                  )}
                </>
              )}
            </ul>
          </section>
        );
      }
      case 'retire': {
        return (
          <section className='section__container'>
            <header>
              <h2>{t('DISPOSAL_INFO')}</h2>
            </header>
            <ul className='form-list'>
              {readOnly ? (
                <>
                  <TextInput
                    value={contentMeta.projectTitle}
                    meta={buildMeta({
                      key: 'projectTitle',
                      label: 'BIZ_TITLE',
                      disabled: true,
                      required: true,
                      style: {
                        gridColumn: '1/3',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={Handler.numberWithCommas(contentMeta.amount)}
                    meta={buildMeta({
                      key: 'requestCredit',
                      label: 'DISPOSAL_QUANTITY',
                      className: 'unit',
                      unitLabel: 'POP_CREDIT_VRC',
                      disabled: true,
                      required: true,
                      style: {
                        maxWidth: '420px',
                      },
                    })}
                    onChange={formChange}
                  ></TextInput>
                  <TextInput
                    value={formData.reason}
                    meta={buildMeta(formMeta.reason)}
                    onChange={formChange}
                  ></TextInput>
                  {formData.reason === t('DIRECT_INPUT_SPACE') && (
                    <TextInput
                      value={formData.reasonDetail}
                      meta={buildMeta(formMeta.reasonCustom)}
                      onChange={formChange}
                    ></TextInput>
                  )}
                </>
              ) : (
                <>
                  <SelectBox
                    value={formData.projectSeq}
                    onChange={formChange}
                    meta={buildMeta(formMeta.projectSeq)}
                  ></SelectBox>
                  <div>
                    <TextInput
                      value={formData.amount}
                      meta={buildMeta({
                        key: 'amount',
                        label: 'DISPOSAL_QUANTITY',
                        className: 'unit',
                        unitLabel: 'POP_CREDIT_VRC',
                        required: true,
                        style: {
                          maxWidth: '420px',
                        },
                      })}
                      onChange={(value, meta) => {
                        const max = parseInt(contentMeta.reductionNow);
                        const val = value >= max ? max : value;
                        formChange(val, meta);
                      }}
                    ></TextInput>
                    <span style={{ paddingTop: 12, marginLeft: 12, fontSize: 14, display: 'block' }}>
                      * {t('DISPOSABLE_QUANTITY')} :{' '}
                      <em style={{ color: '#5d3be5' }}>{Handler.numberWithCommas(contentMeta.reductionNow)}</em> VRC
                      {t('CREDIT')}
                    </span>
                  </div>
                  <SelectBox
                    value={formData.retireReason}
                    onChange={formChange}
                    meta={buildMeta(formMeta.retireReason)}
                  ></SelectBox>
                  {formData.reason === t('DIRECT_INPUT_SPACE') && (
                    <TextInput
                      value={formData.retireReasonCustom}
                      meta={buildMeta(formMeta.retireReasonCustom)}
                      onChange={formChange}
                    ></TextInput>
                  )}
                </>
              )}
            </ul>
          </section>
        );
      }
      default:
        return null;
    }
  };

  return (
    <article className='content-project'>
      <section className='section__container'>
        <header>
          <h2>{mode === 'move' ? t('TRANSFERER_INFO') : t('APPLICANT')}</h2>
        </header>
        <ul className='form-list'>
          <TextInput
            value={contentMeta.companyName}
            meta={buildMeta({
              key: 'companyName',
              label: t('COMPANY_NAME'),
              disabled: true,
              required: true,
              style: {
                maxWidth: '420px',
              },
            })}
            onChange={formChange}
          ></TextInput>
          <TextInput
            value={contentMeta.businessNumber}
            meta={buildMeta({
              key: 'businessNumber',
              label: t('BIZ_NUMBER'),
              disabled: true,
              required: true,
              style: {
                maxWidth: '420px',
              },
            })}
            onChange={formChange}
          ></TextInput>
          <TextInput
            value={contentMeta.ceoName}
            meta={buildMeta({
              key: 'ceoName',
              label: t('CEO_NAME'),
              disabled: true,
              required: true,
              style: {
                maxWidth: '420px',
              },
            })}
            onChange={formChange}
          ></TextInput>
          <TextInput
            value={contentMeta.phone}
            meta={buildMeta({
              key: 'phone',
              label: 'CONTACT_MOBILE',
              disabled: true,
              required: true,
              style: {
                maxWidth: '420px',
              },
            })}
            onChange={formChange}
          ></TextInput>
          {contentMeta.address && (
            <Address
              value={contentMeta.address}
              onChange={formChange}
              meta={buildMeta({
                required: true,
                disabled: true,
                key: 'address',
                label: t('HEADQ_ADDRESS'),
                type: 'content',
                style: {
                  gridColumn: '1/3',
                },
              })}
            ></Address>
          )}
        </ul>
        <div style={{ width: 944, marginTop: 40, marginBottom: 40 }} className='border-grey-200'></div>
        <ul className='form-list'>
          <TextInput
            value={mode == 'move' ? formData.assignorName : formData.name}
            meta={buildMeta(mode == 'move' ? formMeta.assignorName : formMeta.name)}
            onChange={formChange}
          ></TextInput>
          <TextInput
            value={mode == 'move' ? formData.assignorPhone : formData.phone}
            meta={buildMeta(mode == 'move' ? formMeta.assignorPhone : formMeta.phone)}
            onChange={formChange}
          ></TextInput>
          <TextInput
            value={mode == 'move' ? formData.assignorTeam : formData.team}
            meta={buildMeta(mode == 'move' ? formMeta.assignorTeam : formMeta.team)}
            onChange={formChange}
          ></TextInput>
          <TextInput
            value={mode == 'move' ? formData.assignorEmail : formData.email}
            meta={buildMeta(mode == 'move' ? formMeta.assignorEmail : formMeta.email)}
            onChange={formChange}
          ></TextInput>
        </ul>
      </section>
      {infoByMode(mode)}
    </article>
  );
};

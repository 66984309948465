import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';

export const ModalConfirm = (props) => {
  const { title, content, okLabel, cancelLabel, requestAction, onClose } = props;
  const { t } = useTranslation();

  const handlerOkAction = () => {
    requestAction();
    onClose();
  };

  return (
    <article className='credit-action-modal'>
      <header>
        <h4>{title}</h4>
        <div
          className='ico close-small'
          onClick={() => {
            onClose();
          }}
        ></div>
      </header>
      <section className='text-center pt-8 pb-6 whitespace-pre-wrap'>
        <p>{content}</p>
      </section>
      <section className='button__container' style={{ marginBottom: 40 }}>
        <Button
          type='info'
          style={{ width: '180px', height: '50px', marginLeft: 0 }}
          onClick={onClose}
          label={cancelLabel || t('CANCEL')}
        ></Button>
        <Button
          style={{ width: '180px', height: '50px', marginLeft: 12 }}
          onClick={() => handlerOkAction()}
          label={okLabel || t('CONFIRM')}
        ></Button>
      </section>
    </article>
  );
};

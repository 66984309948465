import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { Button, ContentProject, ModalProjectAction, PageHeader, Stepper } from '../../components';
import { ContentContext, SnackbarContext, UserContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';
import { AREA, SUBAREA } from '../../options';
import { modalStyle } from '../../styles/style';

export const ProjectWritingPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { ERROR } = useLabel();
  const { slug } = useParams();
  const { userInfo } = useContext(UserContext);
  const { businessInfo, methodologyInfo } = useContext(ContentContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [step, setStep] = useState(0);
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      address: userInfo?.address,
      companyName: userInfo?.companyName,
      ceoName: userInfo?.ceoName,
      businessNumber: userInfo?.businessNumber,
      businessFile: userInfo?.businessFile,
      site: '',
      fax: '',
      name: '',
      phone: '',
      department: '',
      email: '',
      isExported: false,
      title: '',
      area: '',
      subArea: '',
      startDate: '',
      endDate: '',
      reductionStartDate: '',
      reductionEndDate: '',
      methodologySeq: '',
      purpose: '',
      content: '',
      location: {
        addr1: '',
        addr2: '',
      },
      isDomestic: false,
      workplaceImages: [],
      reductionBase: '',
      reductionBusiness: '',
      reductionLeak: '',
      reduction: '',
      monitoringType: 'Document',
      monitoringPlan: '',
      beforeImages: [],
      afterImages: [],
      evidence: [],
      proofFile: '',
      //타기관
      exportedTitle: '',
      exportedOrgan: '',
      exportedDate: '',
    },
    {
      companyName: {
        key: 'companyName',
        label: 'COMPANY_NAME',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      ceoName: {
        key: 'ceoName',
        label: 'CEO_NAME',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      businessNumber: {
        key: 'businessNumber',
        label: 'BIZ_NUMBER',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: 'BIZ_CERTIFICATION',
        path: 'project/editor',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      address: {
        required: true,
        key: 'address',
        label: 'HEADQ_ADDRESS',
        type: 'content',
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      site: {
        key: 'site',
        label: 'HOMEPAGE',
        disabled: true,

        style: {
          maxWidth: '420px',
        },
      },
      fax: {
        key: 'fax',
        label: 'FAX',
        disabled: true,

        style: {
          maxWidth: '420px',
        },
      },
      name: {
        key: 'name',
        label: 'CONTACT_NAME',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      phone: {
        key: 'phone',
        label: 'MOBILE',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      department: {
        key: 'department',
        label: 'DEPARTMENT',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      email: {
        key: 'email',
        label: 'EMAIL',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      isExported: {
        key: 'isExported',
        style: {
          gridColumn: '1/3',
        },
        label: 'OTHER_ORG',
        subLabel: 'OTHER_ORG_MSG',
        required: true,
        menus: [
          {
            label: 'NO',
            value: false,
          },
          {
            label: 'OTHER_ORG_REQUIRED',
            value: true,
          },
        ],
      },
      title: {
        key: 'title',
        label: 'PROJECT',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      area: {
        key: 'area',
        label: 'BIZ_TYPE',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: AREA,
      },
      subArea: {
        key: 'subArea',
        label: `BIZ_TYPE`,
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: SUBAREA,
      },
      reductionStartDate: {
        key: 'reductionStartDate',
        label: 'REDUCTION_START_DATE',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      reductionEndDate: {
        key: 'reductionEndDate',
        label: 'REDUCTION_END_DATE',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      methodologySeq: {
        key: 'methodologySeq',
        label: 'METHODOLOGY',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        value: '',
        menus: [],
      },
      purpose: {
        key: 'purpose',
        label: 'PURPOSE',
        maxSize: 500,
        style: {
          gridColumn: '1/3',
        },
        required: true,
      },
      content: {
        key: 'content',
        label: 'CONTENT',
        path: 'dummy',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        className: 'content',
      },
      location: {
        required: true,
        key: 'location',
        label: 'BIZ_SITE',
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      workplaceImages: {
        key: 'workplaceImages',
        type: 'image',
        label: 'BIZ_SITE_PHOTO',
        subLabel: 'MAXIMUM_ATTACHMENTS',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      reductionBase: {
        key: 'reductionBase',
        label: 'BASELINE_EMISSION',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {},
      },
      reductionBusiness: {
        key: 'reductionBusiness',
        label: 'BIZ_EMISSION',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {},
      },
      reductionLeak: {
        key: 'reductionLeak',
        label: 'LEAKAGE',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {},
      },
      reduction: {
        key: 'reduction',
        label: 'GHG_REDUCTION_QUANTITY',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {},
      },
      monitoringType: {
        key: 'monitoringType',
        style: {
          gridColumn: '1/3',
        },
        label: 'MONITORING_PLAN_METHOD',
        // subLabel: `${PROJECT['']}\n
        // ${PROJECT['PAPER_MONITORING_MSG']}\n
        // ${PROJECT['SYSTEM_MONITORING_MSG']}`,
        required: true,
        menus: [
          {
            label: 'PAPER_MONITORING',
            value: 'Document',
          },
          {
            label: 'SYSTEM_MONITORING',
            value: 'System',
          },
        ],
      },
      monitoringPlan: {
        key: 'monitoringPlan',
        label: 'REDUCTION_MONITORING_PLAN_EXP',
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      beforeImages: {
        key: 'beforeImages',
        type: 'image',
        label: 'BEFORE_BIZ_IMAGE',
        subLabel: 'MAXIMUM_ATTACHMENTS',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      afterImages: {
        key: 'afterImages',
        type: 'image',
        label: 'AFTER_BIZ_IMAGE',
        subLabel: 'MAXIMUM_ATTACHMENTS',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      proofFile: {
        type: 'file',
        key: 'proofFile',
        label: 'BIZ_CERTIFICATION',
        path: 'project/editor',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      exportedTitle: {
        key: 'exportedTitle',
        label: 'OTHER_ORG_NAME',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      exportedOrgan: {
        key: 'exportedOrgan',
        label: 'REGISTRAR',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      exportedDate: {
        key: 'exportedDate',
        label: 'REGISTRATION_DATE',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    isSameAddress: false,
    methodologyYear: 0,
    termsAggree: false,
    termsList: [],
    methodologyTitle: '',
    calculateFile: '',
    monitoringCalcFile: '',
  });
  const [holdTermsCheck, setHoldTermsCheck] = useState(false);
  const [originData, setOriginData] = useState(null);
  const [holdUpdateData, setHoldUpdateData] = useState(false); //init data useEffect update hold용
  const [forceReadOnly, setForceReadOnly] = useState(false);

  //보완 완료용 모달
  const [actionOpen, setActionOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);

  //hook
  useEffect(() => {
    if (userInfo) {
      let new_data = _.clone(formData);
      setFormData({
        ...new_data,
        address: userInfo.address,
        companyName: userInfo.companyName,
        ceoName: userInfo.ceoName,
        businessNumber: userInfo.businessNumber,
        businessFile: userInfo.businessFile,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (slug && methodologyInfo) {
      API.Project.GetWriting(slug).then(
        (res) => {
          let result = res.data;
          console.log('GetWriting result', result);
          setOriginData(result);
        },
        (err) => {
          console.log('err', err);
          history.push('/error');
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  }, [slug, methodologyInfo]);

  useEffect(() => {
    if (originData) {
      //step 1 check terms mapper
      console.log('originData', originData);
      let init_termsAgree = false;
      let init_methodologyTitle = '';
      if (originData?.projectDocTerms?.length > 0) {
        setHoldTermsCheck(true);
        init_termsAgree = true;
      }

      //증빙서류 자료는 methodology에 담겨있는 mapper base로 진행한다.
      let originMethodology = null;
      if (originData.project && originData.project.methodology.seq) {
        console.log('originDat', originData);
        originMethodology = Handler.getMethodologyBySeq(methodologyInfo, originData.project.methodology.seq);

        //증빙서류 업데이트
        let evidence_label = Handler.buildEvidenceLabel(originMethodology, false);
        let new_formMeta = _.clone(formMeta);
        new_formMeta.evidence.label = evidence_label;
        setFormMeta(new_formMeta);
        init_methodologyTitle = originData.project.methodology.title;
      }

      let originEvidence = null;
      if (originData?.projectDocEvidence) {
        originEvidence = Handler.getEvidenceByMapper(originData?.projectDocEvidence, false);
      }

      //update logic
      let new_contentMeta = _.clone(contentMeta);
      new_contentMeta.termsAggree = init_termsAgree;
      new_contentMeta.methodologyTitle = init_methodologyTitle;
      new_contentMeta.isSameAddress = originData.isCheckedArea;

      const getInitLocation = (data) => {
        if (data.location) {
          return data.location;
        } else {
          return {
            addr1: '',
            addr2: '',
          };
        }
      };

      setContentMeta(new_contentMeta);
      setHoldUpdateData(true);
      setFormData({
        ...formData,
        address: userInfo.address,
        companyName: userInfo.companyName,
        ceoName: userInfo.ceoName,
        businessNumber: userInfo.businessNumber,
        businessFile: userInfo.businessFile,
        site: userInfo.site,
        fax: userInfo.fax,
        name: originData.name,
        phone: originData.phone,
        department: originData.department,
        email: originData.email,
        isExported: originData.isExported,
        title: originData.title,
        area: originMethodology ? originMethodology.area : '',
        subArea: originMethodology ? originMethodology.subArea : '',
        methodologySeq: originMethodology ? originMethodology.seq : '',
        reductionStartDate: originData.reductionStartDate,
        reductionEndDate: originData.reductionEndDate,
        purpose: originData.purpose,
        content: originData.content,
        isDomestic: originData.isDomestic,
        location: getInitLocation(originData),
        reductionBase: originData.reductionBase,
        reductionBusiness: originData.reductionBusiness,
        reductionLeak: originData.reductionLeak,
        reduction: originData.reduction,
        monitoringType: originData.monitoringType,
        monitoringPlan: originData.monitoringPlan,
        beforeImages: originData.beforeImages ? originData.beforeImages : [],
        afterImages: originData.afterImages ? originData.afterImages : [],
        workplaceImages: originData.workplaceImages ? originData.workplaceImages : [],
        evidence: originEvidence,
        proofFile: originData.proofFile ? originData.proofFile : '',
        exportedTitle: originData.exportedTitle,
        exportedOrgan: originData.exportedOrgan,
        exportedDate: originData.exportedDate,
      });
    }
  }, [originData]);

  useEffect(() => {
    if (!holdUpdateData && !contentMeta.isSameAddress) {
      let new_data = _.clone(formData);
      new_data.location = {
        addr1: '',
        addr2: '',
      };
      setFormData(new_data);
    }
  }, [formData.isDomestic]);

  useEffect(() => {
    if (formData.reductionBase && formData.reductionBusiness && formData.reductionLeak) {
      if (
        Number.isInteger(Number(formData.reductionBase)) &&
        Number.isInteger(Number(formData.reductionBusiness)) &&
        Number.isInteger(Number(formData.reductionLeak))
      ) {
        formChange(
          Number(formData.reductionBase) - Number(formData.reductionBusiness) - Number(formData.reductionLeak),
          'reduction',
        );
      } else {
        formChange('', 'reduction');
      }
    } else {
      formChange('', 'reduction');
    }
  }, [formData.reductionBase, formData.reductionBusiness, formData.reductionLeak]);

  useEffect(() => {
    let new_meta = _.clone(formMeta);
    setFormMeta(new_meta);

    if (!holdUpdateData) {
      let new_data = _.clone(formData);
      new_data.subArea = '';
      new_data.methodologySeq = '';
      setFormData(new_data);
    }
  }, [formData.area, businessInfo]);

  useEffect(() => {
    let new_meta = _.clone(formMeta);
    let subArea = formData.subArea;
    let area = formData.area;

    /* 세부분야별 방법론 목록 */
    if (subArea && methodologyInfo) {
      let new_methodology_menus = Handler.buildMethodologyMenus(methodologyInfo, area, subArea);
      new_meta.methodologySeq.menus = new_methodology_menus;
    } else {
      new_meta.methodologySeq.menus = [];
    }
    setFormMeta(new_meta);

    if (!holdUpdateData) {
      let new_data = _.clone(formData);
      new_data.methodologySeq = '';
      setFormData(new_data);
    }
  }, [formData.subArea, methodologyInfo]);

  //방법론 변경되었을 때 로직
  useEffect(() => {
    if (formData.methodologySeq) {
      let target_methodology = Handler.getMethodologyBySeq(methodologyInfo, formData.methodologySeq);
      console.log('target_methodology', target_methodology);
      console.log('originData', originData);
      if (target_methodology) {
        //방법론 약관 셋팅
        let terms_value = Handler.buildTermsValue(target_methodology);
        let new_contentMeta = _.clone(contentMeta);
        console.log('terms_value', terms_value);
        console.log('new_contentMeta', new_contentMeta);
        new_contentMeta['termsList'] = terms_value;
        new_contentMeta['methodologyYear'] = target_methodology.expiredYear;
        new_contentMeta['methodologyTitle'] = target_methodology.title;
        new_contentMeta['calculateFile'] = target_methodology.calculateFile;
        new_contentMeta['monitoringCalcFile'] = target_methodology.monitoringCalcFile;
        console.log('new_contentMeta', new_contentMeta);
        setContentMeta(new_contentMeta);
        // 방법론 증빙서류 셋팅
        if (!holdUpdateData) {
          let evidence_value = Handler.buildEvidenceValue(target_methodology, true);
          let evidence_label = Handler.buildEvidenceLabel(target_methodology, true);
          console.log('evidence_value', evidence_value);
          console.log('evidence_label', evidence_label);
          formChange(evidence_value, 'evidence');
          let new_formMeta = _.clone(formMeta);
          new_formMeta.evidence.label = evidence_label;
          setFormMeta(new_formMeta);
        }
      }
    } else {
      let new_contentMeta = _.clone(contentMeta);
      new_contentMeta['termsList'] = [];
      new_contentMeta['methodologyYear'] = 0;
      new_contentMeta['methodologyTitle'] = '';
      setContentMeta(new_contentMeta);
      let new_formMeta = _.clone(formMeta);
      new_formMeta.evidence.label = [];
      setFormMeta(new_formMeta);
    }
  }, [formData.methodologySeq]);

  useEffect(() => {
    if (holdUpdateData) setHoldUpdateData(false);
  }, [holdUpdateData]);

  useEffect(() => {
    if (holdTermsCheck) {
      setHoldTermsCheck(false);
    } else {
      onChangeContentMeta(false, 'termsAggree');
    }
  }, [contentMeta.termsList]);

  useEffect(() => {
    if (contentMeta.isSameAddress) {
      let new_data = _.clone(formData);
      new_data.isDomestic = true;
      new_data.location = formData.address;
      setFormData(new_data);
    }
  }, [contentMeta.isSameAddress]);

  useEffect(() => {
    document.body.scrollTop = 0;
    if (step === 2) {
      // readonly setup;
      setForceReadOnly(true);
    } else {
      // origin
      setForceReadOnly(false);
    }
  }, [step]);

  //event
  const onCahngeStep = (new_value) => {
    setStep(new_value);
  };

  const onChangeContentMeta = (newValue, key) => {
    setContentMeta({ ...contentMeta, [key]: newValue });
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const requestProjectWrite = (body, type, slug) => {
    if (slug) {
      API.Project.PutWriting(slug, body).then(
        (res) => {
          if (type === 'next') {
            setStep(step + 1);
          } else {
            updateSnackbar({
              type: 'success',
              open: true,
              message: t('SAVED'),
            });
          }
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      API.Project.PostNew(body).then(
        (res) => {
          const result = res.data;
          if (res) {
            history.replace(`/project/writing/${result.seq}`);
          }
          if (type === 'next') {
            setStep(step + 1);
          } else {
            updateSnackbar({
              type: 'success',
              open: true,
              message: t('SAVED'),
            });
          }
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  };

  //api
  const requestSave = (type) => {
    // validation check
    const VALIDATION_LIST = [
      [
        'name',
        'address',
        'phone',
        'email',
        `${formData.isExported && 'exportedTitle'}`,
        `${formData.isExported && 'exportedOrgan'}`,
        `${formData.isExported && 'exportedDate'}`,
        'title',
        'methodologySeq',
        'purpose',
        'content',
        'location',
        'reductionStartDate',
        'reductionEndDate',
      ],
      [
        'reductionBase',
        'reductionBusiness',
        'reductionLeak',
        'evidence',
        'monitoringType',
        'monitoringPlan',
        `${formData.isExported && 'proofFile'}`,
      ],
      [],
      [],
    ];
    console.log('requestSave step', step);

    let isValidation = Handler.formValidation(formData, VALIDATION_LIST[step]);
    const isValid = Handler.formValidationKey(formData, formMeta, VALIDATION_LIST[step]);
    console.log('isValidation', isValidation);
    console.log('isValid', isValid);

    if (!isValidation) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: `${ERROR['REQUIRED_INFO']}\n - ${ERROR['MISSING_CONTENT']}: ${isValid.inValidKeys.map(
          (el) => ` ${t(el)}`,
        )}`,
      });
      return;
    }
    // step meta check
    if (step === 0) {
      if (!formData.location.addr1) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR['CHECK_LOCATION'],
        });
        return;
      }
      if (contentMeta.termsList.length > 0 && !contentMeta.termsAggree) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR['CHECK_AGREE'],
        });
        return;
      }
    }

    if (step === 1) {
      console.log('evidence', formData.evidence);
      let isEmpty = false;
      for (let i in formData.evidence) {
        let target = formData.evidence[i];
        if (!target.data) {
          isEmpty = true;
        }
      }
      if (isEmpty) {
        updateSnackbar({
          type: 'error',
          open: true,
          message: ERROR['CHECK_SUPPORT_DOC'],
        });
        return;
      }
    }

    let body = {
      address: formData.address,
      site: formData.site,
      fax: formData.fax,
      name: formData.name,
      phone: formData.phone,
      department: formData.department,
      email: formData.email,
      isCheckedArea: contentMeta.isSameAddress,
      isExported: formData.isExported,
      exportedTitle: formData.exportedTitle,
      exportedOrgan: formData.exportedOrgan,
      exportedDate: formData.exportedDate,
      title: formData.title,
      methodologySeq: Number(formData.methodologySeq),
      purpose: formData.purpose,
      content: formData.content,
      location: JSON.stringify(formData.location),
      // area: formData.area,
      // subArea: formData.subArea,
      reductionStartDate: formData.reductionStartDate,
      reductionEndDate: formData.reductionEndDate,
      isDomestic: formData.isDomestic,
      workplaceImages: formData.workplaceImages.length > 0 ? formData.workplaceImages : [],

      reductionBase: formData.reductionBase || 0,
      reductionBusiness: formData.reductionBusiness,
      reductionLeak: formData.reductionLeak || 0,
      reduction: formData.reduction || 0,
      monitoringType: formData.monitoringType || 'Document',
      monitoringPlan: formData.monitoringPlan,
      beforeImages: formData.beforeImages.length > 0 ? formData.beforeImages : [],
      afterImages: formData.afterImages.length > 0 ? formData.afterImages : [],
      proofFile: formData.proofFile,
      evidence: formData.evidence.length > 0 ? formData.evidence : [],
    };
    console.log('PUT Body', body);

    if (step === 2) {
      if (type === 'save') {
        requestProjectWrite(body, type, slug);
      } else {
        API.Project.PostNextAction(slug, {
          nextAction: 'ProjectSubmit',
        }).then(
          (res) => {
            let result = res.data;
            console.log('PostNextAction  result', result);
            history.push('/request_complete?type=project');
          },
          (err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          },
        );
      }
    } else {
      requestProjectWrite(body, type, slug);
    }
  };

  const handleModalRequest = (body) => {
    if (body.nextAction === 'RequestProjectComplete') {
      requestSave('next');
    } else {
      API.Project.PostNextAction(slug, body).then(
        (res) => {
          let result = res.data;
          console.log('PostNextAction  result', result);
          closeActionModal();
          history.push('/project/list');
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    }
  };

  console.log('formData', formData);
  console.log('formMeta', formMeta);
  console.log('contentMeta', contentMeta);
  console.log('originData', originData);
  return (
    <article className='basic-page'>
      <PageHeader title={t('PROJECT')} type='project' />
      <article className='content__wrapper block'>
        <Stepper
          meta={{ type: 'round-writing', style: { marginTop: 40 } }}
          value={step}
          label={[t('OVERVIEW'), t('ESTIMATED_DOCUMENT'), t('FINAL')]}
          onChange={onCahngeStep}
        ></Stepper>
        <ContentProject
          step={step}
          formData={formData}
          formMeta={formMeta}
          formChange={formChange}
          setFormData={setFormData}
          setFormMeta={setFormMeta}
          contentMeta={contentMeta}
          contentMetaChange={onChangeContentMeta}
          readOnly={forceReadOnly}
          type={originData ? originData.type : ''}
        />
        <section className='button__container' style={{}}>
          <div className='prev__box'>
            <Button
              disabled={step === 0}
              type={'secondary'}
              style={{ width: '96px', marginRight: 12 }}
              onClick={() => {
                setStep(step - 1);
              }}
              label={t('PREV')}
            ></Button>
          </div>
          <Button
            type={'secondary'}
            style={{ width: '240px', marginRight: 12 }}
            onClick={() => {
              requestSave('save');
            }}
            label={t('TEMP_SAVE')}
          ></Button>
          {originData && originData.type === 'Improve' ? (
            <Button
              style={{ width: '240px' }}
              onClick={() => {
                if (step === 2) {
                  console.log('check');
                  setSelectedAction({
                    seq: slug,
                  });
                  setSelectedNextAction('ImproveProjectComplete');
                  setActionOpen(true);
                } else {
                  requestSave('next');
                }
              }}
              label={step === 2 ? t('COMPLEMENT_APPLY') : t('TEMP_SAVE_NEXT')}
            ></Button>
          ) : (
            <Button
              style={{ width: '240px' }}
              onClick={() => {
                if (step === 2) {
                  setSelectedNextAction('RequestProjectConfirm');
                  setActionOpen(true);
                } else {
                  requestSave('next');
                }
              }}
              label={step === 2 ? t('PROJECT_SUBMIT') : t('TEMP_SAVE_NEXT')}
            ></Button>
          )}
        </section>
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalProjectAction
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalProjectAction>
        </Box>
      </Modal>
    </article>
  );
};
